import React, { useContext, useState } from 'react'
import {
  Avatar,
  Button,
  Drawer,
  Form,
  Input,
  message,
  Upload,
  Image,
  Menu,
} from 'antd'
import SubMenu from 'antd/lib/menu/SubMenu'
import { useHistory } from 'react-router-dom'
import {
  UserOutlined,
  LogoutOutlined,
  PieChartOutlined,
  FileDoneOutlined,
  TeamOutlined,
  IdcardOutlined,
  ToolOutlined,
  TagsOutlined,
  BarsOutlined,
  PlusCircleOutlined,
  ControlOutlined,
  BookOutlined,
  AuditOutlined,
  ApartmentOutlined,
  QuestionCircleOutlined,
  InboxOutlined,
  StarOutlined,
} from '@ant-design/icons'
import {
  Container,
  ActionButtons,
  ProfileButton,
  ProfilePhoto,
  DropDownMenu,
  MenuItem,
  Logo,
} from './styles'
import AuthContext, { TOKEN_API } from '../../contexts/auth'
import api from 'services/api'
import { MaskPhone } from 'services/utils'
import main from '../../assets/image/logo.svg'

const tailLayout = {
  wrapperCol: { offset: 6, span: 16 },
}

function Header() {
  const { logout, user, setUser } = useContext(AuthContext)
  const [userForm] = Form.useForm()
  const [passwordForm] = Form.useForm()
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [drawerPasswordVisible, setDrawerPasswordVisible] = useState(false)
  const [buttonVerify, setButtonVerify] = useState(true)
  const [upload, setUpload] = useState(true)
  const [image, setImage] = useState('')
  const token = localStorage.getItem(TOKEN_API)

  const onClose = () => {
    setDrawerVisible(false)
    userForm.resetFields()
  }

  const onClosePassword = () => {
    setDrawerPasswordVisible(false)
    passwordForm.resetFields()
  }

  const onSave = () => {
    const data = userForm.getFieldsValue()
    data.image = image
    api
      .put(`updateme`, data)
      .then((res) => {
        message.success('Dados atualizados com sucesso!')
        setUser(res.data)
        onClose()
      })
      .catch((error) => {
        console.log(error)
        message.error('Erro ao salvar os dados, tente novamente.')
      })
  }

  const onSavePassword = () => {
    const data = passwordForm.getFieldValue()
    data.email = user.email
    console.log(data)
    api
      .put(`updatepassword`, data)
      .then(() => {
        message.success('Dados atualizados com sucesso!')
        onClosePassword()
      })
      .catch((e) => {
        if (e.response.data.errors) {
          e.response.data.errors.forEach((info) => {
            message.error(info.message)
          })
        } else {
          message.error(e.response.data.error)
        }
      })
  }

  const beforeUpload = (file) => {
    const validFormat = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!validFormat) {
      message.error('Envie somente arquivos do formato JPG/PNG')
      return false
    }
    return validFormat
  }
  const history = useHistory()
  return (
    <Container>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Logo src={main} />
        <Menu
          theme='light'
          defaultActiveFirst
          defaultSelectedKeys={['1']}
          mode='horizontal'
          style={{ minWidth: 950, marginLeft: 30 }}
        >
          <Menu.Item
            key='dashboard'
            onClick={() => {
              history.push('/dashboard')
            }}
            defaultChecked
            icon={<PieChartOutlined />}
          >
            Dashboard
          </Menu.Item>
          <Menu.Item
            key='os'
            onClick={() => history.push('/os')}
            icon={<ToolOutlined />}
          >
            Ordens de Serviços
          </Menu.Item>
          <Menu.Item
            key='contratos'
            onClick={() => history.push('/contratos')}
            icon={<FileDoneOutlined />}
          >
            Contratos
          </Menu.Item>
          <SubMenu
            key='cadastros'
            icon={<PlusCircleOutlined />}
            title='Cadastros'
          >
            <Menu.Item
              key='clientes'
              onClick={() => history.push('/clientes')}
              icon={<TeamOutlined />}
            >
              Clientes
            </Menu.Item>
            <Menu.Item
              key='itens'
              onClick={() => history.push('/itens')}
              icon={<BarsOutlined />}
            >
              Itens
            </Menu.Item>
            <Menu.Item
              key='pecas'
              onClick={() => history.push('/pecas')}
              icon={<InboxOutlined />}
            >
              Peças
            </Menu.Item>
            <Menu.Item
              key='tiposervico'
              onClick={() => history.push('/tiposervico')}
              icon={<BarsOutlined />}
            >
              Tipos de Serviço
            </Menu.Item>
            <Menu.Item
              key='marcas'
              onClick={() => history.push('/marcas')}
              icon={<TagsOutlined />}
            >
              Marcas
            </Menu.Item>
            <Menu.Item
              key='tipoequipamento'
              onClick={() => history.push('/tipoequipamentos')}
              icon={<TagsOutlined />}
            >
              Tipos de Equipamentos
            </Menu.Item>
            <Menu.Item
              key='usuarios'
              onClick={() => history.push('/usuarios')}
              icon={<IdcardOutlined />}
            >
              Usuários
            </Menu.Item>
            <Menu.Item
              key='perfil'
              onClick={() => history.push('/perfis')}
              icon={<ControlOutlined />}
            >
              Perfil de Acesso
            </Menu.Item>
            <Menu.Item
              key='metas'
              onClick={() => history.push('/metas')}
              icon={<StarOutlined />}
            >
              Metas
            </Menu.Item>
          </SubMenu>
          <SubMenu key='pmocs' icon={<ApartmentOutlined />} title='PMOC'>
            <Menu.Item
              key='pmoc'
              onClick={() => history.push('/pmocs')}
              icon={<AuditOutlined />}
            >
              PMOC
            </Menu.Item>
            <Menu.Item
              key='questions'
              onClick={() => history.push('/questions')}
              icon={<QuestionCircleOutlined />}
            >
              Serviços
            </Menu.Item>
          </SubMenu>
          <SubMenu key='relatorios' icon={<BookOutlined />} title='Relatórios'>
            <SubMenu key='comissao' title='Comissão'>
              <Menu.Item
                key='relatorios_tecnicos'
                onClick={() => history.push('/relatorios')}
                // icon={<WalletOutlined />}
              >
                Comissão Líquida Simplificada
              </Menu.Item>
            </SubMenu>
            <SubMenu key='relatorioContratos' title='Contratos'>
              <Menu.Item
                key='relatorio_consumo_items'
                onClick={() => history.push('/relatorio-consumo-items')}
                // icon={<TagsOutlined />}
              >
                Saldo de Item por Contrato
              </Menu.Item>
              <Menu.Item
                key='relatorio_consumo_contratos'
                onClick={() => history.push('/relatorio-saldos-contratos')}
                // icon={<TagsOutlined />}
              >
                Saldo de Contrato
              </Menu.Item>
              <Menu.Item
                key='relatorio_tempo_contratos'
                onClick={() => history.push('/relatorio-tempo-contratos')}
                // icon={<ClockCircleOutlined />}
              >
                Vencimento de Contratos
              </Menu.Item>
            </SubMenu>
            <SubMenu key='equipamentos' title='Equipamentos'>
              <Menu.Item
                key='relatorio_equipamentos'
                onClick={() => history.push('/relatorio-equipamentos')}
                // icon={<InboxOutlined />}
              >
                Listagem de Equipamentos
              </Menu.Item>
              <Menu.Item
                key='relatorio_servicos_equipamentos'
                onClick={() => history.push('/relatorio-servicos-equipamentos')}
              >
                Histórico de Serviços
              </Menu.Item>
            </SubMenu>
            <SubMenu key='financeiros' title='Financeiros'>
              <Menu.Item
                key='relatorios_locais'
                onClick={() => history.push('/relatorio-locais')}
                // icon={<SmileOutlined />}
              >
                Faturamento de Serviços por Local
              </Menu.Item>
              <Menu.Item
                key='relatorios_clientes'
                onClick={() => history.push('/relatorio-clientes')}
                // icon={<SmileOutlined />}
              >
                Faturamento por Cliente
              </Menu.Item>
              <Menu.Item
                key='relatorios_pecas'
                onClick={() => history.push('/relatorio-pecas')}
                // icon={<InboxOutlined />}
              >
                Faturamento por Peça
              </Menu.Item>
              <Menu.Item
                key='relatorios_contratos'
                onClick={() => history.push('/relatorio-contratos')}
                // icon={<FileDoneOutlined />}
              >
                Faturamento Simplificado por Contrato
              </Menu.Item>
            </SubMenu>
            <SubMenu key='servicos' title='Serviços'>
              <Menu.Item
                key='relatorios_items_agrupados'
                onClick={() => history.push('/relatorio-items-agrupados')}
                // icon={<TagsOutlined />}
              >
                Itens de Serviços Agrupados
              </Menu.Item>
              <Menu.Item
                key='relatorios_items_detalhados'
                onClick={() => history.push('/relatorio-items-detalhados')}
                // icon={<TagsOutlined />}
              >
                Itens de Serviços Detalhados
              </Menu.Item>
              <Menu.Item
                key='relatorios_ordens'
                onClick={() => history.push('/relatorio-ordens')}
                // icon={<ToolOutlined />}
              >
                Ordens de Serviços por Status
              </Menu.Item>
              <Menu.Item
                key='planilha-atendimentos'
                onClick={() => history.push('/planilha-atendimentos')}
                // icon={<ToolOutlined />}
              >
                Planilha de Atendimentos
              </Menu.Item>
            </SubMenu>
          </SubMenu>
          <Menu.Item
            key='organizacao'
            onClick={() => history.push('/organizacao')}
            icon={<FileDoneOutlined />}
          >
            Organização
          </Menu.Item>
        </Menu>
      </div>

      <ActionButtons>
        <ProfileButton
          placement='bottomCenter'
          trigger={['click']}
          overlay={() => (
            <DropDownMenu>
              <MenuItem
                key='1'
                icon={<UserOutlined />}
                onClick={() => {
                  setDrawerVisible(true)
                  userForm.setFieldsValue(user)
                }}
              >
                Conta
              </MenuItem>
              <MenuItem
                key='3'
                icon={<LogoutOutlined />}
                onClick={async () => {
                  await logout().then(() => history.push('/'))
                }}
              >
                Sair
              </MenuItem>
            </DropDownMenu>
          )}
        >
          <div>
            {user?.image ? (
              <ProfilePhoto src={<Image preview={false} src={user.image} />} />
            ) : (
              <ProfilePhoto icon={<UserOutlined />} />
            )}

            {user?.name}
          </div>
        </ProfileButton>
      </ActionButtons>
      <Drawer
        title='Configurações da conta'
        width={550}
        onClose={onClose}
        visible={drawerVisible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} form={userForm}>
          <Form.Item {...tailLayout}>
            <Avatar size={128} icon={<UserOutlined />} src={user?.image} />
          </Form.Item>
          <Form.Item
            label='Nome'
            name='name'
            rules={[
              {
                required: true,
                message: 'Por favor, o nome deve ser informado',
              },
            ]}
          >
            <Input size='large' />
          </Form.Item>
          <Form.Item label='Email' name='email'>
            <Input disabled size='large' />
          </Form.Item>
          <Form.Item label='Senha'>
            <Button
              style={{ width: '100%' }}
              size='large'
              onClick={() => {
                setDrawerPasswordVisible(true)
              }}
            >
              Alterar Senha
            </Button>
          </Form.Item>
          <Form.Item
            label='Telefone'
            name='phone'
            normalize={(value) => MaskPhone(value)}
          >
            <Input size='large' />
          </Form.Item>
          <Form.Item label='Foto de perfil'>
            <Upload.Dragger
              name='file'
              data={{
                pasta: 'avatares',
                organization: user?.organization?.bucket_name,
              }}
              listType='picture-card'
              action={`${process.env.REACT_APP_API}/upload`}
              headers={{ Authorization: `Bearer ${token}` }}
              onRemove={() => setUpload(true)}
              onChange={({ file }) => {
                if (file.status === 'done') {
                  setImage(file.response.url)
                  setUpload(false)
                }
              }}
              showUploadList
              beforeUpload={beforeUpload}
              multiple={false}
            >
              {upload ? (
                <div>
                  <div style={{ marginTop: 8, width: '100%' }}>
                    Selecione ou Arraste o arquivo
                  </div>
                </div>
              ) : null}
            </Upload.Dragger>
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button
              type='primary'
              htmlType='submit'
              onClick={onSave}
              style={{ marginRight: 20 }}
              size='large'
            >
              Salvar
            </Button>
            <Button danger onClick={onClose} size='large'>
              Cancelar
            </Button>
          </Form.Item>
        </Form>
        <Drawer
          title='Alterar Senha'
          width={550}
          closable={false}
          onClose={() => {
            setDrawerPasswordVisible(false)
          }}
          visible={drawerPasswordVisible}
        >
          <Form labelCol={{ span: 9 }} form={passwordForm}>
            <Form.Item
              name='oldPassword'
              label='Senha antiga'
              rules={[
                {
                  required: true,
                  message: 'Por favor insira sua senha antiga!',
                },
              ]}
              hasFeedback
            >
              <Input.Password size='large' />
            </Form.Item>
            <Form.Item
              name='password'
              label='Senha'
              rules={[
                {
                  required: true,
                  message: 'Por favor insira sua senha!',
                },
                {
                  min: 6,
                  message: 'A senha deve ter no mínimo 8 caracteres.',
                },
              ]}
              hasFeedback
            >
              <Input.Password size='large' />
            </Form.Item>

            <Form.Item
              name='confirm'
              label='Confirmar senha'
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Por favor confirme sua senha!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      setButtonVerify(false)
                      return Promise.resolve()
                    }
                    setButtonVerify(true)
                    return Promise.reject(
                      new Error('As duas senhas devem ser iguais!')
                    )
                  },
                }),
              ]}
            >
              <Input.Password size='large' />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 9, span: 16 }}>
              <Button
                size='large'
                type='primary'
                htmlType='submit'
                onClick={onSavePassword}
                disabled={buttonVerify}
                style={{ marginRight: 15 }}
              >
                Salvar
              </Button>
              <Button
                size='large'
                danger
                onClick={() => {
                  passwordForm.resetFields()
                  // setDrawerPasswordVisible(false)
                }}
              >
                Cancelar
              </Button>
            </Form.Item>
          </Form>
        </Drawer>
      </Drawer>
    </Container>
  )
}

export default Header
