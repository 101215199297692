import React, { useEffect, useState } from 'react'
import {
  message,
  Table,
  Space,
  Button,
  Drawer,
  Popconfirm,
  Tooltip,
  Form,
  Input,
  Spin,
  Empty,
  Divider,
} from 'antd'
import {
  EditOutlined,
  DeleteOutlined,
  FullscreenExitOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { useParams, useHistory, useLocation, Link } from 'react-router-dom'
import api from '../../services/api'
import { Enviar, Cancelar } from '../../components/Buttons'
import { Container, PageTitle } from '../../components'

const { Column } = Table

function Setores() {
  const location = useLocation()
  const [clientDataForm] = useState(location.state)

  const { id, localId } = useParams()
  const history = useHistory()
  const [form] = Form.useForm()
  const [setores, setSetores] = useState([])
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [mode, setMode] = useState('insert')
  const [load, setLoad] = useState(false)
  const [setor, setSetor] = useState(null)

  useEffect(() => {
    async function loadEquipments() {
      setLoading(true)
      try {
        await api.get(`/setor?localId=${localId}`).then(async ({ data }) => {
          setSetores(data)
          setLoading(false)
        })
      } catch (error) {
        setLoading(false)
        setLoad(false)
        message.error('Erro ao carregar os dados, atualize a pagina')
      }
    }
    loadEquipments()
  }, [load])

  const openDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    form.resetFields()
    setMode('insert')
    setVisible(false)
  }

  const routes = [
    {
      path: '',
      breadcrumbName: 'Cadastros',
    },
    {
      path: '/clientes',
      breadcrumbName: 'Clientes',
    },
    {
      path: `/clientes/${id}/locais`,
      breadcrumbName: 'Locais do Cliente',
    },
  ]

  function itemRender(route) {
    return (
      <Link
        to={{ pathname: route.path, state: clientDataForm }}
        key={route.breadcrumbName}
      >
        {route.breadcrumbName}
      </Link>
    )
  }
  return (
    <Container>
      <PageTitle
        title='Unidades'
        breadcrumb={{ routes, itemRender }}
        breadcrumbRender={(props, breadcrumb) => (
          <div style={{ marginBottom: -4 }}>{breadcrumb}</div>
        )}
        extra={[
          <Button
            size='large'
            type='primary'
            icon={<PlusOutlined />}
            onClick={() => {
              setMode('insert')
              openDrawer()
            }}
            key='1'
          >
            Adicionar Unidade
          </Button>,
        ]}
      />
      <Table
        dataSource={setores}
        loading={loading}
        rowKey={(record) => record.id}
        size='middle'
        style={{ margin: 20 }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <>
                  <span>Não há registro aqui</span>
                </>
              }
            >
              <Button
                icon={<PlusOutlined />}
                key='insert'
                type='primary'
                size='large'
                onClick={() => {
                  setMode('insert')
                  openDrawer()
                }}
              >
                Adicionar
              </Button>
            </Empty>
          ),
        }}
      >
        <Column title='Unidade' dataIndex='name' key='name' />
        <Column
          title='Local'
          dataIndex='local'
          key='local'
          render={(_, record) => record.local.name}
        />
        <Column
          dataIndex='actions'
          key='actions'
          render={(_, record) => (
            <Space>
              <Tooltip title='Setores' placement='top'>
                <Button
                  icon={<FullscreenExitOutlined />}
                  onClick={() =>
                    history.push({
                      pathname: `/clientes/${id}/locais/${localId}/setores/${record.id}/unidades`,
                      state: { ...clientDataForm, setor: record },
                    })
                  }
                />
              </Tooltip>
              <Tooltip title='Editar' placement='top'>
                <Button
                  icon={<EditOutlined />}
                  onClick={() => {
                    setSetor(record)
                    form.setFieldsValue(record)
                    setMode('update')
                    openDrawer()
                  }}
                />
              </Tooltip>
              <Tooltip title='Excluir Unidade' placement='top'>
                <Popconfirm
                  title='Deseja realmente excluir este item?'
                  okText='Sim'
                  cancelText='Não'
                  onConfirm={async () => {
                    await api
                      .delete(`/setor/${record.id}`)
                      .then(() => {
                        message.success('Item excluido com sucesso')
                        setLoad(!load)
                      })
                      .catch((e) => {
                        message.error(e.response.data.error)
                      })
                  }}
                >
                  <Button
                    icon={<DeleteOutlined />}
                    style={{ color: '#e80909' }}
                  />
                </Popconfirm>
              </Tooltip>
            </Space>
          )}
        />
      </Table>
      <Drawer
        visible={visible}
        onClose={onClose}
        width={550}
        title='Adicionar Unidade'
      >
        <Spin spinning={loading}>
          <Form
            form={form}
            layout='vertical'
            onFinish={(value) => {
              setLoading(true)
              const data = { name: value.name, localId }
              if (mode === 'insert') {
                api
                  .post('/setor', data)
                  .then(() => {
                    setLoading(false)
                    message.success('Cadastro realizado com sucesso!')
                    onClose()
                    setLoad(!load)
                  })
                  .catch((e) => {
                    setLoading(false)
                    if (e.response.data.errors) {
                      e.response.data.errors.forEach((info) => {
                        message.error(info.message)
                      })
                    } else {
                      message.error(e.response.data.error)
                    }
                  })
              } else {
                api
                  .put(`/setor/${setor.id}`, data)
                  .then(() => {
                    setLoading(false)
                    message.success('Cadastro realizado com sucesso!')
                    onClose()
                    setLoad(!load)
                  })
                  .catch((e) => {
                    setLoading(false)
                    if (e.response.data.errors) {
                      e.response.data.errors.forEach((info) => {
                        message.error(info.message)
                      })
                    } else {
                      message.error(e.response.data.error)
                    }
                  })
              }
            }}
          >
            {clientDataForm?.client?.nomeFantasia ? (
              <>
                <Form.Item label='Cliente'>
                  <Input
                    disabled
                    size='large'
                    defaultValue={clientDataForm.client.nomeFantasia}
                  />
                </Form.Item>
                <Form.Item label='Local'>
                  <Input
                    disabled
                    size='large'
                    defaultValue={clientDataForm.local.name}
                  />
                </Form.Item>
              </>
            ) : (
              <></>
            )}
            <Form.Item
              label='Unidade'
              name='name'
              rules={[
                {
                  required: true,
                  message: 'Por favor, o unidade deve ser informado',
                },
              ]}
            >
              <Input size='large' />
            </Form.Item>
            <Divider style={{ borderColor: '#aaa' }} />
            <div style={{ display: 'flex' }}>
              <Enviar
                disabled={mode === 'view'}
                type='primary'
                htmlType='submit'
                style={{ flex: 1 }}
                size='large'
              >
                Adicionar
              </Enviar>
              <Cancelar
                danger
                onClick={onClose}
                style={{ flex: 1 }}
                size='large'
              >
                Cancelar
              </Cancelar>
            </div>
          </Form>
        </Spin>
      </Drawer>
    </Container>
  )
}

export default Setores
