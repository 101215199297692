import React, { useState, useEffect, useContext } from 'react'
import {
  Skeleton,
  message,
  Table,
  Space,
  Button,
  Drawer,
  Form,
  Spin,
  Select,
  Menu,
  Dropdown,
  Empty,
  Tag,
  Tooltip,
  Checkbox,
  Divider,
} from 'antd'
import {
  FilterOutlined,
  DownloadOutlined,
  DownOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { Container, PageTitle } from '../../components'
import { Enviar, Cancelar } from '../../components/Buttons'
import { Helmet } from 'react-helmet'
import api, { pdfUrl } from '../../services/api'
import { format, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import axios from 'axios'
import AuthContext from 'contexts/auth'
import DatePicker from 'components/DatePicker'

const { Column } = Table

const colors = {
  aberto: { name: 'Aberto', color: '#2eb772' },
  execucao: { name: 'Em Execução', color: '#1919d3' },
  aguardando: { name: 'Aguardando', color: '#ede90c' },
  finalizado: { name: 'Finalizado', color: '#3865a3' },
  reaberto: { name: 'Reaberto', color: '#0cedd6' },
  atrasado: { name: 'Atrasado', color: '#d31959' },
  validado: { name: 'Validado', color: '#a161db' },
}

function PlanilhaAtendimentos() {
  const { user } = useContext(AuthContext)
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [mode, setMode] = useState('insert')
  const [filteredData, setfilteredData] = useState([])
  const [clients, setclients] = useState([])
  const [tecnicos, setTecnicos] = useState([])
  const [contracts, setcontracts] = useState([])
  const [loadingTable, setLoadingTable] = useState(false)

  const [checkedList, setCheckedList] = useState([])
  const [indeterminate, setIndeterminate] = useState(true)
  const [checkAll, setCheckAll] = useState(false)

  const [filter, setFilter] = useState(null)

  const onClose = () => {
    // form.resetFields()
    setVisible(false)
    setMode('')
  }

  const loadClients = async () => {
    await api.get('/client').then(({ data }) => {
      setclients(data)
    })
  }

  const loadContracts = async (value) => {
    await api.get(`/contract/os/?clientId=${value}`).then(({ data }) => {
      setcontracts(data)
    })
  }

  useEffect(() => {
    async function loadData() {
      setLoading(true)
      try {
        await api.get(`/user?accessProfile=tecnico`).then(({ data }) => {
          setTecnicos(data)
        })
        loadClients()
      } catch (error) {
        message.error('Erro ao carregar os dados, atualize a pagina')
      } finally {
        setLoading(false)
      }
    }
    loadData()
  }, [])

  const openDrawer = () => {
    setVisible(true)
  }

  const onChange = (list) => {
    setCheckedList(list)
    setIndeterminate(!!list.length && list.length < tecnicos.length)
    setCheckAll(list.length === tecnicos.length)
  }

  const onCheckAllChange = (e) => {
    const ids = tecnicos.map((tecnico) => {
      return tecnico.id
    })
    setCheckedList(
      e.target.checked
        ? form.setFieldsValue({
            tecnicoId: ids,
          })
        : form.setFieldsValue({
            tecnicoId: [],
          })
    )
    setIndeterminate(false)
    setCheckAll(e.target.checked)
  }

  useEffect(() => {
    if (filter) {
      onSubmit(filter)
    }
  }, [])

  const menu = () => {
    return (
      <Menu>
        <Menu.Item key='1'>
          <Button
            onClick={() => tableExportToPdf()}
            icon={<DownloadOutlined />}
            style={{ width: '100%' }}
          >
            Baixar Relatório
          </Button>
        </Menu.Item>
      </Menu>
    )
  }

  const onSubmit = async (values) => {
    setLoadingTable(true)
    setFilter(values)
    await api
      .get(`/reports/attendance-worksheet`, {
        params: {
          clientId: values.clientId,
          contractId: values.contractId,
          localId: values.localId,
          dataInicio: values.dataInicio
            ? format(values.dataInicio, 'yyyy-MM-dd')
            : null,
          dataFim: values.dataFim ? format(values.dataFim, 'yyyy-MM-dd') : null,
          status: values.status?.toString(),
          tecnicoId: values.tecnicoId?.toString(),
        },
      })
      .then(({ data }) => {
        setfilteredData(data)
      })
      .catch((err) => {
        console.log(err)
        message.error('Informe o client e o Contrato')
      })
      .finally(() => {
        setLoadingTable(false)
      })
  }

  const tableExportToPdf = async () => {
    setLoadingTable(true)

    await axios
      .post(
        `${pdfUrl}attendance-worksheet-report/`,
        { user, data: filteredData },
        {
          responseType: 'blob',
        }
      )
      .then((response) => {
        message.success({
          content: 'Baixando PDF...',
        })
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${filteredData.client.nomeFantasia}.pdf`)
        document.body.appendChild(link)
        link.click()
      })
      .finally(() => setLoadingTable(false))
  }

  const [date, setDate] = useState()

  const disabledDate = (current) => {
    if (date) {
      return current && current <= date
    }
    return true
  }

  return (
    <Container>
      <Helmet>
        <title>Arcke | Planilha - Planilha Atendimentos</title>
      </Helmet>
      <Skeleton loading={false} active>
        <PageTitle
          title='Planilha Atendimentos'
          extra={[
            <Dropdown
              key='1'
              trigger={['click']}
              overlay={menu}
              placement='bottomCenter'
            >
              <Button size='large'>
                Exportar Tabela <DownOutlined />
              </Button>
            </Dropdown>,
            <Button
              icon={<FilterOutlined />}
              type='primary'
              size='large'
              onClick={() => {
                setMode('insert')
                openDrawer()
              }}
              key='2'
            >
              Filtrar
            </Button>,
          ]}
        />
        <Table
          dataSource={filteredData.orders}
          scroll={{ x: 1500 }}
          loading={loadingTable}
          rowKey={(record) => record.id}
          size='middle'
          style={{ margin: 20 }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <>
                    <span>Não há registro aqui</span>
                  </>
                }
              >
                <Button
                  icon={<PlusOutlined />}
                  key='insert'
                  type='primary'
                  size='large'
                  onClick={() => {
                    setMode('insert')
                    openDrawer()
                  }}
                >
                  Adicionar
                </Button>
              </Empty>
            ),
          }}
        >
          <Column
            title='ID'
            dataIndex='id'
            width={100}
            key='id'
            fixed
            render={(_, record) => <Tag>{record?.id}</Tag>}
          />
          <Column
            title='Nº Ref'
            width={120}
            fixed
            dataIndex='numRef'
            key='numRef'
          />
          <Column
            title='Local'
            width={170}
            dataIndex='local'
            key='local'
            render={(_, record) => {
              if (!record.localAtendimento && !record.local) {
                return 'Não informado'
              } else if (record.localAtendimento && !record.local) {
                return record.localAtendimento
              } else if (record.local.name && !record.localAtendimento) {
                return record?.local?.name
              }
            }}
          />
          <Column
            title='Crição'
            dataIndex='createdAt'
            width={170}
            key='createdAt'
            render={(_, record) =>
              format(parseISO(record.createdAt), 'dd/MM/yyyy', {
                locale: ptBR,
              })
            }
          />
          <Column
            title='Previsão de Atendimento'
            dataIndex='endDate'
            width={170}
            key='endDate'
            render={(_, record) =>
              format(parseISO(record.previsaoAtendimento), 'dd/MM/yyyy', {
                locale: ptBR,
              })
            }
          />
          <Column
            title='Status'
            dataIndex='status'
            width={150}
            key='status'
            render={(_, record) => (
              <Tag color={colors[record.status].color}>
                {colors[record.status].name}
              </Tag>
            )}
          />
          <Column
            title='Descrição'
            dataIndex='description'
            width={250}
            key='description'
          />
          <Column
            title='Técnico'
            width={170}
            dataIndex='tecnico'
            key='tecnico'
            render={(_, record) =>
              record?.tecnico ? record.tecnico.name : 'Não definido'
            }
          />
        </Table>
        <Drawer
          open={visible}
          onClose={onClose}
          width={550}
          title='Filtro de Relatório'
        >
          <Spin spinning={loading}>
            <Form form={form} layout='vertical' onFinish={onSubmit}>
              <Form.Item label='Cliente' required>
                <Form.Item
                  name='clientId'
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  noStyle
                >
                  <Select
                    size='large'
                    showSearch
                    placeholder='Selecione...'
                    optionLabelProp='label'
                    optionFilterProp='children'
                    allowClear
                    onChange={loadContracts}
                  >
                    {clients.map((client) => (
                      <Select.Option
                        key={client.id}
                        value={client.id}
                        label={client.nomeFantasia}
                      >
                        {client.nomeFantasia}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form.Item>
              <Form.Item label='Contrato' required>
                <Form.Item
                  name='contractId'
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  noStyle
                >
                  <Select
                    size='large'
                    showSearch
                    placeholder='Selecione...'
                    optionLabelProp='label'
                    optionFilterProp='children'
                    allowClear
                  >
                    {contracts.map((contract) => (
                      <Select.Option
                        key={contract.id}
                        value={contract.id}
                        label={contract.name}
                      >
                        {contract.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form.Item>
              <Form.Item label='Data de Criação' name='dataInicio'>
                <div
                  style={{ display: 'flex', alignItems: 'baseline', gap: 8 }}
                >
                  <Form.Item name='dataInicio' noStyle>
                    <DatePicker
                      style={{ width: '100%' }}
                      size='large'
                      onChange={(e) => setDate(e)}
                    />
                  </Form.Item>
                  até
                  <Form.Item name='dataFim' noStyle>
                    <DatePicker
                      style={{ width: '100%' }}
                      size='large'
                      disabledDate={disabledDate}
                    />
                  </Form.Item>
                </div>
              </Form.Item>
              <Form.Item label='Status' name='status'>
                <Select
                  size='large'
                  placeholder='Selecione...'
                  optionLabelProp='label'
                  optionFilterProp='children'
                  maxTagCount='responsive'
                  allowClear
                  mode='multiple'
                >
                  {[
                    { id: 'aberto', name: 'Aberto' },
                    { id: 'execucao', name: 'Em Execução' },
                    { id: 'aguardando', name: 'Aguardando' },
                    { id: 'finalizado', name: 'Finalizado' },
                    { id: 'reaberto', name: 'Reaberto' },
                    { id: 'atrasado', name: 'Atrasado' },
                    { id: 'validado', name: 'Validado' },
                  ].map((item) => (
                    <Select.Option
                      key={item.id}
                      value={item.id}
                      label={item.name}
                    >
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label='Técnico'>
                <Space style={{ width: '100%' }}>
                  <Form.Item name='tecnicoId' noStyle>
                    <Select
                      size='large'
                      showSearch
                      style={{ width: 480 }}
                      placeholder='Selecione...'
                      optionLabelProp='label'
                      optionFilterProp='children'
                      allowClear
                      mode='multiple'
                      onChange={onChange}
                      defaultValue={checkedList}
                      maxTagCount='responsive'
                    >
                      {tecnicos.map((tecnico) => (
                        <Select.Option
                          key={tecnico.id}
                          value={tecnico.id}
                          label={tecnico.name}
                        >
                          {tecnico.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Tooltip placement='topLeft' title='Selecionar todos.'>
                    <Checkbox
                      indeterminate={indeterminate}
                      onChange={onCheckAllChange}
                      checked={checkAll}
                    />
                  </Tooltip>
                </Space>
              </Form.Item>
              <Divider style={{ borderColor: '#aaa' }} />
              <Form.Item shouldUpdate noStyle>
                {() => {
                  return (
                    <div style={{ display: 'flex' }}>
                      <Enviar
                        type='primary'
                        htmlType='submit'
                        style={{ flex: 1 }}
                        size='large'
                      >
                        Gerar Relatório
                      </Enviar>
                      <Cancelar
                        danger
                        disabled={mode === null}
                        onClick={onClose}
                        style={{ flex: 1 }}
                        size='large'
                      >
                        Cancelar
                      </Cancelar>
                    </div>
                  )
                }}
              </Form.Item>
            </Form>
          </Spin>
        </Drawer>
      </Skeleton>
    </Container>
  )
}

export default PlanilhaAtendimentos
