/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import {
  Skeleton,
  message,
  Table,
  Space,
  Button,
  Popconfirm,
  Descriptions,
  Tooltip,
  Drawer,
  Form,
  Spin,
  Select,
  Divider,
  Input,
} from 'antd'
import {
  DeleteOutlined,
  SearchOutlined,
  EyeOutlined,
  SubnodeOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { Enviar, Cancelar } from 'components/Buttons'
import { Container, PageTitle } from 'components'
import api from 'services/api'
import { useRouter } from 'services/hooks/useRouter'
import Highlighter from 'react-highlight-words'
import { Link, useParams } from 'react-router-dom'

const { Column } = Table

export default function ItensDetalhados() {
  const { history, state } = useRouter()
  const { contrato, item } = state
  const [form] = Form.useForm()
  const { id } = useParams()
  const [itemLocal, setItemLoca] = useState({})
  const [itens, setItens] = useState(item.items)
  const [loading, setLoading] = useState(false)
  const [load, setLoad] = useState(false)
  const [locais, setLocais] = useState([])
  const [visible, setVisible] = useState(false)
  const [mode, setMode] = useState('insert')
  const [totalUsage, setTotalUsage] = useState([])

  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  useEffect(() => {
    async function getData() {
      try {
        setLoading(true)
        const { data } = await api.get(
          `/itemlocal/itens_detalhados?contractId=${contrato.id}&itemId=${item.id}`
        )
        console.log(data)
        let total = 0
        data.usage.forEach((use) => {
          total += Number(use.usage)
        })
        setTotalUsage(total)
        setItens(data.usage)
      } catch (error) {
        message.error('Erro ao carregar os dados, atualize a pagina')
      } finally {
        setLoading(false)
      }
    }
    getData()
  }, [load])

  const onClose = () => {
    form.resetFields()
    setMode('insert')
    setLoad(!load)
    setVisible(false)
  }

  const loadLocais = async () => {
    await api
      .get(
        `/local?clientId=${contrato.clientId}&contractId=${contrato.id}&itemId=${item.itemId}`
      )
      .then(({ data }) => {
        console.log(data)
        setLocais(data)
      })
  }

  const routes = [
    {
      path: '/contratos',
      breadcrumbName: 'Contratos',
    },
    {
      path: `/contratos/${id}/itens`,
      breadcrumbName: 'Itens do Contrato',
    },
  ]

  function itemRender(route) {
    return (
      <Link to={{ pathname: route.path }} key={route.breadcrumbName}>
        {route.breadcrumbName}
      </Link>
    )
  }

  return (
    <Container>
      <Skeleton loading={loading} active>
        <PageTitle
          title={`Locais do Item`}
          breadcrumb={{ routes, itemRender }}
          breadcrumbRender={(props, breadcrumb) => (
            <div style={{ marginBottom: -4 }}>{breadcrumb}</div>
          )}
          extra={[
            <Button
              icon={<PlusOutlined />}
              size='large'
              type='primary'
              onClick={() => {
                setVisible(true)
                setMode('insert')
                loadLocais()
                // form.setFieldsValue({
                //   item: itens[0].itemId,
                //   equipmentTypeId: itens[0].equipmentTypeId,
                //   number: itens[0].number,
                //   unityValue: Number(itens[0].unityValue),
                //   quantity: itens[0].quantity,
                //   itemDuplicado: itens[0].itemDuplicado,
                // })
              }}
              key='1'
            >
              Adicionar Local ao Item
            </Button>,
          ]}
        ></PageTitle>
        <div
          style={{
            backgroundColor: '#fff',
            height: 50,
            display: 'flex',
            alignItems: 'center',
            padding: '0 10px',
            marginTop: 10,
            margin: '10px',
          }}
        >
          <Descriptions size='small' column={2}>
            <Descriptions.Item label='Item'>
              {item.item.description}
            </Descriptions.Item>
            <Descriptions.Item label='Total utilizado'>
              {totalUsage}/{item.quantity}
            </Descriptions.Item>
          </Descriptions>
        </div>
        <Table
          dataSource={itens}
          loading={loading}
          rowKey={(record) => record.localId}
          size='middle'
          style={{ margin: 20 }}
        >
          {/* <Column title='ID' dataIndex='id' key='id' /> */}
          <Column
            title='Local do Item'
            dataIndex='local'
            key='local'
            filterDropdown={({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) => (
              <div style={{ padding: 8 }}>
                <Input
                  size='large'
                  ref={searchInput}
                  placeholder={`Filtrar Local`}
                  value={selectedKeys[0]}
                  onChange={(e) =>
                    setSelectedKeys(e.target.value ? [e.target.value] : [])
                  }
                  onPressEnter={() =>
                    handleSearch(selectedKeys, confirm, 'local')
                  }
                  style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                  <Button
                    type='primary'
                    onClick={() => handleSearch(selectedKeys, confirm, 'local')}
                    icon={<SearchOutlined />}
                    size='small'
                    style={{ width: 90 }}
                  >
                    Procurar
                  </Button>
                  <Button
                    onClick={() => clearFilters && handleReset(clearFilters)}
                    size='small'
                    style={{ width: 90 }}
                  >
                    Limpar
                  </Button>
                  <Button
                    type='link'
                    size='small'
                    onClick={() => {
                      confirm({ closeDropdown: false })
                      setSearchText(selectedKeys[0])
                      setSearchedColumn('local')
                    }}
                  >
                    Filtrar
                  </Button>
                </Space>
              </div>
            )}
            filterIcon={(filtered) => (
              <SearchOutlined
                style={{ color: filtered ? '#1890ff' : '#777', fontSize: 15 }}
              />
            )}
            onFilter={(value, record) =>
              record.local.name
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase())
            }
            onFilterDropdownOpenChange={(visible) => {
              if (visible) {
                setTimeout(() => searchInput.current?.select(), 100)
              }
            }}
            render={(_, record) => {
              return searchedColumn === 'local' ? (
                <Highlighter
                  highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                  searchWords={[searchText]}
                  autoEscape
                  textToHighlight={
                    record.local.name ? record.local.name.toString() : ''
                  }
                />
              ) : (
                record.local.name
              )
            }}
          />
          <Column
            title='Quantidade Utilizada'
            dataIndex='utilizada'
            key='utilizada'
            render={(_, record) => record.usage}
          />
          <Column
            dataIndex='actions'
            key='actions'
            render={(_, record) => (
              <Space>
                {/* <Tooltip title='Visualizar' placement='top'>
                  <Button
                    icon={<EyeOutlined />}
                    onClick={() => {
                      setLocais([record.local])
                      setVisible(true)
                      setMode('update')
                      setItemLoca(record)
                      form.setFieldsValue({
                        localId: record.localId,
                        item: record.itemId,
                        equipmentTypeId: record.equipmentTypeId,
                        number: record.number,
                        unityValue: Number(record.unityValue),
                        quantity: record.quantity,
                      })
                    }}
                  />
                </Tooltip> */}
                <Tooltip title='Aditivos' placement='top'>
                  <Button
                    disabled={record.itemDuplicado === true}
                    icon={<SubnodeOutlined />}
                    onClick={() =>
                      history.push({
                        pathname: `/contratos/${contrato.id}/itens/${record.id}/aditivos`,
                        state: { ...contrato, item: record },
                      })
                    }
                  />
                </Tooltip>
                <Tooltip title='Excluir item' placement='top'>
                  <Popconfirm
                    title='Deseja realmente excluir este item?'
                    okText='Sim'
                    disabled={record.itemDuplicado === true}
                    cancelText='Não'
                    onConfirm={async () => {
                      console.log(
                        `/itemlocal/${item.id}/removelocal/${record.localId}`
                      )
                      await api
                        .delete(
                          `/itemlocal/${item.id}/removelocal/${record.localId}`
                        )
                        .then(() => {
                          message.success('Item excluido com sucesso')
                          setLoad(!load)
                        })
                        .catch(() => {
                          message.error('Erro ao excluir item, tente novamente')
                        })
                    }}
                  >
                    <Button
                      disabled={record.itemDuplicado === true}
                      icon={<DeleteOutlined />}
                      style={{ color: '#e80909' }}
                    />
                  </Popconfirm>
                </Tooltip>
              </Space>
            )}
          />
        </Table>
        <Drawer
          visible={visible}
          onClose={onClose}
          width={550}
          destroyOnClose
          title={`${
            mode === 'insert' ? 'Adicionar' : 'Atualizar'
          } Local ao Item`}
        >
          <Spin spinning={loading}>
            <Form
              form={form}
              layout='vertical'
              onFinish={(value) => {
                // setLoading(true)
                if (mode === 'insert') {
                  console.log(value)
                  api
                    .post(`/itemlocal/newlocal/${item.id}`, value)
                    .then(() => {
                      message.success('Cadastro realizado com sucesso!')
                      onClose()
                    })
                    .catch((e) => {
                      if (e.response.data.errors) {
                        e.response.data.errors.forEach((info) => {
                          message.error(info.message)
                        })
                      } else {
                        message.error(e.response?.data.error)
                      }
                    })
                    .finally(() => setLoading(false))
                } else {
                  console.log(itemLocal)
                  const data = {
                    ...value,
                    itemId: itens[0].itemId,
                    contractId: contrato.id,
                    clientId: contrato.cliente.id,
                  }
                  api
                    .put(`/itemlocal/updatelocal/${itemLocal.id}`, data)
                    .then(() => {
                      message.success('Cadastro realizado com sucesso!')
                      onClose()
                    })
                    .catch((e) => {
                      if (e.response.data.errors) {
                        e.response.data.errors.forEach((info) => {
                          message.error(info.message)
                        })
                      } else {
                        message.error(e.response?.data.error)
                      }
                    })
                    .finally(() => setLoading(false))
                }
              }}
            >
              <Form.Item
                label='Local'
                name='localId'
                rules={[
                  {
                    required: true,
                    message: 'Por favor, o Local deve ser informado',
                  },
                ]}
                shouldUpdate
              >
                <Select
                  // mode='multiple'
                  size='large'
                  disabled={mode !== 'insert'}
                  showSearch
                  placeholder='Selecione...'
                  optionLabelProp='label'
                  optionFilterProp='children'
                >
                  {locais.map((itemUni) => (
                    <Select.Option
                      key={itemUni.id}
                      value={itemUni.id}
                      label={`${itemUni.name}`}
                    >
                      {itemUni.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Divider style={{ borderColor: '#aaa' }} />
              <div style={{ display: 'flex' }}>
                <Enviar
                  disabled={mode === 'view'}
                  type='primary'
                  htmlType='submit'
                  size='large'
                  style={{ flex: 1 }}
                >
                  {mode === 'insert' ? 'Adicionar' : 'Atualizar'}
                </Enviar>
                <Cancelar
                  danger
                  onClick={onClose}
                  size='large'
                  style={{ flex: 1 }}
                >
                  Cancelar
                </Cancelar>
              </div>
            </Form>
          </Spin>
        </Drawer>
      </Skeleton>
    </Container>
  )
}
