import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import Routes from './routes'
import { AuthProvider } from './contexts/auth'
import './styles/App.less'
import './styles/App.css'
import { DrawerProvider } from 'contexts/DrawerContext'

function App() {
  return (
    <AuthProvider>
      <DrawerProvider>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </DrawerProvider>
    </AuthProvider>
  )
}

export default App
