import {
  formatCPF,
  formatCNPJ,
  formatCEP,
} from '@brazilian-utils/brazilian-utils'

export function maskCpfCnpj(value) {
  if (value.length <= 14) {
    return formatCPF(value, { pad: false })
  }
  return formatCNPJ(value)
}

export function MaskCEP(value) {
  return formatCEP(value)
}
export function MaskPhone(value) {
  let clean = value.replace(/\D/g, '')
  clean = clean.replace(/^0/, '')
  if (clean.length > 10) {
    clean = clean.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3')
  }
  if (clean.length > 5) {
    clean = clean.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, '($1) $2-$3')
  }
  if (clean.length > 2) {
    clean = clean.replace(/^(\d\d)(\d{0,5})/, '($1) $2')
  } else {
    clean = clean.replace(/^(\d*)/, '($1')
  }

  return clean
}

export function formatarMoeda(value) {
  const formato = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })
  return formato.format(Number(value))
}

export function saveBlob(blob, filename) {
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.style.display = 'none'
  const url = window.URL.createObjectURL(blob)
  a.href = url
  a.download = filename
  a.click()
  window.URL.revokeObjectURL(url)
}
