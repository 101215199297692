import React, { useEffect, /* useRef, */ useState } from 'react'
import {
  message,
  Table,
  Space,
  Button,
  Drawer,
  Popconfirm,
  Tooltip,
  Form,
  Input,
  Spin,
  Empty,
  Select,
  Divider,
} from 'antd'
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons'
import api from 'services/api'
import { Container, PageTitle } from 'components'
import { Enviar, Cancelar } from 'components/Buttons'
import { Helmet } from 'react-helmet'
import { DrawerServicoPMOC } from '../Questions/Drawer'

const { Column } = Table

function Pmocs() {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [mode, setMode] = useState('insert')
  const [load, setLoad] = useState(false)
  const [pmocs, setPmocs] = useState([])
  const [questions, setQuestions] = useState([])
  const [selectedQuestions, setSelectedQuestions] = useState([])
  const [pmoc, setPmoc] = useState(null)

  // const [question, setQuestion] = useState('')
  const [loadQuestion /* setLoadQuestion */] = useState(false)
  const [
    drawerCreateServicePMOCIsVisible,
    setDrawerCreateServicePMOCIsVisible,
  ] = useState(false)

  const openDrawerCreateServiceType = () => {
    setDrawerCreateServicePMOCIsVisible(true)
  }

  const onCloseDrawerCreateServicePMOCIsVisible = () => {
    loadQuestions()
    setDrawerCreateServicePMOCIsVisible(false)
  }

  useEffect(() => {
    loadData()
  }, [load])

  useEffect(() => {
    loadQuestions()
  }, [loadQuestion])

  async function loadData() {
    setLoading(true)
    await api
      .get('/pmocs')
      .then(({ data }) => {
        setPmocs(data)
      })
      .catch((error) => {
        console.log(error)
        message.error('Erro ao carregar os dados, atualize a pagina')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  async function loadQuestions() {
    await api
      .get('/questions')
      .then(({ data }) => {
        setQuestions(data)
      })
      .catch((error) => {
        console.log(error)
        message.error('Erro ao carregar os dados, atualize a pagina')
      })
  }

  const openDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    form.resetFields()
    setMode('insert')
    setPmoc(null)
    setSelectedQuestions([])
    setVisible(false)
    setLoad(!load)
  }

  const handleSubmitForm = async (value) => {
    console.log(value)
    setLoading(true)
    if (mode === 'insert') {
      const data = {
        ...value,
        name: value.name.toUpperCase(),
      }
      api
        .post('/pmocs', data)
        .then(() => {
          message.success('Cadastro realizado com sucesso!')
        })
        .catch((e) => {
          if (e.response.data.errors) {
            e.response.data.errors.forEach((info) => {
              message.error(info.message)
            })
          } else {
            message.error(e.response.data.error)
          }
        })
        .finally(() => {
          setLoading(false)
          onClose()
        })
    } else {
      const data = {
        ...value,
        name: value.name.toUpperCase(),
      }
      await api
        .put(`/pmocs/${pmoc.id}`, data)
        .then(() => {
          setLoading(false)
          message.success('Cadastro atualizado com sucesso!')
          onClose()
        })
        .catch((e) => {
          setLoading(false)
          if (e.response.data.errors) {
            e.response.data.errors.forEach((info) => {
              message.error(info.message)
            })
          } else {
            message.error(e.response.data.error)
          }
        })
    }
  }

  const handlePmocEditing = (record) => {
    setPmoc(record)
    const questions = record.questions.map((question) => {
      return {
        question: question.id,
        frequency: question?.meta?.pivot_frequency,
      }
    })
    setSelectedQuestions(
      record.questions.map((question) => {
        return question.id
      })
    )
    form.setFieldsValue({
      name: record.name,
      questions,
    })
    setMode('update')
    openDrawer()
  }

  // const inputRef = useRef(null)

  // const onQuestionInputChange = (event) => {
  //   setQuestion(event.target.value)
  //   console.log(event.target.value)
  // }

  // const createQuestion = (e) => {
  //   e.preventDefault()
  //   const data = {
  //     question: question.toUpperCase(),
  //   }
  //   api
  //     .post('/questions', data)
  //     .then(() => {
  //       message.success('Cadastro realizado com sucesso!')
  //       setLoadQuestion(!loadQuestion)
  //     })
  //     .catch((e) => {
  //       if (e.response.data.errors) {
  //         e.response.data.errors.forEach((info) => {
  //           message.error(info.message)
  //         })
  //       } else {
  //         message.error(e.response.data.error)
  //       }
  //     })
  //     .finally(() => {
  //       setLoading(false)
  //     })
  //   setQuestion('')
  //   inputRef.current?.focus()
  // }

  return (
    <Container>
      <Helmet>
        <title>Arcke | PMOC</title>
      </Helmet>
      <PageTitle
        title='PMOC'
        extra={[
          <Button
            type='primary'
            size='large'
            icon={<PlusOutlined />}
            onClick={() => {
              setMode('insert')
              openDrawer()
            }}
            key='1'
          >
            Adicionar PMOC
          </Button>,
        ]}
      />
      <Table
        dataSource={pmocs}
        loading={loading}
        rowKey={(record) => record.id}
        size='middle'
        style={{ margin: 20 }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <>
                  <span>Não há registro aqui</span>
                </>
              }
            >
              <Button
                icon={<PlusOutlined />}
                key='insert'
                type='primary'
                size='large'
                onClick={() => {
                  setMode('insert')
                  openDrawer()
                }}
              >
                Adicionar
              </Button>
            </Empty>
          ),
        }}
      >
        <Column title='ID' dataIndex='id' width={100} key='id' />
        <Column title='PMOC' dataIndex='name' key='name' />
        <Column
          dataIndex='actions'
          key='actions'
          title='Ações'
          width={20}
          render={(_, record) => (
            <Space>
              <Tooltip title='Editar' placement='top'>
                <Button
                  icon={<EditOutlined />}
                  onClick={() => handlePmocEditing(record)}
                />
              </Tooltip>
              <Tooltip title='Excluir Tipo' placement='top'>
                <Popconfirm
                  title='Deseja realmente excluir este item?'
                  okText='Sim'
                  cancelText='Não'
                  onConfirm={async () => {
                    await api
                      .delete(`/pmocs/${record.id}`)
                      .then(() => {
                        message.success('Item excluido com sucesso')
                      })
                      .catch(() => {
                        message.error('Erro ao excluir item, tente novamente')
                      })
                      .finally(() => {
                        setLoad(!load)
                      })
                  }}
                >
                  <Button
                    icon={<DeleteOutlined />}
                    style={{ color: '#e80909' }}
                  />
                </Popconfirm>
              </Tooltip>
            </Space>
          )}
        />
      </Table>
      <Drawer
        visible={visible}
        onClose={onClose}
        size='large'
        title={mode === 'insert' ? 'Adicionar PMOC' : 'Atualizar PMOC'}
      >
        <Spin spinning={loading}>
          <Form form={form} onFinish={handleSubmitForm} layout='vertical'>
            <Form.Item
              label='Nome do PMOC'
              name='name'
              rules={[
                {
                  required: true,
                  message: 'Por favor, o nome do PMOC deve ser informado.',
                },
              ]}
            >
              <Input size='large' />
            </Form.Item>
            <Form.List name='questions'>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <div
                      key={key}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        columnGap: '8px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >
                        <Form.Item
                          id='formQuestions'
                          style={{ width: '100%' }}
                          {...restField}
                          name={[name, 'question']}
                          label={`Serviço ${name + 1}`}
                          rules={[
                            {
                              required: true,
                              message: 'Selecione um Serviço.',
                            },
                          ]}
                        >
                          <Select
                            size='large'
                            style={{ width: '100%' }}
                            placeholder='Selecione um Serviço'
                            allowClear
                            showSearch
                            optionFilterProp='children'
                            onSelect={() => {
                              setSelectedQuestions(
                                form.getFieldValue('questions').map((item) => {
                                  return item?.question
                                })
                              )
                            }}
                          >
                            {questions.map((question) => {
                              return (
                                <Select.Option
                                  key={question.id}
                                  value={question.id}
                                  disabled={selectedQuestions.includes(
                                    question.id
                                  )}
                                >
                                  {question.question}
                                </Select.Option>
                              )
                            })}
                          </Select>
                        </Form.Item>
                        <Button
                          size='large'
                          style={{
                            padding: '0 11px',
                            borderColor: '#aaa',
                            marginBottom: -5,
                          }}
                          onClick={() => {
                            openDrawerCreateServiceType()
                          }}
                        >
                          <PlusOutlined />
                        </Button>
                      </div>
                      <Form.Item
                        {...restField}
                        style={{ width: '50%' }}
                        name={[name, 'frequency']}
                        label={`Periodicidade`}
                        rules={[
                          {
                            required: true,
                            message: 'Selecione uma Periodo.',
                          },
                        ]}
                      >
                        <Select
                          size='large'
                          placeholder='Selecione um Periodo'
                          showSearch
                          optionFilterProp='children'
                        >
                          <Select.Option value={'s'}>SEMANAL</Select.Option>
                          <Select.Option value={'q'}>QUINZENAL</Select.Option>
                          <Select.Option value={'m'}>MENSAL</Select.Option>
                          <Select.Option value={'b'}>BIMESTRAL</Select.Option>
                          <Select.Option value={'t'}>TRIMESTRAL</Select.Option>
                          <Select.Option value={'se'}>SEMESTRAL</Select.Option>
                          <Select.Option value={'a'}>ANUAL</Select.Option>
                        </Select>
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </div>
                  ))}
                  <Form.Item>
                    <Button onClick={() => add()} block icon={<PlusOutlined />}>
                      Adicionar Serviço
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Divider style={{ borderColor: '#bbb' }} />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Enviar
                disabled={mode === 'view'}
                type='primary'
                htmlType='submit'
                size='large'
                style={{ flex: 1 }}
              >
                {mode === 'insert' ? 'Adicionar' : 'Atualizar'}
              </Enviar>
              <Cancelar
                size='large'
                danger
                onClick={onClose}
                style={{ flex: 1 }}
              >
                Cancelar
              </Cancelar>
            </div>
          </Form>
        </Spin>
      </Drawer>
      <DrawerServicoPMOC
        isVisible={drawerCreateServicePMOCIsVisible}
        onClose={onCloseDrawerCreateServicePMOCIsVisible}
        loading={loading}
        setLoading={setLoading}
      />
    </Container>
  )
}

export default Pmocs
