import React, { useContext, useEffect, useState } from 'react'
import {
  Skeleton,
  message,
  Table,
  Button,
  Drawer,
  Form,
  Select,
  Menu,
  Dropdown,
  Empty,
  Divider,
} from 'antd'
import {
  FilterOutlined,
  DownloadOutlined,
  DownOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import api, { pdfUrl } from '../../services/api'
import { Container, PageTitle } from '../../components'
import { Enviar, Cancelar } from '../../components/Buttons'
import { format } from 'date-fns'
import { Helmet } from 'react-helmet'
import AuthContext from 'contexts/auth'
import axios from 'axios'
import Tag from 'antd/es/tag'
import DatePicker from 'components/DatePicker'

const { Column } = Table

function RelatorioServicoEquipamentos() {
  const { user } = useContext(AuthContext)
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const [tableData, setTableData] = useState([])
  const [loadingTable, setLoadingTable] = useState(false)
  const [clients, setclients] = useState([])
  const [locals, setLocals] = useState([])
  const [contracts, setcontracts] = useState([])
  const [filter, setFilter] = useState(null)

  const onClose = () => {
    setVisible(false)
  }

  const loadClients = async () => {
    await api.get('/client').then(({ data }) => {
      setclients(data)
    })
  }

  const openDrawer = () => {
    loadClients()
    setVisible(true)
  }

  useEffect(() => {
    if (filter) {
      onSubmit(filter)
    }
  }, [])

  const onSubmit = async (value) => {
    setLoadingTable(true)
    setFilter(value)
    try {
      await api
        .get(`/reports/equipment-service-list/`, {
          params: value,
        })
        .then(({ data }) => {
          setTableData(data)
        })
    } catch (err) {
      message.error('Informe as datas corretas')
    } finally {
      setLoadingTable(false)
    }
  }

  const menu = () => (
    <Menu>
      <Menu.Item key='1'>
        <Button
          icon={<DownloadOutlined />}
          disabled={tableData.length < 1}
          onClick={() => tableExportToPdf()}
          style={{ width: '100%' }}
        >
          Baixar Relatório
        </Button>
      </Menu.Item>
    </Menu>
  )

  const tableExportToPdf = async () => {
    setLoadingTable(true)

    const dateInicio = format(new Date(), 'yyyy/MM/dd')

    await axios
      .post(
        `${pdfUrl}equipment-service-report/`,
        {
          user,
          data: {
            ...tableData,
            date: form.getFieldsValue(['dataInicio', 'dataFim']),
          },
        },
        {
          responseType: 'blob',
        }
      )
      .then((response) => {
        message.success({
          content: 'Baixando PDF...',
        })

        // const _url = window.URL.createObjectURL(response.data)
        // if (_url) {
        //   window.open(_url, 'Axios data', 'width=841,height=800')?.focus()
        // }
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `${dateInicio
            .replace('/', '-')
            .replace('/', '-')
            .replace('/', '-')}.pdf`
        )
        document.body.appendChild(link)
        link.click()
      })
    setLoadingTable(false)
  }

  const loadContracts = async (value) => {
    await api.get(`/contract/os/?clientId=${value}`).then(({ data }) => {
      setcontracts(data)
    })

    await api
      .get(`/local`, { params: { clientId: value } })
      .then(({ data }) => {
        setLocals(data)
      })
  }

  const [date, setDate] = useState()

  const disabledDate = (current) => {
    if (date) {
      return current && current <= date
    }
    return true
  }

  return (
    <Container>
      <Helmet>
        <title>Arcke | Relatório - Equipamentos</title>
      </Helmet>
      <Skeleton loading={false} active>
        <PageTitle
          title='Relatório de Histórico de Serviços de Equipamentos'
          extra={[
            <Dropdown
              key='1'
              trigger={['click']}
              overlay={menu}
              placement='bottomCenter'
            >
              <Button size='large'>
                Exportar Tabela <DownOutlined />
              </Button>
            </Dropdown>,
            <Button
              icon={<FilterOutlined />}
              type='primary'
              size='large'
              onClick={() => {
                openDrawer()
              }}
              key='2'
            >
              Filtrar
            </Button>,
          ]}
        />
        <Table
          dataSource={tableData.equipments}
          scroll={{ x: 1500 }}
          loading={loadingTable}
          rowKey={(record) => record.id}
          size='middle'
          style={{ margin: 20 }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <>
                    <span>Não há registro aqui</span>
                  </>
                }
              >
                <Button
                  icon={<PlusOutlined />}
                  key='insert'
                  type='primary'
                  size='large'
                  onClick={() => {
                    openDrawer()
                  }}
                >
                  Adicionar
                </Button>
              </Empty>
            ),
          }}
        >
          <Column
            title='ID'
            dataIndex='id'
            width={100}
            key='id'
            fixed
            render={(_, record) => <Tag>{record?.id}</Tag>}
          />
          <Column
            title='Equipamento'
            width={250}
            fixed
            dataIndex='name'
            key='name'
          />
          <Column
            title='Serviços'
            width={170}
            dataIndex='services'
            key='services'
            render={(_, record) => record?.services?.length}
          />
        </Table>
        <Drawer
          open={visible}
          onClose={onClose}
          width={550}
          title='Filtro de Relatório'
        >
          <Form form={form} layout='vertical' onFinish={onSubmit}>
            <Form.Item label='Cliente' required>
              <Form.Item
                name='clientId'
                rules={[
                  {
                    required: true,
                  },
                ]}
                noStyle
              >
                <Select
                  size='large'
                  showSearch
                  placeholder='Selecione...'
                  optionLabelProp='label'
                  optionFilterProp='children'
                  allowClear
                  onChange={loadContracts}
                >
                  {clients.map((client) => (
                    <Select.Option
                      key={client.id}
                      value={client.id}
                      label={client.nomeFantasia}
                    >
                      {client.nomeFantasia}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form.Item>
            <Form.Item label='Contrato' required>
              <Form.Item
                name='contractId'
                rules={[
                  {
                    required: true,
                  },
                ]}
                noStyle
              >
                <Select
                  size='large'
                  showSearch
                  placeholder='Selecione...'
                  optionLabelProp='label'
                  optionFilterProp='children'
                  allowClear
                >
                  {contracts.map((contract) => (
                    <Select.Option
                      key={contract.id}
                      value={contract.id}
                      label={contract.name}
                    >
                      {contract.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form.Item>
            <Form.Item label='Local'>
              <Form.Item name='localId' noStyle>
                <Select
                  size='large'
                  showSearch
                  placeholder='Selecione...'
                  optionLabelProp='label'
                  optionFilterProp='children'
                  allowClear
                >
                  {locals.map((local) => (
                    <Select.Option
                      key={local.id}
                      value={local.id}
                      label={local.name}
                    >
                      {local.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form.Item>
            <Form.Item label='Data de Finalização' name='dataInicio' required>
              <div style={{ display: 'flex', alignItems: 'baseline', gap: 8 }}>
                <Form.Item name='dataInicio' noStyle required>
                  <DatePicker
                    style={{ width: '100%' }}
                    size='large'
                    onChange={(e) => setDate(e)}
                  />
                </Form.Item>
                até
                <Form.Item name='dataFim' noStyle required>
                  <DatePicker
                    style={{ width: '100%' }}
                    size='large'
                    disabledDate={disabledDate}
                  />
                </Form.Item>
              </div>
            </Form.Item>
            {/* <Form.Item label='Status' name='status'>
              <Select
                size='large'
                placeholder='Selecione...'
                optionLabelProp='label'
                optionFilterProp='children'
                maxTagCount='responsive'
                allowClear
                mode='multiple'
              >
                {[
                  { id: 'aberto', name: 'Aberto' },
                  { id: 'execucao', name: 'Em Execução' },
                  { id: 'aguardando', name: 'Aguardando' },
                  { id: 'finalizado', name: 'Finalizado' },
                  { id: 'reaberto', name: 'Reaberto' },
                  { id: 'atrasado', name: 'Atrasado' },
                  { id: 'validado', name: 'Validado' },
                ].map((item) => (
                  <Select.Option
                    key={item.id}
                    value={item.id}
                    label={item.name}
                  >
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item> */}
            <Divider style={{ borderColor: '#aaa' }} />
            <Form.Item shouldUpdate noStyle>
              {() => {
                return (
                  <div style={{ display: 'flex' }}>
                    <Enviar
                      type='primary'
                      htmlType='submit'
                      style={{ flex: 1 }}
                      size='large'
                    >
                      Gerar Relatório
                    </Enviar>
                    <Cancelar
                      danger
                      onClick={onClose}
                      style={{ flex: 1 }}
                      size='large'
                    >
                      Cancelar
                    </Cancelar>
                  </div>
                )
              }}
            </Form.Item>
          </Form>
        </Drawer>
      </Skeleton>
    </Container>
  )
}

export { RelatorioServicoEquipamentos }
