import styled from 'styled-components'

export const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, #7c91c9 0%, #52c7f3 100%);
`
export const Content = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #fff;
  align-items: center;
  height: 650px;
  width: 400px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.3);
`

export const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const FormContainer = styled.aside`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  width: 100%;
  margin-bottom: 20px;
`
// #52c7f3
// #7c91c9
