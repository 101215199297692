/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react'
import {
  message,
  Table,
  Space,
  Button,
  Drawer,
  Popconfirm,
  Descriptions,
  Modal,
  Typography,
  Tooltip,
  Form,
  Spin,
  InputNumber,
  Select,
  Switch,
  Tag,
  Pagination,
  Empty,
  Divider,
  Input,
  Card,
} from 'antd'
import NumberFormat from 'react-number-format'
import {
  EditOutlined,
  DeleteOutlined,
  FileAddOutlined,
  SearchOutlined,
  SubnodeOutlined,
  CloseOutlined,
  CheckOutlined,
  PlusOutlined,
  SettingOutlined,
  EllipsisOutlined,
  InboxOutlined,
} from '@ant-design/icons'
import { useHistory, useParams } from 'react-router-dom'
import { Container, PageTitle } from 'components'
import { formatarMoeda } from 'services/utils'
import { Enviar, Cancelar } from 'components/Buttons'
import api from 'services/api'
import Meta from 'antd/lib/card/Meta'
import Dragger from 'antd/lib/upload/Dragger'
import AuthContext, { TOKEN_API } from 'contexts/auth'

const { Column } = Table
const { Text, Paragraph } = Typography
const { TextArea } = Input

function ItemPhotos() {
  const { user } = useContext(AuthContext)
  const { itemId } = useParams()
  const history = useHistory()
  const [form] = Form.useForm()
  const [item, setItem] = useState({})
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [load, setLoad] = useState(false)
  const [mode, setMode] = useState('insert')
  const [fileList, setFileList] = useState([])
  const token = localStorage.getItem(TOKEN_API)
  const [photo, setPhoto] = useState({})

  useEffect(() => {
    loadServicos()
  }, [load])

  async function loadServicos() {
    setLoading(true)
    await api
      .get(`/ordem_servico/listar-item/${itemId}`)
      .then(({ data }) => {
        console.log(data)
        setItem(data)
      })
      .catch(() => {
        message.error('Erro ao carregar os dados, atualize a pagina')
        setLoading(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  function onCloseDrawer() {
    setVisible(false)
    form.resetFields()
    setFileList([])
  }

  const handleDeletePhoto = async (photoId) => {
    await api.delete(`/ordem_servico/photos/${photoId}`).finally(() => {
      setLoad(!load)
    })
  }

  const handleSubmit = async (value) => {
    const { foto, description } = value
    if (mode === 'insert') {
      try {
        setLoading(true)
        await api
          .post('/ordem_servico/photos', {
            servicoItemId: itemId,
            photo: foto?.file?.response?.url,
            description,
          })
          .then(() => {
            setLoading(false)
          })
          .finally(() => {
            setLoad(!load)
            setVisible(false)
          })
      } catch (error) {
        message.error('Erro ao carregar os dados, atualize a pagina')
        setLoading(false)
      }
    } else {
      try {
        setLoading(true)
        await api
          .put(`/ordem_servico/photos/${photo.id}`, {
            photo: foto?.file?.response?.url,
            description,
          })
          .then(() => {
            setLoading(false)
          })
          .finally(() => {
            setLoad(!load)
            onCloseDrawer()
          })
      } catch (error) {
        message.error('Erro ao carregar os dados, atualize a pagina')
        setLoading(false)
      }
    }
  }

  const props = {
    name: 'file',
    multiple: false,
    listType: 'picture',
    data: {
      pasta: 'servico_fotos',
      organization: user?.organization.bucket_name,
    },
    action: `${process.env.REACT_APP_API}upload`,
    headers: { Authorization: `Bearer ${token}` },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        // setValue('slideImage', info.file.response.url)
        message.success(`${info.file.name} Arquivo enviado com sucesso`)
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
      if (info.fileList) {
        setFileList(info.fileList)
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files)
    },
  }

  return (
    <Container>
      <PageTitle
        title={item?.item?.item?.description}
        subTitle={`Item ID: ${itemId}`}
        onBack={() => history.goBack()}
        extra={[
          <Button
            icon={<PlusOutlined />}
            key='insert'
            type='primary'
            size='large'
            onClick={() => {
              setVisible(true)
              setMode('insert')
            }}
          >
            Adicionar Foto
          </Button>,
        ]}
      />
      <Space style={{ margin: 20, flexWrap: 'wrap' }}>
        {item?.fotos?.map((foto) => {
          return (
            <Card
              key={foto.id}
              style={{ width: 300 }}
              cover={
                <img
                  alt='example'
                  src={foto.photo}
                  style={{ height: 250, objectFit: 'cover' }}
                />
              }
              actions={[
                <Button
                  type='text'
                  key='edit'
                  icon={<EditOutlined />}
                  onClick={() => {
                    setFileList([
                      {
                        uid: '-1',
                        name: `${foto.id}.png`,
                        status: 'done',
                        url: foto.photo,
                        response: { url: foto.photo },
                        thumbUrl: foto.photo,
                      },
                    ])
                    setVisible(true)
                    setMode('update')
                    setPhoto(foto)
                    form.setFieldsValue({
                      ...foto,
                      foto: {
                        file: {
                          uid: '-1',
                          name: `${foto.id}.png`,
                          status: 'done',
                          url: foto.photo,
                          response: { url: foto.photo },
                          thumbUrl: foto.photo,
                        },
                      },
                    })
                  }}
                >
                  Editar
                </Button>,
                <Popconfirm
                  key='delete'
                  title='Dejesa deletar essa foto?'
                  onConfirm={() => handleDeletePhoto(foto.id)}
                  okText='Sim'
                  cancelText='Não'
                >
                  <Button
                    type='text'
                    key='delete'
                    danger
                    icon={<DeleteOutlined />}
                  >
                    Deletar
                  </Button>
                </Popconfirm>,
              ]}
            >
              <Meta description={foto.description} />
            </Card>
          )
        })}
      </Space>
      <Drawer
        visible={visible}
        onClose={onCloseDrawer}
        width={550}
        title='Trocar Item'
      >
        <Spin spinning={loading}>
          <Form form={form} layout='vertical' onFinish={handleSubmit}>
            <Form.Item
              label='Descrição'
              name='description'
              rules={[
                {
                  max: 255,
                  required: true,
                  message: 'Por favor, a descrição deve ser informada',
                },
              ]}
            >
              <TextArea
                size='large'
                type='text'
                autoSize={{ minRows: 3, maxRows: 5 }}
                showCount
                maxLength={255}
              />
            </Form.Item>
            <Form.Item
              label='Foto'
              name='foto'
              rules={[
                {
                  required: true,
                  message: 'Por favor, uma foto deve ser enviada.',
                },
              ]}
            >
              <Dragger {...props} fileList={fileList}>
                <p className='ant-upload-drag-icon'>
                  <InboxOutlined />
                </p>
                <p className='ant-upload-text'>
                  Click or drag file to this area to upload
                </p>
                <p className='ant-upload-hint'>
                  Support for a single or bulk upload. Strictly prohibit from
                  uploading company data or other band files
                </p>
              </Dragger>
            </Form.Item>
            <Divider style={{ borderColor: '#aaa' }} />
            <div style={{ display: 'flex' }}>
              <Enviar
                type='primary'
                htmlType='submit'
                size='large'
                style={{ flex: 1 }}
              >
                Cadastrar
              </Enviar>{' '}
              <Cancelar
                danger
                size='large'
                onClick={onCloseDrawer}
                style={{ flex: 1 }}
              >
                Cancelar
              </Cancelar>
            </div>
          </Form>
        </Spin>
      </Drawer>
    </Container>
  )
}

export default ItemPhotos
