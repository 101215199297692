/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react'
import {
  message,
  Table,
  Space,
  Button,
  Drawer,
  Popconfirm,
  Tooltip,
  Form,
  Input,
  Spin,
  Empty,
  Divider,
} from 'antd'
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import api from 'services/api'
import { Container, PageTitle } from 'components'
import { Enviar, Cancelar } from 'components/Buttons'
import { Helmet } from 'react-helmet'

const { Column } = Table

function Questions() {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [mode, setMode] = useState('insert')
  const [load, setLoad] = useState(false)
  const [questions, setQuestions] = useState([])
  const [question, setQuestion] = useState(null)

  useEffect(() => {
    loadData()
  }, [load])

  const openDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    form.resetFields()
    setMode('insert')
    setVisible(false)
    setLoad(!load)
  }

  function handleQuestiondEditing(question) {
    setQuestion(question)
    form.setFieldsValue({
      question: question.question,
    })
    setMode('update')
    openDrawer()
  }

  async function loadData() {
    setLoading(true)
    await api
      .get('/questions')
      .then(({ data }) => {
        setQuestions(data)
      })
      .catch((error) => {
        console.log(error)
        message.error('Erro ao carregar os dados, atualize a pagina')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  async function handleSubmitForm(value) {
    setLoading(true)
    if (mode === 'insert') {
      const data = {
        question: value.question.toUpperCase(),
      }
      api
        .post('/questions', data)
        .then(() => {
          setLoading(false)
          message.success('Cadastro realizado com sucesso!')
          onClose()
        })
        .catch((e) => {
          setLoading(false)
          if (e.response.data.errors) {
            e.response.data.errors.forEach((info) => {
              message.error(info.message)
            })
          } else {
            message.error(e.response.data.error)
          }
        })
    } else {
      const data = {
        question: value.question.toUpperCase(),
      }
      await api
        .put(`/questions/${question.id}`, data)
        .then(() => {
          setLoading(false)
          message.success('Cadastro atualizado com sucesso!')
          onClose()
        })
        .catch((e) => {
          setLoading(false)
          if (e.response.data.errors) {
            e.response.data.errors.forEach((info) => {
              message.error(info.message)
            })
          } else {
            message.error(e.response.data.error)
          }
        })
    }
  }

  async function handleDeleteQuestion(recordId) {
    await api
      .delete(`/pmocs/${recordId}`)
      .then(() => {
        message.success('Item excluido com sucesso')
      })
      .catch(() => {
        message.error('Erro ao excluir item, tente novamente')
      })
      .finally(() => {
        setLoad(!load)
      })
  }

  return (
    <Container>
      <Helmet>
        <title>Arcke | Serviços de PMOC</title>
      </Helmet>
      <PageTitle
        title='Serviços de PMOC'
        extra={[
          <Button
            type='primary'
            size='large'
            icon={<PlusOutlined />}
            onClick={() => {
              setMode('insert')
              openDrawer()
            }}
            key='1'
          >
            Adicionar Serviço de PMOC
          </Button>,
        ]}
      />
      <Table
        dataSource={questions}
        loading={loading}
        rowKey={(record) => record.id}
        size='middle'
        style={{ margin: 20 }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <>
                  <span>Não há registro aqui</span>
                </>
              }
            >
              <Button
                icon={<PlusOutlined />}
                key='insert'
                type='primary'
                size='large'
                onClick={() => {
                  setMode('insert')
                  openDrawer()
                }}
              >
                Adicionar
              </Button>
            </Empty>
          ),
        }}
      >
        <Column title='ID' dataIndex='id' key='id' />
        <Column title='Serviço' dataIndex='question' key='question' />
        <Column
          dataIndex='actions'
          key='actions'
          title='Ações'
          width={20}
          render={(_, record) => (
            <Space>
              <Tooltip title='Editar' placement='top'>
                <Button
                  icon={<EditOutlined />}
                  onClick={() => handleQuestiondEditing(record)}
                />
              </Tooltip>
              <Tooltip title='Excluir Serviço de PMOC' placement='top'>
                <Popconfirm
                  title='Deseja realmente excluir este item?'
                  okText='Sim'
                  cancelText='Não'
                  onConfirm={() => handleDeleteQuestion(record.id)}
                >
                  <Button
                    icon={<DeleteOutlined />}
                    style={{ color: '#e80909' }}
                  />
                </Popconfirm>
              </Tooltip>
            </Space>
          )}
        />
      </Table>
      <Drawer
        visible={visible}
        onClose={onClose}
        width={550}
        title={
          mode === 'insert'
            ? 'Cadastrar serviço de PMOC'
            : 'Atualizar serviço de PMOC'
        }
      >
        <Spin spinning={loading}>
          <Form form={form} layout='vertical' onFinish={handleSubmitForm}>
            <Form.Item
              label='Serviço de PMOC'
              name='question'
              rules={[
                {
                  required: true,
                  message: 'Por favor, o serviço de PMOC deve ser informado.',
                },
              ]}
            >
              <Input size='large' />
            </Form.Item>
            <Divider style={{ borderColor: '#aaa' }} />
            <div style={{ display: 'flex' }}>
              <Enviar
                disabled={mode === 'view'}
                type='primary'
                htmlType='submit'
                size='large'
                style={{ flex: 1 }}
              >
                {mode === 'insert' ? 'Cadastrar' : 'Atualizar'}
              </Enviar>
              <Cancelar
                size='large'
                danger
                onClick={onClose}
                style={{ flex: 1 }}
              >
                Cancelar
              </Cancelar>
            </div>
          </Form>
        </Spin>
      </Drawer>
    </Container>
  )
}

export default Questions
