import styled from 'styled-components'

export const DashboardContainer = styled.div`
  width: 100%;
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #25569d;
  }
  div.custom-tooltip {
    background-color: white;
    padding: 10px;
    border-radius: 10px;

    .label {
      font-size: 16px;
      color: black;
    }
    .desc {
      color: gray;
    }
    span {
      color: black;
      font-size: 16px;
    }
  }
`
