import React from 'react'
import { message, Drawer, Form, Input, Spin, Select, Divider } from 'antd'
import api from '../../../services/api'
import { Cancelar, Enviar } from '../../../components/Buttons'
import NumberFormat from 'react-number-format'

const { Option } = Select

export function DrawerCadastrarPeca({
  isVisible,
  onClose,
  loading,
  setLoading,
}) {
  const [form] = Form.useForm()

  return (
    <Drawer
      visible={isVisible}
      onClose={() => {
        form.resetFields()
        onClose()
      }}
      width={550}
      title='Cadastrar Peça'
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout='vertical'
          onFinish={(value) => {
            setLoading(true)
            const data = {
              ...value,
              name: value.name.toUpperCase(),
              description: value.description?.toUpperCase(),
            }
            api
              .post('/parts', data)
              .then(() => {
                message.success('Cadastro realizado com sucesso!')
                form.resetFields()
                onClose()
              })
              .catch((e) => {
                if (e.response.data.errors) {
                  e.response.data.errors.forEach((info) => {
                    message.error(info.message)
                  })
                } else {
                  message.error(e.response.data.error)
                }
              })
              .finally(() => {
                setLoading(false)
              })
          }}
        >
          <Form.Item
            label='Nome'
            name='name'
            rules={[
              {
                required: true,
                message: 'Por favor, o nome da peça deve ser informado',
              },
            ]}
          >
            <Input size='large' />
          </Form.Item>
          <Form.Item label='Descrição' name='description'>
            <Input size='large' />
          </Form.Item>
          <Form.Item
            label='Unidade'
            name='unit'
            rules={[
              {
                required: true,
                message: 'Por favor, a unidade deve ser informada',
              },
            ]}
          >
            <Select
              placeholder='Selecione...'
              optionLabelProp='label'
              size='large'
            >
              <Option key='UN' value='UN' label='UN - Unidade'>
                UN - Unidade
              </Option>
              <Option key='CX' value='CX' label='CX - Caixa'>
                CX - Caixa
              </Option>
              <Option key='CJ' value='CJ' label='CJ - Conjunto'>
                CJ - Conjunto
              </Option>
              <Option key='KIT' value='KIT' label='KIT - Kit'>
                KIT - Kit
              </Option>
              <Option key='BR' value='BR' label='BR - Barra'>
                BR - Barra
              </Option>
              <Option key='M1' value='M1' label='M - Metro'>
                M - Metro
              </Option>
              <Option key='M2' value='M2' label='M2 - Metro Quadrado'>
                M2 - Metro Quadrado
              </Option>
              <Option key='M3' value='M3' label='M3 - Metro Cúbico'>
                M3 - Metro Cúbico
              </Option>
              <Option key='GL' value='GL' label='GL - Galão'>
                GL - Galão
              </Option>
              <Option key='KG' value='KG' label='KG - Quilograma'>
                KG - Quilograma
              </Option>
              <Option key='LT' value='LT' label='LT - Litro'>
                LT - Litro
              </Option>
              <Option key='TON' value='TON' label='TON - Tonelada'>
                TON - Tonelada
              </Option>
              <Option key='PC' value='PC' label='PC - Pacote'>
                PC - Pacote
              </Option>
              <Option key='ROLO' value='ROLO' label='ROLO - Rolo'>
                ROLO - Rolo
              </Option>
            </Select>
          </Form.Item>
          <Form.Item
            label='Valor'
            name='price'
            trigger='onValueChange'
            rules={[
              {
                required: true,
                message: 'Por favor, o valor deve ser informado',
              },
            ]}
          >
            <NumberFormat
              style={{ padding: '8px' }}
              thousandSeparator='.'
              decimalSeparator=','
              prefix='R$'
              decimalScale={2}
              className='ant-input'
              fixedDecimalScale
              onValueChange={(value) =>
                form.setFieldsValue({ price: value.floatValue })
              }
            />
          </Form.Item>
          <Divider style={{ borderColor: '#bbb' }} />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Enviar
              type='primary'
              htmlType='submit'
              size='large'
              style={{ flex: 1 }}
            >
              Cadastrar
            </Enviar>
            <Cancelar danger onClick={onClose} size='large' style={{ flex: 1 }}>
              Cancelar
            </Cancelar>
          </div>
        </Form>
      </Spin>
    </Drawer>
  )
}
