import React, { useEffect, useState } from 'react'
import {
  message,
  Table,
  Space,
  Button,
  Drawer,
  Popconfirm,
  Tooltip,
  Form,
  Input,
  Spin,
  Empty,
  Select,
  Divider,
} from 'antd'
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import api from '../../services/api'
import { Container, PageTitle } from '../../components'
import { Enviar, Cancelar } from '../../components/Buttons'
import { Helmet } from 'react-helmet'
import NumberFormat from 'react-number-format'
import { formatarMoeda } from 'services/utils'

const { Column } = Table
const { Option } = Select

const Pecas = () => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [load, setLoad] = useState(false)
  const [mode, setMode] = useState('insert')
  const [visible, setVisible] = useState(false)
  const [part, setPart] = useState({})
  const [parts, setParts] = useState([])
  const [meta, setMeta] = useState({ current_page: 1, per_page: 10 })

  useEffect(() => {
    loadParts()
  }, [load])

  const loadParts = async () => {
    setLoading(true)
    await api
      .get(`/parts/?page=${meta.current_page}&pageSize=${meta.per_page}`)
      .then(({ data }) => {
        setParts(data.data)
        setMeta(data.meta)
      })
      .catch((error) => {
        console.log(error)
        message.error('Erro ao carregar os dados, atualize a pagina')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onOpenDrawer = () => {
    setVisible(true)
  }

  const onCloseDrawer = () => {
    form.resetFields()
    setVisible(false)
    setLoad(!load)
  }

  const onSubmit = async (value) => {
    setLoading(true)
    const data = {
      ...value,
      name: value.name.toUpperCase(),
      description: value.description?.toUpperCase(),
    }

    if (mode === 'insert') {
      await api
        .post('/parts', data)
        .then(() => {
          message.success('Cadastro realizado com sucesso!')
          onCloseDrawer()
        })
        .catch((e) => {
          if (e.response.data.errors) {
            e.response.data.errors.forEach((info) => {
              message.error(info.message)
            })
          } else {
            message.error(e.response.data.error)
          }
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      await api
        .put(`/parts/${part.id}`, data)
        .then(() => {
          message.success('Cadastro realizado com sucesso!')
          onCloseDrawer()
        })
        .catch((e) => {
          if (e.response.data.errors) {
            e.response.data.errors.forEach((info) => {
              message.error(info.message)
            })
          } else {
            message.error(e.response.data.error)
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const actions = (_, record) => {
    return (
      <Space>
        <Tooltip title='Editar' placement='top'>
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              setPart(record)
              form.setFieldsValue({ ...record, price: Number(record.price) })
              setMode('update')
              onOpenDrawer()
            }}
          />
        </Tooltip>
        <Tooltip title='Excluir Peça' placement='top'>
          <Popconfirm
            title='Deseja realmente excluir este item?'
            okText='Sim'
            cancelText='Não'
            onConfirm={async () => {
              await api
                .delete(`/parts/${record.id}`)
                .then(() => {
                  message.success('Item excluido com sucesso')
                  setLoad(!load)
                })
                .catch(() => {
                  message.error('Erro ao excluir item, tente novamente')
                })
            }}
          >
            <Button icon={<DeleteOutlined />} style={{ color: '#e80909' }} />
          </Popconfirm>
        </Tooltip>
      </Space>
    )
  }

  const routes = [
    {
      path: '/',
      breadcrumbName: 'Cadastros',
    },
  ]

  return (
    <Container>
      <Helmet>
        <title>Arcke | Peças</title>
      </Helmet>
      <Spin spinning={loading}>
        <PageTitle
          title='Peças'
          breadcrumb={{ routes }}
          breadcrumbRender={(props, breadcrumb) => (
            <div style={{ marginBottom: -4 }}>{breadcrumb}</div>
          )}
          extra={[
            <Button
              type='primary'
              size='large'
              icon={<PlusOutlined />}
              onClick={() => {
                setMode('insert')
                onOpenDrawer()
              }}
              key='1'
            >
              Cadastrar Peça
            </Button>,
          ]}
        />
        <Table
          dataSource={parts}
          loading={loading}
          rowKey={(record) => record.id}
          size='middle'
          pagination={{
            total: meta?.total,
            pageSize: meta?.per_page,
            current: meta?.current_page,
            showTotal: (total) => `Total de ${total} itens`,
            onChange: async (page, pageSize) => {
              setMeta({
                ...meta,
                per_page: pageSize,
                current_page: page,
              })
              setLoad(!load)
            },
          }}
          style={{ margin: 20 }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <>
                    <span>Não há registro aqui</span>
                  </>
                }
              >
                <Button
                  icon={<PlusOutlined />}
                  key='insert'
                  type='primary'
                  size='large'
                  onClick={() => {
                    setMode('insert')
                    onOpenDrawer()
                  }}
                >
                  Cadastrar
                </Button>
              </Empty>
            ),
          }}
        >
          <Column title='Peça' dataIndex='name' key='name' />
          <Column title='Descrição' dataIndex='description' key='description' />
          <Column title='Unidade' dataIndex='unit' key='unit' />
          <Column
            title='Preço'
            dataIndex='price'
            key='price'
            render={(text) => `${formatarMoeda(Number(text))}`}
          />
          <Column
            dataIndex='actions'
            key='actions'
            title='Ações'
            width={20}
            render={actions}
          />
        </Table>
        <Drawer
          visible={visible}
          onClose={onCloseDrawer}
          width={550}
          title={`${mode === 'insert' ? 'Cadastrar' : 'Atualizar'} Peça`}
        >
          <Spin spinning={loading}>
            <Form form={form} layout='vertical' onFinish={onSubmit}>
              <Form.Item
                label='Nome'
                name='name'
                rules={[
                  {
                    required: true,
                    message: 'Por favor, o nome da peça deve ser informado',
                  },
                ]}
              >
                <Input size='large' />
              </Form.Item>
              <Form.Item label='Descrição' name='description'>
                <Input size='large' />
              </Form.Item>
              <Form.Item
                label='Unidade'
                name='unit'
                rules={[
                  {
                    required: true,
                    message: 'Por favor, a unidade deve ser informada',
                  },
                ]}
              >
                <Select
                  placeholder='Selecione...'
                  optionLabelProp='label'
                  size='large'
                >
                  <Option key='UN' value='UN' label='UN - Unidade'>
                    UN - Unidade
                  </Option>
                  <Option key='CX' value='CX' label='CX - Caixa'>
                    CX - Caixa
                  </Option>
                  <Option key='CJ' value='CJ' label='CJ - Conjunto'>
                    CJ - Conjunto
                  </Option>
                  <Option key='KIT' value='KIT' label='KIT - Kit'>
                    KIT - Kit
                  </Option>
                  <Option key='BR' value='BR' label='BR - Barra'>
                    BR - Barra
                  </Option>
                  <Option key='M1' value='M1' label='M - Metro'>
                    M - Metro
                  </Option>
                  <Option key='M2' value='M2' label='M2 - Metro Quadrado'>
                    M2 - Metro Quadrado
                  </Option>
                  <Option key='M3' value='M3' label='M3 - Metro Cúbico'>
                    M3 - Metro Cúbico
                  </Option>
                  <Option key='GL' value='GL' label='GL - Galão'>
                    GL - Galão
                  </Option>
                  <Option key='KG' value='KG' label='KG - Quilograma'>
                    KG - Quilograma
                  </Option>
                  <Option key='LT' value='LT' label='LT - Litro'>
                    LT - Litro
                  </Option>
                  <Option key='TON' value='TON' label='TON - Tonelada'>
                    TON - Tonelada
                  </Option>
                  <Option key='PC' value='PC' label='PC - Pacote'>
                    PC - Pacote
                  </Option>
                  <Option key='ROLO' value='ROLO' label='ROLO - Rolo'>
                    ROLO - Rolo
                  </Option>
                </Select>
              </Form.Item>
              <Form.Item
                label='Valor'
                name='price'
                trigger='onValueChange'
                rules={[
                  {
                    required: true,
                    message: 'Por favor, o valor deve ser informado',
                  },
                ]}
              >
                <NumberFormat
                  style={{ padding: '8px' }}
                  thousandSeparator='.'
                  decimalSeparator=','
                  prefix='R$'
                  decimalScale={2}
                  className='ant-input'
                  fixedDecimalScale
                  onValueChange={(value) =>
                    form.setFieldsValue({ price: value.floatValue })
                  }
                />
              </Form.Item>
              <Divider style={{ borderColor: '#bbb' }} />
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Enviar
                  disabled={mode === 'view'}
                  type='primary'
                  htmlType='submit'
                  size='large'
                  style={{ flex: 1 }}
                >
                  {mode === 'insert' ? 'Cadastrar' : 'Atualizar'}
                </Enviar>
                <Cancelar
                  danger
                  onClick={onCloseDrawer}
                  size='large'
                  style={{ flex: 1 }}
                >
                  Cancelar
                </Cancelar>
              </div>
            </Form>
          </Spin>
        </Drawer>
      </Spin>
    </Container>
  )
}

export { Pecas }
