/* eslint-disable no-shadow */
import { message } from 'antd'
import React, { createContext, useContext, useEffect, useState } from 'react'
import api from 'services/api'

export const TOKEN_API = '@arcke:token_api'
export const STORAGE_USER = '@arcke:user_data'

const AuthContext = createContext({
  setUser: () => {},
  signed: false,
  user: {},
  login: () => Promise,
  logout: () => {},
  loading: false,
})

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function loadStorageData() {
      const storageUser = localStorage.getItem(STORAGE_USER)
      const storageToken = localStorage.getItem(TOKEN_API)

      if (storageToken && storageUser) {
        setUser(JSON.parse(storageUser))
        setLoading(false)
        api.defaults.headers.common.Authorization = `Bearer ${storageToken}`
      } else if (!storageUser || !storageToken) {
        localStorage.clear()
        setUser(null)
        setLoading(false)
      }
    }
    loadStorageData()
  }, [])

  useEffect(() => {
    api.interceptors.response.use(undefined, (error) => {
      if (error.response) {
        if (error.response.status === 401) {
          if (error.response.data.errors) {
            message.warn('Login expirado, favor entrar novamente!')
            localStorage.clear()
            setUser(null)
          }
        }
      }
      return Promise.reject(error)
    })
  }, [])

  async function login(data) {
    setLoading(true)
    return new Promise((resolve, reject) => {
      api
        .post('/plogin', data)
        .then((response) => {
          setLoading(false)
          const { token, user } = response.data
          setUser(user)

          api.defaults.headers.common.Authorization = `Bearer ${token}`
          api.defaults.headers.Authorization = `Bearer ${token}`

          localStorage.setItem(STORAGE_USER, JSON.stringify(user))
          localStorage.setItem(TOKEN_API, token)
          resolve(response)
        })
        .catch((erro) => {
          setLoading(false)
          reject(erro)
        })
    })
  }

  async function logout() {
    return new Promise((resolve) => {
      api.post('/logout').then((response) => {
        localStorage.clear()
        setUser(null)
        resolve(response)
      })
    })
  }

  return (
    <AuthContext.Provider
      value={{ signed: !!user, user, login, logout, loading, setUser }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const context = useContext(AuthContext)
  return context
}

export default AuthContext
