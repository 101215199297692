import React, { useContext, useEffect, useState } from 'react'
import {
  Skeleton,
  message,
  Table,
  Button,
  Drawer,
  Form,
  Spin,
  Select,
  Menu,
  Dropdown,
  Empty,
  Divider,
} from 'antd'
import {
  FilterOutlined,
  DownloadOutlined,
  DownOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import api, { pdfUrl } from '../../services/api'
import { Container, PageTitle } from '../../components'
import { Enviar, Cancelar } from '../../components/Buttons'
import { format } from 'date-fns'
import { Helmet } from 'react-helmet'
import AuthContext from 'contexts/auth'
import axios from 'axios'
import Tag from 'antd/es/tag'

const { Column } = Table

function RelatorioEquipamentos() {
  const { user } = useContext(AuthContext)
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState([])
  const [tableData, settableData] = useState([])
  const [clientes, setClientes] = useState([])
  const [loadingTable, setLoadingTable] = useState(false)
  const [loadingSelect, setLoadingSelect] = useState(false)

  const [filter, setFilter] = useState(null)

  const onClose = () => {
    // form.resetFields()
    setVisible(false)
  }

  const loadClients = async () => {
    setLoadingSelect(true)
    await api.get('/client').then(({ data }) => {
      setClientes(data)
    })
  }

  const openDrawer = () => {
    setVisible(true)
  }

  useEffect(() => {
    if (filter) {
      onSubmit(filter)
    }
  }, [])

  const onSubmit = async (value) => {
    setLoadingTable(true)
    setFilter(value)
    try {
      await api
        .get(`/reports/equipment-list/`, {
          params: {
            clientId: value.clientId,
          },
        })
        .then(({ data }) => {
          console.log(data)
          const setores = []
          data.locais.forEach((local) => {
            local.setores.forEach((setor) => {
              setores.push(setor)
            })
          })
          setData(data)
          settableData(setores)
        })
    } catch (err) {
      message.error('Informe as datas corretas')
    } finally {
      setLoadingTable(false)
    }
  }

  const menu = () => (
    <Menu>
      <Menu.Item key='1'>
        <Button
          icon={<DownloadOutlined />}
          disabled={tableData.length < 1}
          onClick={() => tableExportToPdf()}
          style={{ width: '100%' }}
        >
          Baixar Relatório
        </Button>
      </Menu.Item>
    </Menu>
  )

  const tableExportToPdf = async () => {
    setLoadingTable(true)

    const dateInicio = format(new Date(), 'yyyy/MM/dd')

    await axios
      .post(
        `${pdfUrl}equipment-report/`,
        { user, data },
        {
          responseType: 'blob',
        }
      )
      .then((response) => {
        message.success({
          content: 'Baixando PDF...',
        })
        // const newWin = window.open(
        //   'about:blank',
        //   'Axios data',
        //   'width=841,height=800'
        // )
        // newWin.document.write(response.data)
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `${dateInicio
            .replace('/', '-')
            .replace('/', '-')
            .replace('/', '-')}.pdf`
        )
        document.body.appendChild(link)
        link.click()
      })
    setLoadingTable(false)
  }

  return (
    <Container>
      <Helmet>
        <title>Arcke | Relatório - Equipamentos</title>
      </Helmet>
      <Skeleton loading={false} active>
        <PageTitle
          title='Relatório de Listagem de Equipamentos'
          extra={[
            <Dropdown
              key='1'
              trigger={['click']}
              overlay={menu}
              placement='bottomCenter'
            >
              <Button size='large'>
                Exportar Tabela <DownOutlined />
              </Button>
            </Dropdown>,
            <Button
              icon={<FilterOutlined />}
              type='primary'
              size='large'
              onClick={() => {
                openDrawer()
              }}
              key='2'
            >
              Filtrar
            </Button>,
          ]}
        />
        <Table
          dataSource={tableData}
          scroll={{ x: 1500 }}
          pagination={{
            total: data?.length,
          }}
          loading={loadingTable}
          rowKey={(record) => record.id}
          size='middle'
          style={{ margin: 20 }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <>
                    <span>Não há registro aqui</span>
                  </>
                }
              >
                <Button
                  icon={<PlusOutlined />}
                  key='insert'
                  type='primary'
                  size='large'
                  onClick={() => {
                    openDrawer()
                  }}
                >
                  Adicionar
                </Button>
              </Empty>
            ),
          }}
        >
          <Column
            title='ID'
            dataIndex='id'
            width={100}
            key='id'
            fixed
            render={(_, record) => <Tag>{record?.id}</Tag>}
          />
          <Column
            title='Contrato'
            width={250}
            fixed
            dataIndex='name'
            key='name'
          />
          <Column
            title='Equipmentos'
            width={170}
            dataIndex='equipments'
            key='equipments'
            render={(_, record) => record?.equipments?.length}
          />
        </Table>
        <Drawer
          visible={visible}
          onClose={onClose}
          width={550}
          title='Filtro de Relatório'
        >
          <Spin spinning={false}>
            <Form form={form} layout='vertical' onFinish={onSubmit}>
              <Form.Item
                rules={[{ required: true, message: 'Selecione um cliente' }]}
                label='Clientes'
                name='clientId'
              >
                <Select
                  size='large'
                  showSearch
                  placeholder='Selecione...'
                  allowClear
                  notFoundContent={loadingSelect ? <Spin /> : null}
                  onFocus={() => {
                    if (clientes.length < 1) {
                      loadClients()
                    }
                  }}
                  optionLabelProp='label'
                  optionFilterProp='children'
                >
                  {clientes.map((cliente) => (
                    <Select.Option
                      key={cliente.id}
                      value={cliente.id}
                      label={cliente.nomeFantasia}
                    >
                      {cliente.nomeFantasia}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Divider style={{ borderColor: '#aaa' }} />
              <div style={{ display: 'flex' }}>
                <Enviar
                  type='primary'
                  htmlType='submit'
                  style={{ flex: 1 }}
                  size='large'
                >
                  Gerar Relatório
                </Enviar>
                <Cancelar
                  danger
                  onClick={onClose}
                  style={{ flex: 1 }}
                  size='large'
                >
                  Cancelar
                </Cancelar>
              </div>
            </Form>
          </Spin>
        </Drawer>
      </Skeleton>
    </Container>
  )
}

export { RelatorioEquipamentos }
