import React, { useState, useEffect, useContext, useRef } from 'react'
import {
  Button,
  Table,
  message,
  Space,
  Modal,
  Typography,
  Dropdown,
  Menu,
  Empty,
  Divider,
  Tag,
  Input,
} from 'antd'
import { withRouter, useHistory } from 'react-router-dom'
import {
  EditOutlined,
  DeleteOutlined,
  ReconciliationOutlined,
  PlusOutlined,
  EnvironmentOutlined,
  SolutionOutlined,
  MoreOutlined,
  EyeOutlined,
  SearchOutlined,
  ClearOutlined,
} from '@ant-design/icons'
import { Container, PageTitle } from 'components'
import api from 'services/api'
import DrawerCliente from 'components/Drawer/DrawerCliente'
import DrawerContext from 'contexts/DrawerContext'
import { Helmet } from 'react-helmet'
import Highlighter from 'react-highlight-words'

const { Column } = Table
const { Text, Paragraph } = Typography

function Clientes() {
  const { load, setLoad } = useContext(DrawerContext)

  const history = useHistory()
  const [clients, setClients] = useState([])
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [client, setClient] = useState(null)
  const [modal, setModal] = useState(false)
  const [drawerData, setDrawerData] = useState({
    data: {},
    mode: '',
    visible: false,
  })

  useEffect(() => {
    async function loadClients() {
      try {
        setLoading(true)
        await api.get('/client').then(({ data }) => {
          setClients(data)
        })
      } catch (error) {
        message.error('Erro ao carregar os dados, atualize a pagina')
      } finally {
        setLoading(false)
      }
    }
    loadClients()
  }, [load])

  const onClose = () => {
    setDrawerData({
      data: {},
      mode: '',
      visible: false,
    })
    setLoading(false)
    setLoad(!load)
  }

  function openDrawer(record) {
    setDrawerData({
      data: record,
      mode: 'update',
      visible: true,
    })
  }

  const menu = (record) => (
    <Menu>
      <Menu.Item
        key={1}
        icon={<EyeOutlined />}
        onClick={() => {
          setClient(record)
          setOpen(true)
        }}
      >
        Visualizar
      </Menu.Item>
      <Menu.Item
        key={2}
        icon={<SolutionOutlined />}
        onClick={() => history.push(`/clientes/${record.id}/usuarios`)}
      >
        Central do Cliente
      </Menu.Item>
      <Menu.Item
        key={3}
        icon={<EditOutlined />}
        onClick={() => {
          openDrawer(record)
        }}
      >
        Editar
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key={4}
        icon={<ReconciliationOutlined />}
        onClick={() => history.push(`/clientes/${record.id}/equipamentos`)}
      >
        Equipamentos
      </Menu.Item>
      <Menu.Item
        key={5}
        icon={<EnvironmentOutlined />}
        onClick={() => history.push(`/clientes/${record.id}/locais`)}
      >
        Locais
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key={6}
        icon={<DeleteOutlined />}
        danger
        type='primary'
        onClick={() => {
          console.log('open')
          setModal(true)
        }}
      >
        Deletar
      </Menu.Item>
      <Modal
        destroyOnClose
        width='400px'
        title='Deseja deletar o contrato?'
        visible={modal}
        onCancel={() => setModal(false)}
        onOk={async () => {
          await api
            .delete(`/client/${record.id}`)
            .then(() => {
              message.success('Cliente excluido com sucesso')
              setLoad(!load)
            })
            .catch(() => {
              message.error('Erro ao excluir cliente, tente novamente')
            })
        }}
        okText='Deletar'
        cancelText='Cancelar'
      >
        <p>Tem certeza que deseja excluir o cliente?</p>
      </Modal>
    </Menu>
  )

  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const routes = [
    {
      path: '',
      breadcrumbName: 'Cadastros',
    },
  ]

  return (
    <Container>
      <Helmet>
        <title>Arcke | Clientes</title>
      </Helmet>
      <PageTitle
        title='Clientes'
        breadcrumb={{ routes }}
        breadcrumbRender={(props, breadcrumb) => (
          <div style={{ marginBottom: -4 }}>{breadcrumb}</div>
        )}
        s
        extra={[
          <Button
            icon={<PlusOutlined />}
            key='1'
            type='primary'
            size='large'
            onClick={() => {
              setDrawerData({
                data: {},
                mode: 'insert',
                visible: true,
              })
            }}
          >
            Cadastrar Cliente
          </Button>,
        ]}
      />
      <Table
        dataSource={clients}
        loading={loading}
        rowKey={(record) => record.id}
        size='small'
        style={{ margin: 20 }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <>
                  <span>Não há registro aqui</span>
                </>
              }
            >
              <Button
                icon={<PlusOutlined />}
                key='insert'
                type='primary'
                size='large'
                onClick={() => {
                  setDrawerData({
                    data: {},
                    mode: 'insert',
                    visible: true,
                  })
                }}
              >
                Cadastrar
              </Button>
            </Empty>
          ),
        }}
      >
        <Column
          title='Nome Fantasia'
          dataIndex='nomeFantasia'
          key='nomeFantasia'
          filterDropdown={({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
            <div style={{ padding: 8, display: 'flex', alignItems: 'center' }}>
              <Input
                ref={searchInput}
                placeholder={`Filtrar Nome`}
                value={selectedKeys[0]}
                onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() =>
                  handleSearch(selectedKeys, confirm, 'nomeFantasia')
                }
                style={{ display: 'block' }}
              />
              <Button
                type='primary'
                style={{ marginLeft: 6, marginRight: 6, minWidth: 32 }}
                onClick={() =>
                  handleSearch(selectedKeys, confirm, 'nomeFantasia')
                }
                icon={<SearchOutlined />}
              />
              <Button
                onClick={() => clearFilters && handleReset(clearFilters)}
                icon={<ClearOutlined />}
                style={{ minWidth: 32 }}
              />
            </div>
          )}
          filterIcon={(filtered) => (
            <SearchOutlined
              style={{ color: filtered ? '#1890ff' : '#777', fontSize: 15 }}
            />
          )}
          onFilter={(value, record) =>
            record.nomeFantasia
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          }
          onFilterDropdownOpenChange={(visible) => {
            if (visible) {
              setTimeout(() => searchInput.current?.select(), 100)
            }
          }}
          render={(_, record) =>
            searchedColumn === 'nomeFantasia' ? (
              <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={
                  record.nomeFantasia ? record.nomeFantasia.toString() : ''
                }
              />
            ) : (
              record.nomeFantasia
            )
          }
        />
        <Column
          title='Razão Social'
          dataIndex='razaoSocial'
          key='razaoSocial'
          render={(value, record) => {
            return record.clientType === 'fisica' ? (
              <Tag color={'blue'}>PESSOA FÍSICA</Tag>
            ) : (
              value
            )
          }}
        />
        <Column
          title='CPF/CNPJ'
          dataIndex='cpfCnpj'
          key='cnpj'
          filterIcon={(filtered) => (
            <SearchOutlined
              style={{ color: filtered ? '#1890ff' : '#777', fontSize: 15 }}
            />
          )}
          onFilter={(value, record) =>
            record.cpfCnpj
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          }
          filterDropdown={({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
            <div style={{ padding: 8, display: 'flex', alignItems: 'center' }}>
              <Input
                ref={searchInput}
                placeholder={`Filtrar CPF/CNPJ`}
                value={selectedKeys[0]}
                onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() =>
                  handleSearch(selectedKeys, confirm, 'cpfCnpj')
                }
                style={{ display: 'block' }}
              />

              <Button
                type='primary'
                onClick={() => handleSearch(selectedKeys, confirm, 'cpfCnpj')}
                icon={<SearchOutlined />}
                style={{ marginLeft: 6, marginRight: 6, minWidth: 32 }}
              />
              <Button
                onClick={() => clearFilters && handleReset(clearFilters)}
                style={{ minWidth: 32 }}
                icon={<ClearOutlined />}
              />
            </div>
          )}
          render={(_, record) =>
            searchedColumn === 'cpfCnpj' ? (
              <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={
                  record.cpfCnpj ? record.cpfCnpj.toString() : ''
                }
              />
            ) : (
              record.cpfCnpj
            )
          }
        />
        <Column
          key='actions'
          title='Ações'
          width={20}
          render={(_, record) => (
            <Space>
              <Dropdown
                overlay={menu(record)}
                placement='bottomRight'
                trigger={['click']}
                arrow
              >
                <Button icon={<MoreOutlined />} />
              </Dropdown>
            </Space>
          )}
        />
      </Table>
      {drawerData.visible === true && (
        <DrawerCliente
          data={drawerData}
          onClose={onClose}
          setLoading={setLoading}
          loading={loading}
        />
      )}
      <Modal
        title='Cliente'
        visible={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={null}
      >
        {client && (
          <>
            <Text strong>
              {client.razaoSocial ? 'Nome Fantasia' : 'Nome Completo'}
            </Text>
            <Paragraph>{client.nomeFantasia}</Paragraph>
            <Text strong>CPF/CNPJ</Text>
            <Paragraph>{client.cpfCnpj}</Paragraph>
            {client.clientType === 'juridica' && (
              <>
                <Text strong>Razão Social</Text>
                <Paragraph>{client.razaoSocial}</Paragraph>
                <Text strong>Inscrição Estadual</Text>
                <Paragraph>{client.ie}</Paragraph>
                <Text strong>Inscrição Municipal</Text>
                <Paragraph>{client.im}</Paragraph>
              </>
            )}
            <Divider />
            {client.address ? (
              <>
                <Text strong>CEP</Text>
                <Paragraph>{client.address?.cep}</Paragraph>
                <Text strong>Cidade</Text>
                <Paragraph>{client.address?.cidade}</Paragraph>
                <Text strong>Rua</Text>
                <Paragraph>{client.address?.rua}</Paragraph>
                <Text strong>Numero</Text>
                <Paragraph>{client.address?.numero}</Paragraph>
                <Divider />
              </>
            ) : null}
            {client.phones.length > 0 && (
              <>
                <Text strong>Telefone</Text>
                {client.phones.map(({ id, phone }) => (
                  <Paragraph key={id}>{phone}</Paragraph>
                ))}
              </>
            )}
            {client.emails.length > 0 && (
              <>
                <Text strong>Email</Text>
                {client.emails.map(({ id, email }) => (
                  <Paragraph key={id}>{email}</Paragraph>
                ))}
              </>
            )}
          </>
        )}
      </Modal>
    </Container>
  )
}

export default withRouter(Clientes)
