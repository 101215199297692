import styled from 'styled-components'
import { Layout, Dropdown, Avatar, Menu } from 'antd'

/* export const Container = styled.div`
  height: 5%;
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  background-color: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.5);
` */
export const Container = styled(Layout.Header)`
  background: #fff;
  padding: 0;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  /* position: fixed;
  z-index: 1;
  width: 100%; */
`

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-right: 10px;
`

export const ProfileButton = styled(Dropdown)`
  &:hover {
    cursor: pointer;
  }
`

export const ProfilePhoto = styled(Avatar)`
  margin-right: 10px;
`

export const DropDownMenu = styled(Menu)``

export const MenuItem = styled(Menu.Item)``

/* export const NotificationButton = styled.div`
  height: 100%;
  width: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #000000;
  font-size: 18px;
  margin-left: 5px;
  margin-right: 5px;
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
  }
` */

/* export const ProfileButton = styled.div`
  display: flex;
  width: 135px;
  flex-direction: row;
  align-items: center;
  height: 100%;
  margin: 5px;
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
  }
` */

/* export const ProfilePhoto = styled.img`
  margin-left: 5px;
  margin-right: 5px;
  height: 24px;
  width: 24px;
  border-radius: 24px;
` */

export const ProfileName = styled.p`
  margin: 0;
`

/* export const DropDownMenu = styled.div`
  position: absolute;
  top: 48px;
  width: 135px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  border-radius: 10px;
  background-color: #ffffff;
  overflow: hidden;
`

export const MenuItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
  padding-left: 10px;
  font-size: 14px;
  transition: background 0.5s;

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
  }
` */

export const Label = styled.label`
  margin-left: 10px;
  &:hover {
    cursor: pointer;
  }
`

export const Logo = styled.img`
  background-color: #fff;
  max-width: 80%;
  max-height: 120px;
`
