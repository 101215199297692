import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import {
  Table,
  message,
  Typography,
  Space,
  Tooltip,
  Button,
  Empty,
  Popconfirm,
  Drawer,
  Spin,
  Form,
  Input,
  Divider,
  Select,
} from 'antd'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Container, PageTitle } from '../../../components'
import { Cancelar, Enviar } from 'components/Buttons'
import NumberFormat from 'react-number-format'
import api from '../../../services/api'
import { formatarMoeda } from 'services/utils'
import { DrawerCadastrarPeca } from '../../Pecas/Drawer'

const { Column } = Table
const { Text } = Typography
const { Option } = Select

function PecasOrdem() {
  const { osId } = useParams()

  const [form] = Form.useForm()

  const [parts, setParts] = useState([])
  const [allParts, setAllParts] = useState([])
  const [loading, setLoading] = useState(false)
  const [load, setLoad] = useState(false)
  const [visible, setVisible] = useState(false)
  const [mode, setMode] = useState('insert')
  const [drawerCreatePieceIsVisible, setDrawerCreatePieceIsVisible] =
    useState(false)

  useEffect(() => {
    loadOrderParts()
  }, [load])

  useEffect(() => {
    loadParts()
  }, [])

  const openDrawerCreatePiece = () => {
    setDrawerCreatePieceIsVisible(true)
  }

  const onCloseDrawerCreatePiece = () => {
    setDrawerCreatePieceIsVisible(false)
    loadParts()
  }

  const loadOrderParts = async () => {
    setLoading(true)
    await api
      .get(`/ordem_servico/parts/${osId}`)
      .then(({ data }) => {
        setParts(data.parts)
      })
      .catch(() => {
        message.error('Erro ao carregar os dados, atualize a pagina')
        setLoading(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const loadParts = async () => {
    setLoading(true)
    await api
      .get(`/parts/all-parts`)
      .then(({ data }) => {
        setAllParts(data)
      })
      .catch((error) => {
        console.log(error)
        message.error('Erro ao carregar os dados, atualize a pagina')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onCloseDrawer = () => {
    form.resetFields()
    setVisible(false)
    setLoad(!load)
    setMode('insert')
  }

  const onSubmit = async (values) => {
    await api
      .post(`/ordem_servico/parts/${osId}`, values)
      .then(() => {
        message.success('Cadastro realizado com sucesso!')
        onCloseDrawer()
      })
      .catch((e) => {
        if (e.response.data.errors) {
          e.response.data.errors.forEach((info) => {
            message.error(info.message)
          })
        } else {
          message.error(e.response.data.error)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const filteredParts = allParts.filter((localItem) => {
    return !parts.find((serviceItem) => localItem.id === serviceItem.id)
  })

  const routes = [
    {
      path: '/os',
      breadcrumbName: 'Ordens de Serviço',
    },
  ]

  function itemRender(route) {
    return (
      <Link to={{ pathname: route.path }} key={route.breadcrumbName}>
        {route.breadcrumbName}
      </Link>
    )
  }

  return (
    <Container>
      <PageTitle
        title='Peças utilizadas'
        breadcrumb={{ routes, itemRender }}
        breadcrumbRender={(props, breadcrumb) => (
          <div style={{ marginBottom: -4 }}>{breadcrumb}</div>
        )}
        extra={[
          <Button
            type='primary'
            size='large'
            icon={<PlusOutlined />}
            onClick={() => {
              setVisible(true)
            }}
            key='1'
          >
            Adicionar Peça
          </Button>,
        ]}
      />
      <Table
        dataSource={parts}
        loading={loading}
        rowKey={(record) => record.id}
        size='middle'
        style={{ margin: 20 }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <>
                  <span>Não há registro aqui</span>
                </>
              }
            ></Empty>
          ),
        }}
      >
        {/* <Column
          title='ID'
          dataIndex='id'
          key='id'
          width={'60px'}
          render={(_, record) => <Tag>{record?.id}</Tag>}
        /> */}
        <Column title='Nome' dataIndex='name' key='name' filterSearch={true} />
        <Column
          title='Descrição'
          dataIndex='description'
          key='description'
          filterSearch={true}
        />
        <Column
          title='Quantidade'
          dataIndex='quantity'
          key='quantity'
          render={(_, record) => (
            <Text>
              {String(record.meta?.pivot_quantity.toFixed(3)).replace('.', ',')}
            </Text>
          )}
        />
        <Column
          title='Preço Unitário'
          dataIndex='price'
          key='price'
          render={(_, record) => (
            <Text>{formatarMoeda(record.meta?.pivot_price)}</Text>
          )}
        />
        <Column
          title='Preço Total'
          dataIndex='price'
          key='price'
          render={(_, record) => (
            <Text>
              {formatarMoeda(
                record.meta?.pivot_price * record.meta?.pivot_quantity
              )}
            </Text>
          )}
        />
        <Column
          key='actions'
          title='Ações'
          render={(_, record) => (
            <Space>
              <Tooltip placement='top' title='Editar Peça'>
                <Button
                  icon={<EditOutlined />}
                  onClick={() => {
                    form.setFieldsValue({
                      partId: record.id,
                      price: Number(record.meta?.pivot_price),
                      quantity: record.meta?.pivot_quantity,
                    })
                    setVisible(true)
                    setMode('update')
                  }}
                />
              </Tooltip>
              <Tooltip title='Excluir Peça' placement='top'>
                <Popconfirm
                  title='Deseja realmente excluir este item?'
                  okText='Sim'
                  cancelText='Não'
                  onConfirm={async () => {
                    await api
                      .delete(`/ordem_servico/parts/${osId}`, {
                        params: {
                          partId: record.id,
                        },
                      })
                      .then(() => {
                        message.success('Item excluido com sucesso')
                      })
                      .catch(() => {
                        message.error('Erro ao excluir item, tente novamente')
                      })
                      .finally(() => {
                        setLoad(!load)
                      })
                  }}
                >
                  <Button
                    icon={<DeleteOutlined />}
                    style={{ color: '#e80909' }}
                  />
                </Popconfirm>
              </Tooltip>
            </Space>
          )}
        />
      </Table>
      <Drawer
        visible={visible}
        onClose={onCloseDrawer}
        width={550}
        title='Adicionar Peça'
      >
        <Spin spinning={loading}>
          <Form form={form} layout='vertical' onFinish={onSubmit}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Form.Item
                label='Peça'
                name='partId'
                style={{ width: '100%' }}
                rules={[
                  {
                    required: true,
                    message: 'Por favor, a peça deve ser informada',
                  },
                ]}
              >
                <Select
                  size='large'
                  style={{ width: '100%' }}
                  disabled={mode !== 'insert'}
                  showSearch
                  removeIcon
                  allowClear
                  optionFilterProp='children'
                  onSelect={(value) => {
                    const part = allParts.find((part) => part.id === value)
                    form.setFieldsValue({ price: Number(part?.price) })
                  }}
                >
                  {mode === 'insert'
                    ? filteredParts.map((part) => {
                        return (
                          <Option value={part.id} key={part.id}>
                            {part.name}
                          </Option>
                        )
                      })
                    : allParts.map((part) => {
                        return (
                          <Option value={part.id} key={part.id}>
                            {part.name}
                          </Option>
                        )
                      })}
                </Select>
              </Form.Item>
              <Button
                size='large'
                style={{
                  padding: '0 11px',
                  borderColor: '#aaa',
                  marginBottom: -5,
                }}
                onClick={() => {
                  openDrawerCreatePiece()
                }}
              >
                <PlusOutlined />
              </Button>
              <DrawerCadastrarPeca
                isVisible={drawerCreatePieceIsVisible}
                onClose={onCloseDrawerCreatePiece}
                loading={loading}
                setLoading={setLoading}
              />
            </div>
            <Form.Item
              label='Quantidade'
              name='quantity'
              rules={[
                {
                  required: true,
                  message: 'Por favor, a quantidade da peça deve ser informada',
                },
              ]}
            >
              <Input size='large' type='number' min={0.0001} step='any' />
            </Form.Item>
            <Form.Item
              label='Valor'
              name='price'
              trigger='onValueChange'
              rules={[
                {
                  required: true,
                  message: 'Por favor, o valor deve ser informado',
                },
              ]}
            >
              <NumberFormat
                thousandSeparator='.'
                decimalSeparator=','
                prefix='R$'
                decimalScale={2}
                className='ant-input'
                fixedDecimalScale
                onValueChange={(value) =>
                  form.setFieldsValue({ price: value.floatValue })
                }
                style={{ padding: '9px' }}
              />
            </Form.Item>
            <Divider style={{ borderColor: '#bbb' }} />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Enviar
                type='primary'
                htmlType='submit'
                size='large'
                style={{ flex: 1 }}
              >
                Adicionar
              </Enviar>
              <Cancelar
                danger
                onClick={onCloseDrawer}
                size='large'
                style={{ flex: 1 }}
              >
                Cancelar
              </Cancelar>
            </div>
          </Form>
        </Spin>
      </Drawer>
    </Container>
  )
}

export { PecasOrdem }
