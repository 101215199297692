import React, { useEffect, useRef, useState } from 'react'
import {
  message,
  Table,
  Space,
  Button,
  Drawer,
  Popconfirm,
  Tooltip,
  Form,
  Spin,
  Empty,
  Select,
  DatePicker,
  Divider,
} from 'antd'
import {
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined,
  RetweetOutlined,
  ClearOutlined,
} from '@ant-design/icons'
import api from 'services/api'
import { Container, PageTitle } from 'components'
import { Enviar, Cancelar } from 'components/Buttons'
import { Helmet } from 'react-helmet'
import NumberFormat from 'react-number-format'
import locale from 'antd/es/date-picker/locale/pt_BR'
import { formatarMoeda } from 'services/utils'
import { format } from 'date-fns'
import ptBr from 'date-fns/locale/pt-BR'
import dayjs from 'dayjs'

const { Column } = Table

function Metas() {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [mode, setMode] = useState('insert')
  const [technical, setTechnical] = useState([])
  const [load, setLoad] = useState(false)
  const [scores, setScores] = useState([])
  const [score, setScore] = useState(null)
  const [meta, setMeta] = useState({
    current_page: 1,
    per_page: 10,
  })
  const searchInput = useRef(null)
  const [date, setDate] = useState('')

  useEffect(() => {
    loadData()
  }, [load, date])

  useEffect(() => {
    loadTechnical()
  }, [])

  const loadTechnical = async () => {
    api
      .get(`/user?accessProfile=tecnico`)
      .then(({ data }) => {
        setTechnical(data)
      })
      .catch((error) => {
        if (error.response.status !== 401) {
          message.error('Erro ao carregar os dados, atualize a pagina')
        }
      })
  }

  const loadData = async () => {
    setLoading(true)
    await api
      .get('/scores', {
        params: {
          page: meta.current_page,
          perPage: meta.per_page,
          date,
        },
      })
      .then(({ data }) => {
        setScores(data.data)
        setMeta(data.meta)
      })
      .catch(() => {
        message.error('Erro ao carregar os dados, atualize a pagina')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const openDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    form.resetFields()
    setMode('insert')
    setScore(null)
    setVisible(false)
    setLoad(!load)
  }

  const handleSubmitForm = async (value) => {
    const date = new Date(value.date)
    const startDate = new Date(date.getFullYear(), date.getMonth(), 1)
      .toISOString()
      .slice(0, 10)
    const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0)
      .toISOString()
      .slice(0, 10)
    setLoading(true)
    if (mode === 'insert') {
      const data = {
        ...value,
        startDate,
        endDate,
      }
      api
        .post('/scores', data)
        .then(() => {
          message.success('Cadastro realizado com sucesso!')
          onClose()
        })
        .catch((e) => {
          if (e.response.data.errors) {
            e.response.data.errors.forEach((info) => {
              message.error(info.message)
            })
          } else {
            message.error(e.response.data.error)
          }
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      const data = {
        ...value,
        name: value.name.toUpperCase(),
      }
      await api
        .put(`/scores/${score.id}`, data)
        .then(() => {
          setLoading(false)
          message.success('Cadastro atualizado com sucesso!')
          onClose()
        })
        .catch((e) => {
          setLoading(false)
          if (e.response.data.errors) {
            e.response.data.errors.forEach((info) => {
              message.error(info.message)
            })
          } else {
            message.error(e.response.data.error)
          }
        })
    }
  }

  const disabledDate = (current) => {
    return current && current < dayjs().endOf('day')
  }

  const handleSearch = (selectedKeys, confirm) => {
    confirm()
    setDate(selectedKeys[0])
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setDate('')
  }

  const routes = [
    {
      path: '/',
      breadcrumbName: 'Cadastros',
    },
  ]

  return (
    <Container>
      <Helmet>
        <title>Arcke | Metas</title>
      </Helmet>
      <PageTitle
        title='Metas'
        breadcrumb={{ routes }}
        breadcrumbRender={(props, breadcrumb) => (
          <div style={{ marginBottom: -4 }}>{breadcrumb}</div>
        )}
        extra={[
          <Button
            type='primary'
            size='large'
            icon={<PlusOutlined />}
            onClick={() => {
              setMode('insert')
              openDrawer()
            }}
            key='1'
          >
            Cadastrar Meta
          </Button>,
        ]}
      />
      <Table
        dataSource={scores}
        loading={loading}
        rowKey={(record) => record.id}
        size='middle'
        style={{ margin: 20 }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <>
                  <span>Não há registro aqui</span>
                </>
              }
            >
              <Button
                icon={<PlusOutlined />}
                key='insert'
                type='primary'
                size='large'
                onClick={() => {
                  setMode('insert')
                  openDrawer()
                }}
              >
                Cadastrar
              </Button>
            </Empty>
          ),
        }}
        pagination={{
          total: meta?.total,
          pageSize: meta?.per_page,
          current: meta?.current_page,
          showTotal: (total) => `Total de ${total} itens`,
          onChange: async (page, pageSize) => {
            setMeta({
              ...meta,
              per_page: pageSize,
              current_page: page,
            })
            setLoad(!load)
          },
        }}
      >
        <Column
          title='Técnico'
          dataIndex='name'
          key='name'
          render={(_, record) => record.user.name}
        />
        <Column
          title='Meta'
          dataIndex='goal'
          key='goal'
          render={(_, record) => `${formatarMoeda(record.goal)}`}
        />
        <Column
          title='Atual'
          dataIndex='score'
          key='score'
          render={(_, record) => `${formatarMoeda(record?.score)}`}
        />
        <Column
          title='Mês'
          dataIndex='startDate'
          key='startDate'
          filterIcon={() => (
            <SearchOutlined
              style={{ color: date ? '#1890ff' : '#777', fontSize: 15 }}
            />
          )}
          filterDropdown={({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
            <div style={{ padding: 8, display: 'flex', alignItems: 'center' }}>
              <DatePicker
                value={
                  selectedKeys[0] ? dayjs(selectedKeys[0], 'YYYY/MM/DD') : ''
                }
                ref={searchInput}
                onChange={(e) => {
                  const value = e
                    ? new Date(e.year(), e.month(), 1)
                        .toISOString()
                        .slice(0, 10)
                    : setDate('')

                  setSelectedKeys(value ? [value] : [])
                }}
                style={{ display: 'block', width: '100%' }}
                locale={locale}
                format={'MM/YYYY'}
                picker='month'
              />
              <Button
                type='primary'
                onClick={() => {
                  handleSearch(selectedKeys, confirm)
                }}
                style={{ marginLeft: 6, marginRight: 6, minWidth: 32 }}
                icon={<SearchOutlined />}
              />
              <Button
                onClick={() => handleReset(clearFilters)}
                icon={<ClearOutlined />}
                style={{ minWidth: 32 }}
              />
            </div>
          )}
          render={(_, record) =>
            format(new Date(record.endDate), 'MMMM/yyyy', {
              locale: ptBr,
            }).toUpperCase()
          }
        />
        <Column
          dataIndex='actions'
          key='actions'
          title='Ações'
          width={20}
          render={(_, record) => (
            <Space>
              <Tooltip title='Recalcular Meta' placement='top'>
                <Button
                  onClick={async () => {
                    await api
                      .put(`/scores/recalculate-score/${record.id}`)
                      .then(() => {
                        message.success('Meta Atualizada!')
                      })
                      .catch(() => {
                        message.error('Erro ao excluir item, tente novamente')
                      })
                      .finally(() => {
                        setLoad(!load)
                      })
                  }}
                  icon={<RetweetOutlined />}
                />
              </Tooltip>
              <Tooltip title='Excluir Meta' placement='top'>
                <Popconfirm
                  title='Deseja realmente excluir este item?'
                  okText='Sim'
                  cancelText='Não'
                  onConfirm={async () => {
                    await api
                      .delete(`/scores/${record.id}`)
                      .then(() => {
                        message.success('Item excluido com sucesso')
                      })
                      .catch(() => {
                        message.error('Erro ao excluir item, tente novamente')
                      })
                      .finally(() => {
                        setLoad(!load)
                      })
                  }}
                >
                  <Button
                    icon={<DeleteOutlined />}
                    style={{ color: '#e80909' }}
                  />
                </Popconfirm>
              </Tooltip>
            </Space>
          )}
        />
      </Table>
      <Drawer
        visible={visible}
        onClose={onClose}
        width={550}
        title='Cadastrar Meta'
      >
        <Spin spinning={loading}>
          <Form form={form} onFinish={handleSubmitForm} layout='vertical'>
            <Form.Item
              label='Meta'
              name='goal'
              trigger='onValueChange'
              rules={[
                {
                  required: true,
                  message: 'Por favor, a Meta deve ser informada.',
                },
              ]}
            >
              <NumberFormat
                style={{ padding: '8px' }}
                thousandSeparator='.'
                decimalSeparator=','
                prefix='R$'
                decimalScale={2}
                className='ant-input'
                fixedDecimalScale
                onValueChange={(value) => {
                  form.setFieldsValue({ goal: value.floatValue })
                }}
              />
            </Form.Item>
            <Form.Item
              label='Técnico'
              name='userId'
              rules={[
                {
                  required: true,
                  message: 'Por favor, o Técnico deve ser informado.',
                },
              ]}
            >
              <Select
                placeholder='Selecione...'
                showSearch
                allowClear
                size='large'
                optionLabelProp='label'
                optionFilterProp='children'
              >
                {technical.map((tecnico) => (
                  <Select.Option
                    key={tecnico.id}
                    value={tecnico.id}
                    label={tecnico.name}
                  >
                    {tecnico.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label='Mês da meta' name='date'>
              <DatePicker
                size='large'
                disabledDate={disabledDate}
                locale={locale}
                style={{ width: '100%' }}
                picker='month'
              />
            </Form.Item>
            <Divider style={{ borderColor: '#bbb' }} />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Enviar
                disabled={mode === 'view'}
                type='primary'
                htmlType='submit'
                size='large'
                style={{ flex: 1 }}
              >
                Cadastrar
              </Enviar>
              <Cancelar
                size='large'
                danger
                onClick={onClose}
                style={{ flex: 1 }}
              >
                Cancelar
              </Cancelar>
            </div>
          </Form>
        </Spin>
      </Drawer>
    </Container>
  )
}

export { Metas }
