/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useRef } from 'react'
import {
  Button,
  Table,
  message,
  Drawer,
  Popconfirm,
  Space,
  Modal,
  Typography,
  Avatar,
  Tooltip,
  Form,
  Upload,
  Image,
  Select,
  Input,
  Spin,
  Divider,
} from 'antd'
import { useHistory, withRouter } from 'react-router-dom'
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  LoadingOutlined,
  PlusOutlined,
  InfoCircleOutlined,
  SearchOutlined,
  EyeInvisibleOutlined,
  TeamOutlined,
  ClearOutlined,
} from '@ant-design/icons'
import { Container, PageTitle } from '../../components'
import { Enviar, Cancelar } from '../../components/Buttons'
import api from '../../services/api'
import { MaskPhone, maskCpfCnpj } from '../../services/utils'
import { Helmet } from 'react-helmet'
import { TOKEN_API } from 'contexts/auth'
import Highlighter from 'react-highlight-words'

const { Paragraph, Text } = Typography
const { Column } = Table

function Usuarios() {
  const history = useHistory()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [load, setLoad] = useState(false)
  const [open, setOpen] = useState(false)
  const [visible, setVisible] = useState(false)
  const [mode, setMode] = useState('insert')
  const [imageUrl, setImageUrl] = useState('')
  const [users, setUsers] = useState([])
  const [user, setUser] = useState(null)
  const [roles, setRoles] = useState([])
  const [show, setShow] = useState(true)

  useEffect(() => {
    async function loadUsers() {
      try {
        setLoading(true)
        await api
          .get('/user', {
            params: {
              show,
            },
          })
          .then(({ data }) => {
            setUsers(data)
          })
        await api.get('/roles').then(({ data }) => {
          setRoles(data)
        })
        setLoading(false)
        setLoad(false)
      } catch (error) {
        message.error('Erro ao carregar os dados, atualize a pagina')
        setLoading(false)
        setLoad(false)
      }
    }
    loadUsers()
  }, [load, show])

  const openDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    form.resetFields()
    setMode('insert')
    setVisible(false)
    setImageUrl('')
    setLoad(!load)
  }

  const beforeUpload = (file) => {
    const validFormat = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!validFormat) {
      message.error('Envie somente arquivos do formato JPG/PNG')
    }
    return validFormat
  }

  if (mode !== 'insert') {
    form.setFieldsValue({
      name: user.name,
      email: user.email,
      cpfcnpj: maskCpfCnpj(user.cpfcnpj),
      phone: user.phone ? MaskPhone(user.phone) : '',
      accessProfile: user.accessProfile,
      porcentagem: user.porcentagem,
      roles: user.roles.map((role) => {
        return role.id
      }),
      permissions: user.permissions.map((permission) => {
        return permission.id
      }),
    })
  }

  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const routes = [
    {
      path: '/',
      breadcrumbName: 'Cadastros',
    },
  ]

  return (
    <Container>
      <Helmet>
        <title>Arcke | Usuários</title>
      </Helmet>
      <PageTitle
        title='Usuários'
        breadcrumb={{ routes }}
        breadcrumbRender={(props, breadcrumb) => (
          <div style={{ marginBottom: -4 }}>{breadcrumb}</div>
        )}
        extra={[
          <Button
            type='ghost'
            danger={!show}
            key='2'
            onClick={() => {
              setShow(!show)
            }}
            icon={<EyeInvisibleOutlined />}
            size='large'
          />,
          <Button
            type='primary'
            icon={<PlusOutlined />}
            key='1'
            onClick={() => {
              setMode('insert')
              openDrawer()
            }}
            size='large'
          >
            Cadastrar Usuário
          </Button>,
        ]}
      />
      <Table
        rowKey={(record) => record.id}
        dataSource={users}
        loading={loading}
        size='small'
        style={{ margin: 20 }}
      >
        <Column title='ID' dataIndex='id' key='id' />
        <Column title='Nome' dataIndex='name' key='name' />
        <Column
          title='Email'
          dataIndex='email'
          key='email'
          filterDropdown={({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
            <div style={{ padding: 8, display: 'flex', alignItems: 'center' }}>
              <Input
                ref={searchInput}
                placeholder={`Filtrar Nome`}
                value={selectedKeys[0]}
                onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() =>
                  handleSearch(selectedKeys, confirm, 'email')
                }
                style={{ display: 'block' }}
              />
              <Button
                type='primary'
                style={{ marginLeft: 6, marginRight: 6, minWidth: 32 }}
                onClick={() => handleSearch(selectedKeys, confirm, 'email')}
                icon={<SearchOutlined />}
              />
              <Button
                icon={<ClearOutlined />}
                onClick={() => clearFilters && handleReset(clearFilters)}
                style={{ minWidth: 32 }}
              />
            </div>
          )}
          filterIcon={(filtered) => (
            <SearchOutlined
              style={{ color: filtered ? '#1890ff' : '#777', fontSize: 15 }}
            />
          )}
          onFilter={(value, record) =>
            record.email.toString().toLowerCase().includes(value.toLowerCase())
          }
          onFilterDropdownOpenChange={(visible) => {
            if (visible) {
              setTimeout(() => searchInput.current?.select(), 100)
            }
          }}
          render={(_, record) =>
            searchedColumn === 'email' ? (
              <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={record.email ? record.email.toString() : ''}
              />
            ) : (
              record.email
            )
          }
        />
        <Column title='Função' dataIndex='accessProfile' key='accessProfile' />
        <Column
          key='actions'
          title='Ações'
          width={20}
          render={(record) => (
            <Space>
              <Tooltip placement='bottom' title='Assistentes'>
                <Button
                  icon={<TeamOutlined />}
                  onClick={() => history.push(`/usuarios/${record.id}`)}
                />
              </Tooltip>
              <Tooltip placement='bottom' title='Visualizar'>
                <Button
                  icon={<EyeOutlined />}
                  onClick={() => {
                    setUser(record)
                    setOpen(true)
                  }}
                />
              </Tooltip>
              <Tooltip placement='bottom' title='Editar'>
                <Button
                  icon={<EditOutlined />}
                  onClick={() => {
                    setUser(record)
                    setMode('update')
                    openDrawer()
                  }}
                />
              </Tooltip>
              {record.active ? (
                <Tooltip title='Apagar usuário'>
                  <Popconfirm
                    title='Deseja realmente excluir este usuário?'
                    okText='Sim'
                    cancelText='Não'
                    placement='topRight'
                    onConfirm={async () => {
                      await api
                        .delete(`/user/${record.id}`)
                        .then(() => {
                          message.success('Usuario excluido com sucesso')
                          setLoad(true)
                        })
                        .catch(() => {
                          message.error(
                            'Erro ao excluir usuario, tente novamente'
                          )
                        })
                    }}
                  >
                    <Button
                      icon={<DeleteOutlined />}
                      style={{ color: '#e80909' }}
                    />
                  </Popconfirm>
                </Tooltip>
              ) : null}
            </Space>
          )}
        />
      </Table>
      <Drawer
        visible={visible}
        onClose={onClose}
        width={550}
        title={`${mode === 'insert' ? 'Cadastrar' : 'Atualizar'} Usuário`}
      >
        <Spin spinning={loading}>
          <Form
            form={form}
            layout='vertical'
            initialValues={{
              name: '',
              email: '',
              cpf: '',
              phone: '',
              image: '',
              password: '',
              accessProfile: '',
            }}
            onFinish={(value) => {
              setLoading(true)
              if (mode === 'insert') {
                const data = {
                  ...value,
                  name: value.name.toUpperCase(),
                }
                console.log(data)
                api
                  .post('/user', data)
                  .then(() => {
                    setLoading(false)
                    message.success('Cadastro realizado com sucesso!')
                    onClose()
                  })
                  .catch((e) => {
                    if (e.response.data.errors) {
                      e.response.data.errors.forEach((info) => {
                        message.error(info.message)
                      })
                    } else {
                      message.error(e.response.data.error)
                    }
                    setLoading(false)
                  })
              } else {
                if (value.password === '') {
                  delete value.password
                }
                const data = {
                  ...value,
                  name: value.name.toUpperCase(),
                }
                api
                  .put(`/user/${user.id}`, data)
                  .then(() => {
                    setLoading(false)
                    message.success('Usuário atualizado com sucesso!')
                    onClose()
                  })
                  .catch((e) => {
                    if (e.response.data.errors) {
                      e.response.data.errors.forEach((info) => {
                        message.error(info.message)
                      })
                    } else {
                      message.error(e.response.data.error)
                    }
                    setLoading(false)
                  })
              }
            }}
          >
            <Form.Item
              label='Nome'
              name='name'
              style={{ minWidth: '100%' }}
              rules={[
                {
                  required: true,
                  message: 'Por favor, o nome deve ser informado',
                },
              ]}
            >
              <Input size='large' />
            </Form.Item>
            <Form.Item
              label='Email'
              name='email'
              style={{ minWidth: '100%' }}
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: 'Por favor, o email deve ser informado',
                },
              ]}
            >
              <Input size='large' />
            </Form.Item>
            <Form.Item
              label='Senha'
              name='password'
              style={{ minWidth: '100%' }}
              rules={[
                {
                  required: mode === 'insert',
                  message: 'Por favor, a senha deve ser informada',
                },
              ]}
            >
              <Input size='large' type='password' />
            </Form.Item>
            <Form.Item
              label='CPF/CNPJ'
              name='cpfcnpj'
              style={{ minWidth: '100%' }}
              normalize={(value) => maskCpfCnpj(value)}
              rules={[
                {
                  required: true,
                  message: 'Por favor, o CPF/CNPJ deve ser informado',
                },
              ]}
            >
              <Input size='large' />
            </Form.Item>
            <Form.Item
              label='Telefone'
              name='phone'
              style={{ minWidth: '100%' }}
              normalize={(value) => MaskPhone(value)}
            >
              <Input size='large' />
            </Form.Item>
            <Form.Item
              label='Comissão'
              style={{ minWidth: '100%' }}
              tooltip={{
                title: 'Porcentagem do tecnico sobre valor unitario do item.',
                icon: <InfoCircleOutlined />,
              }}
              name='porcentagem'
            >
              <Input size='large' />
            </Form.Item>
            <Form.Item
              label='Cargo'
              name='accessProfile'
              style={{ minWidth: '100%' }}
            >
              <Select
                size='large'
                placeholder='Selecione...'
                style={{ width: '100%' }}
              >
                <Select.Option value='administrador'>
                  Administrador
                </Select.Option>
                <Select.Option value='tecnico'>Técnico</Select.Option>
                <Select.Option value='atendente'>Atendente</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label='Perfil de acesso'
              name='roles'
              style={{ minWidth: '100%' }}
            >
              <Select
                size='large'
                placeholder='Selecione...'
                mode='multiple'
                style={{ width: '100%' }}
              >
                {roles.map((role) => {
                  return (
                    <Select.Option key={role.id} value={role.id}>
                      {role.role_name}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label='Foto'
              name='image'
              valuePropName='image'
              style={{ minWidth: '100%' }}
            >
              <Upload
                name='file'
                listType='picture-card'
                showUploadList={false}
                beforeUpload={beforeUpload}
                multiple={false}
                action={`${process.env.REACT_APP_API}/upload`}
                data={{ pasta: 'usuários' }}
                headers={{
                  Authorization: `Bearer ${localStorage.getItem(TOKEN_API)}`,
                }}
                onChange={(info) => {
                  const { status } = info.file
                  if (status === 'done') {
                    message.success(
                      `${info.file.name} sucesso ao fazer upload.`
                    )
                    form.setFieldsValue({ image: info.file.response.url })
                    setImageUrl(info.file.response.url)
                  } else if (status === 'error') {
                    message.error(`${info.file.name} falha ao fazer upload.`)
                  }
                }}
              >
                {imageUrl || user?.image ? (
                  <Image src={imageUrl || user.image} />
                ) : (
                  <div>
                    {loading ? <LoadingOutlined /> : <PlusOutlined />}
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                )}
              </Upload>
            </Form.Item>
            <Divider style={{ borderColor: '#bbb' }} />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Enviar
                disabled={mode === 'view'}
                type='primary'
                htmlType='submit'
                size='large'
                style={{ flex: 1 }}
              >
                {mode === 'insert' ? 'Cadastrar' : 'Atualizar'}
              </Enviar>
              <Cancelar
                danger
                onClick={onClose}
                size='large'
                style={{ flex: 1 }}
              >
                Cancelar
              </Cancelar>
            </div>
          </Form>
        </Spin>
      </Drawer>
      <Modal
        title='Usuário'
        visible={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={null}
      >
        {user && (
          <>
            {user.image && (
              <>
                <Avatar
                  size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                  src={user.image}
                />
                <br />
              </>
            )}
            <Text strong>Nome</Text>
            <Paragraph>{user.name}</Paragraph>
            <Text strong>Email</Text>
            <Paragraph>{user.email}</Paragraph>
            <Text strong>CPF</Text>
            <Paragraph>{user.cpfcnpj}</Paragraph>
            <Text strong>Telefone</Text>
            <Paragraph>{user.phone}</Paragraph>
            <Text strong>Perfil de acesso</Text>
            <Paragraph>{user.accessProfile}</Paragraph>
          </>
        )}
      </Modal>
    </Container>
  )
}

export default withRouter(Usuarios)
