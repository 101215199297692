import React, { useEffect, useRef, useState } from 'react'
import {
  message,
  Table,
  Space,
  Button,
  Popconfirm,
  Tooltip,
  Input,
  Spin,
  Image,
  Empty,
  Modal,
  Typography,
  Row,
  Col,
  Select,
} from 'antd'
import {
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
  PrinterOutlined,
  PlusOutlined,
  BarcodeOutlined,
  EyeOutlined,
  ClearOutlined,
} from '@ant-design/icons'
import { useParams, Link } from 'react-router-dom'
import { Container, PageTitle } from 'components'
import { format, parseISO } from 'date-fns'
import { DrawerEquipamentos } from 'components/Drawer/Equipamentos'
import api, { pdfUrl } from 'services/api'
import axios from 'axios'

const { Column } = Table
const { Text, Paragraph } = Typography

function Equipamentos() {
  const { clientId } = useParams()
  const [equipments, setEquipments] = useState([])
  const [equipment, setEquipment] = useState()
  const [locais, setLocais] = useState([])
  const [setores, setSetores] = useState([])
  const [marcas, setMarcas] = useState([])
  const [loading, setLoading] = useState(false)
  const [drawer, setDrawer] = useState({ drawer: 'none' })
  const [load, setLoad] = useState(false)
  const [open, setOpen] = useState(false)
  const searchInput = useRef(null)

  const { Option } = Select

  useEffect(() => {
    loadEquipments()
  }, [load])

  useEffect(() => {
    loadMarcas()
    loadLocais()
    loadSetores()
  }, [drawer])

  const loadEquipments = async () => {
    setLoading(true)
    await api
      .get(`/equipment?clientId=${clientId}`)
      .then(async ({ data }) => {
        setEquipments(data)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const generatePng = async (record) => {
    try {
      message.loading({
        content: 'Carregando informações do Equipamento',
        key: record.id,
        duration: 0,
      })
      const response = await axios.get(
        `${pdfUrl}html/equipment-tag/${record.uuid}`,
        {
          params: {
            code: record.code,
          },
          responseType: 'blob',
        }
      )
      message.success({
        content: 'Baixando PDF...',
        key: record.id,
      })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${record.uuid}.png`)
      document.body.appendChild(link)
      link.click()
      setLoading(false)
    } catch (error) {
      message.error({
        content: 'Erro ao gerar a imagem.',
        key: record.id,
      })
    }
  }

  const loadMarcas = async () => {
    await api.get('/marca').then(({ data }) => {
      setMarcas(data)
    })
  }

  const loadLocais = async () => {
    await api.get(`/local?clientId=${clientId}`).then(({ data }) => {
      setLocais(data)
    })
  }

  const loadSetores = async () => {
    await api
      .get(`/setor?clientId=${clientId}`)
      .then(({ data }) => {
        setSetores(data)
      })
      .finally(() => setLoading(false))
  }

  const routes = [
    {
      path: '',
      breadcrumbName: 'Cadastros',
    },
    {
      path: '/clientes',
      breadcrumbName: 'Clientes',
    },
  ]

  function itemRender(route) {
    return (
      <Link to={{ pathname: route.path }} key={route.breadcrumbName}>
        {route.breadcrumbName}
      </Link>
    )
  }

  return (
    <Container>
      <Spin spinning={loading}>
        <PageTitle
          title='Equipamentos do Cliente'
          breadcrumb={{ routes, itemRender }}
          breadcrumbRender={(props, breadcrumb) => (
            <div style={{ marginBottom: -4 }}>{breadcrumb}</div>
          )}
          extra={[
            <Button
              icon={<PlusOutlined />}
              type='primary'
              size='large'
              key='insert'
              onClick={() =>
                setDrawer({
                  drawer: 'insert',
                })
              }
            >
              Adicionar Equipamento ao Cliente
            </Button>,
          ]}
        />
        <Table
          dataSource={equipments}
          loading={loading}
          rowKey={(record) => record.id}
          size='middle'
          scroll={{ x: 1000 }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <>
                    <span>Não há registro aqui</span>
                  </>
                }
              >
                <Button
                  icon={<PlusOutlined />}
                  key='insert'
                  type='primary'
                  onClick={() => {
                    setDrawer({
                      drawer: 'insert',
                    })
                  }}
                >
                  Adicionar
                </Button>
              </Empty>
            ),
          }}
        >
          <Column
            title='Equipamento do Cliente'
            dataIndex='name'
            key='name'
            fixed='left'
            width={210}
            filterIcon={(filtered) => (
              <SearchOutlined
                style={{ color: filtered ? '#1890ff' : '#777', fontSize: 15 }}
              />
            )}
            onFilter={(value, record) =>
              record.name
                .toString()
                .toLowerCase()
                .includes(value.toString().toLowerCase())
            }
            filterDropdown={({
              selectedKeys,
              setSelectedKeys,
              confirm,
              clearFilters,
            }) => (
              <div
                style={{ padding: 8, display: 'flex', alignItems: 'center' }}
              >
                <Input
                  placeholder='Filtrar nome'
                  value={selectedKeys[0]}
                  style={{ width: 188, display: 'block' }}
                  onChange={(e) =>
                    setSelectedKeys(e.target.value ? [e.target.value] : [])
                  }
                  onPressEnter={() => {
                    confirm()
                  }}
                />
                <Button
                  style={{ marginLeft: 6, marginRight: 6, minWidth: 32 }}
                  type='primary'
                  icon={<SearchOutlined />}
                  onClick={() => confirm()}
                />
                <Button
                  icon={<ClearOutlined />}
                  style={{ minWidth: 32 }}
                  onClick={() => {
                    clearFilters()
                    setSelectedKeys([])
                    confirm()
                  }}
                />
              </div>
            )}
          />
          <Column
            title='Marca'
            dataIndex='marca'
            key='marca'
            width={150}
            render={(_, record) => <Text>{record?.marca?.name}</Text>}
            filterIcon={(filtered) => (
              <SearchOutlined
                style={{ color: filtered ? '#1890ff' : '#777', fontSize: 15 }}
              />
            )}
            onFilter={(value, record) =>
              record.marca.id
                .toString()
                .toLowerCase()
                .includes(value.toString().toLowerCase())
            }
            filterDropdown={({
              selectedKeys,
              setSelectedKeys,
              confirm,
              clearFilters,
            }) => (
              <div
                style={{ padding: 8, display: 'flex', alignItems: 'center' }}
              >
                <Select
                  showSearch
                  value={selectedKeys}
                  style={{ width: 280, display: 'block' }}
                  onSelect={(e) => {
                    setSelectedKeys([e])
                  }}
                >
                  {marcas.map((marca, index) => {
                    return (
                      <Option key={index} value={marca.id}>
                        {marca.name}
                      </Option>
                    )
                  })}
                </Select>
                <Button
                  style={{ marginLeft: 6, marginRight: 6, minWidth: 32 }}
                  type='primary'
                  icon={<SearchOutlined />}
                  onClick={() => {
                    confirm()
                  }}
                />
                <Button
                  icon={<ClearOutlined />}
                  style={{ minWidth: 32 }}
                  onClick={() => {
                    clearFilters()
                    setSelectedKeys([])
                    confirm()
                  }}
                />
              </div>
            )}
          />
          <Column
            title='Local'
            dataIndex='local'
            key='local'
            width={300}
            render={(_, record) => record.local.name}
            filterIcon={(filtered) => (
              <SearchOutlined
                style={{ color: filtered ? '#1890ff' : '#777', fontSize: 15 }}
              />
            )}
            onFilter={(value, record) =>
              record.local.id
                .toString()
                .toLowerCase()
                .includes(value.toString().toLowerCase())
            }
            filterDropdown={({
              selectedKeys,
              setSelectedKeys,
              confirm,
              clearFilters,
            }) => (
              <div
                style={{ padding: 8, display: 'flex', alignItems: 'center' }}
              >
                <Select
                  showSearch
                  optionFilterProp='children'
                  value={selectedKeys}
                  style={{ width: 380, display: 'block' }}
                  onSelect={(e) => {
                    setSelectedKeys([e])
                  }}
                >
                  {locais.map((local, index) => {
                    return (
                      <Option key={index} value={local.id}>
                        {local.name}
                      </Option>
                    )
                  })}
                </Select>
                <Button
                  style={{ marginLeft: 6, marginRight: 6, minWidth: 32 }}
                  type='primary'
                  icon={<SearchOutlined />}
                  onClick={() => {
                    confirm()
                  }}
                />
                <Button
                  icon={<ClearOutlined />}
                  style={{ minWidth: 32 }}
                  onClick={() => {
                    clearFilters()
                    setSelectedKeys([])
                    confirm()
                  }}
                />
              </div>
            )}
          />
          <Column
            title='Unidade'
            width={200}
            dataIndex='setor'
            key='setor'
            render={(_, record) => record?.setor?.name}
            filterIcon={(filtered) => (
              <SearchOutlined
                style={{ color: filtered ? '#1890ff' : '#777', fontSize: 15 }}
              />
            )}
            onFilter={(value, record) =>
              record.setor.id
                .toString()
                .toLowerCase()
                .includes(value.toString().toLowerCase())
            }
            filterDropdown={({
              selectedKeys,
              setSelectedKeys,
              confirm,
              clearFilters,
            }) => (
              <div
                style={{ padding: 8, display: 'flex', alignItems: 'center' }}
              >
                <Select
                  showSearch
                  value={selectedKeys}
                  style={{ width: 280, display: 'block' }}
                  onSelect={(e) => {
                    setSelectedKeys([e])
                  }}
                >
                  {setores.map((setor, index) => {
                    return (
                      <Option key={index} value={setor.id}>
                        {setor.name}
                      </Option>
                    )
                  })}
                </Select>
                <Button
                  style={{ marginLeft: 6, marginRight: 6, minWidth: 32 }}
                  type='primary'
                  icon={<SearchOutlined />}
                  onClick={() => {
                    confirm()
                  }}
                />
                <Button
                  icon={<ClearOutlined />}
                  style={{ minWidth: 32 }}
                  onClick={() => {
                    clearFilters()
                    setSelectedKeys([])
                    confirm()
                  }}
                />
              </div>
            )}
          />
          <Column
            title='Setor'
            width={200}
            dataIndex='unidade'
            key='unidade'
            render={(_, record) => record?.unidade?.name}
            filterIcon={(filtered) => (
              <SearchOutlined
                style={{ color: filtered ? '#1890ff' : '#777', fontSize: 15 }}
              />
            )}
            onFilter={(value, record) =>
              record?.unidade?.name
                .toString()
                .toLowerCase()
                .includes(value.toString().toLowerCase())
            }
            filterDropdown={({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              close,
            }) => (
              <div
                style={{ padding: 8, display: 'flex', alignItems: 'center' }}
              >
                <Input
                  ref={searchInput}
                  placeholder={`Procurar unidade`}
                  value={selectedKeys[0]}
                  onChange={(e) =>
                    setSelectedKeys(e.target.value ? [e.target.value] : [])
                  }
                  onPressEnter={() => confirm()}
                  style={{ display: 'block' }}
                />
                <Button
                  style={{ marginLeft: 6, marginRight: 6, minWidth: 32 }}
                  type='primary'
                  icon={<SearchOutlined />}
                  onClick={() => {
                    confirm()
                  }}
                />
                <Button
                  icon={<ClearOutlined />}
                  style={{ minWidth: 32 }}
                  onClick={() => {
                    clearFilters()
                    setSelectedKeys([])
                    confirm()
                  }}
                />
              </div>
            )}
            onFilterDropdownOpenChange={(visible) => {
              if (visible) {
                setTimeout(() => searchInput?.current?.select(), 100)
              }
            }}
          />
          <Column
            title='Nº Serie'
            width={200}
            dataIndex='serialNumber'
            key='serialNumber'
            filterIcon={(filtered) => (
              <SearchOutlined
                style={{ color: filtered ? '#1890ff' : '#777', fontSize: 15 }}
              />
            )}
            onFilter={(value, record) =>
              record?.serialNumber
                ?.toString()
                .toLowerCase()
                .includes(value.toString().toLowerCase())
            }
            filterDropdown={({
              selectedKeys,
              setSelectedKeys,
              confirm,
              clearFilters,
            }) => (
              <div
                style={{ padding: 8, display: 'flex', alignItems: 'center' }}
              >
                <Input
                  placeholder='Filtrar nº serie:'
                  value={selectedKeys[0]}
                  style={{ width: 188, display: 'block' }}
                  onChange={(e) =>
                    setSelectedKeys(e.target.value ? [e.target.value] : [])
                  }
                  onPressEnter={() => {
                    confirm()
                  }}
                />
                <Button
                  style={{ marginLeft: 6, marginRight: 6, minWidth: 32 }}
                  type='primary'
                  icon={<SearchOutlined />}
                  onClick={() => confirm()}
                />
                <Button
                  icon={<ClearOutlined />}
                  style={{ minWidth: 32 }}
                  onClick={() => {
                    if (clearFilters) {
                      clearFilters()
                    }
                  }}
                />
              </div>
            )}
          />
          <Column title='BTUS' dataIndex='btus' key='btus' width={150} />
          <Column title='Codigo' dataIndex='code' key='code' width={200} />
          <Column title='UUID' dataIndex='uuid' key='uuid' width={320} />
          <Column
            dataIndex='actions'
            key='actions'
            fixed='right'
            title='Ações'
            width={250}
            render={(_, record) => (
              <Space>
                <Tooltip title='Visualizar' placement='top'>
                  <Button
                    icon={<EyeOutlined />}
                    onClick={() => {
                      setEquipment(record)
                      setOpen(true)
                    }}
                  />
                </Tooltip>
                <Tooltip title='Editar' placement='top'>
                  <Button
                    icon={<EditOutlined />}
                    onClick={() =>
                      setDrawer({
                        drawer: 'update',
                        equipamento: record,
                      })
                    }
                  />
                </Tooltip>
                <Tooltip title='Imprimir' placement='top'>
                  <Button
                    disabled={!record.uuid || !record.code}
                    icon={<PrinterOutlined />}
                    onClick={() => generatePng(record)}
                  />
                </Tooltip>
                <Tooltip title='Gerar Codigos' placement='top'>
                  <Popconfirm
                    title='Deseja gerar os codigos desse equipmento?'
                    okText='Sim'
                    cancelText='Não'
                    disabled={record.uuid || record.code}
                    onConfirm={async () => {
                      await api
                        .put(`/equipment/codes/${record.id}`)
                        .then(() => {
                          message.success('Codigos gerados!')
                          setLoad(!load)
                        })
                    }}
                  >
                    <Button
                      disabled={record.uuid || record.code}
                      icon={<BarcodeOutlined />}
                    />
                  </Popconfirm>
                </Tooltip>
                <Tooltip title='Excluir equipamento' placement='top'>
                  <Popconfirm
                    title='Deseja realmente excluir este item?'
                    okText='Sim'
                    cancelText='Não'
                    onConfirm={async () => {
                      await api.delete(`/equipment/${record.id}`).then(() => {
                        message.success('Item excluido com sucesso')
                        setLoad(true)
                      })
                    }}
                  >
                    <Button
                      icon={<DeleteOutlined />}
                      style={{ color: '#e80909' }}
                    />
                  </Popconfirm>
                </Tooltip>
              </Space>
            )}
          />
        </Table>
        <DrawerEquipamentos setVisible={setDrawer} config={drawer} />
        <Modal
          title='Equipamento'
          visible={open}
          onOk={() => setOpen(false)}
          onCancel={() => setOpen(false)}
          footer={null}
          style={{ top: 20 }}
        >
          {equipment && (
            <>
              <Text strong>Equipamento</Text>
              <Paragraph>{equipment?.name}</Paragraph>
              <Text strong>Local</Text>
              <Paragraph>{equipment?.local.name}</Paragraph>
              <Text strong>Unidade</Text>
              <Paragraph>{equipment?.setor.name}</Paragraph>
              <Text strong>Setor</Text>
              <Paragraph>
                {equipment?.unidade ? equipment?.unidade.name : '--'}
              </Paragraph>
              <Row align='space-between'>
                <Col>
                  <Text strong>Modelo</Text>
                  <Paragraph>{equipment?.model}</Paragraph>
                </Col>
                <Col align='end'>
                  <Text strong>Número de Série</Text>
                  <Paragraph>{equipment?.serialNumber}</Paragraph>
                </Col>
              </Row>
              <Row align='space-between'>
                <Col>
                  <Text strong>Marca</Text>
                  <Paragraph>{equipment?.marca?.name}</Paragraph>
                </Col>
                <Col>
                  <Text strong>Gás</Text>
                  <Paragraph>{equipment?.gas}</Paragraph>
                </Col>
                <Col align='end'>
                  <Text strong>Voltagem</Text>
                  <Paragraph>{equipment?.voltagem}</Paragraph>
                </Col>
              </Row>
              <Row align='space-between'>
                <Col>
                  <Text strong>Codigo</Text>
                  <Paragraph>{equipment?.code}</Paragraph>
                </Col>
                <Col>
                  <Text strong>UUID</Text>
                  <Paragraph>{equipment?.uuid}</Paragraph>
                </Col>
              </Row>
              <Text strong>Data de Fabricação</Text>
              <Paragraph>
                {equipment?.fabricacao &&
                  format(parseISO(equipment?.fabricacao), 'dd/MM/yyyy')}
              </Paragraph>
              <Row align='space-between'>
                <Col align='start'>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text strong>Foto do Equipamento</Text>
                    <Image width={200} src={equipment?.image} />
                  </div>
                </Col>
                <Col align='end'>
                  <Text strong>QRCode</Text>
                  <div
                    style={{ width: 150 }}
                    dangerouslySetInnerHTML={{ __html: equipment?.uuid }}
                  />
                </Col>
              </Row>
            </>
          )}
        </Modal>
      </Spin>
    </Container>
  )
}

export default Equipamentos
