import React, { useEffect, useState } from 'react'
import {
  message,
  Table,
  Space,
  Button,
  Drawer,
  Popconfirm,
  Tooltip,
  Form,
  Input,
  Spin,
  Empty,
  Select,
} from 'antd'
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { useParams, Link } from 'react-router-dom'
import { Enviar, Cancelar } from '../../components/Buttons'
import { Container, PageTitle } from '../../components'
import api from '../../services/api'

const { Column } = Table

const profiles = [
  { label: 'Administrador', value: 'administrator' },
  { label: 'Gerente', value: 'manager' },
  // { label: 'Moderador', value: 'moderator' },
]

function UsuariosCliente() {
  const { clientId } = useParams()
  const [form] = Form.useForm()
  const [users, setUsers] = useState([])
  const [user, setUser] = useState({})
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [mode, setMode] = useState('insert')
  const [load, setLoad] = useState(false)
  const [locals, setLocals] = useState([])

  useEffect(() => {
    loadUsers()
    loadLocais(clientId)
  }, [load])

  const loadUsers = async () => {
    setLoading(true)
    await api
      .get(`/client/users/${clientId}`)
      .then(({ data }) => {
        setUsers(data)
      })
      .catch(() => {
        message.error('Erro ao carregar os dados, atualize a pagina')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const openDrawer = (mode = 'insert') => {
    setVisible(true)
    setMode(mode)
  }

  const handleEditUser = (user) => {
    const data = { ...user, locals: user.locals.map((local) => local.id) }
    form.setFieldsValue(data)
    openDrawer('update')
    setUser(data)
  }

  const onClose = () => {
    form.resetFields()
    setMode('insert')
    setVisible(false)
  }

  const loadLocais = async (clientId) => {
    await api.get(`/local?clientId=${clientId}`).then(({ data }) => {
      setLocals(data)
    })
  }

  const onSubmit = (values) => {
    console.log(values)
    setLoading(true)
    if (mode === 'insert') {
      api
        .post(`/client/users/${clientId}`, values)
        .then(() => {
          message.success('Cadastro realizado com sucesso!')
          setLoad(!load)
          onClose()
        })
        .catch((e) => {
          if (e.response.data.errors) {
            e.response.data.errors.forEach((info) => {
              message.error(info.message)
            })
          } else {
            message.error(e.response.data.error)
          }
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      api
        .put(`/client/users/${user.id}/`, values)
        .then(() => {
          message.success('Cadastro realizado com sucesso!')
          setLoad(!load)
          onClose()
        })
        .catch((e) => {
          if (e.response.data.errors) {
            e.response.data.errors.forEach((info) => {
              message.error(info.message)
            })
          } else {
            message.error(e.response.data.error)
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const routes = [
    {
      path: '',
      breadcrumbName: 'Cadastros',
    },
    {
      path: '/clientes',
      breadcrumbName: 'Clientes',
    },
  ]

  function itemRender(route) {
    return (
      <Link to={{ pathname: route.path }} key={route.breadcrumbName}>
        {route.breadcrumbName}
      </Link>
    )
  }

  return (
    <Container>
      <PageTitle
        title='Central do Cliente'
        breadcrumb={{ routes, itemRender }}
        breadcrumbRender={(props, breadcrumb) => (
          <div style={{ marginBottom: -4 }}>{breadcrumb}</div>
        )}
        extra={[
          <Button
            icon={<PlusOutlined />}
            size='large'
            type='primary'
            onClick={() => {
              setMode('insert')
              openDrawer()
            }}
            key='1'
          >
            Adicionar Usuário a Central do Cliente
          </Button>,
        ]}
      />
      <Table
        dataSource={users}
        loading={loading}
        rowKey={(record) => record.id}
        size='middle'
        style={{ margin: 20 }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <>
                  <span>Não há registro aqui</span>
                </>
              }
            >
              <Button
                icon={<PlusOutlined />}
                key='insert'
                type='primary'
                size='large'
                onClick={() => {
                  setMode('insert')
                  openDrawer()
                }}
              >
                Adicionar
              </Button>
            </Empty>
          ),
        }}
      >
        <Column title='Nome' dataIndex='name' key='name' />
        <Column title='Email' dataIndex='email' key='email' />
        <Column
          title='Cargo'
          dataIndex='profile'
          key='profile'
          render={(value) => {
            if (value === 'manager') {
              return 'Gerente'
            }
            if (value === 'administrator') {
              return 'Administrador'
            }
          }}
        />
        <Column
          dataIndex='actions'
          key='actions'
          render={(_, record) => (
            <Space>
              <Tooltip title='Editar' placement='top'>
                <Button
                  icon={<EditOutlined />}
                  onClick={() => handleEditUser(record)}
                />
              </Tooltip>
              <Tooltip title='Retirar Usuário' placement='top'>
                <Popconfirm
                  title='Deseja realmente retirar este item?'
                  okText='Sim'
                  cancelText='Não'
                  onConfirm={async () => {
                    await api
                      .delete(`/client/users/${record.id}`)
                      .then(() => {
                        message.success('Item retirado com sucesso')
                        setLoad(!load)
                      })
                      .catch((e) => {
                        message.error(e.response.data.error)
                      })
                  }}
                >
                  <Button
                    icon={<DeleteOutlined />}
                    style={{ color: '#e80909' }}
                  />
                </Popconfirm>
              </Tooltip>
            </Space>
          )}
        />
      </Table>
      <Drawer
        visible={visible}
        onClose={onClose}
        width={550}
        title={`${
          mode === 'insert'
            ? 'Adicionar Usuário a Central do Cliente'
            : 'Atualizar Usuário da Central do Cliente'
        }`}
      >
        <Spin spinning={loading}>
          <Form
            form={form}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
            style={{ marginTop: 30 }}
            onFinish={onSubmit}
          >
            <Form.Item
              label='Nome'
              name='name'
              rules={[
                {
                  required: true,
                  message: 'Por favor, o nome deve ser informado',
                },
              ]}
            >
              <Input size='large' />
            </Form.Item>
            <Form.Item
              label='Email'
              name='email'
              rules={[
                {
                  required: true,
                  message: 'Por favor, o email deve ser informado',
                },
              ]}
            >
              <Input size='large' disabled={mode === 'update'} type='email' />
            </Form.Item>
            {mode === 'insert' && (
              <Form.Item
                label='Senha'
                name='password'
                rules={[
                  {
                    required: true,
                    message: 'Por favor, o senha deve ser informado',
                  },
                ]}
              >
                <Input.Password size='large' />
              </Form.Item>
            )}
            <Form.Item
              label='Cargo'
              name='profile'
              rules={[
                {
                  required: true,
                  message: 'Por favor, o cargo deve ser informado',
                },
              ]}
            >
              <Select size='large'>
                {profiles.map((profile) => {
                  return (
                    <Select.Option key={profile.value} value={profile.value}>
                      {profile.label}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
            {locals.length > 0 ? (
              <Form.Item label='Local' name='locals'>
                <Select
                  size='large'
                  placeholder='Selecione...'
                  showSearch
                  allowClear
                  optionLabelProp='label'
                  optionFilterProp='children'
                  mode='multiple'
                >
                  {locals.map((local) => (
                    <Select.Option
                      key={local.id}
                      value={local.id}
                      label={local.name}
                    >
                      {local.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            ) : null}
            <Form.Item wrapperCol={{ offset: 4 }}>
              <Enviar
                disabled={mode === 'view'}
                type='primary'
                htmlType='submit'
              >
                {mode === 'insert' ? 'Adicionar' : 'Atualizar'}
              </Enviar>
              <Cancelar danger onClick={onClose}>
                Cancelar
              </Cancelar>
            </Form.Item>
          </Form>
        </Spin>
      </Drawer>
    </Container>
  )
}

export { UsuariosCliente }
