import React, { useEffect, useState } from 'react'
import { Drawer, Spin, Form, Select, Input, message } from 'antd'
import { Cancelar, Enviar } from 'components/Buttons'
import api from '../../../services/api'
import { DrawerEquipamentos } from 'components/Drawer/Equipamentos'
import { PlusOutlined } from '@ant-design/icons'

function DrawerServico({ isOpen, onClose, service, clientId }) {
  const [equipments, setEquipments] = useState([])
  const [form] = Form.useForm()
  const [drawer, setDrawer] = useState({ drawer: 'none' })

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getData = async () => {
      await api
        .get(`/equipment`, {
          params: {
            localId: service.localId,
            setorId: service.setorId,
          },
        })
        .then(({ data }) => {
          setEquipments(data)
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false))
    }
    if (isOpen) {
      getData()
      form.setFieldsValue(service)
    }
  }, [isOpen, drawer.drawer])

  const handleCloseDrawer = (refresh = false) => {
    if (loading) {
      return
    }
    form.resetFields()
    onClose(refresh)
  }

  const onSubmit = (values) => {
    console.log(values)
    api
      .put(`ordem_servico/servico/${service.id}`, values)
      .then(() => {
        message.success('Serviço atualizado com sucesso!')
        handleCloseDrawer(true)
      })
      .catch((e) => {
        if (e.response.data.errors) {
          e.response.data.errors.forEach((info) => {
            message.error(info.message)
          })
        } else {
          message.error(e.response.data.error)
        }
      })
      .finally(() => setLoading(false))
  }

  return (
    <>
      <DrawerEquipamentos config={drawer} setVisible={setDrawer} />
      <Drawer
        visible={isOpen}
        onClose={() => handleCloseDrawer(false)}
        width={550}
        title='Editar Serviço'
      >
        <Spin spinning={loading}>
          <Form form={form} layout='vertical' onFinish={onSubmit}>
            <Form.Item
              label='Descrição'
              name='description'
              rules={[
                {
                  required: true,
                  message: 'Por favor, a Descrição deve ser informada',
                },
              ]}
            >
              <Input.TextArea rows={4} allowClear style={{ resize: 'none' }} />
            </Form.Item>
            <Form.Item
              label='Equipamento'
              name='equipmentId'
              rules={[
                {
                  required: true,
                  message: 'Por favor, o Equipamento deve ser informado',
                },
              ]}
            >
              {equipments.length > 0 && (
                <Select
                  size='large'
                  showSearch
                  removeIcon
                  allowClear
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    option?.children
                      ?.toLowerCase()
                      ?.includes(input.toLowerCase())
                  }
                >
                  {equipments.map((equipment) => {
                    return (
                      <Select.Option
                        key={equipment.id}
                        value={equipment.id}
                        label={
                          equipment.btus
                            ? `${equipment.name.trim()} - ${equipment.btus}`
                            : equipment.name.trim()
                        }
                      >
                        {equipment.btus
                          ? `${equipment.name.trim()} - ${equipment.btus}`
                          : equipment.name.trim()}
                      </Select.Option>
                    )
                  })}
                </Select>
              )}
            </Form.Item>
            <Form.Item>
              <Enviar
                style={{ width: '100%' }}
                icon={service?.equipamento ? undefined : <PlusOutlined />}
                size='large'
                onClick={() => {
                  if (service.equipamento) {
                    setDrawer({
                      drawer: 'update',
                      equipamento: service.equipamento,
                    })
                  } else {
                    setDrawer({
                      drawer: 'insert',
                      client: { id: clientId },
                    })
                  }
                }}
              >
                {service?.equipamento ? `Editar` : `Cadastrar`} Equipamento
              </Enviar>
            </Form.Item>
            <Form.Item>
              <Enviar type='primary' htmlType='submit' size='large'>
                Cadastrar
              </Enviar>
              <Cancelar
                danger
                size='large'
                onClick={() => handleCloseDrawer(false)}
              >
                Cancelar
              </Cancelar>
            </Form.Item>
          </Form>
        </Spin>
      </Drawer>
    </>
  )
}

export default DrawerServico
