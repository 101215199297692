import React, { useContext } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import AuthContext, { TOKEN_API } from './contexts/auth'
import App from './routes/app.routes'
import Login from './pages/Login'
import { Recuperar } from './pages/Recuperar'

export default function Routes() {
  const { signed } = useContext(AuthContext)
  const storageToken = localStorage.getItem(TOKEN_API)

  if (!signed && !storageToken) {
    return (
      <Switch>
        <Route path='/' exact component={Login} />
        <Route path='/recuperar/:token/:id' exact component={Recuperar} />
        {!storageToken && (
          <Route component={() => <Redirect to={{ pathname: '/' }} />} />
        )}
      </Switch>
    )
  }

  return <App />
}
