/* eslint-disable react/display-name */
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams, Link } from 'react-router-dom'
import {
  Modal,
  Table,
  message,
  Typography,
  Image,
  Space,
  Tooltip,
  Button,
  Empty,
  Tag,
  Input,
  Popconfirm,
} from 'antd'
import {
  DollarOutlined,
  EditOutlined,
  ReconciliationOutlined,
  SearchOutlined,
  ClearOutlined,
} from '@ant-design/icons'
import api from '../../../services/api'
import { Container, PageTitle } from '../../../components'
import Highlighter from 'react-highlight-words'
import DrawerServico from './editarServico'

const { Column } = Table
const { Text, Paragraph } = Typography

function Servicos() {
  const { osId } = useParams()
  const history = useHistory()
  const [servicos, setServicos] = useState([])
  const [ordemServico, setOrdemServico] = useState()
  const [servico, setServico] = useState()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [load, setLoad] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    loadServicos()
  }, [load])

  async function loadServicos() {
    setLoading(true)
    await api
      .get(`/ordem_servico/normal/${osId}`)
      .then(({ data }) => {
        setOrdemServico(data)
      })
      .catch(() => {
        message.error('Erro ao carregar os dados, atualize a pagina')
      })
      .finally(() => {
        setLoading(false)
      })
    setLoading(true)
    await api
      .get(`/ordem_servico/servico?ordemServicoId=${osId}`)
      .then(({ data }) => {
        setServicos(data)
      })
      .catch(() => {
        message.error('Erro ao carregar os dados, atualize a pagina')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const searchInput = useRef(null)

  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8, display: 'flex', alignItems: 'center' }}>
        <Input
          ref={searchInput}
          placeholder={`Pesquisar descrição`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            display: 'block',
          }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          style={{ marginLeft: 6, marginRight: 6, minWidth: 32 }}
        />
        <Button
          onClick={() => clearFilters && handleReset(clearFilters)}
          icon={<ClearOutlined />}
          style={{ minWidth: 32 }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : '#777',
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const routes = [
    {
      path: '/os',
      breadcrumbName: 'Ordens de Serviço',
    },
  ]

  function itemRender(route) {
    return (
      <Link to={{ pathname: route.path }} key={route.breadcrumbName}>
        {route.breadcrumbName}
      </Link>
    )
  }

  const onClose = (refresh = false) => {
    if (refresh) {
      setLoad(!load)
    }
    setServico(undefined)
    setIsOpen(false)
  }

  return (
    <Container>
      <PageTitle
        title='Serviços realizados'
        breadcrumb={{ routes, itemRender }}
        breadcrumbRender={(props, breadcrumb) => (
          <div style={{ marginBottom: -4 }}>{breadcrumb}</div>
        )}
      />
      <Table
        dataSource={servicos}
        loading={loading}
        rowKey={(record) => record.id}
        size='middle'
        style={{ margin: 20 }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <>
                  <span>Não há registro aqui</span>
                </>
              }
            ></Empty>
          ),
        }}
      >
        <Column
          title='ID'
          dataIndex='id'
          key='id'
          width={'60px'}
          render={(_, record) => <Tag>{record?.id}</Tag>}
        />
        <Column
          title='Descrição'
          dataIndex='description'
          key='description'
          filterMode='tree'
          filterSearch={true}
          {...getColumnSearchProps('description')}
        />
        <Column
          title='Equipamento'
          dataIndex='equipamento'
          key='equipamento'
          render={(_, record) => <Text>{record.equipamento?.name}</Text>}
        />
        <Column
          key='actions'
          title='Ações'
          render={(_, record) => (
            <Space>
              <Tooltip placement='top' title='Resetar Preços' zIndex={0}>
                <Popconfirm
                  placement='topLeft'
                  title={'Deseja resetar os preços dos itens desse serviço?'}
                  onConfirm={async () => {
                    await api.put(
                      `/ordem_servico/servico/reset-item-price/${record.id}`
                    )
                  }}
                  okText='Sim'
                  cancelText='Não'
                >
                  <Button icon={<DollarOutlined />} />
                </Popconfirm>
              </Tooltip>
              <Tooltip placement='top' title='Visualizar'>
                <Button
                  icon={<SearchOutlined />}
                  onClick={() => {
                    setServico(record)
                    setOpen(true)
                  }}
                />
              </Tooltip>
              <Tooltip placement='top' title='Editar Itens'>
                <Button
                  icon={<EditOutlined />}
                  onClick={() => {
                    history.push(`${record.id}/`)
                  }}
                />
              </Tooltip>
              <Tooltip placement='top' title='Editar Serviço'>
                <Button
                  icon={<ReconciliationOutlined />}
                  onClick={() => {
                    setIsOpen(true)
                    setServico(record)
                  }}
                />
              </Tooltip>
            </Space>
          )}
        />
      </Table>
      <DrawerServico
        isOpen={isOpen}
        service={servico}
        clientId={ordemServico?.clientId}
        onClose={onClose}
      />
      <Modal
        title='Serviço realizado'
        visible={open}
        onCancel={() => setOpen(false)}
        onOk={() => setOpen(false)}
        footer={null}
      >
        {servico && (
          <>
            <Text strong>Descrição</Text>
            <Paragraph>{servico.description}</Paragraph>
            <Text strong>Equipamento</Text>
            <Paragraph>{servico.equipamento?.name}</Paragraph>
            <Text strong>Itens atendidos</Text>
            {servico.items?.map((item) => (
              <Paragraph key={item.id}>{item.item.item.description}</Paragraph>
            ))}
            {servico.items?.map(
              (item) =>
                item.fotos?.length > 0 && (
                  <Space
                    direction='vertical'
                    style={{ width: '100%' }}
                    key={item.id}
                  >
                    <Text strong>Fotos do Atendimento</Text>
                    <Image.PreviewGroup>
                      {item.fotos.map((photo) => (
                        <Image src={photo.photo} width={150} key={photo.id} />
                      ))}
                    </Image.PreviewGroup>
                  </Space>
                )
            )}
          </>
        )}
      </Modal>
    </Container>
  )
}
export default Servicos
