import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  PieChartOutlined,
  FileDoneOutlined,
  TeamOutlined,
  IdcardOutlined,
  ToolOutlined,
  TagsOutlined,
  BarsOutlined,
  PlusCircleOutlined,
  ControlOutlined,
  BookOutlined,
  AuditOutlined,
  ApartmentOutlined,
  QuestionCircleOutlined,
  SmileOutlined,
  WalletOutlined,
  InboxOutlined,
  StarOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons'
import { Layout, Menu } from 'antd'
import { TitleContainer, Logo, Box } from './styles'

import main from '../../assets/image/logo.svg'
import main2 from '../../assets/image/logo2.svg'
import SubMenu from 'antd/lib/menu/SubMenu'

const { Sider } = Layout

function SideBar({ collapse, setCollapse }) {
  const history = useHistory()
  return (
    <Sider
      collapsible
      theme='light'
      width='260px'
      style={{
        borderRight: '1px solid #EAEDF0',
        position: 'fixed',
        height: '100%',
      }}
      collapsed={collapse}
      onCollapse={(collapsed) => {
        setCollapse(collapsed)
      }}
    >
      {collapse ? (
        <TitleContainer>
          <Logo src={main2} />
        </TitleContainer>
      ) : (
        <TitleContainer>
          <Logo src={main} />
        </TitleContainer>
      )}
      <Box className='menuBody'>
        <Menu
          theme='light'
          defaultSelectedKeys={['1']}
          defaultOpenKeys={['sub1']}
          mode='inline'
        >
          <Menu.Item
            key='dashboard'
            onClick={() => {
              history.push('/dashboard')
            }}
            icon={<PieChartOutlined />}
          >
            Dashboard
          </Menu.Item>
          <Menu.Item
            key='os'
            onClick={() => history.push('/os')}
            icon={<ToolOutlined />}
          >
            Ordem de Serviço
          </Menu.Item>
          <Menu.Item
            key='contratos'
            onClick={() => history.push('/contratos')}
            icon={<FileDoneOutlined />}
          >
            Contratos
          </Menu.Item>
          <SubMenu
            key='cadastros'
            icon={<PlusCircleOutlined />}
            title='Cadastros'
          >
            <Menu.Item
              key='clientes'
              onClick={() => history.push('/clientes')}
              icon={<TeamOutlined />}
            >
              Clientes
            </Menu.Item>
            <Menu.Item
              key='itens'
              onClick={() => history.push('/itens')}
              icon={<BarsOutlined />}
            >
              Itens
            </Menu.Item>
            <Menu.Item
              key='pecas'
              onClick={() => history.push('/pecas')}
              icon={<InboxOutlined />}
            >
              Peças
            </Menu.Item>
            <Menu.Item
              key='tiposervico'
              onClick={() => history.push('/tiposervico')}
              icon={<BarsOutlined />}
            >
              Tipos de Serviço
            </Menu.Item>
            <Menu.Item
              key='marcas'
              onClick={() => history.push('/marcas')}
              icon={<TagsOutlined />}
            >
              Marcas
            </Menu.Item>
            <Menu.Item
              key='tipoequipamento'
              onClick={() => history.push('/tipoequipamentos')}
              icon={<TagsOutlined />}
            >
              Tipos de Equipamentos
            </Menu.Item>
            <Menu.Item
              key='usuarios'
              onClick={() => history.push('/usuarios')}
              icon={<IdcardOutlined />}
            >
              Usuários
            </Menu.Item>
            <Menu.Item
              key='perfil'
              onClick={() => history.push('/perfis')}
              icon={<ControlOutlined />}
            >
              Perfil de Acesso
            </Menu.Item>
            <Menu.Item
              key='metas'
              onClick={() => history.push('/metas')}
              icon={<StarOutlined />}
            >
              Metas
            </Menu.Item>
          </SubMenu>
          <SubMenu key='pmocs' icon={<ApartmentOutlined />} title='PMOC'>
            <Menu.Item
              key='pmoc'
              onClick={() => history.push('/pmocs')}
              icon={<AuditOutlined />}
            >
              PMOC
            </Menu.Item>
            <Menu.Item
              key='questions'
              onClick={() => history.push('/questions')}
              icon={<QuestionCircleOutlined />}
            >
              Perguntas
            </Menu.Item>
          </SubMenu>
          <SubMenu key='relatorios' icon={<BookOutlined />} title='Relatorios'>
            <Menu.Item
              key='relatorios_pecas'
              onClick={() => history.push('/relatorio-pecas')}
              icon={<InboxOutlined />}
            >
              Peças
            </Menu.Item>
            <Menu.Item
              key='relatorios_contratos'
              onClick={() => history.push('/relatorio-contratos')}
              icon={<FileDoneOutlined />}
            >
              Contratos
            </Menu.Item>
            <Menu.Item
              key='relatorios_ordens'
              onClick={() => history.push('/relatorio-ordens')}
              icon={<ToolOutlined />}
            >
              Ordens de Serviços
            </Menu.Item>
            <Menu.Item
              key='relatorios_tecnicos'
              onClick={() => history.push('/relatorios')}
              icon={<WalletOutlined />}
            >
              Comissões
            </Menu.Item>
            <Menu.Item
              key='relatorios_clientes'
              onClick={() => history.push('/relatorio-clientes')}
              icon={<SmileOutlined />}
            >
              Clientes
            </Menu.Item>
            <Menu.Item
              key='relatorios_items'
              onClick={() => history.push('/relatorio-items-agrupados')}
              icon={<TagsOutlined />}
            >
              Items
            </Menu.Item>
            <Menu.Item
              key='relatorio_consumo_items'
              onClick={() => history.push('/relatorio-consumo-items')}
              icon={<TagsOutlined />}
            >
              Consumo de Itens
            </Menu.Item>
            <Menu.Item
              key='relatorio_tempo_contratos'
              onClick={() => history.push('/relatorio-tempo-contratos')}
              icon={<ClockCircleOutlined />}
            >
              Tempo de Contratos
            </Menu.Item>
            <Menu.Item
              key='relatorio_consumo_contratos'
              onClick={() => history.push('/relatorio-saldos-contratos')}
              icon={<TagsOutlined />}
            >
              Consumo de Contratos
            </Menu.Item>
            <Menu.Item
              key='relatorio_equipamentos'
              onClick={() => history.push('/relatorio-equipamentos')}
              icon={<InboxOutlined />}
            >
              Equipamentos
            </Menu.Item>
          </SubMenu>
          <Menu.Item
            key='organizacao'
            onClick={() => history.push('/organizacao')}
            icon={<FileDoneOutlined />}
          >
            Organização
          </Menu.Item>
        </Menu>
      </Box>
    </Sider>
  )
}

export default SideBar
