/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react'
import {
  message,
  Table,
  Space,
  Button,
  Drawer,
  Popconfirm,
  Tooltip,
  Form,
  Input,
  Spin,
  Empty,
  Divider,
} from 'antd'
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import api from 'services/api'
import { Container, PageTitle } from 'components'
import { Enviar, Cancelar } from 'components/Buttons'
import { Helmet } from 'react-helmet'

const { Column } = Table

function TipoServico() {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [mode, setMode] = useState('insert')
  const [load, setLoad] = useState(false)
  const [tipoServicos, setTipoServicos] = useState([])
  const [tipoServico, setTipoServico] = useState(null)

  useEffect(() => {
    async function loadEquipments() {
      setLoading(true)
      try {
        await api.get('/servicetype').then(({ data }) => {
          setTipoServicos(data)
          setLoading(false)
          setLoad(false)
        })
      } catch (error) {
        setLoading(false)
        setLoad(false)
        message.error('Erro ao carregar os dados, atualize a pagina')
      }
    }
    loadEquipments()
  }, [load])

  const openDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    form.resetFields()
    setMode('insert')
    setVisible(false)
    setLoad(!load)
  }

  const routes = [
    {
      path: '/',
      breadcrumbName: 'Cadastros',
    },
  ]

  return (
    <Container>
      <Helmet>
        <title>Arcke | Tipos de Serviços</title>
      </Helmet>
      <PageTitle
        title='Tipos de Serviços'
        breadcrumb={{ routes }}
        breadcrumbRender={(props, breadcrumb) => (
          <div style={{ marginBottom: -4 }}>{breadcrumb}</div>
        )}
        extra={[
          <Button
            type='primary'
            size='large'
            icon={<PlusOutlined />}
            onClick={() => {
              setMode('insert')
              openDrawer()
            }}
            key='1'
          >
            Cadastrar Tipo de Serviço
          </Button>,
        ]}
      />
      <Table
        dataSource={tipoServicos}
        loading={loading}
        rowKey={(record) => record.id}
        size='middle'
        style={{ margin: 20 }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <>
                  <span>Não há registro aqui</span>
                </>
              }
            >
              <Button
                icon={<PlusOutlined />}
                key='insert'
                type='primary'
                size='large'
                onClick={() => {
                  setMode('insert')
                  openDrawer()
                }}
              >
                Cadastrar
              </Button>
            </Empty>
          ),
        }}
      >
        <Column title='Tipo de Serviço' dataIndex='name' key='name' />
        <Column
          dataIndex='actions'
          key='actions'
          title='Ações'
          width={20}
          render={(_, record) => (
            <Space>
              <Tooltip title='Editar' placement='top'>
                <Button
                  icon={<EditOutlined />}
                  onClick={() => {
                    setTipoServico(record)
                    form.setFieldsValue({
                      name: record.name,
                    })
                    setMode('update')
                    openDrawer()
                  }}
                />
              </Tooltip>
              <Tooltip title='Excluir Tipo de Serviço' placement='top'>
                <Popconfirm
                  title='Deseja realmente excluir este item?'
                  okText='Sim'
                  cancelText='Não'
                  onConfirm={async () => {
                    await api
                      .delete(`/servicetype/${record.id}`)
                      .then(() => {
                        message.success('Item excluido com sucesso')
                        setLoad(true)
                      })
                      .catch(() => {
                        message.error('Erro ao excluir item, tente novamente')
                      })
                  }}
                >
                  <Button
                    icon={<DeleteOutlined />}
                    style={{ color: '#e80909' }}
                  />
                </Popconfirm>
              </Tooltip>
            </Space>
          )}
        />
      </Table>
      <Drawer
        visible={visible}
        onClose={onClose}
        width={550}
        title={`${
          mode === 'insert' ? 'Cadastrar' : 'Atualizar'
        } Tipo de Serviço`}
      >
        <Spin spinning={loading}>
          <Form
            form={form}
            layout='vertical'
            onFinish={(value) => {
              setLoading(true)
              if (mode === 'insert') {
                const data = {
                  name: value.name.toUpperCase(),
                }
                api
                  .post('/servicetype', data)
                  .then(() => {
                    setLoading(false)
                    message.success('Cadastro realizado com sucesso!')
                    onClose()
                  })
                  .catch((e) => {
                    setLoading(false)
                    if (e.response.data.errors) {
                      e.response.data.errors.forEach((info) => {
                        message.error(info.message)
                      })
                    } else {
                      message.error(e.response.data.error)
                    }
                  })
              } else {
                const data = {
                  name: value.name.toUpperCase(),
                }
                api
                  .put(`/servicetype/${tipoServico.id}`, data)
                  .then(() => {
                    setLoading(false)
                    message.success('Cadastro realizado com sucesso!')
                    onClose()
                  })
                  .catch((e) => {
                    setLoading(false)
                    if (e.response.data.errors) {
                      e.response.data.errors.forEach((info) => {
                        message.error(info.message)
                      })
                    } else {
                      message.error(e.response.data.error)
                    }
                  })
              }
            }}
          >
            <Form.Item
              label='Tipo de serviço'
              name='name'
              rules={[
                {
                  required: true,
                  message:
                    'Por favor, o nome do tipo de serviço deve ser informado.',
                },
              ]}
            >
              <Input size='large' />
            </Form.Item>
            <Divider style={{ borderColor: '#bbb' }} />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Enviar
                disabled={mode === 'view'}
                type='primary'
                htmlType='submit'
                size='large'
                style={{ flex: 1 }}
              >
                {mode === 'insert' ? 'Cadastrar' : 'Atualizar'}
              </Enviar>
              <Cancelar
                size='large'
                danger
                onClick={onClose}
                style={{ flex: 1 }}
              >
                Cancelar
              </Cancelar>
            </div>
          </Form>
        </Spin>
      </Drawer>
    </Container>
  )
}

export default TipoServico
