import React, { useState, useEffect, useContext } from 'react'
import {
  Skeleton,
  message,
  Table,
  Space,
  Button,
  Drawer,
  Form,
  Spin,
  Select,
  Menu,
  Dropdown,
  Empty,
  Tag,
  Tooltip,
  Checkbox,
  Input,
  Divider,
} from 'antd'
import {
  FilterOutlined,
  DownloadOutlined,
  DownOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { Container, PageTitle } from '../../components'
import { Enviar, Cancelar } from '../../components/Buttons'
import { Helmet } from 'react-helmet'
import api, { pdfUrl } from '../../services/api'
import { useAtendimentoTag } from 'services/hooks/useAtendimentoTag'
import { format } from 'date-fns'
import axios from 'axios'
import AuthContext from 'contexts/auth'
import DatePicker from 'components/DatePicker'

const { Column } = Table

const colors = {
  aberto: { name: 'Aberto', color: '#2eb772' },
  execucao: { name: 'Em Execução', color: '#1919d3' },
  aguardando: { name: 'Aguardando', color: '#ede90c' },
  finalizado: { name: 'Finalizado', color: '#3865a3' },
  reaberto: { name: 'Reaberto', color: '#0cedd6' },
  atrasado: { name: 'Atrasado', color: '#d31959' },
  validado: { name: 'Validado', color: '#a161db' },
}

function RelatorioItemsDetalhados() {
  const { user } = useContext(AuthContext)
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [mode, setMode] = useState('insert')
  const [load, setLoad] = useState(false)
  const [serviceOrders, setServiceOrders] = useState([])
  const [date, setDate] = useState(null)
  const [clientes, setClientes] = useState([])
  const [tecnicos, setTecnicos] = useState([])
  const [contracts, setContracts] = useState([])
  const [items, setItems] = useState([])
  const [loadingTable, setLoadingTable] = useState(false)
  const [loadingSelect, setLoadingSelect] = useState(false)

  const [checkedList, setCheckedList] = useState([])
  const [indeterminate, setIndeterminate] = useState(true)
  const [checkAll, setCheckAll] = useState(false)

  const [filter, setFilter] = useState(null)

  const [meta, setMeta] = useState({
    current_page: 1,
    per_page: 10,
  })

  const onClose = () => {
    // form.resetFields()
    setVisible(false)
    setMode('')
  }

  const loadClients = async () => {
    setLoadingSelect(true)
    await api.get('/client').then(({ data }) => {
      setClientes(data)
    })
  }

  const loadContracts = async (value) => {
    setLoadingSelect(true)
    await api
      .get(`/contract/os/?clientId=${value}`)
      .then(({ data }) => {
        setContracts(data)
      })
      .finally(() => {
        setLoadingSelect(false)
      })
  }

  useEffect(() => {
    async function loadData() {
      setLoading(true)
      try {
        await api.get(`/user?accessProfile=tecnico`).then(({ data }) => {
          setTecnicos(data)
        })
      } catch (error) {
        message.error('Erro ao carregar os dados, atualize a pagina')
      } finally {
        setLoading(false)
      }
    }
    loadData()
  }, [])

  const openDrawer = () => {
    setVisible(true)
  }

  const onChange = (list) => {
    setCheckedList(list)
    setIndeterminate(!!list.length && list.length < tecnicos.length)
    setCheckAll(list.length === tecnicos.length)
  }

  const onCheckAllChange = (e) => {
    const ids = tecnicos.map((tecnico) => {
      return tecnico.id
    })
    setCheckedList(
      e.target.checked
        ? form.setFieldsValue({
            tecnicoId: ids,
          })
        : form.setFieldsValue({
            tecnicoId: [],
          })
    )
    setIndeterminate(false)
    setCheckAll(e.target.checked)
  }

  useEffect(() => {
    if (filter) {
      onSubmit(filter)
    }
  }, [load])

  const menu = () => {
    return (
      <Menu>
        <Menu.Item key='1'>
          <Button
            onClick={() => tableExportToPdf()}
            icon={<DownloadOutlined />}
            style={{ width: '100%' }}
          >
            Baixar Relatório
          </Button>
        </Menu.Item>
      </Menu>
    )
  }

  function disabledDate(current) {
    return current && current < date
  }

  const onSubmit = async (values) => {
    setLoadingTable(true)
    setFilter(values)
    await api
      .get(`/reports/filter-service-orders/`, {
        params: {
          dataInicio: values.dataInicio
            ? format(values.dataInicio, 'yyyy-MM-dd')
            : null,
          dataFim: values.dataFim ? format(values.dataFim, 'yyyy-MM-dd') : null,
          page: meta?.current_page,
          limit: meta?.per_page,
          tecnicoId: values.tecnicoId?.toString(),
          contractId: values.contractId,
          itemId: values.itemId?.toString(),
          clientId: values.clientId,
          id: values.id,
          status: values.status,
        },
      })
      .then(({ data }) => {
        setServiceOrders(data?.data)
        setMeta(data?.meta)
      })
      .catch((err) => {
        console.log(err)
        message.error('Informe as datas corretas')
      })
      .finally(() => {
        setLoadingTable(false)
      })
  }

  const tableExportToPdf = async () => {
    setLoadingTable(true)

    const fileName = filter.id
      ? `OS-${filter.id}`
      : format(new Date(filter.dataInicio), 'yyyy-MM-dd')

    const data = await getPdfdata().catch(() => setLoadingTable(false))

    await axios
      .post(
        `${pdfUrl}items-report/`,
        { user, data },
        {
          responseType: 'blob',
        }
      )
      .then((response) => {
        message.success({
          content: 'Baixando PDF...',
        })
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${fileName}.pdf`)
        document.body.appendChild(link)
        link.click()
      })
      .finally(() => setLoadingTable(false))
  }

  const getPdfdata = async () => {
    const { data } = await api.get(`/reports/detailed-service-order-report/`, {
      params: {
        dataInicio: filter.dataInicio
          ? format(filter.dataInicio, 'yyyy-MM-dd')
          : null,
        dataFim: filter.dataFim ? format(filter.dataFim, 'yyyy-MM-dd') : null,
        tecnicoId: filter.tecnicoId?.toString(),
        contractId: filter.contractId,
        itemId: filter.itemId?.toString(),
        clientId: filter.clientId,
        id: filter.id,
        status: filter.status,
      },
    })

    return data
  }

  return (
    <Container>
      <Helmet>
        <title>Arcke | Relatório - Itens Detalhados</title>
      </Helmet>
      <Skeleton loading={false} active>
        <PageTitle
          title='Relatório de Itens de Serviços Detalhados'
          extra={[
            <Dropdown
              key='1'
              trigger={['click']}
              overlay={menu}
              placement='bottomCenter'
            >
              <Button size='large'>
                Exportar Tabela <DownOutlined />
              </Button>
            </Dropdown>,
            <Button
              icon={<FilterOutlined />}
              type='primary'
              size='large'
              onClick={() => {
                setMode('insert')
                openDrawer()
              }}
              key='2'
            >
              Filtrar
            </Button>,
          ]}
        />
        <Table
          dataSource={serviceOrders}
          scroll={{ x: 1500 }}
          pagination={{
            total: meta?.total,
            pageSize: meta?.per_page,
            current: meta?.current_page,
            showTotal: (total) => `Total de ${total} itens`,
            onChange: async (page, pageSize) => {
              setMeta({
                ...meta,
                per_page: pageSize,
                current_page: page,
              })
              setLoad(!load)
            },
          }}
          loading={loadingTable}
          rowKey={(record) => record.id}
          size='middle'
          style={{ margin: 20 }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <>
                    <span>Não há registro aqui</span>
                  </>
                }
              >
                <Button
                  icon={<PlusOutlined />}
                  key='insert'
                  type='primary'
                  size='large'
                  onClick={() => {
                    setMode('insert')
                    openDrawer()
                  }}
                >
                  Adicionar
                </Button>
              </Empty>
            ),
          }}
        >
          <Column
            title='ID'
            dataIndex='id'
            width={100}
            key='id'
            fixed
            render={(_, record) => <Tag>{record?.id}</Tag>}
          />
          <Column
            title='Nº Ref'
            width={120}
            fixed
            dataIndex='numRef'
            key='numRef'
          />
          <Column
            title='Descrição'
            dataIndex='description'
            width={250}
            key='description'
          />
          <Column
            title='Cliente'
            width={250}
            dataIndex='cliente'
            className='client-column'
            key='cliente'
            render={(_, record) =>
              record.clientId ? (
                <Tag color='blue'>{record?.cliente?.nomeFantasia}</Tag>
              ) : (
                <Tag>{useAtendimentoTag(record.atendimento)}</Tag>
              )
            }
          />
          <Column
            title='Local'
            width={170}
            dataIndex='local'
            key='local'
            render={(_, record) => {
              if (!record.localAtendimento && !record.local) {
                return 'Não informado'
              } else if (record.localAtendimento && !record.local) {
                return record.localAtendimento
              } else if (record.local.name && !record.localAtendimento) {
                return record?.local?.name
              }
            }}
          />
          <Column
            title='Status'
            dataIndex='status'
            width={150}
            key='status'
            render={(_, record) => (
              <Tag color={colors[record.status].color}>
                {colors[record.status].name}
              </Tag>
            )}
          />
          <Column
            title='Técnico'
            width={170}
            dataIndex='tecnico'
            key='tecnico'
            render={(_, record) =>
              record?.tecnico ? record.tecnico.name : 'Não definido'
            }
          />
        </Table>
        <Drawer
          open={visible}
          onClose={onClose}
          width={550}
          title='Filtro de Relatório'
        >
          <Spin spinning={loading}>
            <Form form={form} layout='vertical' onFinish={onSubmit}>
              <Form.Item shouldUpdate noStyle>
                {() => {
                  const date = form.getFieldValue('dataInicio')
                  if (!date) {
                    return (
                      <Form.Item
                        label='ID'
                        name='id'
                        placeholder='Insira um ID'
                      >
                        <Input size='large' type={'number'} />
                      </Form.Item>
                    )
                  }
                }}
              </Form.Item>
              <Form.Item shouldUpdate noStyle>
                {() => {
                  const id = form.getFieldValue('id')
                  if (!id) {
                    return (
                      <>
                        <Form.Item label='Data de início' name='dataInicio'>
                          <DatePicker
                            style={{ width: '100%' }}
                            onChange={(value) => {
                              setDate(value)
                            }}
                            size='large'
                          />
                        </Form.Item>
                        <Form.Item label='Data de finalização' name='dataFim'>
                          <DatePicker
                            style={{ width: '100%' }}
                            disabled={date === null}
                            disabledDate={disabledDate}
                            size='large'
                          />
                        </Form.Item>
                        <Form.Item label='Status' name='status'>
                          <Select
                            size='large'
                            placeholder='Selecione...'
                            optionLabelProp='label'
                            optionFilterProp='children'
                            maxTagCount='responsive'
                            allowClear
                            onFocus={async () => {
                              await api.get('/item').then(({ data }) => {
                                setItems(data)
                              })
                            }}
                          >
                            {[
                              { id: 'aberto', name: 'Aberto' },
                              { id: 'execucao', name: 'Em Execução' },
                              { id: 'aguardando', name: 'Aguardando' },
                              { id: 'finalizado', name: 'Finalizado' },
                              { id: 'reaberto', name: 'Reaberto' },
                              { id: 'atrasado', name: 'Atrasado' },
                              { id: 'validado', name: 'Validado' },
                            ].map((item) => (
                              <Select.Option
                                key={item.id}
                                value={item.id}
                                label={item.name}
                              >
                                {item.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item label='Clientes' name='clientId'>
                          <Select
                            size='large'
                            showSearch
                            placeholder='Selecione...'
                            allowClear
                            notFoundContent={loadingSelect ? <Spin /> : null}
                            onFocus={() => {
                              if (clientes.length < 1) {
                                loadClients()
                              }
                            }}
                            onChange={(value) => {
                              loadContracts(value)
                            }}
                            optionLabelProp='label'
                            optionFilterProp='children'
                          >
                            {clientes.map((cliente) => (
                              <Select.Option
                                key={cliente.id}
                                value={cliente.id}
                                label={cliente.nomeFantasia}
                              >
                                {cliente.nomeFantasia}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item label='Contrato' name='contractId'>
                          <Select
                            size='large'
                            showSearch
                            placeholder='Selecione...'
                            optionLabelProp='label'
                            allowClear
                            notFoundContent={loadingSelect ? <Spin /> : null}
                            optionFilterProp='children'
                          >
                            {contracts.map((contrato) => (
                              <Select.Option
                                key={contrato.id}
                                value={contrato.id}
                                label={`Contrato: ${contrato.name}`}
                              >
                                {`Contrato: ${contrato.name}`}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item label='Items' name='itemId'>
                          <Select
                            size='large'
                            mode='multiple'
                            placeholder='Selecione...'
                            optionLabelProp='label'
                            optionFilterProp='children'
                            maxTagCount='responsive'
                            allowClear
                            onFocus={async () => {
                              await api.get('/item').then(({ data }) => {
                                setItems(data)
                              })
                            }}
                          >
                            {items.map((item) => (
                              <Select.Option
                                key={item.id}
                                value={item.id}
                                label={item.description}
                              >
                                {item.description}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item label='Técnico'>
                          <Space style={{ width: '100%' }}>
                            <Form.Item name='tecnicoId' noStyle>
                              <Select
                                size='large'
                                showSearch
                                style={{ width: 480 }}
                                placeholder='Selecione...'
                                optionLabelProp='label'
                                optionFilterProp='children'
                                allowClear
                                mode='multiple'
                                onChange={onChange}
                                defaultValue={checkedList}
                                maxTagCount='responsive'
                              >
                                {tecnicos.map((tecnico) => (
                                  <Select.Option
                                    key={tecnico.id}
                                    value={tecnico.id}
                                    label={tecnico.name}
                                  >
                                    {tecnico.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                            <Tooltip
                              placement='topLeft'
                              title='Selecionar todos.'
                            >
                              <Checkbox
                                indeterminate={indeterminate}
                                onChange={onCheckAllChange}
                                checked={checkAll}
                              />
                            </Tooltip>
                          </Space>
                        </Form.Item>
                      </>
                    )
                  }
                }}
              </Form.Item>
              <Divider style={{ borderColor: '#aaa' }} />
              <Form.Item shouldUpdate noStyle>
                {() => {
                  return (
                    <div style={{ display: 'flex' }}>
                      <Enviar
                        disabled={
                          date === null && form.getFieldValue('id') === ''
                        }
                        type='primary'
                        htmlType='submit'
                        style={{ flex: 1 }}
                        size='large'
                      >
                        Gerar Relatório
                      </Enviar>
                      <Cancelar
                        danger
                        disabled={mode === null}
                        onClick={onClose}
                        style={{ flex: 1 }}
                        size='large'
                      >
                        Cancelar
                      </Cancelar>
                    </div>
                  )
                }}
              </Form.Item>
            </Form>
          </Spin>
        </Drawer>
      </Skeleton>
    </Container>
  )
}

export default RelatorioItemsDetalhados
