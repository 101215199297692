import React, { useContext, useEffect, useState } from 'react'
import {
  Skeleton,
  message,
  Table,
  Space,
  Button,
  Drawer,
  Tooltip,
  Form,
  Spin,
  Select,
  Checkbox,
  Menu,
  Dropdown,
  Divider,
} from 'antd'
import DatePicker from '../../components/DatePicker'
import {
  FilterOutlined,
  DownloadOutlined,
  DownOutlined,
  SettingOutlined,
} from '@ant-design/icons'
import api, { pdfUrl } from '../../services/api'
import { Container, PageTitle } from '../../components'
import { Enviar, Cancelar } from '../../components/Buttons'
import { format } from 'date-fns'
import AuthContext from 'contexts/auth'
import { Helmet } from 'react-helmet'
import axios from 'axios'

const { Column } = Table

const status = [
  {
    id: 1,
    status: 'aberto',
    label: 'Aberto',
  },
  {
    id: 2,
    status: 'execucao',
    label: 'Execução',
  },
  {
    id: 3,
    status: 'reaberto',
    label: 'Reaberto',
  },
  {
    id: 4,
    status: 'atrasado',
    label: 'Atrasado',
  },
  {
    id: 4,
    status: 'finalizado',
    label: 'Finalizado',
  },
  {
    id: 6,
    status: 'validado',
    label: 'Validado',
  },
]

function RelatorioOrdens() {
  const { user } = useContext(AuthContext)
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [filtrados, setFiltrados] = useState([])
  const [tecnicos, setTecnicos] = useState([])
  const [date, setDate] = useState(null)

  const [checkedList, setCheckedList] = useState([])
  const [indeterminate, setIndeterminate] = useState(true)
  const [checkAll, setCheckAll] = useState(false)

  const [filterQuery, setFilterQuery] = useState({})

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    await api
      .get(`/user?accessProfile=tecnico`)
      .then(({ data }) => {
        setTecnicos(data)
      })
      .catch(() => {
        message.error('Erro ao carregar os dados.')
      })
  }

  const onClose = () => {
    setVisible(false)
  }

  const disabledDate = (current) => {
    return current && current < date
  }

  const onChange = (list) => {
    setCheckedList(list)
    setIndeterminate(!!list.length && list.length < tecnicos.length)
    setCheckAll(list.length === tecnicos.length)
  }

  const onCheckAllChange = (e) => {
    const ids = tecnicos.map((tecnico) => {
      return tecnico.id
    })
    setCheckedList(
      e.target.checked
        ? form.setFieldsValue({
            tecnicoId: ids,
          })
        : form.setFieldsValue({
            tecnicoId: [],
          })
    )
    setIndeterminate(false)
    setCheckAll(e.target.checked)
  }

  const tableExportToPdf = async () => {
    const nameFile = `${format(
      filterQuery.dataInicio,
      'dd-MM-yyyy'
    )} à ${format(
      filterQuery.dataFim ? filterQuery.dataFim : new Date(),
      'dd-MM-yyyy'
    )}`
    await axios
      .post(
        `${pdfUrl}orders-report/`,
        { user, data: filtrados },
        {
          responseType: 'blob',
        }
      )
      .then((response) => {
        message.success({
          content: 'Baixando PDF...',
        })
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${nameFile}.pdf`)
        document.body.appendChild(link)
        link.click()
      })
  }

  const onSubmit = async (value) => {
    setFilterQuery(value)
    setLoading(true)
    api
      .get(`reports/orders`, {
        params: {
          dataInicio: format(value.dataInicio, 'yyyy-MM-dd'),
          dataFim: value.dataFim ? format(value.dataFim, 'yyyy-MM-dd') : null,
          tecnicoId: value.tecnicoId.toString(),
          status: value.status,
        },
      })
      .then(({ data }) => {
        setFiltrados(data)
      })
      .catch(() => {
        message.error('Algo inesperado ocorreu')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const menu = () => {
    return (
      <Menu>
        <Menu.Item key='1'>
          <Button
            icon={<DownloadOutlined />}
            onClick={async () => {
              tableExportToPdf()
            }}
          >
            Baixar Tabela - PDF
          </Button>
        </Menu.Item>
        <Menu.Item key='2'>
          <Button
            disabled={true}
            icon={<DownloadOutlined />}
            onClick={async () => {
              // const csvtest = cosnvertCSV(filtrados)
              // const date = new Date()
              // const blob = new Blob([csvtest], {
              //   type: 'text/csv;charset=utf-8;',
              // })
            }}
          >
            Baixar Tabela - CSV
          </Button>
        </Menu.Item>
      </Menu>
    )
  }

  const menuTabela = (record) => (
    <Menu>
      <Menu.Item key='1'>
        <Button
          icon={<DownloadOutlined />}
          onClick={async () => {
            await axios
              .post(
                `${pdfUrl}orders-report/`,
                { user, data: [record] },
                {
                  responseType: 'blob',
                }
              )
              .then((response) => {
                message.success({
                  content: 'Baixando PDF...',
                })
                const url = window.URL.createObjectURL(
                  new Blob([response.data])
                )
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `${record.name}.pdf`)
                document.body.appendChild(link)
                link.click()
              })
          }}
        >
          Baixar Relatório
        </Button>
      </Menu.Item>
    </Menu>
  )

  return (
    <Container>
      <Helmet>
        <title>Arcke | Relatório - Comissões</title>
      </Helmet>
      <Skeleton loading={loading} active>
        <PageTitle
          title='Relatório de Ordens de Serviços por Status'
          extra={[
            <Dropdown
              key='1'
              trigger={['click']}
              disabled={false || filtrados.length < 1}
              overlay={menu}
              placement='bottomCenter'
            >
              <Button size='large'>
                Exportar Tabela <DownOutlined />
              </Button>
            </Dropdown>,
            <Button
              icon={<FilterOutlined />}
              type='primary'
              size='large'
              onClick={() => {
                setVisible(true)
              }}
              key='2'
            >
              Filtrar
            </Button>,
          ]}
        />
        <Table
          dataSource={filtrados}
          loading={loading}
          rowKey={(record) => record.id}
          size='middle'
          style={{ margin: 20 }}
        >
          <Column title='Nome' dataIndex='name' key='name' />
          <Column
            title='Em Aberto'
            dataIndex='aberto'
            key='aberto'
            render={(_, record) => record.orders.aberto.length}
          />
          <Column
            title='Em Atraso'
            dataIndex='atrasado'
            key='atrasado'
            render={(_, record) => record.orders.atrasado.length}
          />
          <Column
            title='Em Execução'
            dataIndex='execucao'
            key='execucao'
            render={(_, record) => record.orders.execucao.length}
          />
          <Column
            title='Em Reaberto'
            dataIndex='reaberto'
            key='reaberto'
            render={(_, record) => record.orders.reaberto.length}
          />
          <Column
            title='Finalizado'
            dataIndex='finalizado'
            key='finalizado'
            render={(_, record) => record.orders.finalizado.length}
          />
          <Column
            title='Validado'
            dataIndex='validado'
            key='validado'
            render={(_, record) => record.orders.validado.length}
          />

          <Column
            title='Ações'
            dataIndex='actions'
            key='actions'
            render={(_, record) => (
              <Space>
                <Dropdown
                  trigger={['click']}
                  key='1'
                  overlay={menuTabela(record)}
                  placement='bottomCenter'
                >
                  <Button>
                    Ações <SettingOutlined />
                  </Button>
                </Dropdown>
              </Space>
            )}
          />
        </Table>
        <Drawer
          visible={visible}
          onClose={onClose}
          width={550}
          title='Filtro de Relatório'
        >
          <Spin spinning={loading}>
            <Form form={form} layout='vertical' onFinish={onSubmit}>
              <Form.Item label='Técnico'>
                <Space style={{ width: '100%' }}>
                  <Form.Item
                    name='tecnicoId'
                    rules={[
                      {
                        required: true,
                        message: 'Selecine ao menos um técnico',
                      },
                    ]}
                    noStyle
                  >
                    <Select
                      size='large'
                      showSearch
                      style={{ width: 480 }}
                      placeholder='Selecione...'
                      optionLabelProp='label'
                      optionFilterProp='children'
                      allowClear
                      mode='multiple'
                      onChange={onChange}
                      defaultValue={checkedList}
                      maxTagCount='responsive'
                    >
                      {tecnicos.map((tecnico) => (
                        <Select.Option
                          key={tecnico.id}
                          value={tecnico.id}
                          label={tecnico.name}
                        >
                          {tecnico.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Tooltip placement='topLeft' title='Selecionar todos.'>
                    <Checkbox
                      indeterminate={indeterminate}
                      onChange={onCheckAllChange}
                      checked={checkAll}
                    />
                  </Tooltip>
                </Space>
              </Form.Item>
              <Form.Item label='Data de início' name='dataInicio'>
                <DatePicker
                  style={{ width: '100%' }}
                  onChange={(value) => {
                    setDate(value)
                  }}
                  size='large'
                />
              </Form.Item>
              <Form.Item label='Data de finalização' name='dataFim'>
                <DatePicker
                  style={{ width: '100%' }}
                  disabled={date === null}
                  disabledDate={disabledDate}
                  size='large'
                />
                {/* ARRAY['aberto'::text, 'execucao'::text, 'aguardando'::text, 'finalizado'::text, 'reaberto'::text, 'atrasado'::text])) */}
              </Form.Item>
              <Form.Item label='Status' name='status'>
                <Select
                  size='large'
                  showSearch
                  placeholder='Selecione...'
                  allowClear
                  optionLabelProp='label'
                  optionFilterProp='children'
                >
                  {status.map((stats) => (
                    <Select.Option
                      key={stats.id}
                      value={stats.status}
                      label={stats.label}
                    >
                      {stats.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Divider style={{ borderColor: '#aaa' }} />
              <div style={{ display: 'flex' }}>
                <Enviar
                  disabled={date === null}
                  type='primary'
                  htmlType='submit'
                  style={{ flex: 1 }}
                  size='large'
                >
                  Gerar Relatório
                </Enviar>
                <Cancelar
                  danger
                  onClick={onClose}
                  style={{ flex: 1 }}
                  size='large'
                >
                  Cancelar
                </Cancelar>
              </div>
            </Form>
          </Spin>
        </Drawer>
      </Skeleton>
    </Container>
  )
}

export { RelatorioOrdens }
