import styled from 'styled-components'
import fundo from '../../assets/image/fundo.png'

export const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d9d9d9;
  position: relative;
  background-image: url(${fundo});
  background-repeat: no-repeat;
  background-size: cover;
`
export const Content = styled.div`
  display: flex;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  height: 400px;
  width: 500px;
  border-radius: 10px;
  overflow: hidden;
  @media (max-width: 980px) {
    width: 400px;
  }
`

export const FormContainer = styled.div`
  height: 90%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  @media (max-width: 980px) {
    width: 100%;
  }
`

export const LogoContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`
