import React, { useState, useEffect, useContext } from 'react'
import { Helmet } from 'react-helmet'
import {
  Button,
  Table,
  message,
  Drawer,
  Space,
  Modal,
  Typography,
  Form,
  Input,
  Select,
  Upload,
  Radio,
  Spin,
  Menu,
  Dropdown,
  Empty,
  Divider,
  Tag,
} from 'antd'
import { format, parseISO } from 'date-fns'
import NumberFormat from 'react-number-format'
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  OrderedListOutlined,
  ToolOutlined,
  UploadOutlined,
  CopyOutlined,
  EyeOutlined,
  MoreOutlined,
  SubnodeOutlined,
  SearchOutlined,
  ClearOutlined,
  LinkOutlined,
} from '@ant-design/icons'
import { useHistory, withRouter } from 'react-router-dom'
import { Container, PageTitle } from 'components'
import api from 'services/api'
import { formatarMoeda } from 'services/utils'
import { Enviar, Cancelar } from 'components/Buttons'
import DatePicker from 'components/DatePicker'
import DrawerCliente from 'components/Drawer/DrawerCliente'
import AuthContext, { TOKEN_API } from 'contexts/auth'

const { Text, Paragraph, Link } = Typography
const { Column } = Table
const { Option } = Select

const statusFilter = [
  { label: 'Vigente', value: 'vigente' },
  { label: 'Aditivado', value: 'aditivado' },
  { label: 'Cancelado', value: 'cancelado' },
  { label: 'Vencido', value: 'vencido' },
  { label: 'Encerrado', value: 'encerrado' },
]

const colors = {
  vigente: { name: 'Vigente', color: '#098DDF' },
  aditivado: { name: 'Aditivado', color: '#F87C00' },
  vencido: { name: 'Vencido', color: '#FA0000' },
  encerrado: { name: 'Encerrado', color: '#000000' },
  solicitado: { name: 'Solicitado', color: '#df7800' },
  aberto: { name: 'Aberto', color: '#2eb772' },
  execucao: { name: 'Em Execução', color: '#1919d3' },
  aguardando: { name: 'Aguardando', color: '#ede90c' },
  finalizado: { name: 'Finalizado', color: '#3865a3' },
  reaberto: { name: 'Reaberto', color: '#0cedd6' },
  atrasado: { name: 'Atrasado', color: '#d31959' },
  validado: { name: 'Validado', color: '#a161db' },
  pendente: { name: 'Pendente', color: '#787878' },
  cancelado: { name: 'Cancelado', color: '#ef1b1b' },
}

function Contratos() {
  const { user } = useContext(AuthContext)
  const history = useHistory()
  const [form] = Form.useForm()
  const token = localStorage.getItem(TOKEN_API)
  const [modal, setModal] = useState('none') // tipos de modal: 'none'|'duplicar'|'deletar'|'visualizar'
  const [clients, setClients] = useState([])
  const [pmocs, setPmocs] = useState([])
  const [contracts, setContracts] = useState([])
  const [atendentes, setAtendentes] = useState([])
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [mode, setMode] = useState('insert')
  const [contract, setContract] = useState(null)
  const [load, setLoad] = useState(false)
  const [modalData, setModalData] = useState({})
  const [nameFilter, setNameFilter] = useState('')
  const [clientFilter, setClientFilter] = useState('')
  const [status, setStatusFilter] = useState('')
  const [responsavelIdFilter, setResponsavelIdFilter] = useState('')
  const [file, setFile] = useState([])
  const [drawerData, setDrawerData] = useState({
    data: {},
    mode: '',
    visible: false,
  })

  const [metaPagination, setMetaPagination] = useState({
    current_page: 1,
    first_page: 1,
    first_page_url: '/?page=1',
    last_page: 2,
    last_page_url: '/?page=2',
    next_page_url: '/?page=2',
    per_page: 10,
    previous_page_url: null,
    total: null,
  })

  useEffect(() => {
    async function loadData() {
      setLoading(true)
      getContratos()
      setLoading(false)
    }
    loadData()
  }, [load])

  useEffect(() => {
    async function loadData() {
      setLoading(true)
      await Promise.all([getAtendentes(), getClientes(), getPmocs()])
      setLoading(false)
    }
    loadData()
  }, [])

  const getPmocs = async () => {
    try {
      await api.get('/pmocs').then(async ({ data }) => {
        setPmocs(data)
      })
    } catch (error) {
      if (error.response.status !== 401) {
        message.error('Erro ao carregar os dados, atualize a pagina')
      }
    }
  }

  const getClientes = async () => {
    try {
      await api.get('/client').then(async ({ data }) => {
        setClients(data)
      })
    } catch (error) {
      if (error.response.status !== 401) {
        message.error('Erro ao carregar os dados, atualize a pagina')
      }
    }
  }

  const getContratos = async () => {
    try {
      await api
        .get(
          `/contract/?page=${metaPagination?.current_page}&limit=${
            metaPagination?.per_page
          }${nameFilter !== '' ? `&name=${nameFilter}` : ''}${
            clientFilter !== '' ? `&clientId=${clientFilter}` : ''
          }${
            responsavelIdFilter !== ''
              ? `&responsavelId=${responsavelIdFilter}`
              : ''
          }${status !== '' ? `&status=${status}` : ''}`
        )
        .then((response) => {
          setContracts(response.data.data)
          setMetaPagination(response.data.meta)
        })
    } catch (error) {
      if (error.response.status !== 401) {
        message.error('Erro ao carregar os dados, atualize a pagina')
      }
    }
  }

  const getAtendentes = async () => {
    try {
      await api.get('/user?accessProfile=atendente').then(({ data }) => {
        setAtendentes(data)
      })
    } catch (error) {
      if (error.response.status !== 401) {
        message.error('Erro ao carregar os dados, atualize a pagina')
      }
    }
  }

  const beforeUpload = (file) => {
    const validFormat = file.type === 'application/pdf'
    if (!validFormat) {
      message.error('Envie somente arquivos do formato PDF')
    }
    return validFormat
  }

  const openDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    form.resetFields()
    setMode('insert')
    setVisible(false)
  }

  const onCloseClienteDrawer = async () => {
    await api.get('/client').then(async ({ data }) => {
      setClients(data)
    })
    setDrawerData({
      data: {},
      mode: '',
      visible: false,
    })
    setLoading(false)
  }

  const onCancel = () => setModal('none')

  const onDuplicar = async () => {
    await api
      .post('/contract/duplicar_contrato', {
        ...modalData,
        duplicateId: modalData.id,
      })
      .then(() => {
        message.success('Contrato duplicado com sucesso')
        setLoad(!load)
      })
      .catch(() => {
        message.error('Erro ao duplicar o contrato, tente novamente')
      })
      .finally(() => setModal('none'))
  }

  const onDelete = async () => {
    await api
      .delete(`/contract/${modalData.id}`)
      .then(() => {
        message.success('Contrato excluido com sucesso')
        setLoad(!load)
      })
      .catch(() => {
        message.error('Erro ao excluir cliente, tente novamente')
      })
      .finally(() => setModal('none'))
  }

  const handleSubmitForm = async (values) => {
    console.log(values)
    if (mode === 'insert') {
      const data = {
        ...values,
        name: values.name.toUpperCase(),
        objeto: values.objeto.toUpperCase(),
        obs: values.obs?.toUpperCase(),
        contractFile: file[0]?.url,
      }
      setLoading(true)
      await api
        .post('/contract', data)
        .then(() => {
          message.success('Cadastro realizado com sucesso!')
          setLoad(!load)
          onClose()
        })
        .catch((e) => {
          if (e.response.data.errors) {
            e.response.data.errors.forEach((info) => {
              message.error(info.message)
            })
          } else {
            message.error(e.response.data.error)
          }
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setLoading(true)
      await api
        .put(`/contract/${contract.id}`, {
          ...values,
          contractFile: file[0]?.url,
        })
        .then(() => {
          message.success('Contrato atualizado com sucesso!')
          setLoad(!load)
          onClose()
        })
        .catch((e) => {
          if (e.response.data.errors) {
            e.response.data.errors.forEach((info) => {
              message.error(info.message)
            })
          } else {
            message.error(e.response.data.error)
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const menu = (record) => (
    <Menu>
      <Menu.Item
        icon={<EyeOutlined />}
        onClick={() => {
          setContract(record)
          setModal('visualizar')
        }}
      >
        Visualizar
      </Menu.Item>
      <Menu.Item
        key='editar'
        icon={<EditOutlined />}
        onClick={() => {
          setContract(record)
          console.log(contract)

          form.setFieldsValue({
            ...record,
            value: Number(record.value),
            startDate: parseISO(record.startDate),
            endDate: parseISO(record.endDate),
            pmocs: record.pmocs?.map((item) => {
              return item.id
            }),
          })
          if (record.contractFile) {
            setFile([
              {
                uid: '1',
                name: 'arquivo',
                status: 'done',
                url: record.contractFile,
              },
            ])
          }
          setMode('update')
          openDrawer()
        }}
      >
        Editar
      </Menu.Item>
      <Menu.Item
        key='duplicar'
        icon={<CopyOutlined />}
        onClick={async () => {
          setModalData(record)
          setModal('duplicar')
        }}
      >
        Duplicar
        <Modal
          destroyOnClose
          title='Deseja duplicar o contrato?'
          visible={modal === 'duplicar'}
          onOk={onDuplicar}
          onCancel={onCancel}
          okText='Duplicar'
          cancelText='Cancelar'
        >
          <p>{'A duplicação de contrato irá duplicar os itens também!'}</p>
        </Modal>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key='aditivos_contrato'
        icon={<SubnodeOutlined />}
        onClick={() =>
          history.push({
            pathname: `/contratos/${record.id}/aditivoscontrato`,
            state: { ...record },
          })
        }
      >
        Aditivos do contrato
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key='listar'
        icon={<ToolOutlined />}
        onClick={() => history.push(`/contratos/${record.id}/os`)}
      >
        Listar O.S
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key='itens_contrato'
        icon={<OrderedListOutlined />}
        onClick={() =>
          history.push({
            pathname: `/contratos/${record.id}/itens`,
            state: { ...record },
          })
        }
      >
        Itens do contrato
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key='excluir'
        icon={<DeleteOutlined />}
        danger
        type='primary'
        size='large'
        onClick={() => {
          setModalData(record)
          setModal('deletar')
        }}
      >
        Deletar
      </Menu.Item>
      <Modal
        destroyOnClose
        width='400px'
        title='Deseja deletar o contrato?'
        visible={modal === 'deletar'}
        onOk={onDelete}
        onCancel={onCancel}
        okText='Deletar'
        cancelText='Cancelar'
      >
        <p>O contrato será excluido permanentemente!</p>
      </Modal>
    </Menu>
  )

  const props = {
    name: 'file',
    listType: 'text',
    multiple: false,
    action: process.env.REACT_APP_API + `upload`,
    data: {
      pasta: 'contratos',
      organization: user?.organization.bucket_name,
    },
    headers: {
      authorization: `Bearer ${token}`,
    },
    fileList: file,
    beforeUpload,
    onRemove: ({ uid }) => {
      setFile(file.filter((fl) => fl.uid === uid))
    },
    onChange({ fileList }) {
      let newFileList = [...fileList]
      newFileList = newFileList?.map((file) => {
        if (file.response) {
          file.url = file.response.url
        }
        return file
      })
      setFile(newFileList)
    },
    accept: 'application/pdf',
  }

  return (
    <Container>
      <Helmet>
        <title>Arcke | Contratos</title>
      </Helmet>
      <PageTitle
        title='Contratos'
        extra={[
          <Button
            key={1}
            icon={<PlusOutlined />}
            type='primary'
            size='large'
            onClick={() => {
              setMode('insert')
              openDrawer()
            }}
          >
            Adicionar Contrato
          </Button>,
        ]}
      />
      <Table
        dataSource={contracts}
        loading={loading}
        pagination={{
          total: metaPagination?.total,
          pageSize: metaPagination?.per_page,
          current: metaPagination?.current_page || 1,
          showTotal: (total) => `Total de ${total} itens`,
          onChange: async (page, pageSize) => {
            setMetaPagination({
              ...metaPagination,
              per_page: pageSize,
              current_page: page,
            })
            setLoad(!load)
          },
        }}
        rowKey={(record) => record.id}
        size='middle'
        style={{ margin: 20 }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <>
                  <span>Não há registro aqui</span>
                </>
              }
            >
              <Button
                icon={<PlusOutlined />}
                key='insert'
                type='primary'
                size='large'
                onClick={() => {
                  setMode('insert')
                  openDrawer()
                }}
              >
                Adicionar
              </Button>
            </Empty>
          ),
        }}
      >
        <Column
          title='Contrato'
          dataIndex='name'
          key='name'
          filterIcon={(filtered) => (
            <SearchOutlined
              style={{ color: filtered ? '#1890ff' : '#777', fontSize: 15 }}
            />
          )}
          onFilter={(value, record) =>
            record.name
              ? record.name
                  .toString()
                  .toLowerCase()
                  .includes(value.toString().toLowerCase())
              : false
          }
          filterDropdown={({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
            <div style={{ padding: 8, display: 'flex', alignItems: 'center' }}>
              <Input
                placeholder='Filtrar Contrato'
                value={selectedKeys[0]}
                style={{ width: 188, display: 'block' }}
                onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() => {
                  setMetaPagination({ current_page: 1, per_page: 10 })
                  setNameFilter(selectedKeys[0])
                  setLoad(!load)
                }}
              />
              <Button
                type='primary'
                style={{ marginLeft: 6, marginRight: 6 }}
                onClick={() => {
                  setMetaPagination({ current_page: 1, per_page: 10 })
                  setNameFilter(selectedKeys[0] ?? '')
                  setLoad(!load)
                }}
                icon={<SearchOutlined />}
              />
              <Button
                icon={<ClearOutlined />}
                onClick={() => {
                  if (clearFilters) {
                    setNameFilter('')
                    clearFilters()
                    setLoad(!load)
                  }
                }}
              />
            </div>
          )}
        />
        <Column
          title='Cliente'
          dataIndex='cliente'
          key='cliente'
          render={(_, record) =>
            record.cliente ? (
              <Text>{record.cliente?.nomeFantasia}</Text>
            ) : (
              <Tag>Sem Cliente</Tag>
            )
          }
          filterIcon={(filtered) => (
            <SearchOutlined
              style={{ color: filtered ? '#1890ff' : '#777', fontSize: 15 }}
            />
          )}
          filterDropdown={({
            selectedKeys,
            setSelectedKeys,
            confirm,
            clearFilters,
          }) => (
            <div style={{ padding: 8, display: 'flex', alignItems: 'center' }}>
              <Select
                style={{ width: 188, display: 'block' }}
                onSelect={(e) => setSelectedKeys([e])}
              >
                {clients.map((cliente, index) => {
                  return (
                    <Select.Option key={index} value={cliente.id}>
                      {cliente.nomeFantasia}
                    </Select.Option>
                  )
                })}
              </Select>

              <Button
                type='primary'
                style={{ marginLeft: 6, marginRight: 6 }}
                icon={<SearchOutlined />}
                onClick={() => {
                  setMetaPagination({ current_page: 1, per_page: 10 })
                  setClientFilter(selectedKeys[0])
                  setLoad(!load)
                }}
              />
              <Button
                icon={<ClearOutlined />}
                onClick={() => {
                  if (clearFilters) {
                    setClientFilter('')
                    setLoad(!load)
                  }
                }}
              />
            </div>
          )}
        />
        <Column
          title='Responsável'
          dataIndex='responsavel'
          key='responsavel'
          render={(_, record) =>
            record.responsavel ? (
              <Text>{record.responsavel?.name}</Text>
            ) : (
              <Tag>Sem Responsável</Tag>
            )
          }
          filterIcon={(filtered) => (
            <SearchOutlined
              style={{ color: filtered ? '#1890ff' : '#777', fontSize: 15 }}
            />
          )}
          onFilter={(value, record) =>
            record.responsavel
              ? record.responsavel
                  .toString()
                  .toLowerCase()
                  .includes(value.toString().toLowerCase())
              : false
          }
          filterDropdown={({
            selectedKeys,
            setSelectedKeys,
            confirm,
            clearFilters,
          }) => (
            <div style={{ padding: 8, display: 'flex', alignItems: 'center' }}>
              <Select
                style={{ width: 188, display: 'block' }}
                onSelect={(e) => setSelectedKeys([e])}
              >
                {atendentes.map((responsavel, index) => {
                  return (
                    <Select.Option key={index} value={responsavel.id}>
                      {responsavel.name}
                    </Select.Option>
                  )
                })}
              </Select>

              <Button
                type='primary'
                style={{ marginLeft: 6, marginRight: 6 }}
                icon={<SearchOutlined />}
                onClick={() => {
                  setMetaPagination({ current_page: 1, per_page: 10 })
                  setResponsavelIdFilter(selectedKeys[0])
                  setLoad(!load)
                }}
              />
              <Button
                icon={<ClearOutlined />}
                onClick={() => {
                  if (clearFilters) {
                    setResponsavelIdFilter('')
                    setLoad(!load)
                  }
                }}
              />
            </div>
          )}
        />
        <Column
          title='Valor do Contrato'
          dataIndex='value'
          key='value'
          render={(text) => `${formatarMoeda(Number(text))}`}
        />
        <Column
          title='Status'
          dataIndex='status'
          width={150}
          key='status'
          render={(_, record) => (
            <Tag color={colors[record.status].color}>
              {colors[record.status].name}
            </Tag>
          )}
          filterIcon={(filtered) => (
            <SearchOutlined
              style={{ color: filtered ? '#1890ff' : '#777', fontSize: 15 }}
            />
          )}
          onFilter={(value, record) =>
            record.status
              ? record.status
                  .toString()
                  .toLowerCase()
                  .includes(value.toString().toLowerCase())
              : false
          }
          filterDropdown={({
            selectedKeys,
            setSelectedKeys,
            confirm,
            clearFilters,
          }) => (
            <div style={{ padding: 8, display: 'flex', alignItems: 'center' }}>
              <Select
                style={{ width: 188, display: 'block' }}
                onSelect={(e) => setSelectedKeys([e])}
              >
                {statusFilter.map((option, index) => {
                  return (
                    <Select.Option key={index} value={option.value}>
                      {option.label}
                    </Select.Option>
                  )
                })}
              </Select>

              <Button
                type='primary'
                style={{ marginLeft: 6, marginRight: 6 }}
                icon={<SearchOutlined />}
                onClick={() => {
                  setMetaPagination({ current_page: 1, per_page: 10 })
                  setStatusFilter(selectedKeys[0])
                  setLoad(!load)
                }}
              />
              <Button
                icon={<ClearOutlined />}
                onClick={() => {
                  if (clearFilters) {
                    setStatusFilter('')
                    setLoad(!load)
                  }
                }}
              />
            </div>
          )}
        />
        <Column
          title='Dt. de Início'
          dataIndex='startDate'
          key='startDate'
          render={(_, record) =>
            format(parseISO(record.startDate), 'dd/MM/yyyy')
          }
        />
        <Column
          title='Dt. de Finalização'
          dataIndex='endDate'
          key='endDate'
          render={(_, record) => format(parseISO(record.endDate), 'dd/MM/yyyy')}
        />
        <Column
          key='actions'
          title='Ações'
          width={20}
          render={(_, record) => (
            <Space>
              <Dropdown
                overlay={menu(record)}
                placement='bottomRight'
                trigger={['click']}
                arrow
              >
                <Button icon={<MoreOutlined />} />
              </Dropdown>
            </Space>
          )}
        />
      </Table>
      <Drawer
        visible={visible}
        onClose={onClose}
        width={550}
        title={`${mode === 'insert' ? 'Adicionar' : 'Atualizar'} Contrato`}
      >
        <Spin spinning={loading}>
          <Form layout='vertical' form={form} onFinish={handleSubmitForm}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Form.Item
                label='Cliente'
                name='clientId'
                style={{
                  width: '100%',
                }}
              >
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder='Selecione...'
                  optionLabelProp='label'
                  optionFilterProp='children'
                  size='large'
                >
                  {clients.map(({ id, nomeFantasia }) => (
                    <Option key={id} value={id} label={nomeFantasia}>
                      {nomeFantasia}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Button
                size='large'
                style={{
                  padding: '0 11px',
                  borderColor: '#aaa',
                  marginBottom: -5,
                }}
                onClick={() => {
                  setDrawerData({
                    data: {},
                    mode: 'insert',
                    visible: true,
                  })
                }}
              >
                <PlusOutlined />
              </Button>
            </div>
            <DrawerCliente
              data={drawerData}
              onClose={onCloseClienteDrawer}
              load={load}
              setLoad={setLoad}
              setLoading={setLoading}
              loading={loading}
            />
            <Form.Item
              label='Contrato'
              name='name'
              rules={[
                {
                  required: true,
                  message: 'Por favor, o contrato deve ser informado',
                },
              ]}
            >
              <Input size='large' />
            </Form.Item>
            <Form.Item
              label='Objeto'
              name='objeto'
              rules={[
                {
                  max: 255,
                  required: true,
                  message: 'Por favor, o objeto do contrato deve ser informado',
                },
              ]}
            >
              <Input.TextArea
                size='large'
                autoSize={{ minRows: 3, maxRows: 7 }}
                showCount
                maxLength={255}
              />
            </Form.Item>
            <Form.Item
              label='Valor'
              name='value'
              trigger='onValueChange'
              rules={[
                {
                  required: true,
                  message: 'Por favor, o valor deve ser informada',
                },
              ]}
            >
              <NumberFormat
                style={{ padding: '8px' }}
                thousandSeparator='.'
                decimalSeparator=','
                prefix='R$ '
                decimalScale={2}
                className='ant-input'
                fixedDecimalScale
                onValueChange={(value) =>
                  form.setFieldsValue({ value: value.floatValue })
                }
              />
            </Form.Item>
            <Form.Item name='vigencia' label='Vigencia'>
              <div
                style={{
                  display: 'flex',
                }}
              >
                <Form.Item
                  name='startDate'
                  style={{
                    display: 'flex',
                    flex: 1,
                  }}
                >
                  <DatePicker placeholder='Início' size='large' />
                </Form.Item>
                <span
                  style={{
                    display: 'inline-flex',
                    width: '117px',
                    marginTop: 8,
                    justifyContent: 'center',
                  }}
                >
                  até
                </span>
                <Form.Item
                  name='endDate'
                  style={{
                    display: 'flex',
                    flex: 1,
                  }}
                >
                  <DatePicker placeholder='Fim' size='large' />
                </Form.Item>
              </div>
            </Form.Item>
            <Form.Item
              label='Responsável'
              name='responsavelId'
              style={{ marginTop: -20 }}
            >
              <Select
                placeholder='Selecione...'
                optionLabelProp='label'
                size='large'
              >
                {atendentes.map(({ id, name }) => (
                  <Option key={id} value={id} label={name}>
                    {name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label='Observações' name='obs'>
              <Input.TextArea
                autoSize={{ minRows: 3, maxRows: 5 }}
                size='large'
                showCount
                maxLength={255}
              />
            </Form.Item>
            <Form.Item label='PMOC' name='pmocs'>
              <Select
                placeholder='Selecione...'
                optionLabelProp='label'
                size='large'
                mode='multiple'
                allowClear
              >
                {pmocs.map(({ id, name }) => (
                  <Option key={id} value={id} label={name}>
                    {name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label='Status'
              name='status'
              rules={[
                {
                  required: true,
                  message: 'Por favor, informe o status do contrato',
                },
              ]}
            >
              <Radio.Group>
                <Radio value='vigente'>Vigente</Radio>
                <Radio value='aditivado'>Aditivado</Radio>
                <Radio value='cancelado'>Cancelado</Radio>
                <Radio value='vencido'>Vencido</Radio>
                <Radio value='encerrado'>Encerrado</Radio>
              </Radio.Group>
            </Form.Item>
            <Space align='start'>
              <Form.Item
                label='Arquivo'
                name='contractFile'
                valuePropName='file'
              >
                <Upload.Dragger
                  {...props}
                  style={{
                    padding: '0 50px',
                  }}
                >
                  <>
                    <p className='ant-upload-drag-icon'>
                      <UploadOutlined />
                    </p>
                    <p className='ant-upload-text'>
                      Clique ou arraste o arquivo para esta área para carrega-lo
                    </p>
                  </>
                </Upload.Dragger>
              </Form.Item>
            </Space>
            <Divider style={{ borderColor: '#bbb' }} />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Enviar
                disabled={loading}
                type='primary'
                htmlType='submit'
                size='large'
                style={{ flex: 1 }}
              >
                {mode === 'insert' ? 'Adicionar' : 'Atualizar'}
              </Enviar>
              <Cancelar
                danger
                onClick={onClose}
                size='large'
                style={{ flex: 1 }}
              >
                Cancelar
              </Cancelar>
            </div>
          </Form>
        </Spin>
      </Drawer>
      <Modal
        title='Contrato'
        visible={modal === 'visualizar'}
        onOk={onCancel}
        onCancel={onCancel}
        footer={null}
      >
        {contract && (
          <>
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1 }}>
                <Text strong>Contrato</Text>
                <Paragraph>{contract.name}</Paragraph>
              </div>
              <div style={{ flex: 1 }}>
                <Text strong>Cliente</Text>
                <Paragraph>{contract.cliente.nomeFantasia}</Paragraph>
              </div>
            </div>
            <Text strong>Objeto</Text>
            <Paragraph>{contract.objeto}</Paragraph>
            <Divider style={{ borderColor: '#aaa' }} />
            <Text strong>Total de Ordens de Serviço:</Text>
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1 }}>
                <Paragraph>{contract.osTotal}</Paragraph>
                <Text strong>Valor do Contrato</Text>
                <Paragraph>{formatarMoeda(contract.value)}</Paragraph>
              </div>
              <div
                style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
              >
                <Paragraph />
                <Text strong>Status do contrato</Text>
                <div>
                  <Tag color={colors[contract.status].color}>
                    {contract.status}
                  </Tag>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1 }}>
                <Text strong>Início da vigência</Text>
                <Paragraph>
                  {format(parseISO(contract.startDate), 'dd/MM/yyyy')}
                </Paragraph>
              </div>
              <div style={{ flex: 1 }}>
                <Text strong>Fim da vigência</Text>
                <Paragraph>
                  {format(parseISO(contract.endDate), 'dd/MM/yyyy')}
                </Paragraph>
              </div>
            </div>
            <Divider style={{ borderColor: '#aaa' }} />
            {contract?.contractFile && (
              <>
                <Text strong>Arquivo(s)</Text>
                <Link
                  href={contract?.contractFile}
                  target='_blank'
                  style={{ color: '#1890ff', display: 'block' }}
                >
                  {contract?.contractFile?.replace(
                    'https://nyc3.digitaloceanspaces.com/s3-arcke1/zukt_teste/contratos/',
                    ''
                  )}
                  <LinkOutlined style={{ marginLeft: 3 }} />
                </Link>
              </>
            )}
            {contract.obs ? (
              <>
                <Text strong>Observações</Text>
                <Paragraph>{contract.obs}</Paragraph>
              </>
            ) : null}
          </>
        )}
      </Modal>
    </Container>
  )
}

export default withRouter(Contratos)
