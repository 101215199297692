import React, { useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'
import AuthContext from '../contexts/auth'

function ProtectedRoute({ children, ...rest }) {
  const { signed } = useContext(AuthContext)

  return (
    <Route
      {...rest}
      render={() =>
        signed ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        )
      }
    />
  )
}

export default ProtectedRoute
