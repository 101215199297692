import React, { useState, useEffect, useContext, useRef } from 'react'
import { Helmet } from 'react-helmet'
import {
  Button,
  Table,
  message,
  Tag,
  Input,
  Dropdown,
  Menu,
  Modal,
  Empty,
  Typography,
  Row,
  Col,
  Divider,
  Select,
  Popconfirm,
} from 'antd'
import {
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
  ToolOutlined,
  PrinterOutlined,
  RetweetOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  MoreOutlined,
  EyeOutlined,
  FileSearchOutlined,
  DollarCircleOutlined,
  CopyOutlined,
  CheckOutlined,
  InboxOutlined,
  ClearOutlined,
} from '@ant-design/icons'
import { useHistory, useParams, Link } from 'react-router-dom'
import { format, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { Container, PageTitle } from 'components'
import api, { pdfUrl } from 'services/api'
import DrawerOs from 'components/Drawer/OrdemServico'
import AuthContext from 'contexts/auth'
import axios from 'axios'
const { Column } = Table
const { confirm } = Modal
const { Paragraph, Text } = Typography

const statusFilter = [
  { label: 'Aberto', value: 'aberto' },
  { label: 'Aguardando', value: 'aguardando' },
  { label: 'Atrasado', value: 'atrasado' },
  { label: 'Cancelado', value: 'cancelado' },
  { label: 'Execução', value: 'execucao' },
  { label: 'Finalizado', value: 'finalizado' },
  { label: 'Reaberto', value: 'reaberto' },
  { label: 'Pendente', value: 'pendente' },
  { label: 'Solicitado', value: 'solicitado' },
  { label: 'Validado', value: 'validado' },
]

export const colors = {
  solicitado: { name: 'Solicitado', color: '#df7800' },
  aberto: { name: 'Aberto', color: '#2eb772' },
  execucao: { name: 'Em Execução', color: '#1919d3' },
  aguardando: { name: 'Aguardando', color: '#ede90c' },
  finalizado: { name: 'Finalizado', color: '#3865a3' },
  reaberto: { name: 'Reaberto', color: '#0cedd6' },
  atrasado: { name: 'Atrasado', color: '#d31959' },
  validado: { name: 'Validado', color: '#a161db' },
  pendente: { name: 'Pendente', color: '#787878' },
  cancelado: { name: 'Cancelado', color: '#ef1b1b' },
}

export default function OrdemServico() {
  const { user } = useContext(AuthContext)
  const history = useHistory()
  const { contractId } = useParams()
  const [ordens, setOrdens] = useState([])
  const [tecnicos, setTecnicos] = useState([])
  const [clients, setClients] = useState([])
  const [locals, setLocals] = useState([])
  const [loading, setLoading] = useState(false)
  const [load, setLoad] = useState(false)
  const [drawer, setDrawer] = useState({ drawer: 'none' })
  const [ordem, setOrdem] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [numRefFilter, setNumRefFilter] = useState(null)
  const [tecnicoIdFilter, setTecnicoIdFilter] = useState(null)
  const [clientFilter, setClientFilter] = useState(null)
  const [localFilter, setLocalFilter] = useState(null)
  const [idFilter, setIdFilter] = useState(null)
  const [status, setStatusFilter] = useState(null)
  const [filterLoading, setFilterLoading] = useState(false)
  const [meta, setMeta] = useState({
    current_page: 1,
    per_page: 10,
  })

  const statusRef = useRef(null)

  useEffect(() => {
    getOrdens()
  }, [load])

  const loadClients = async () => {
    setFilterLoading(true)
    api
      .get('/client')
      .then(({ data }) => {
        setClients(data)
      })
      .catch((error) => {
        if (error.response.status !== 401) {
          message.error('Erro ao carregar os dados, atualize a pagina')
        }
      })
      .finally(() => {
        setFilterLoading(false)
      })
  }

  const loadTecnicos = async () => {
    setFilterLoading(true)
    api
      .get(`/user?accessProfile=tecnico`)
      .then(({ data }) => {
        setTecnicos(data)
      })
      .catch((error) => {
        if (error.response.status !== 401) {
          message.error('Erro ao carregar os dados, atualize a pagina')
        }
      })
      .finally(() => {
        setFilterLoading(false)
      })
  }

  const loadLocals = async () => {
    setFilterLoading(true)
    api
      .get('/all-local')
      .then(({ data }) => {
        setLocals(data)
      })
      .catch((error) => {
        if (error.response.status !== 401) {
          message.error('Erro ao carregar os dados, atualize a pagina')
        }
      })
      .finally(() => {
        setFilterLoading(false)
      })
  }

  const getOrdens = async () => {
    setLoading(true)
    await api
      .get(`/order-service/`, {
        params: {
          page: meta.current_page,
          limit: meta.per_page,
          contractId: contractId,
          numRef: numRefFilter,
          status: status,
          id: idFilter,
          tecnicoId: tecnicoIdFilter,
          clientId: clientFilter,
          localId: localFilter,
        },
      })
      .then(({ data }) => {
        setOrdens(data?.data)
        setMeta(data?.meta)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  /* 

    Visualizar -> Todos

    Editar -> status !== "finalizado" || "validado"

    Reabrir -> status === "finalizado" || "validado"

    Validar -> status === "finalizado" || "validado"

    Servicos Realizados -> status !== "aberto"

    Inserir Pecas -> status !== "validado" & atendimento === "contrato"

    Espelho da O.S -> atendimento === "contrato"

    Prévia da O.S -> status === "finalizado" || "validado"

    Relatório Fotográfico -> status === "finalizado" || "validado"

    Comissão da O.S -> status === "finalizado" || "validado"

    Deletar -> Todos

  */

  const acoes = (record) => (
    <Menu>
      <Menu.Item
        key='visualizar'
        icon={<EyeOutlined />}
        onClick={() => {
          console.log(record)
          try {
            record.instructions = JSON.parse(record.instructions)
          } catch {
            console.log('null')
          }
          setIsOpen(true)
          setOrdem(record)
        }}
      >
        Visualizar
      </Menu.Item>
      <Menu.Item
        key='historico'
        icon={<InboxOutlined />}
        onClick={() => history.push(`/service-order/${record.id}/historico/`)}
      >
        Restrições
      </Menu.Item>
      <Menu.Item
        key='editar'
        icon={<EditOutlined />}
        onClick={() =>
          setDrawer({
            drawer: 'update',
            ordem: record,
          })
        }
      >
        Editar
      </Menu.Item>
      {(record.status === 'finalizado' || record.status === 'validado') && (
        <>
          <Menu.Item
            key='reabrir'
            icon={<RetweetOutlined />}
            onClick={async () => {
              setLoading(true)
              await api
                .put(`/ordem_servico/reabrir/${record.id}`, {
                  status: 'reaberto',
                })
                .then(() => {
                  message.success('Ordem de Serviço reaberta!')
                  setLoading(false)
                  setLoad(!load)
                })
                .catch(() => setLoading(false))
            }}
          >
            Reabrir
          </Menu.Item>
          <Menu.Item key='validar'>
            <Popconfirm
              zIndex={1000}
              title='Tem certeza que quer validar a O.S?'
              onConfirm={async () => {
                setLoading(true)
                await api
                  .put(`/ordem_servico/validar/${record.id}`)
                  .then(() => {
                    message.success('Ordem de Serviço validada!')
                    setLoading(false)
                    setLoad(!load)
                  })
                  .catch(() => setLoading(false))
              }}
              okText='Sim'
              cancelText='Não'
            >
              <div
                style={{
                  margin: '-12px -24px',
                  padding: '10px 25px',
                  cursor: 'pointer',
                }}
              >
                <CheckOutlined style={{ fontSize: 12 }} />
                <button
                  style={{
                    background: 'transparent',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                >
                  Validar
                </button>
              </div>
            </Popconfirm>
          </Menu.Item>
        </>
      )}
      <Menu.Divider />
      {record.status !== 'aberto' && (
        <Menu.Item
          key='serviços'
          icon={<ToolOutlined />}
          onClick={() => history.push(`/service-order/${record.id}/services/`)}
        >
          Serviços Realizados
        </Menu.Item>
      )}
      {record.atendimento === 'contrato' && (
        <>
          {record.status !== 'validado' && (
            <Menu.Item
              key='pecas'
              icon={<InboxOutlined />}
              onClick={() => history.push(`/service-order/${record.id}/pecas/`)}
            >
              Inserir Peças
            </Menu.Item>
          )}
          <Menu.Divider />
          <Menu.Item
            key='espelho'
            icon={<CopyOutlined />}
            onClick={async () => {
              setLoading(true)
              try {
                message.loading({
                  content: 'Gerando PDF...',
                  key: record.id,
                  duration: 0,
                })
                const os = await api
                  .get(`order-service/${record.id}`)
                  .then(({ data }) => {
                    return data
                  })
                await axios
                  .post(
                    `${pdfUrl}order-mirror/${record.id}`,
                    {
                      os: {
                        ...os,
                        organization: user.organization,
                        cliente: os.contrato.cliente,
                      },
                    },
                    {
                      responseType: 'blob',
                    }
                  )
                  .then((response) => {
                    message.success({
                      content: 'Baixando PDF...',
                      key: record.id,
                    })
                    const url = window.URL.createObjectURL(
                      new Blob([response.data])
                    )
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', `${record.id}.pdf`)
                    document.body.appendChild(link)
                    link.click()
                  })
                setLoading(false)
              } catch (error) {
                console.log(error)
                message.error({
                  content: 'Erro ao gerar PDF.',
                  key: record.id,
                })
              }
              setLoading(false)
            }}
          >
            Espelho da O.S
          </Menu.Item>
        </>
      )}
      {(record.status === 'finalizado' || record.status === 'validado') && (
        <>
          <Menu.Item
            key='previsualizar'
            icon={<FileSearchOutlined />}
            disabled={
              record.atendimento === 'avulso' ||
              record.atendimento === 'orcamento'
            }
            onClick={async () => {
              setLoading(true)

              try {
                message.loading({
                  content: 'Carregando informações da OS',
                  key: record.id,
                  duration: 0,
                })
                const os = await api
                  .get(`order-service/${record.id}`)
                  .then(({ data }) => {
                    return data
                  })

                message.loading({
                  content: 'Gerando Pré visualização...',
                  key: record.id,
                  duration: 0,
                })
                await axios
                  .post(
                    `${pdfUrl}html/${os.id}`,
                    { user, os },
                    {
                      responseType: 'text/html',
                    }
                  )
                  .then((response) => {
                    const newWin = window.open(
                      'about:blank',
                      'Axios data',
                      'width=841,height=800'
                    )
                    newWin.document.write(response.data)
                  })
                message.success({
                  content: 'Pré visualização gerada..',
                  key: record.id,
                })
                setLoading(false)
              } catch (error) {
                console.log(error)
                message.error({
                  content: 'Erro ao gerar a pré-visualização relatório',
                  key: record.id,
                })
              } finally {
                setLoading(false)
              }
            }}
          >
            Prévia da O.S
          </Menu.Item>
          <Menu.Item
            key='imprimir'
            icon={<PrinterOutlined />}
            onClick={async () => {
              setLoading(true)
              try {
                message.loading({
                  content: 'Carregando informações da OS',
                  key: record.id,
                  duration: 0,
                })
                const os = await api
                  .get(`order-service/${record.id}`)
                  .then(({ data }) => {
                    return data
                  })

                message.loading({
                  content: 'Gerando PDF...',
                  key: record.id,
                  duration: 0,
                })
                await axios
                  .post(
                    pdfUrl,
                    { user, os },
                    {
                      timeout: 300000,
                      responseType: 'blob',
                    }
                  )
                  .then((response) => {
                    message.success({
                      content: 'Baixando PDF...',
                      key: record.id,
                    })
                    const url = window.URL.createObjectURL(
                      new Blob([response.data])
                    )
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', `${record.id}.pdf`)
                    document.body.appendChild(link)
                    link.click()
                  })
                setLoading(false)
              } catch (error) {
                console.log(error)
                message.error({
                  content: 'Erro ao gerar PDF.',
                  key: record.id,
                })
              }
              setLoading(false)
            }}
          >
            Relatório Fotográfico
          </Menu.Item>
          <Menu.Item
            key='comissao'
            icon={<DollarCircleOutlined />}
            disabled={
              record.atendimento === 'avulso' ||
              record.atendimento === 'orcamento'
            }
            onClick={async () => {
              setLoading(true)

              try {
                message.loading({
                  content: 'Carregando informações da OS',
                  key: record.id,
                  duration: 0,
                })
                const os = await api
                  .get(`order-service/prices/${record.id}`)
                  .then(({ data }) => {
                    setLoading(false)
                    return data
                  })

                message.loading({
                  content: 'Gerando Pré visualização...',
                  key: record.id,
                  duration: 0,
                })
                await axios
                  .post(
                    `${pdfUrl}html/prices/${os.serviceOrder.id}`,
                    { user, os: os.serviceOrder },
                    {
                      responseType: 'blob',
                    }
                  )
                  .then((response) => {
                    message.success({
                      content: 'Baixando PDF...',
                      key: record.id,
                    })
                    const url = window.URL.createObjectURL(
                      new Blob([response.data])
                    )
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', `${record.numRef}.pdf`)
                    document.body.appendChild(link)
                    link.click()
                  })
                message.success({
                  content: 'Pré visualização gerada..',
                  key: record.id,
                })
                setLoading(false)
              } catch (error) {
                console.log(error)
                message.error({
                  content: 'Erro ao gerar a pré-visualização relatório',
                  key: record.id,
                })
              } finally {
                setLoading(false)
              }
            }}
          >
            Comissão da O.S
          </Menu.Item>
        </>
      )}
      <Menu.Divider />
      <Menu.Item key='deletar' danger type='primary'>
        <Popconfirm
          title='Tem certeza que deseja deletar a OS?'
          onConfirm={() => {
            deleteOs(record.id)
          }}
          okText='Sim'
          key={2}
          cancelText='Não'
        >
          <div
            style={{
              margin: '-12px -24px',
              padding: '10px 25px',
              cursor: 'pointer',
            }}
          >
            <DeleteOutlined style={{ fontSize: 12 }} />
            <button
              style={{
                background: 'transparent',
                border: 'none',
                cursor: 'pointer',
              }}
            >
              Deletar
            </button>
          </div>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  )

  const deleteOs = async (idDelete) => {
    confirm({
      title: 'Você deseja realmente excluir?',
      icon: <ExclamationCircleOutlined />,
      content: 'Essa ação é irreversível após a confirmação',
      okText: 'Confirmar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk: async () => {
        setLoading(true)
        await api.delete(`/ordem_servico/normal/${idDelete}`).then(() => {
          setLoading(false)
          setLoad(!load)
          message.success('OS deletada com sucesso!')
        })
      },
    })
  }

  const locale = {
    emptyText: (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={
          <>
            <span>Não há registro aqui</span>
          </>
        }
      >
        <Button
          icon={<PlusOutlined />}
          key='insert'
          type='primary'
          size='large'
          onClick={() =>
            setDrawer({
              drawer: 'insert',
            })
          }
        >
          Adicionar
        </Button>
      </Empty>
    ),
  }

  const handleRefreshData = () => {
    setLoad(!load)
  }

  const routes = [
    {
      path: '/contratos',
      breadcrumbName: 'Contratos',
    },
  ]

  function itemRender(route) {
    return (
      <Link to={{ pathname: route.path }} key={route.breadcrumbName}>
        {route.breadcrumbName}
      </Link>
    )
  }

  return (
    <Container>
      <Helmet>
        <title>Arcke | Ordens de Serviços</title>
      </Helmet>
      <PageTitle
        title='Ordens de Serviços'
        breadcrumb={contractId && { routes, itemRender }}
        breadcrumbRender={(props, breadcrumb) => (
          <div style={{ marginBottom: -4 }}>{breadcrumb}</div>
        )}
        extra={[
          <Button
            icon={<PlusOutlined />}
            key='insert'
            type='primary'
            size='large'
            onClick={() =>
              setDrawer({
                drawer: 'insert',
              })
            }
          >
            Adicionar Ordem de Serviço
          </Button>,
        ]}
      />
      <Table
        locale={locale}
        scroll={{ x: 1500 }}
        pagination={{
          total: meta?.total,
          pageSize: meta?.per_page,
          current: meta?.current_page,
          showTotal: (total) => `Total de ${total} itens`,
          onChange: async (page, pageSize) => {
            setMeta({
              ...meta,
              per_page: pageSize,
              current_page: page,
            })
            setLoad(!load)
          },
        }}
        dataSource={ordens}
        loading={loading}
        rowKey={(record) => record.id}
        size='middle'
      >
        <Column
          title='ID'
          dataIndex='id'
          width={110}
          key='id'
          fixed
          render={(_, record) => <Tag>{record?.id}</Tag>}
          filterIcon={(filtered) => (
            <SearchOutlined
              style={{ color: filtered ? '#1890ff' : '#777', fontSize: 15 }}
            />
          )}
          onFilter={(value, record) =>
            record.id
              ? record.id
                  .toString()
                  .toLowerCase()
                  .includes(value.toString().toLowerCase())
              : false
          }
          filterDropdown={({
            selectedKeys,
            setSelectedKeys,
            confirm,
            clearFilters,
          }) => (
            <div style={{ padding: 8, display: 'flex', alignItems: 'center' }}>
              <Input
                placeholder='Filtrar ID:'
                value={selectedKeys[0]}
                style={{ width: 188, display: 'block' }}
                onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() => {
                  setMeta({ current_page: 1, per_page: 10 })
                  setIdFilter(selectedKeys[0])
                  setLoad(!load)
                }}
              />
              <Button
                type='primary'
                icon={<SearchOutlined />}
                style={{ marginLeft: 6, marginRight: 6 }}
                onClick={() => {
                  setMeta({ current_page: 1, per_page: 10 })
                  setIdFilter(selectedKeys[0] ?? null)
                  setLoad(!load)
                }}
              />
              <Button
                icon={<ClearOutlined />}
                onClick={() => {
                  if (clearFilters) {
                    setIdFilter(null)
                    clearFilters()
                    setLoad(!load)
                  }
                }}
              />
            </div>
          )}
        />
        <Column
          title='Nº Ref'
          width={110}
          fixed
          dataIndex='numRef'
          key='numRef'
          filterIcon={(filtered) => (
            <SearchOutlined
              style={{ color: filtered ? '#1890ff' : '#777', fontSize: 15 }}
            />
          )}
          onFilter={(value, record) =>
            record.numRef
              ? record.numRef
                  .toString()
                  .toLowerCase()
                  .includes(value.toString().toLowerCase())
              : false
          }
          filterDropdown={({
            selectedKeys,
            setSelectedKeys,
            confirm,
            clearFilters,
          }) => (
            <div style={{ padding: 8, display: 'flex', alignItems: 'center' }}>
              <Input
                placeholder='Filtrar Nº Ref.:'
                value={selectedKeys[0]}
                style={{ width: 188, display: 'block' }}
                onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() => {
                  setMeta({ current_page: 1, per_page: 10 })
                  setNumRefFilter(selectedKeys[0])
                  setLoad(!load)
                }}
              />
              <Button
                type='primary'
                icon={<SearchOutlined />}
                style={{ marginLeft: 6, marginRight: 6 }}
                onClick={() => {
                  setMeta({ current_page: 1, per_page: 10 })
                  setNumRefFilter(selectedKeys[0] ?? null)
                  setLoad(!load)
                }}
              />
              <Button
                icon={<ClearOutlined />}
                onClick={() => {
                  if (clearFilters) {
                    setNumRefFilter(null)
                    clearFilters()
                    setLoad(!load)
                  }
                }}
              />
            </div>
          )}
        />
        <Column
          title='Descrição'
          dataIndex='description'
          width={250}
          key='description'
        />
        <Column
          title='Cliente'
          width={280}
          dataIndex='cliente'
          key='cliente'
          render={(_, record) =>
            record.cliente ? (
              <Text>{record.cliente?.nomeFantasia}</Text>
            ) : (
              <Tag>Sem Cliente</Tag>
            )
          }
          filterIcon={(filtered) => (
            <SearchOutlined
              style={{ color: filtered ? '#1890ff' : '#777', fontSize: 15 }}
              onClick={() => {
                if (clients.length < 1) {
                  loadClients()
                }
              }}
            />
          )}
          filterDropdown={({
            selectedKeys,
            setSelectedKeys,
            confirm,
            clearFilters,
          }) => (
            <div style={{ padding: 8, display: 'flex', alignItems: 'center' }}>
              <Select
                loading={filterLoading}
                showSearch
                allowClear
                optionLabelProp='label'
                optionFilterProp='children'
                style={{ width: 280, display: 'block' }}
                onSelect={(e) => setSelectedKeys([e])}
              >
                {clients.map((cliente, index) => {
                  return (
                    <Select.Option key={index} value={cliente.id}>
                      {cliente.nomeFantasia}
                    </Select.Option>
                  )
                })}
              </Select>
              <Button
                style={{ marginLeft: 6, marginRight: 6 }}
                type='primary'
                icon={<SearchOutlined />}
                onClick={() => {
                  setMeta({ current_page: 1, per_page: 10 })
                  setClientFilter(selectedKeys[0])
                  setLoad(!load)
                }}
              />
              <Button
                icon={<ClearOutlined />}
                onClick={() => {
                  if (clearFilters) {
                    setClientFilter(null)
                    setLoad(!load)
                  }
                }}
              />
            </div>
          )}
        />
        <Column
          title='Local'
          width={250}
          dataIndex='local'
          key='local'
          render={(_, record) => {
            if (!record.localAtendimento && !record.local) {
              return 'Não informado'
            } else if (record.localAtendimento && !record.local) {
              return record.localAtendimento
            } else if (record.local.name && !record.localAtendimento) {
              return record?.local?.name
            }
          }}
          filterIcon={(filtered) => (
            <SearchOutlined
              onClick={() => {
                if (locals.length < 1) {
                  loadLocals()
                }
              }}
              style={{ color: filtered ? '#1890ff' : '#777', fontSize: 15 }}
            />
          )}
          filterDropdown={({
            selectedKeys,
            setSelectedKeys,
            confirm,
            clearFilters,
          }) => (
            <div style={{ padding: 8, display: 'flex', alignItems: 'center' }}>
              <Select
                showSearch
                allowClear
                value={selectedKeys}
                loading={filterLoading}
                optionFilterProp='children'
                style={{ width: 280, display: 'block' }}
                onSelect={(e) => setSelectedKeys([e])}
                onClear={() => {
                  setSelectedKeys([''])
                  setLocalFilter(null)
                  setLoad(!load)
                }}
              >
                {locals.map((client) => {
                  return (
                    <Select.OptGroup
                      key={client.id}
                      label={client.nomeFantasia}
                    >
                      {client.locais.map((local) => {
                        return (
                          <Select.Option key={local.id} value={local.id}>
                            {local.name}
                          </Select.Option>
                        )
                      })}
                    </Select.OptGroup>
                  )
                })}
              </Select>
              <Button
                type='primary'
                icon={<SearchOutlined />}
                style={{ marginLeft: 6, marginRight: 6 }}
                onClick={() => {
                  setMeta({ current_page: 1, per_page: 10 })
                  setLocalFilter(selectedKeys[0])
                  setLoad(!load)
                }}
              />
              <Button
                icon={<ClearOutlined />}
                onClick={() => {
                  if (clearFilters) {
                    setSelectedKeys([''])
                    setLocalFilter(null)
                    setLoad(!load)
                  }
                }}
              />
            </div>
          )}
        />
        <Column
          title='Status'
          dataIndex='status'
          width={150}
          key='status'
          render={(_, record) => (
            <Tag color={colors[record.status].color}>
              {colors[record.status].name}
            </Tag>
          )}
          filterIcon={(filtered) => (
            <SearchOutlined
              style={{ color: filtered ? '#1890ff' : '#777', fontSize: 15 }}
            />
          )}
          onFilter={(value, record) =>
            record.numRef
              ? record.numRef
                  .toString()
                  .toLowerCase()
                  .includes(value.toString().toLowerCase())
              : false
          }
          filterDropdown={({
            selectedKeys = [],
            setSelectedKeys,
            confirm,
            clearFilters,
          }) => (
            <div style={{ padding: 8, display: 'flex', alignItems: 'center' }}>
              <Select
                allowClear
                mode='multiple'
                ref={statusRef}
                style={{ width: 188, display: 'block' }}
                onChange={(e) => {
                  const temp = [...e]
                  setSelectedKeys(temp)
                }}
                value={selectedKeys}
              >
                {statusFilter.map((option, index) => {
                  return (
                    <Select.Option key={index} value={option.value}>
                      {option.label}
                    </Select.Option>
                  )
                })}
              </Select>
              <Button
                type='primary'
                icon={<SearchOutlined />}
                style={{ marginLeft: 6, marginRight: 6 }}
                onClick={() => {
                  setMeta({ current_page: 1, per_page: 10 })
                  setStatusFilter(selectedKeys.toString())
                  setLoad(!load)
                }}
              />
              <Button
                icon={<ClearOutlined />}
                onClick={() => {
                  if (clearFilters) {
                    setSelectedKeys([])
                    setStatusFilter(null)
                    setLoad(!load)
                  }
                }}
              />
            </div>
          )}
        />
        <Column
          title='Técnico'
          width={170}
          dataIndex='tecnico'
          key='tecnico'
          filterIcon={(filtered) => (
            <SearchOutlined
              style={{ color: filtered ? '#1890ff' : '#777', fontSize: 15 }}
              onClick={() => {
                if (tecnicos.length < 1) {
                  loadTecnicos()
                }
              }}
            />
          )}
          render={(_, record) =>
            record?.tecnico ? record.tecnico.name : 'Não definido'
          }
          onFilter={(value, record) =>
            record.tecnicoIdFilter
              ? record.tecnicoIdFilter
                  .toString()
                  .toLowerCase()
                  .includes(value.toString().toLowerCase())
              : false
          }
          filterDropdown={({
            selectedKeys,
            setSelectedKeys,
            confirm,
            clearFilters,
          }) => (
            <div style={{ padding: 8, display: 'flex', alignItems: 'center' }}>
              <Select
                loading={filterLoading}
                style={{ width: 188, display: 'block' }}
                onSelect={(e) => setSelectedKeys([e])}
              >
                {tecnicos.map((tecnico, index) => {
                  return (
                    <Select.Option key={index} value={tecnico.id}>
                      {tecnico.name}
                    </Select.Option>
                  )
                })}
              </Select>
              <Button
                type='primary'
                icon={<SearchOutlined />}
                style={{ marginLeft: 6, marginRight: 6 }}
                onClick={() => {
                  setMeta({ current_page: 1, per_page: 10 })
                  setTecnicoIdFilter(selectedKeys[0])
                  setLoad(!load)
                }}
              />
              <Button
                icon={<ClearOutlined />}
                onClick={() => {
                  if (clearFilters) {
                    setTecnicoIdFilter(null)
                    setLoad(!load)
                  }
                }}
              />
            </div>
          )}
        />
        <Column
          title='Aberto em'
          dataIndex='createdAt'
          width={170}
          key='createdAt'
          render={(_, record) =>
            format(parseISO(record.createdAt), 'dd/MM/yyyy HH:mm', {
              locale: ptBR,
            })
          }
        />
        <Column
          title='Ações'
          dataIndex='actions'
          key='actions'
          width={100}
          fixed='right'
          render={(_, record) => (
            <Dropdown
              overlay={acoes(record)}
              placement='bottomRight'
              trigger={['click']}
              arrow
            >
              <Button icon={<MoreOutlined />} />
            </Dropdown>
          )}
        />
      </Table>
      <Modal
        title={`Ordem de serviço - ${ordem?.id}`}
        visible={isOpen}
        onCancel={() => setIsOpen(false)}
        onOk={() => setIsOpen(false)}
        footer={null}
      >
        <>
          <Row>
            <Col span={8}>
              <Text strong>ID</Text>
              <Paragraph>
                <Tag>{ordem?.id}</Tag>
              </Paragraph>
            </Col>
          </Row>
          <Row justify='space-between'>
            <Col span={8}>
              <Text strong>Tipo da O.S</Text>
              <Paragraph>{ordem?.atendimento?.toUpperCase()}</Paragraph>
            </Col>
            <Col span={8}>
              <Text strong>Status</Text>
              <Paragraph>
                {ordem?.status && (
                  <Tag color={colors[ordem?.status].color}>
                    {colors[ordem?.status].name}
                  </Tag>
                )}
              </Paragraph>
            </Col>
          </Row>
          <Divider />
          {ordem?.instructions?.serviceType && (
            <>
              <Paragraph>
                <Text strong>Solicitação do cliente:</Text>
              </Paragraph>
              <Paragraph>
                <Text strong>Tipo de serviço: </Text>
                {ordem?.instructions.serviceType.toString()}
              </Paragraph>
              <Paragraph>
                <Text strong>Tipo de Equipamento: </Text>
                {ordem?.instructions.equipmentType.toString()}
              </Paragraph>
              <Paragraph>
                <Text strong>Endereço: </Text>
                {ordem?.instructions.address}
              </Paragraph>
              <Paragraph>
                <Text strong>Quantidade: </Text>
                {ordem?.instructions.quantity}
              </Paragraph>
              {ordem?.instructions?.file && (
                <Paragraph>
                  <Text strong>Anexo: </Text>
                  <Button
                    type='dashed'
                    color='blue'
                    href={ordem?.instructions?.file}
                    target='_blank'
                  >
                    Baixar
                  </Button>
                </Paragraph>
              )}
              <Divider />
            </>
          )}
          {ordem?.description && (
            <>
              <Text strong>Descrição</Text>
              <Paragraph>{ordem?.description}</Paragraph>
            </>
          )}
          {ordem?.previsaoAtendimento && (
            <>
              <Text strong>Previsão de Atendimento</Text>
              <Paragraph>
                {format(parseISO(ordem?.previsaoAtendimento), 'dd/MM/yyyy', {
                  locale: ptBR,
                })}
              </Paragraph>
            </>
          )}
          {ordem?.cliente && (
            <>
              <Text strong>Cliente</Text>
              <Paragraph>{ordem?.cliente?.nomeFantasia}</Paragraph>
            </>
          )}
          {ordem?.local && (
            <>
              <Text strong>Local</Text>
              <Paragraph>{ordem?.local?.address}</Paragraph>
            </>
          )}
          {ordem?.tecnico && (
            <>
              <Divider />
              <Text strong>Tecnico</Text>
              <Paragraph>{ordem?.tecnico?.name}</Paragraph>
            </>
          )}
          {ordem?.tipo && (
            <>
              <Text strong>Tipo de Serviço</Text>
              <Paragraph>{ordem?.tipo?.name}</Paragraph>
            </>
          )}
          {ordem?.tipoEquipamento && (
            <>
              <Text strong>Tipo de Equipamento</Text>
              <Paragraph>{ordem?.tipoEquipamento?.name}</Paragraph>
            </>
          )}
        </>
      </Modal>
      <DrawerOs
        setVisible={setDrawer}
        refreshData={handleRefreshData}
        config={drawer}
      />
    </Container>
  )
}
