import React, { useEffect, useState } from 'react'
import {
  message,
  Table,
  Space,
  Button,
  Drawer,
  Popconfirm,
  Tooltip,
  Form,
  Input,
  Spin,
  Empty,
  Divider,
} from 'antd'
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { useParams, useHistory, useLocation, Link } from 'react-router-dom'
import { Enviar, Cancelar } from '../../components/Buttons'
import { Container, PageTitle } from '../../components'
import api from '../../services/api'

const { Column } = Table

function Unidades() {
  const location = useLocation()
  const cliente = location.state

  const { id, setorId, localId } = useParams()
  const history = useHistory()
  const [form] = Form.useForm()
  const [setores, setUnidades] = useState([])
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [mode, setMode] = useState('insert')
  const [load, setLoad] = useState(false)
  const [setor, setSetor] = useState(null)

  useEffect(() => {
    async function loadUnidades() {
      setLoading(true)
      try {
        await api.get(`/unidade?setorId=${setorId}`).then(async ({ data }) => {
          setUnidades(data)
          setLoading(false)
        })
      } catch (error) {
        setLoading(false)
        setLoad(false)
        message.error('Erro ao carregar os dados, atualize a pagina')
      }
    }
    loadUnidades()
  }, [load])

  const openDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    form.resetFields()
    setMode('insert')
    setVisible(false)
  }

  const routes = [
    {
      path: ``,
      breadcrumbName: 'Cadastros',
    },
    {
      path: `/clientes`,
      breadcrumbName: 'Clientes',
    },
    {
      path: `/clientes/${id}/locais`,
      breadcrumbName: 'Locais do Cliente',
    },
    {
      path: `/clientes/${id}/locais/${cliente.local?.id}/setores/`,
      breadcrumbName: 'Unidades',
    },
  ]

  function itemRender(route) {
    return (
      <Link
        to={{ pathname: route.path, state: cliente }}
        key={route.breadcrumbName}
      >
        {route.breadcrumbName}
      </Link>
    )
  }

  return (
    <Container>
      <PageTitle
        breadcrumb={{ routes, itemRender }}
        itemRender={routes}
        onBack={() =>
          history.push({
            pathname: `/clientes/${id}/locais/${localId}/setores`,
            state: {
              client: cliente.client,
              local: cliente.local,
            },
          })
        }
        title='Setores'
        extra={[
          <Button
            type='primary'
            size='large'
            icon={<PlusOutlined />}
            onClick={() => {
              setMode('insert')
              openDrawer()
            }}
            key='1'
          >
            Adicionar Setor
          </Button>,
        ]}
      />
      <Table
        dataSource={setores}
        loading={loading}
        rowKey={(record) => record.id}
        size='middle'
        style={{ margin: 20 }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <>
                  <span>Não há registro aqui</span>
                </>
              }
            >
              <Button
                icon={<PlusOutlined />}
                key='insert'
                type='primary'
                size='large'
                onClick={() => {
                  setMode('insert')
                  openDrawer()
                }}
              >
                Adicionar
              </Button>
            </Empty>
          ),
        }}
      >
        <Column title='ID' dataIndex='id' key='id' />
        <Column title='Setores' dataIndex='name' key='name' />
        <Column
          dataIndex='actions'
          key='actions'
          render={(_, record) => (
            <Space>
              <Tooltip title='Editar' placement='top'>
                <Button
                  icon={<EditOutlined />}
                  onClick={() => {
                    setSetor(record)
                    form.setFieldsValue(record)
                    setMode('update')
                    openDrawer()
                  }}
                />
              </Tooltip>
              <Tooltip title='Excluir Setor' placement='top'>
                <Popconfirm
                  title='Deseja realmente excluir este item?'
                  okText='Sim'
                  cancelText='Não'
                  onConfirm={async () => {
                    await api
                      .delete(`/unidade/${record.id}`)
                      .then(() => {
                        message.success('Item excluido com sucesso')
                        setLoad(true)
                      })
                      .catch((e) => {
                        message.error(e.response.data.error)
                      })
                  }}
                >
                  <Button
                    icon={<DeleteOutlined />}
                    style={{ color: '#e80909' }}
                  />
                </Popconfirm>
              </Tooltip>
            </Space>
          )}
        />
      </Table>
      <Drawer
        visible={visible}
        onClose={onClose}
        width={550}
        title={`${mode === 'insert' ? 'Adicionar' : 'Atualizar'} Setor`}
      >
        <Spin spinning={loading}>
          <Form
            form={form}
            layout='vertical'
            onFinish={(value) => {
              setLoading(true)
              const data = { ...value, setorId }
              if (mode === 'insert') {
                api
                  .post('/unidade', data)
                  .then(() => {
                    setLoading(false)
                    message.success('Cadastro realizado com sucesso!')
                    onClose()
                    setLoad(!load)
                  })
                  .catch((e) => {
                    setLoading(false)
                    if (e.response.data.errors) {
                      e.response.data.errors.forEach((info) => {
                        message.error(info.message)
                      })
                    } else {
                      message.error(e.response.data.error)
                    }
                  })
              } else {
                api
                  .put(`/unidade/${setor.id}`, data)
                  .then(() => {
                    setLoading(false)
                    message.success('Cadastro realizado com sucesso!')
                    onClose()
                    setLoad(!load)
                  })
                  .catch((e) => {
                    setLoading(false)
                    if (e.response.data.errors) {
                      e.response.data.errors.forEach((info) => {
                        message.error(info.message)
                      })
                    } else {
                      message.error(e.response.data.error)
                    }
                  })
              }
            }}
          >
            {cliente?.client?.nomeFantasia ? (
              <>
                <Form.Item label='Cliente'>
                  <Input
                    size='large'
                    disabled
                    defaultValue={cliente.client.nomeFantasia}
                  />
                </Form.Item>
                <Form.Item label='Local'>
                  <Input
                    size='large'
                    disabled
                    defaultValue={cliente.local.name}
                  />
                </Form.Item>
                <Form.Item label='Setor'>
                  <Input
                    size='large'
                    disabled
                    defaultValue={cliente.setor.name}
                  />
                </Form.Item>
              </>
            ) : null}
            <Form.Item
              label='Setor'
              name='name'
              rules={[
                {
                  required: true,
                  message: 'Por favor, o setor deve ser informado',
                },
              ]}
            >
              <Input size='large' />
            </Form.Item>
            <Divider style={{ borderColor: '#aaa' }} />
            <div style={{ display: 'flex' }}>
              <Enviar
                disabled={mode === 'view'}
                type='primary'
                htmlType='submit'
                style={{ flex: 1 }}
                size='large'
              >
                {mode === 'insert' ? 'Adicionar' : 'Atualizar'}
              </Enviar>
              <Cancelar
                danger
                onClick={onClose}
                style={{ flex: 1 }}
                size='large'
              >
                Cancelar
              </Cancelar>
            </div>
          </Form>
        </Spin>
      </Drawer>
    </Container>
  )
}

export default Unidades
