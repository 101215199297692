import React from 'react'
import { message, Drawer, Form, Input, Spin, Divider } from 'antd'
import api from '../../../services/api'
import { Cancelar, Enviar } from '../../../components/Buttons'

export function DrawerTipoServico({ isVisible, onClose, loading, setLoading }) {
  const [form] = Form.useForm()

  return (
    <Drawer
      visible={isVisible}
      onClose={() => {
        form.resetFields()
        onClose()
      }}
      width={550}
      title='Cadastrar Tipo de Serviço'
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout='vertical'
          onFinish={(value) => {
            setLoading(true)
            const data = {
              name: value.name.toUpperCase(),
            }
            api
              .post('/servicetype', data)
              .then(() => {
                setLoading(false)
                message.success('Cadastro realizado com sucesso!')
                form.resetFields()
                onClose()
              })
              .catch((e) => {
                setLoading(false)
                if (e.response.data.errors) {
                  e.response.data.errors.forEach((info) => {
                    message.error(info.message)
                  })
                } else {
                  message.error(e.response.data.error)
                }
              })
          }}
        >
          <Form.Item
            label='Tipo de serviço'
            name='name'
            rules={[
              {
                required: true,
                message:
                  'Por favor, o nome do tipo de serviço deve ser informado.',
              },
            ]}
          >
            <Input size='large' />
          </Form.Item>
          <Divider style={{ borderColor: '#bbb' }} />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Enviar
              type='primary'
              htmlType='submit'
              size='large'
              style={{ flex: 1 }}
            >
              Cadastrar
            </Enviar>
            <Cancelar
              size='large'
              style={{ flex: 1 }}
              danger
              onClick={() => {
                form.resetFields()
                onClose()
              }}
            >
              Cancelar
            </Cancelar>
          </div>
        </Form>
      </Spin>
    </Drawer>
  )
}
