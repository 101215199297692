import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Drawer,
  Input,
  message,
  Spin,
  Typography,
  Form,
  Space,
  Divider,
  Select,
} from 'antd'
import Modal from 'antd/lib/modal/Modal'
import { Cancelar, Enviar } from 'components/Buttons'
import DrawerContext from 'contexts/DrawerContext'
import React, { useContext, useEffect, useState } from 'react'
import api from 'services/api'
import { MaskCEP, maskCpfCnpj, MaskPhone } from 'services/utils'

const { Option } = Select
const { Text, Paragraph } = Typography

function DrawerCliente({ onClose, setLoading, loading, data }) {
  const { load, setLoad } = useContext(DrawerContext)

  const states = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
  ]
  const [modalConfirm, setModalConfirm] = useState({
    visible: false,
    data: {},
    newClient: {},
  })
  const [form] = Form.useForm()
  const [formEndereco] = Form.useForm()
  const client = data.data
  const [clientType, setClientType] = useState('juridica')

  useEffect(() => {
    async function loadClients() {
      console.log(client)
      try {
        setLoading(true)
        if (data.mode === 'update') {
          form.setFieldsValue({
            ...client,
            cpfCnpj: maskCpfCnpj(client.cpfCnpj),
            cep: MaskCEP(client.cep),
          })
        }
        setLoading(false)
      } catch (error) {
        console.log(error)
        message.error('Erro ao carregar os dados, atualize a pagina')
      }
    }
    loadClients()
  }, [load])

  function onBlurCep(ev) {
    const { value } = ev.target

    const cep = value?.replace(/[^0-9]/g, '')

    if (cep?.length !== 8) {
      return
    }

    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then((data) => {
        console.log(data)
        formEndereco.setFieldsValue({
          estado: data.uf,
          cidade: data.localidade,
          bairro: data.bairro,
          rua: data.logradouro,
        })
      })
  }

  const clientUpperCase = (valueInput) => {
    if (clientType === 'juridica') {
      return {
        ...valueInput,
        nomeFantasia: valueInput.nomeFantasia.toUpperCase(),
        razaoSocial: valueInput.razaoSocial.toUpperCase(),
        ie: valueInput.ie.toUpperCase() === 'ISENTO' ? 'ISENTO' : valueInput.ie,
        im: valueInput.ie.toUpperCase() === 'ISENTO' ? 'ISENTO' : valueInput.im,
      }
    } else {
      return {
        ...valueInput,
        nomeFantasia: valueInput.nomeFantasia.toUpperCase(),
        razaoSocial: '',
        ie: '',
        im: '',
      }
    }
  }

  const createClient = (valueInput) => {
    const value = clientUpperCase(valueInput)

    if (data.mode === 'insert') {
      api
        .post('/verifycnpj', value)
        .then((response) => {
          if (response.data[1] === false) {
            storeClient(value)
          } else {
            setModalConfirm({
              visible: true,
              data: response.data[0],
              newClient: value,
            })
          }
        })
        .catch((e) => {
          if (e.response.data.errors) {
            e.response.data.errors.forEach((info) => {
              message.error(info.message)
            })
          } else {
            message.error(e.response.data?.error)
          }
          setLoading(false)
        })
    } else {
      setLoading(true)
      api
        .put(`/client/${client.id}`, value)
        .then(() => {
          setLoading(false)
          message.success('Cliente atualizado com sucesso!')
          onClose()
        })
        .catch((e) => {
          if (e.response.data.errors) {
            e.response.data.errors.forEach((info) => {
              message.error(info.message)
            })
          } else {
            message.error(e.response.data.error)
          }
          setLoading(false)
        })
    }
  }

  const storeClient = async (value) => {
    const formatedValue = {
      clientType: value.clientType,
      nomeFantasia: value.nomeFantasia,
      cpfCnpj: value.cpfCnpj,
      razaoSocial: value.razaoSocial,
      ie: value.ie,
      im: value.im,
      phones: value.phones,
      emails: value.emails,
    }
    const address = {
      cep: value.cep,
      estado: value.estado,
      cidade: value.cidade,
      bairro: value.bairro,
      rua: value.rua,
      numero: value.numero,
      complemento: value.complemento,
    }

    setLoading(true)
    await api
      .post(`/client`, formatedValue)
      .then(() => {
        onClose()
        setLoading(false)
        message.success('Cliente cadastratdo com sucesso!')
      })
      .catch((e) => {
        console.log(e)
        if (e.response.data.errors) {
          e.response.data.errors.forEach((info) => {
            message.error(info.message)
          })
        } else {
          message.error(e.response.data.error)
        }
        setLoading(false)
      })

    setModalConfirm({
      visible: false,
      data: {},
      newClient: {},
    })

    api
      .post(`/client/clientaddress`, address)
      .then(() => {
        setLoading(false)
        setLoad(!load)
        message.success('Endereço atualizado com sucesso!')
      })
      .catch((e) => {
        if (e.response.data.errors) {
          e.response.data.errors.forEach((info) => {
            message.error(info.message)
          })
        } else {
          message.error(e.response.data.error)
        }
        setLoading(false)
      })
  }

  return (
    <>
      <Drawer
        visible={data.visible === true}
        onClose={onClose}
        width={550}
        title={`${data.mode === 'update' ? 'Atualizar' : 'Cadastrar'} Cliente`}
      >
        <Spin spinning={loading}>
          <Form
            form={form}
            layout='vertical'
            onFinish={(value) => {
              createClient(value)
            }}
          >
            <Form.Item
              label='Tipo de cliente'
              name='clientType'
              rules={[
                {
                  required: true,
                  message: 'Por favor, o Tipo de cliente deve ser informado',
                },
              ]}
            >
              <Select
                placeholder='Selecione'
                size='large'
                onChange={(e) => {
                  setClientType(e)
                }}
              >
                <Option value='juridica'>Pessoa Jurídica</Option>
                <Option value='fisica'>Pessoa Física</Option>
              </Select>
            </Form.Item>
            <Divider style={{ borderColor: '#bbb' }} />
            <Form.Item
              label={
                clientType === 'juridica' ? 'Nome fantasia' : 'Nome completo'
              }
              name='nomeFantasia'
              rules={[
                {
                  required: true,
                  message: 'Por favor, o Nome Fantasia deve ser informado',
                },
              ]}
            >
              <Input size='large' />
            </Form.Item>
            <Form.Item
              label={clientType === 'juridica' ? 'CNPJ' : 'CPF'}
              name='cpfCnpj'
              normalize={(value) => maskCpfCnpj(value)}
              rules={[
                {
                  required: true,
                  message: 'Por favor, o CPF/CNPJ deve ser informado',
                },
              ]}
            >
              <Input size='large' />
            </Form.Item>

            {clientType === 'juridica' ? (
              <>
                <Form.Item
                  style={{
                    flex: 1,
                    direction: 'row',
                  }}
                  label='Razão social'
                  name='razaoSocial'
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, a Razão Social deve ser informado',
                    },
                  ]}
                >
                  <Input size='large' />
                </Form.Item>
                <Form.Item>
                  <Form.Item
                    label='Inscrição estadual'
                    name='ie'
                    style={{ width: '100%' }}
                    rules={[
                      {
                        required: true,
                        message:
                          'Por favor, a Inscrição Estadual deve ser informado',
                      },
                    ]}
                  >
                    <Input size='large' />
                  </Form.Item>
                  <Form.Item
                    label='Inscrição municipal'
                    name='im'
                    rules={[
                      {
                        required: true,
                        message:
                          'Por favor, a Inscrição Municipal deve ser informado',
                      },
                    ]}
                  >
                    <Input size='large' />
                  </Form.Item>
                  <Form.Item
                    label='CEP'
                    name='cep'
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, o CEP deve ser informado',
                      },
                    ]}
                  >
                    <Input onBlur={onBlurCep} size='large' />
                  </Form.Item>
                  <Form.Item
                    label='Estado'
                    name='estado'
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, o Estado deve ser informado',
                      },
                    ]}
                  >
                    <Select size='large' placeholder='Selecione o Estado'>
                      {states.map((state) => (
                        <Option key={state}>{state}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label='Cidade'
                    name='cidade'
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, a Cidade deve ser informada',
                      },
                    ]}
                  >
                    <Input size='large' />
                  </Form.Item>
                  <Form.Item
                    label='Bairro'
                    name='bairro'
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, o Bairro deve ser informado',
                      },
                    ]}
                  >
                    <Input size='large' />
                  </Form.Item>
                  <Form.Item
                    label='Rua'
                    name='rua'
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, a Rua deve ser informada',
                      },
                    ]}
                  >
                    <Input size='large' />
                  </Form.Item>
                  <Form.Item
                    label='Numero'
                    name='numero'
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, o Numero deve ser informado',
                      },
                    ]}
                  >
                    <Input size='large' />
                  </Form.Item>
                  <Form.Item label='Complemento' name='complemento'>
                    <Input size='large' />
                  </Form.Item>
                  <Divider style={{ borderColor: '#bbb' }} />
                  {/* <Form.Item
                    style={{
                      justifyContent: 'center',
                    }}
                  >
                    <Enviar
                      disabled={data.mode === 'view'}
                      type='primary'
                      htmlType='submit'
                      size='large'
                      style={{ width: 'calc(50% - 20px)' }}
                    >
                      {data.mode === 'update' ? 'Atualizar' : 'Cadastrar'}
                    </Enviar>
                    <Cancelar
                      danger
                      onClick={onClose}
                      size='large'
                      style={{ width: 'calc(50% - 20px)' }}
                    >
                      Cancelar
                    </Cancelar>
                  </Form.Item> */}
                </Form.Item>
              </>
            ) : null}
            <div
              style={{
                display: 'flex',
                gap: '25px',
              }}
            >
              <div style={{ width: '50%' }}>
                <Form.List name='phones'>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{ display: 'flex' }}
                          align='baseline'
                        >
                          <Form.Item
                            label='Telefone'
                            {...restField}
                            name={[name, 'phone']}
                            rules={[
                              {
                                required: true,
                                message: 'Insira um telefone.',
                              },
                            ]}
                            normalize={(value) => MaskPhone(value)}
                          >
                            <Input
                              size='large'
                              addonAfter={
                                <MinusCircleOutlined
                                  onClick={() => remove(name)}
                                />
                              }
                            />
                          </Form.Item>
                        </Space>
                      ))}
                      <Form.Item>
                        <Button
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Adicionar Telefone
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
                {/* <Form.List name='phones'>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field) => (
                        <Form.Item
                          key={field.key}
                          {...field}
                          label='Telefone'
                          name={[field.name, 'phone']}
                          rules={[
                            {
                              required: true,
                              message: 'Insira um telefone.',
                            },
                          ]}
                          normalize={(value) => MaskPhone(value)}
                        >
                          <Input
                            size='large'
                            addonAfter={
                              <MinusCircleOutlined
                                onClick={() => remove(field.name)}
                              />
                            }
                          />
                        </Form.Item>
                      ))}

                      <Form.Item>
                        <Button
                          type='dashed'
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Adicionar Telefone
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List> */}
              </div>
              <div style={{ width: '50%' }}>
                <Form.List name='emails'>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field) => (
                        <Space key={field.key} align='baseline'>
                          <Form.Item
                            {...field}
                            label='Email'
                            name={[field.name, 'email']}
                            rules={[
                              { required: true, message: 'Insira um email.' },
                            ]}
                          >
                            <Input
                              size='large'
                              addonAfter={
                                <MinusCircleOutlined
                                  onClick={() => remove(field.name)}
                                />
                              }
                            />
                          </Form.Item>
                        </Space>
                      ))}
                      <Form.Item>
                        <Button
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Adicionar Email
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </div>
            </div>
            <Divider style={{ borderColor: '#bbb' }} />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Enviar
                disabled={data.mode === 'view'}
                type='primary'
                htmlType='submit'
                size='large'
                style={{ flex: 1 }}
              >
                {data.mode === 'insert' ? 'Cadastrar' : 'Atualizar'}
              </Enviar>
              <Cancelar
                danger
                onClick={onClose}
                size='large'
                style={{ flex: 1 }}
              >
                Cancelar
              </Cancelar>
            </div>
          </Form>
        </Spin>
      </Drawer>
      {modalConfirm.visible === true && (
        <Modal
          key='modal'
          visible={modalConfirm.visible === true}
          title='Este CNPJ já está cadastrado, deseja continuar?'
          onCancel={() =>
            setModalConfirm({
              visible: false,
              data: {},
              newClient: {},
            })
          }
          footer={[
            <Button
              key='back'
              onClick={() => {
                setModalConfirm({ visible: false })
                setLoad(!load)
              }}
            >
              Cancelar
            </Button>,
            <Button
              key='submit'
              type='primary'
              onClick={() => {
                storeClient(modalConfirm.newClient)
              }}
            >
              Continuar
            </Button>,
          ]}
        >
          <>
            <Text strong>Nome Fantasia</Text>
            <Paragraph>{modalConfirm.data?.nomeFantasia}</Paragraph>
            <Text strong>Razão Social</Text>
            <Paragraph>{modalConfirm.data?.razaoSocial}</Paragraph>
            <Text strong>CPF/CNPJ</Text>
            <Paragraph>{modalConfirm.data?.cpfCnpj}</Paragraph>
          </>
        </Modal>
      )}
    </>
  )
}

export default DrawerCliente
