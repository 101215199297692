import React, { useState, useEffect, useRef } from 'react'
import { useParams, withRouter, Link } from 'react-router-dom'
import {
  Button,
  Table,
  message,
  Drawer,
  Popconfirm,
  Space,
  Modal,
  Typography,
  Avatar,
  Tooltip,
  Form,
  Select,
  Input,
  Spin,
} from 'antd'
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  PlusOutlined,
  SearchOutlined,
  ClearOutlined,
} from '@ant-design/icons'
import { Container, PageTitle } from '../../components'
import { Enviar, Cancelar } from '../../components/Buttons'
import { Helmet } from 'react-helmet'
import Highlighter from 'react-highlight-words'
import api from '../../services/api'

const { Paragraph, Text } = Typography
const { Column } = Table

function Assistentes() {
  const { id } = useParams()
  const [form] = Form.useForm()
  const [technician, setTechnician] = useState({})
  const [loading, setLoading] = useState(false)
  const [load, setLoad] = useState(false)
  const [open, setOpen] = useState(false)
  const [visible, setVisible] = useState(false)
  const [users, setUsers] = useState([])
  const [user, setUser] = useState(null)
  const [mode, setMode] = useState('insert')
  const [roles, setRoles] = useState([])

  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  useEffect(() => {
    async function loadUsers() {
      try {
        setLoading(true)
        await api.get(`/user/${id}`).then(({ data }) => {
          setTechnician(data)
          setUsers(data.users)
        })
      } catch (error) {
        message.error('Erro ao carregar os dados, atualize a pagina')
      } finally {
        setLoading(false)
      }
    }
    loadUsers()
  }, [load])

  useEffect(() => {
    async function loadRoles() {
      await api
        .get('/roles')
        .then(({ data }) => {
          setRoles(data)
        })
        .finally(() => setLoading(false))
    }
    loadRoles()
  }, [])

  const openDrawer = (data, mode) => {
    setVisible(true)
    if (mode === 'update') {
      form.setFieldsValue({
        name: data.name,
        email: data.email,
        accessProfile: data.accessProfile,
        roles: data.roles?.map((role) => {
          return role.id
        }),
        permissions: data.permissions.map((permission) => {
          return permission.id
        }),
      })
    }
  }

  const onClose = () => {
    form.resetFields()
    setMode('insert')
    setVisible(false)
  }

  const onSubmit = async (value) => {
    setLoading(true)
    const data = {
      ...value,
      name: value.name.toUpperCase(),
    }
    if (mode === 'insert') {
      await api
        .post(`/user/assistant/${id}`, data)
        .then(() => {
          message.success('Cadastro realizado com sucesso!')
          onClose()
        })
        .catch((e) => {
          if (e.response.data.errors) {
            e.response.data.errors.forEach((info) => {
              message.error(info.message)
            })
          } else {
            message.error(e.response.data.error)
          }
        })
        .finally(() => {
          setLoad(!load)
          setLoading(false)
        })
    } else {
      await api
        .put(`/user/assistant/${user.id}`, data)
        .then(() => {
          message.success('Assistente atualizado com sucesso!')
          onClose()
        })
        .catch((e) => {
          if (e.response.data.errors) {
            e.response.data.errors.forEach((info) => {
              message.error(info.message)
            })
          } else {
            message.error(e.response.data.error)
          }
        })
        .finally(() => {
          setLoad(!load)
          setLoading(false)
        })
    }
  }

  const routes = [
    {
      path: '',
      breadcrumbName: 'Cadastros',
    },
    {
      path: '/usuarios',
      breadcrumbName: 'Usúarios',
    },
  ]

  function itemRender(route) {
    return (
      <Link to={{ pathname: route.path }} key={route.breadcrumbName}>
        {route.breadcrumbName}
      </Link>
    )
  }

  return (
    <Container>
      <Helmet>
        <title>Arcke | {technician?.name ? technician?.name : ''}</title>
      </Helmet>
      <PageTitle
        title='Assistentes'
        breadcrumb={{ routes, itemRender }}
        breadcrumbRender={(props, breadcrumb) => (
          <div style={{ marginBottom: -4 }}>{breadcrumb}</div>
        )}
        extra={[
          <Button
            type='primary'
            size='large'
            icon={<PlusOutlined />}
            key='1'
            onClick={() => {
              openDrawer('', 'insert')
            }}
          >
            Adicionar Assistente
          </Button>,
        ]}
      />
      <Table
        rowKey={(record) => record.id}
        dataSource={users}
        loading={loading}
        size='small'
        style={{ margin: 20 }}
      >
        <Column title='ID' dataIndex='id' key='id' />
        <Column title='Nome' dataIndex='name' key='name' />
        <Column
          title='Email'
          dataIndex='email'
          key='email'
          filterDropdown={({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
            <div style={{ padding: 8, display: 'flex', alignItems: 'center' }}>
              <Input
                ref={searchInput}
                placeholder={`Filtrar Nome`}
                value={selectedKeys[0]}
                onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() =>
                  handleSearch(selectedKeys, confirm, 'email')
                }
                style={{ display: 'block' }}
              />

              <Button
                type='primary'
                style={{ marginLeft: 6, marginRight: 6, minWidth: 32 }}
                onClick={() => handleSearch(selectedKeys, confirm, 'email')}
                icon={<SearchOutlined />}
              />
              <Button
                icon={<ClearOutlined />}
                style={{ minWidth: 32 }}
                onClick={() => clearFilters && handleReset(clearFilters)}
              />
            </div>
          )}
          filterIcon={(filtered) => (
            <SearchOutlined
              style={{ color: filtered ? '#1890ff' : '#777', fontSize: 15 }}
            />
          )}
          onFilter={(value, record) =>
            record.email.toString().toLowerCase().includes(value.toLowerCase())
          }
          onFilterDropdownOpenChange={(visible) => {
            if (visible) {
              setTimeout(() => searchInput.current?.select(), 100)
            }
          }}
          render={(_, record) =>
            searchedColumn === 'email' ? (
              <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={record.email ? record.email.toString() : ''}
              />
            ) : (
              record.email
            )
          }
        />
        <Column
          title='Função'
          dataIndex='accessProfile'
          key='accessProfile'
          render={() => 'ASSISTENTE'}
        />
        <Column
          key='actions'
          title='Ações'
          width={20}
          render={(record) => (
            <Space>
              <Tooltip placement='bottom' title='Visualizar'>
                <Button
                  icon={<EyeOutlined />}
                  onClick={() => {
                    setUser(record)
                    setOpen(true)
                  }}
                />
              </Tooltip>
              <Tooltip placement='bottom' title='Editar'>
                <Button
                  icon={<EditOutlined />}
                  onClick={() => {
                    setMode('update')
                    setUser(record)
                    openDrawer(record, 'update')
                  }}
                />
              </Tooltip>
              <Tooltip title='Apagar Assistente' placement='bottomLeft'>
                <Popconfirm
                  title='Deseja realmente excluir este Assistente?'
                  okText='Sim'
                  cancelText='Não'
                  placement='topLeft'
                  onConfirm={async () => {
                    await api
                      .delete(`/user/${record.id}`)
                      .then(() => {
                        message.success('Assistente excluido com sucesso')
                      })
                      .catch(() => {
                        message.error(
                          'Erro ao excluir Assistente, tente novamente'
                        )
                      })
                      .finally(() => {
                        setLoad(!load)
                      })
                  }}
                >
                  <Button
                    icon={<DeleteOutlined />}
                    style={{ color: '#e80909' }}
                  />
                </Popconfirm>
              </Tooltip>
            </Space>
          )}
        />
      </Table>
      <Drawer
        visible={visible}
        onClose={onClose}
        width={550}
        title={`${mode === 'insert' ? 'Adicionar' : 'Atualizar'} Assistente`}
      >
        <Spin spinning={loading}>
          <Form
            form={form}
            layout='vertical'
            initialValues={{
              accessProfile: 'assistente',
            }}
            onFinish={onSubmit}
          >
            <Form.Item
              label='Nome'
              name='name'
              rules={[
                {
                  required: true,
                  message: 'Por favor, o nome deve ser informado',
                },
              ]}
            >
              <Input size='large' />
            </Form.Item>
            <Form.Item
              label='Email'
              name='email'
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: 'Por favor, o email deve ser informado',
                },
              ]}
            >
              <Input size='large' />
            </Form.Item>
            <Form.Item
              label='Senha'
              name='password'
              rules={[
                {
                  required: mode === 'insert',
                  message: 'Por favor, a senha deve ser informada',
                },
              ]}
            >
              <Input type='password' size='large' />
            </Form.Item>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ width: 'calc(50% - 14px)' }}>
                <Form.Item
                  label='Cargo'
                  initialValues={'assistente'}
                  name='accessProfile'
                >
                  <Select
                    placeholder='Selecione...'
                    size='large'
                    style={{ width: '100%' }}
                    disabled
                  >
                    <Select.Option value='administrador'>
                      Administrador
                    </Select.Option>
                    <Select.Option value='tecnico'>Técnico</Select.Option>
                    <Select.Option value='atendente'>Atendente</Select.Option>
                    <Select.Option value='assistente'>Assistente</Select.Option>
                  </Select>
                </Form.Item>
              </div>
              <div style={{ width: 'calc(50% - 14px)' }}>
                <Form.Item label='Perfil de acesso' name='roles'>
                  <Select
                    placeholder='Selecione...'
                    size='large'
                    mode='multiple'
                    style={{ width: '100%' }}
                  >
                    {roles.map((role) => {
                      return (
                        <Select.Option key={role.id} value={role.id}>
                          {role.role_name}
                        </Select.Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <Enviar
                type='primary'
                htmlType='submit'
                size='large'
                style={{ flex: 1 }}
              >
                {mode === 'insert' ? 'Adicionar' : 'Atualizar'}
              </Enviar>
              <Cancelar
                danger
                onClick={onClose}
                size='large'
                style={{ flex: 1 }}
              >
                Cancelar
              </Cancelar>
            </div>
          </Form>
        </Spin>
      </Drawer>
      <Modal
        title='Assistente'
        visible={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={null}
      >
        {user && (
          <>
            {user.image && (
              <>
                <Avatar
                  size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                  src={user.image}
                />
                <br />
              </>
            )}
            <Text strong>Nome</Text>
            <Paragraph>{user.name}</Paragraph>
            <Text strong>Email</Text>
            <Paragraph>{user.email}</Paragraph>
            <Text strong>Perfil de acesso</Text>
            <Paragraph>{user.accessProfile}</Paragraph>
          </>
        )}
      </Modal>
    </Container>
  )
}

export default withRouter(Assistentes)
