import React, { useContext, useState } from 'react'
import {
  Skeleton,
  message,
  Table,
  Button,
  Drawer,
  Form,
  Spin,
  Menu,
  Dropdown,
  Divider,
} from 'antd'
import {
  FilterOutlined,
  DownloadOutlined,
  DownOutlined,
  SettingOutlined,
} from '@ant-design/icons'
import api, { pdfUrl } from '../../services/api'
import { Container, PageTitle } from '../../components'
import { Enviar, Cancelar } from '../../components/Buttons'
import { format } from 'date-fns'
import { Helmet } from 'react-helmet'
import DatePicker from '../../components/DatePicker'
import AuthContext from 'contexts/auth'
import axios from 'axios'

const { Column } = Table

function RelatoriosTempoContratos() {
  const { user } = useContext(AuthContext)
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [filtrados, setFiltrados] = useState([])
  const [date, setDate] = useState(null)
  const [filterQuery, setFilterQuery] = useState({})

  const onClose = () => {
    setVisible(false)
    form.resetFields()
  }

  const disabledDate = (current) => {
    return current && current < date
  }

  const exportTable = async () => {
    setLoading(true)
    const nameFile = `${format(filterQuery.startDate, 'dd-MM-yyyy')} à ${format(
      filterQuery.endDate ? filterQuery.endDate : new Date(),
      'dd-MM-yyyy'
    )}`
    await axios
      .post(
        `${pdfUrl}contracts-end-report/`,
        { user, data: filtrados },
        {
          responseType: 'blob',
        }
      )
      .then((response) => {
        message.success({
          content: 'Baixando PDF...',
        })
        // const newWin = window.open(
        //   'about:blank',
        //   'Axios data',
        //   'width=841,height=800'
        // )
        // newWin.document.write(response.data)
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${nameFile}.pdf`)
        document.body.appendChild(link)
        link.click()
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const exportRow = async (record) => {
    setLoading(true)
    const nameFile = record.cliente.nomeFantasia
    await axios
      .post(
        `${pdfUrl}contracts-end-report/`,
        { user, data: [record] },
        {
          responseType: 'blob',
        }
      )
      .then((response) => {
        message.success({
          content: 'Baixando PDF...',
        })
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${nameFile}.pdf`)
        document.body.appendChild(link)
        link.click()
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onSubmit = async (value) => {
    console.log(value)
    setFilterQuery(value)
    setLoading(true)
    api
      .get(`reports/contracts-end/`, {
        params: {
          startDate: format(value.startDate, 'yyyy-MM-dd'),
          endDate: format(value.endDate, 'yyyy-MM-dd'),
        },
      })
      .then(({ data }) => {
        setFiltrados(data)
      })
      .catch(() => {
        message.error('Algo inesperado ocorreu')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const menu = () => {
    return (
      <Menu>
        <Menu.Item key='1'>
          <Button icon={<DownloadOutlined />} onClick={exportTable}>
            Baixar Tabela - PDF
          </Button>
        </Menu.Item>
        <Menu.Item key='2'>
          <Button disabled={true} icon={<DownloadOutlined />}>
            Baixar Tabela - CSV
          </Button>
        </Menu.Item>
      </Menu>
    )
  }

  const menuTabela = (record) => (
    <Menu>
      <Menu.Item key='1'>
        <Button
          icon={<DownloadOutlined />}
          onClick={() => {
            exportRow(record)
          }}
        >
          Baixar Relatório
        </Button>
      </Menu.Item>
    </Menu>
  )

  return (
    <Container>
      <Helmet>
        <title>Arcke | Relatório - Faturamento</title>
      </Helmet>
      <Skeleton loading={false}>
        <PageTitle
          title='Relatório de Vencimento de Contratos'
          extra={[
            <Dropdown
              key='1'
              trigger={['click']}
              disabled={false || filtrados.length < 1}
              overlay={menu}
              placement='bottomCenter'
            >
              <Button size='large'>
                Exportar Tabela <DownOutlined />
              </Button>
            </Dropdown>,
            <Button
              icon={<FilterOutlined />}
              type='primary'
              size='large'
              onClick={() => {
                setVisible(true)
              }}
              key='2'
            >
              Filtrar
            </Button>,
          ]}
        />
        <Table
          dataSource={filtrados}
          loading={loading}
          rowKey={(record) => record.id}
          size='middle'
          style={{ margin: 20 }}
        >
          <Column title='Contrato' dataIndex='name' key='name' />
          <Column
            title='Cliente'
            dataIndex='client'
            key='client'
            render={(_, record) => record.cliente.nomeFantasia}
          />
          <Column
            title='Responsavel'
            dataIndex='responsavel'
            key='responsavel'
            render={(_, record) =>
              record.responsavel?.name ? record.responsavel?.name : ''
            }
          />

          <Column
            title='Ações'
            dataIndex='actions'
            key='actions'
            render={(_, record) => (
              <Dropdown
                trigger={['click']}
                key='1'
                overlay={menuTabela(record)}
                placement='bottomCenter'
              >
                <Button icon={<SettingOutlined />}>Ações</Button>
              </Dropdown>
            )}
          />
        </Table>
        <Drawer
          visible={visible}
          onClose={onClose}
          width={550}
          title='Filtro de Relatório'
        >
          <Spin spinning={loading}>
            <Form form={form} layout='vertical' onFinish={onSubmit}>
              <Form.Item
                label='Data de início'
                name='startDate'
                rules={[
                  { required: true, message: 'Data de início é obrigatória.' },
                ]}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  onChange={(value) => {
                    setDate(value)
                  }}
                  size='large'
                />
              </Form.Item>
              <Form.Item
                label='Data final'
                name='endDate'
                rules={[
                  { required: true, message: 'Data Final é obrigatoria.' },
                ]}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  disabled={date === null}
                  disabledDate={disabledDate}
                  size='large'
                />
              </Form.Item>
              <Divider style={{ borderColor: '#aaa' }} />
              <div style={{ display: 'flex' }}>
                <Enviar
                  type='primary'
                  htmlType='submit'
                  style={{ flex: 1 }}
                  size='large'
                >
                  Gerar Relatório
                </Enviar>
                <Cancelar
                  danger
                  onClick={onClose}
                  style={{ flex: 1 }}
                  size='large'
                >
                  Cancelar
                </Cancelar>
              </div>
            </Form>
          </Spin>
        </Drawer>
      </Skeleton>
    </Container>
  )
}

export { RelatoriosTempoContratos }
