import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import {
  message,
  Button,
  Drawer,
  Spin,
  Form,
  Input,
  Divider,
  Timeline,
  Tag,
  Typography,
  Empty,
  Select,
} from 'antd'
import { CheckOutlined, PlusOutlined } from '@ant-design/icons'
import { Container, PageTitle } from '../../../components'
import { Cancelar, Enviar } from 'components/Buttons'
import { colors } from '..'
import { format, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'

import api from '../../../services/api'
import DatePicker from 'components/DatePicker'
import moment from 'moment'

const { Text } = Typography

function HistoricoOrdem() {
  const { osId } = useParams()

  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)
  const [load, setLoad] = useState(false)
  const [visible, setVisible] = useState(false)
  const [serviceOrder, setServiceOrder] = useState()

  useEffect(() => {
    loadHistorico()
  }, [load])

  const loadHistorico = async () => {
    setLoading(true)
    await api
      .get(`/ordem_servico/historico/${osId}`)
      .then(({ data }) => {
        setServiceOrder(data)
      })
      .catch(() => {
        message.error('Erro ao carregar os dados, atualize a pagina')
        setLoading(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onCloseDrawer = () => {
    form.resetFields()
    setVisible(false)
    setLoad(!load)
  }

  const onSubmit = async (values) => {
    await api
      .post(`/ordem_servico/historico/${osId}`, values)
      .then(() => {
        message.success('Cadastro realizado com sucesso!')
        onCloseDrawer()
      })
      .catch((e) => {
        if (e.response.data.errors) {
          e.response.data.errors.forEach((info) => {
            message.error(info.message)
          })
        } else {
          message.error(e.response.data.error)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const routes = [
    {
      path: '/os',
      breadcrumbName: 'Ordens de Serviço',
    },
  ]

  function itemRender(route) {
    return (
      <Link to={{ pathname: route.path }} key={route.breadcrumbName}>
        {route.breadcrumbName}
      </Link>
    )
  }

  const disabledDate = (current) => {
    return current <= moment().endOf('day').add({ day: -1 })
  }

  return (
    <Container>
      <PageTitle
        title='Restrições na OS'
        breadcrumb={{ routes, itemRender }}
        breadcrumbRender={(props, breadcrumb) => (
          <div style={{ marginBottom: -4 }}>{breadcrumb}</div>
        )}
        extra={[
          <Button
            size='large'
            icon={<CheckOutlined />}
            onClick={() => {
              setVisible(true)
              form.setFieldValue('status', 'reaberto')
            }}
            disabled={serviceOrder?.status !== 'aguardando'}
            key='1'
          >
            Solucionar Pendência
          </Button>,
          <Button
            type='primary'
            size='large'
            disabled={serviceOrder?.status !== 'aguardando'}
            icon={<PlusOutlined />}
            onClick={() => {
              setVisible(true)
              form.setFieldValue('status', 'aguardando')
            }}
            key='2'
          >
            Adicionar Histórico
          </Button>,
        ]}
      />
      <div
        style={{
          backgroundColor: '#ffffff',
          padding: '16px 16px 0 16px',
          border: '1px solid #e2e2e2',
          margin: '16px 10px',
          borderRadius: '15px !important',
        }}
      >
        {serviceOrder?.historico.length > 0 ? (
          <Timeline mode='left'>
            {serviceOrder?.historico.map((historico) => {
              return (
                <Timeline.Item
                  key={historico.key}
                  label={
                    historico.status ? (
                      <Tag color={colors[historico.status].color}>
                        {colors[historico.status].name}
                      </Tag>
                    ) : null
                  }
                >
                  <Text>{historico.description}</Text>
                  <br />
                  <Text type='secondary'>- {historico.user?.name}</Text>
                  <br />
                  <Text strong>
                    {format(
                      parseISO(historico.createdAt),
                      "dd 'de' MMMM 'de' yyyy 'às' HH:mm",
                      {
                        locale: ptBR,
                      }
                    )}
                  </Text>
                </Timeline.Item>
              )
            })}
          </Timeline>
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <>
                <span>Não há registro aqui</span>
              </>
            }
          />
        )}
      </div>
      <Drawer
        open={visible}
        onClose={onCloseDrawer}
        width={550}
        title='Adicionar Histórico'
      >
        <Spin spinning={loading}>
          <Form form={form} layout='vertical' onFinish={onSubmit}>
            <Form.Item
              label='Motivo'
              name='title'
              rules={[
                {
                  required: true,
                  message: 'Por favor, o motivo deve ser informada.',
                },
              ]}
            >
              <Input size='large' />
            </Form.Item>
            <Form.Item
              label='Descrição/Comentario'
              name='description'
              rules={[
                {
                  required: true,
                  message: 'Por favor, a descrição deve ser informada.',
                },
              ]}
            >
              <Input.TextArea size='large' />
            </Form.Item>
            <Form.Item
              label='Status'
              name='status'
              rules={[
                {
                  required: true,
                  message: 'Por favor, o status deve ser informado.',
                },
              ]}
            >
              <Select
                optionLabelProp='label'
                optionFilterProp='children'
                size='large'
                placeholder='Selecione...'
                showSearch
                allowClear
                disabled
              >
                {Object.keys(colors).map((color) => {
                  return (
                    <Select.Option
                      key={color}
                      value={color}
                      label={colors[color]?.name}
                    >
                      {colors[color]?.name}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
            {form.getFieldValue('status') === 'reaberto' && (
              <Form.Item
                label='Previsão de conclusão'
                name='previsaoConclusao'
                rules={[
                  {
                    required: true,
                    message:
                      'Por favor, a previsão de conclusão deve ser informada.',
                  },
                ]}
              >
                <DatePicker
                  disabledDate={disabledDate}
                  size='large'
                  style={{ width: '100%' }}
                />
              </Form.Item>
            )}
            <Divider style={{ borderColor: '#bbb' }} />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Enviar
                type='primary'
                htmlType='submit'
                size='large'
                style={{ flex: 1 }}
              >
                Adicionar
              </Enviar>
              <Cancelar
                danger
                onClick={onCloseDrawer}
                size='large'
                style={{ flex: 1 }}
              >
                Cancelar
              </Cancelar>
            </div>
          </Form>
        </Spin>
      </Drawer>
    </Container>
  )
}

export { HistoricoOrdem }
