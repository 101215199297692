import React, { useContext, useEffect, useState } from 'react'
import {
  Skeleton,
  message,
  Table,
  Space,
  Button,
  Drawer,
  Form,
  Spin,
  Select,
  Menu,
  Dropdown,
  Tooltip,
  Checkbox,
  Divider,
} from 'antd'
import {
  FilterOutlined,
  DownloadOutlined,
  DownOutlined,
  SettingOutlined,
} from '@ant-design/icons'
import api, { pdfUrl } from '../../services/api'
import { Container, PageTitle } from '../../components'
import { Enviar, Cancelar } from '../../components/Buttons'
import { format } from 'date-fns'
import { Helmet } from 'react-helmet'
import { formatarMoeda } from 'services/utils'
import DatePicker from '../../components/DatePicker'
import AuthContext from 'contexts/auth'
import axios from 'axios'

const { Column } = Table

function RelatorioPecas() {
  const { user } = useContext(AuthContext)
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [filtrados, setFiltrados] = useState([])
  const [clients, setClients] = useState([])
  const [contracts, setContracts] = useState([])
  const [date, setDate] = useState(null)
  const [tecnicos, setTecnicos] = useState([])
  const [parts, setParts] = useState([])

  const [checkedList, setCheckedList] = useState([])
  const [indeterminate, setIndeterminate] = useState(true)
  const [checkAll, setCheckAll] = useState(false)

  const [filterQuery, setFilterQuery] = useState({})

  const onChange = (list) => {
    setCheckedList(list)
    setIndeterminate(!!list.length && list.length < tecnicos.length)
    setCheckAll(list.length === tecnicos.length)
  }

  const onCheckAllChange = (e) => {
    const ids = tecnicos.map((tecnico) => {
      return tecnico.id
    })
    setCheckedList(
      e.target.checked
        ? form.setFieldsValue({
            tecnicoId: ids,
          })
        : form.setFieldsValue({
            tecnicoId: [],
          })
    )
    setIndeterminate(false)
    setCheckAll(e.target.checked)
  }

  useEffect(() => {
    loadClients()
    loadContracts()
    loadTecnicos()
    loadParts()
  }, [])

  const loadParts = async () => {
    await api
      .get(`/parts/all-parts`)
      .then(({ data }) => {
        setParts(data)
      })
      .catch(() => {
        message.error('Erro ao carregar os dados, atualize a pagina')
      })
  }

  const loadTecnicos = async (attendantId) => {
    await api
      .get(`/user?accessProfile=tecnico`)
      .then(({ data }) => {
        setTecnicos(data)
      })
      .catch(() => {
        message.error('Erro ao carregar os dados.')
      })
  }

  const loadClients = async (attendantId) => {
    await api
      .get(`/client`, {
        params: {
          attendantId,
        },
      })
      .then(({ data }) => {
        setClients(data)
      })
      .catch(() => {
        message.error('Erro ao carregar os dados.')
      })
  }

  const loadContracts = async (clientId) => {
    await api
      .get(`/contract/os`, {
        params: {
          clientId,
        },
      })
      .then(({ data }) => {
        setContracts(data)
      })
      .catch(() => {
        message.error('Erro ao carregar os dados.')
      })
  }

  const onClose = () => {
    setVisible(false)
  }

  const disabledDate = (current) => {
    return current && current < date
  }

  const exportTable = async () => {
    setLoading(true)
    const startDate = format(filterQuery.startDate, 'dd-MM-yyyy')
    const endDate = format(
      filterQuery.endDate ? filterQuery.endDate : new Date(),
      'dd-MM-yyyy'
    )
    const nameFile = `${startDate} à ${endDate}`
    await axios
      .post(
        `${pdfUrl}parts-report/`,
        {
          user,
          data: {
            technical: filtrados,
            period: {
              startDate: filterQuery.startDate,
              endDate: filterQuery.endDate ? filterQuery.endDate : new Date(),
            },
          },
        },
        {
          responseType: 'blob',
        }
      )
      .then((response) => {
        message.success({
          content: 'Baixando PDF...',
        })
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${nameFile}.pdf`)
        document.body.appendChild(link)
        link.click()
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const exportRow = async (record) => {
    setLoading(true)
    const nameFile = record.name
    await axios
      .post(
        `${pdfUrl}parts-report/`,
        {
          user,
          data: {
            technical: [record],
            period: {
              startDate: filterQuery.startDate,
              endDate: filterQuery.endDate ? filterQuery.endDate : new Date(),
            },
          },
        },
        {
          responseType: 'blob',
        }
      )
      .then((response) => {
        message.success({
          content: 'Baixando PDF...',
        })
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${nameFile}.pdf`)
        document.body.appendChild(link)
        link.click()
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onSubmit = async (value) => {
    setFilterQuery(value)
    setLoading(true)
    api
      .get(`reports/parts/`, {
        params: {
          startDate: format(value.startDate, 'yyyy-MM-dd'),
          endDate: value.endDate ? format(value.endDate, 'yyyy-MM-dd') : null,
          attendantId: value.attendantId,
          partId: value?.partId?.toString(),
          technicianId: value?.tecnicoId?.toString(),
          clientId: value.clientId,
          contractId: value.contractId,
        },
      })
      .then(({ data }) => {
        console.log(data)
        setFiltrados(data)
      })
      .catch(() => {
        message.error('Algo inesperado ocorreu')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const menu = () => {
    return (
      <Menu>
        <Menu.Item key='1'>
          <Button icon={<DownloadOutlined />} onClick={exportTable}>
            Baixar Tabela - PDF
          </Button>
        </Menu.Item>
        <Menu.Item key='2'>
          <Button disabled={true} icon={<DownloadOutlined />}>
            Baixar Tabela - CSV
          </Button>
        </Menu.Item>
      </Menu>
    )
  }

  const menuTabela = (record) => (
    <Menu>
      <Menu.Item key='1'>
        <Button
          icon={<DownloadOutlined />}
          onClick={() => {
            exportRow(record)
          }}
        >
          Baixar Relatório
        </Button>
      </Menu.Item>
    </Menu>
  )

  return (
    <Container>
      <Helmet>
        <title>Arcke | Relatório - Faturamento</title>
      </Helmet>
      <Skeleton loading={false}>
        <PageTitle
          title='Relatório de Faturamento por Peça'
          extra={[
            <Dropdown
              key='1'
              trigger={['click']}
              disabled={false || filtrados.length < 1}
              overlay={menu}
              placement='bottomCenter'
            >
              <Button size='large'>
                Exportar Tabela <DownOutlined />
              </Button>
            </Dropdown>,
            <Button
              icon={<FilterOutlined />}
              type='primary'
              size='large'
              onClick={() => {
                setVisible(true)
              }}
              key='2'
            >
              Filtrar
            </Button>,
          ]}
        />
        <Table
          dataSource={filtrados}
          loading={loading}
          rowKey={(record) => record.id}
          size='middle'
          style={{ margin: 20 }}
        >
          <Column
            title='Nome'
            dataIndex='name'
            key='name'
            render={(_, record) => record.name}
          />
          <Column
            title='Ordens'
            dataIndex='ordens'
            key='ordens'
            render={(_, record) => record.orders.length}
          />
          <Column
            title='Total'
            dataIndex='total'
            key='total'
            render={(_, record) => formatarMoeda(record.totalParts)}
          />

          <Column
            title='Ações'
            dataIndex='actions'
            key='actions'
            render={(_, record) => (
              <Dropdown
                trigger={['click']}
                key='1'
                overlay={menuTabela(record)}
                placement='bottomCenter'
              >
                <Button icon={<SettingOutlined />}>Ações</Button>
              </Dropdown>
            )}
          />
        </Table>
        <Drawer
          visible={visible}
          onClose={onClose}
          width={550}
          title='Filtro de Relatório'
        >
          <Spin spinning={loading}>
            <Form
              form={form}
              layout='vertical'
              onFinish={onSubmit}
              defaultValue={{ tecnicoId: checkedList }}
            >
              <Form.Item label='Técnico'>
                <Space style={{ width: '100%' }}>
                  <Form.Item
                    name='tecnicoId'
                    rules={[
                      {
                        required: false,
                        message: 'Selecine ao menos um técnico',
                      },
                    ]}
                    noStyle
                  >
                    <Select
                      size='large'
                      showSearch
                      style={{ width: 480 }}
                      placeholder='Selecione...'
                      optionLabelProp='label'
                      optionFilterProp='children'
                      allowClear
                      mode='multiple'
                      onChange={onChange}
                      maxTagCount='responsive'
                    >
                      {tecnicos.map((tecnico) => (
                        <Select.Option
                          key={tecnico.id}
                          value={tecnico.id}
                          label={tecnico.name}
                        >
                          {tecnico.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Tooltip placement='topLeft' title='Selecionar todos.'>
                    <Checkbox
                      indeterminate={indeterminate}
                      onChange={onCheckAllChange}
                      checked={checkAll}
                    />
                  </Tooltip>
                </Space>
              </Form.Item>
              <Form.Item label='Peças'>
                <Space style={{ width: '100%' }}>
                  <Form.Item
                    name='partId'
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                    noStyle
                  >
                    <Select
                      size='large'
                      showSearch
                      style={{ width: 480 }}
                      placeholder='Selecione...'
                      optionLabelProp='label'
                      optionFilterProp='children'
                      allowClear
                      mode='multiple'
                      onChange={onChange}
                      maxTagCount='responsive'
                    >
                      {parts.map((part) => (
                        <Select.Option
                          key={part.id}
                          value={part.id}
                          label={part.name}
                        >
                          {part.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Tooltip placement='topLeft' title='Selecionar todos.'>
                    <Checkbox
                      indeterminate={indeterminate}
                      onChange={onCheckAllChange}
                      checked={checkAll}
                    />
                  </Tooltip>
                </Space>
              </Form.Item>
              <Form.Item label='Cliente'>
                <Form.Item
                  name='clientId'
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                  noStyle
                >
                  <Select
                    size='large'
                    showSearch
                    placeholder='Selecione...'
                    optionLabelProp='label'
                    optionFilterProp='children'
                    allowClear
                    onChange={loadContracts}
                  >
                    {clients.map((client) => (
                      <Select.Option
                        key={client.id}
                        value={client.id}
                        label={client.nomeFantasia}
                      >
                        {client.nomeFantasia}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form.Item>
              <Form.Item label='Contrato'>
                <Form.Item
                  name='contractId'
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                  noStyle
                >
                  <Select
                    size='large'
                    showSearch
                    placeholder='Selecione...'
                    optionLabelProp='label'
                    optionFilterProp='children'
                    allowClear
                  >
                    {contracts.map((contract) => (
                      <Select.Option
                        key={contract.id}
                        value={contract.id}
                        label={contract.name}
                      >
                        {contract.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form.Item>
              <Form.Item label='Data de início' name='startDate'>
                <DatePicker
                  style={{ width: '100%' }}
                  onChange={(value) => {
                    setDate(value)
                  }}
                  size='large'
                />
              </Form.Item>
              <Form.Item label='Data de finalização' name='endDate'>
                <DatePicker
                  style={{ width: '100%' }}
                  disabled={date === null}
                  disabledDate={disabledDate}
                  size='large'
                />
                {/* ARRAY['aberto'::text, 'execucao'::text, 'aguardando'::text, 'finalizado'::text, 'reaberto'::text, 'atrasado'::text])) */}
              </Form.Item>
              <Divider style={{ borderColor: '#aaa' }} />
              <div style={{ display: 'flex' }}>
                <Enviar
                  disabled={date === null}
                  type='primary'
                  htmlType='submit'
                  style={{ flex: 1 }}
                  size='large'
                >
                  Gerar Relatório
                </Enviar>
                <Cancelar
                  danger
                  onClick={onClose}
                  style={{ flex: 1 }}
                  size='large'
                >
                  Cancelar
                </Cancelar>
              </div>
            </Form>
          </Spin>
        </Drawer>
      </Skeleton>
    </Container>
  )
}

export { RelatorioPecas }
