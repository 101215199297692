import React, { useEffect, useState } from 'react'
import {
  Card,
  Col,
  Divider,
  Drawer,
  Form,
  Radio,
  Row,
  Select,
  Spin,
  Tooltip,
  Typography,
} from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Pie, Column } from '@ant-design/charts'

import { Cancelar, Enviar } from 'components/Buttons'
import DatePicker from 'components/DatePicker'
import api from '../../../services/api'

const { Text } = Typography

const loadOrders = async (filter) => {
  return api.get('/dashboard/orders', {
    params: filter,
  })
}

const loadTechnical = async (filter) => {
  return api.get('/dashboard/technical', {
    params: filter,
  })
}

const loadEquipmentTypes = async (filter) => {
  return await api.get('/dashboard/equipment-types', {
    params: filter,
  })
}

function Relatorio({ visible, setVisible, resetFilter }) {
  const [form] = Form.useForm()
  const [technical, setTechnical] = useState([])
  const [equipmenTypes, setEquipmentTypes] = useState([])
  const [orders, setOrders] = useState({})
  const [loading, setLoading] = useState(false)
  const [clientes, setClientes] = useState([])
  const [contratos, setContratos] = useState([])
  const [customDate, setCustomDate] = useState(false)
  const [disableDate, setDisableDate] = useState('')
  const [filter, setFilter] = useState({})

  useEffect(() => {
    async function loadData() {
      setLoading(true)
      await loadTechnical(filter).then(({ data }) => {
        const dataSanitazed = data.map((item) => {
          return {
            type: item.name,
            value: Number(item.totalOS),
          }
        })
        setTechnical(dataSanitazed)
      })
      await loadOrders(filter).then(({ data }) => {
        setOrders(data)
      })
      await loadEquipmentTypes(filter).then(({ data }) => {
        const dataSanitazed = data.map((item) => {
          return {
            type: item.name,
            value: +item.total,
          }
        })
        setEquipmentTypes(dataSanitazed)
      })
    }
    loadData().finally(() => setLoading(false))
  }, [filter])

  useEffect(() => {
    setFilter({})
  }, [resetFilter])

  const config = {
    xField: 'type',
    yField: 'value',
    label: {
      position: 'middle',
      style: {
        fill: '#FFFFFF',
        opacity: 0.75,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      type: {
        alias: 'Tecnico',
      },
      value: {
        alias: 'Finalizados',
      },
    },
  }

  const configPie = {
    appendPadding: 10,
    angleField: 'value',
    colorField: 'type',
    radius: 0.8,
    innerRadius: 0.7,
    label: {
      type: 'inner',
      offset: '-50%',
      style: {
        textAlign: 'center',
      },
      autoRotate: false,
      content: '{value}',
    },
    interactions: [
      {
        type: 'pie-legend-active',
      },
      {
        type: 'element-active',
      },
    ],
  }

  const onClose = () => {
    setVisible(false)
  }

  const loadClientes = async () => {
    await api.get('/client').then(({ data }) => setClientes(data))
  }

  const loadContratos = async (clientId = null) => {
    await api
      .get(`/contract`, {
        params: {
          clientId,
          page: 1,
          limit: 1000,
        },
      })
      .then(({ data }) => setContratos(data.data))
  }

  const disabledDate = (current) => {
    return current && current < disableDate
  }

  const handleSubmit = (value) => {
    setFilter(value)
  }

  return (
    <Spin spinning={loading}>
      <Row
        style={{
          justifyContent: 'space-between',
          margin: '15px 25px 25px 25px',
        }}
      >
        <Card
          style={{
            width: 'calc(16.6% - 10px)',
            borderRadius: 2,
            borderBottom: '4px solid #51CAF5',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Text style={{ color: '#8C8C8C' }}>{'OS Totais'}</Text>
              <Tooltip title='Total de Ordens de Serviços no período.'>
                <InfoCircleOutlined style={{ fontSize: 20, cursor: 'help' }} />
              </Tooltip>
            </div>
            <Text
              style={{
                fontSize: '32px',
                color: '#262626',
                fontWeight: 500,
              }}
            >
              {orders?.total || 0}
            </Text>
          </div>
        </Card>
        <Card
          style={{
            width: 'calc(16.6% - 10px)',
            borderRadius: 2,
            borderBottom: '4px solid #39AF72',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Text style={{ color: '#8C8C8C' }}>{'OS em Aberto'}</Text>
              <Tooltip title='Ordens de Serviços aguardando atendimento pelo Técnico.'>
                <InfoCircleOutlined style={{ fontSize: 20, cursor: 'help' }} />
              </Tooltip>
            </div>
            <Text
              style={{
                fontSize: '32px',
                color: '#262626',
                fontWeight: 500,
              }}
            >
              {orders?.aberto || 0}
            </Text>
          </div>
        </Card>
        <Card
          style={{
            width: 'calc(16.6% - 10px)',
            borderRadius: 2,
            borderBottom: '4px solid #1A1BCB',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Text style={{ color: '#8C8C8C' }}>{'OS em Execução'}</Text>
              <Tooltip title='Ordens de Serviços em processo de atendimento pelo Técnico'>
                <InfoCircleOutlined style={{ fontSize: 20, cursor: 'help' }} />
              </Tooltip>
            </div>
            <Text
              style={{
                fontSize: '32px',
                color: '#262626',
                fontWeight: 500,
              }}
            >
              {orders?.execucao || 0}
            </Text>
          </div>
        </Card>
        <Card
          style={{
            width: 'calc(16.6% - 10px)',
            borderRadius: 2,
            borderBottom: '4px solid #C32059',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Text style={{ color: '#8C8C8C' }}>{'OS em Atraso'}</Text>
              <Tooltip title='Ordens de Serviços onde a previsão de conclusão expirou e que ainda não foram atendidas ou finalizadas pelo Técnico.'>
                <InfoCircleOutlined style={{ fontSize: 20, cursor: 'help' }} />
              </Tooltip>
            </div>
            <Text
              style={{
                fontSize: '32px',
                color: '#262626',
                fontWeight: 500,
              }}
            >
              {orders?.atrasado || 0}
            </Text>
          </div>
        </Card>
        <Card
          style={{
            width: 'calc(16.6% - 10px)',
            borderRadius: 2,
            borderBottom: '4px solid #3561A0',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Text style={{ color: '#8C8C8C' }}>{'OS Finalizadas'}</Text>
              <Tooltip title='Ordens de Serviços que já foram atendidas e finalizadas pelo Técnico.'>
                <InfoCircleOutlined style={{ fontSize: 20, cursor: 'help' }} />
              </Tooltip>
            </div>
            <Text
              style={{
                fontSize: '32px',
                color: '#262626',
                fontWeight: 500,
              }}
            >
              {orders?.finalizado || 0}
            </Text>
          </div>
        </Card>
        <Card
          style={{
            width: 'calc(16.6% - 10px)',
            borderRadius: 2,
            borderBottom: '4px solid #9D5EE0',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Text style={{ color: '#8C8C8C' }}>{'OS Validadas'}</Text>
              <Tooltip title='Ordens de Serviços que após finalizadas, foram validadas pela equipe interna.'>
                <InfoCircleOutlined style={{ fontSize: 20, cursor: 'help' }} />
              </Tooltip>
            </div>
            <Text
              style={{
                fontSize: '32px',
                color: '#262626',
                fontWeight: 500,
              }}
            >
              {orders?.validado || 0}
            </Text>
          </div>
        </Card>
      </Row>
      <Row
        style={{
          justifyContent: 'space-around',
          padding: '0 15px',
        }}
      >
        <Card
          title='Ranking de Atendimento por Técnico'
          style={{ width: 'calc(60% - 15px)', borderRadius: 2 }}
        >
          <Column {...config} data={technical} />
        </Card>
        <Card
          title='Atendimento por Tipo de Equipamento'
          style={{ width: 'calc(40% - 15px)', borderRadius: 2 }}
        >
          <Pie {...configPie} data={equipmenTypes} />
        </Card>
      </Row>
      <Drawer
        open={visible}
        onClose={onClose}
        width={550}
        title='Filtrar Informações da Dashboard'
      >
        <Spin spinning={loading}>
          <Form form={form} layout='vertical' onFinish={handleSubmit}>
            <Form.Item label='Período' name='date'>
              <Radio.Group size='large'>
                <Row gutter={[16, 24]}>
                  <Col>
                    <Radio.Button
                      value={0}
                      onClick={() => setCustomDate(false)}
                    >
                      Esse Mês
                    </Radio.Button>
                  </Col>
                  <Col>
                    <Radio.Button
                      value={15}
                      onClick={() => setCustomDate(false)}
                    >
                      Últimos 15 dias
                    </Radio.Button>
                  </Col>
                  <Col>
                    <Radio.Button
                      value={30}
                      onClick={() => setCustomDate(false)}
                    >
                      Últimos 30 dias
                    </Radio.Button>
                  </Col>
                  <Col>
                    <Radio.Button
                      value={60}
                      onClick={() => setCustomDate(false)}
                    >
                      Últimos 60 dias
                    </Radio.Button>
                  </Col>
                  <Col>
                    <Radio.Button
                      value={90}
                      onClick={() => setCustomDate(false)}
                    >
                      Últimos 90 dias
                    </Radio.Button>
                  </Col>
                  <Col>
                    <Radio.Button
                      value={null}
                      onClick={() => setCustomDate(true)}
                    >
                      Personalizado
                    </Radio.Button>
                  </Col>
                </Row>
              </Radio.Group>
            </Form.Item>
            {customDate ? (
              <>
                <Form.Item
                  label='Início do período'
                  name='startDate'
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, o período deve ser informado',
                    },
                  ]}
                >
                  <DatePicker
                    size='large'
                    style={{ width: '100%' }}
                    onChange={(value) => {
                      setDisableDate(value)
                    }}
                    placeholder='Selecionar período'
                  />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, o período deve ser informado',
                    },
                  ]}
                  label='Fim do período'
                  name='endDate'
                >
                  <DatePicker
                    size='large'
                    style={{ width: '100%' }}
                    disabled={customDate === null}
                    disabledDate={disabledDate}
                    placeholder='Selecionar período'
                  />
                </Form.Item>
              </>
            ) : null}
            <Form.Item label='Cliente' name='clientId'>
              <Select
                showSearch
                allowClear
                size='large'
                placeholder='Selecione...'
                optionLabelProp='label'
                optionFilterProp='children'
                onDropdownVisibleChange={loadClientes}
                onChange={(value) => {
                  form.setFieldsValue({ contractId: undefined })
                  loadContratos(value)
                }}
              >
                {clientes.map((cliente) => (
                  <Select.Option
                    key={cliente.id}
                    value={cliente.id}
                    label={cliente.nomeFantasia}
                  >
                    {cliente.nomeFantasia}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {contratos.length > 0 ? (
              <Form.Item label='Contrato' name='contractId'>
                <Select
                  showSearch
                  size='large'
                  placeholder='Selecione...'
                  optionLabelProp='label'
                  optionFilterProp='children'
                  allowClear
                >
                  {contratos.map((contrato) => (
                    <Select.Option
                      key={contrato.id}
                      value={contrato.id}
                      label={`${contrato.name}`}
                    >
                      {`${contrato.name}`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            ) : null}
            <Divider style={{ borderColor: '#aaa' }} />
            <div style={{ display: 'flex' }}>
              <Enviar
                type='primary'
                htmlType='submit'
                size='large'
                style={{ flex: 1 }}
              >
                Filtrar
              </Enviar>
              <Cancelar
                danger
                onClick={() => {
                  form.resetFields()
                  onClose()
                }}
                size='large'
                style={{ flex: 1 }}
              >
                Cancelar
              </Cancelar>
            </div>
          </Form>
        </Spin>
      </Drawer>
    </Spin>
  )
}

export default Relatorio
