import { Form, Input, Button, message, Spin } from 'antd'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import logo from '../../assets/image/logo.svg'
import { Container, Content, LogoContainer, FormContainer } from './styles'

import axios from 'axios'
const api = axios.create({ baseURL: process.env.REACT_APP_API })

export function Recuperar() {
  const [form] = Form.useForm()
  const { token, id } = useParams()
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  return (
    <Container>
      <Content>
        <FormContainer>
          <LogoContainer>
            <img src={logo} alt='logo' />
          </LogoContainer>
          <Spin spinning={loading}>
            <Form
              orientation='left'
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 16 }}
              form={form}
              style={{ width: '100%' }}
              onFinish={async (values) => {
                setLoading(true)
                api
                  .put(
                    `/user/resetpassword/${id}`,
                    { password: values.password },
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then(() => {
                    setLoading(false)
                    history.push('/')
                  })
                  .catch((e) => {
                    if (e.response.data) message.error('Token Expirado!')
                    setLoading(false)
                  })
              }}
            >
              <Form.Item
                name='password'
                label='Senha'
                rules={[
                  {
                    required: true,
                    message: 'A senha deve ser informada',
                  },
                ]}
                hasFeedback
              >
                <Input.Password size='large' />
              </Form.Item>
              <Form.Item
                name='confirm'
                label='Confirme a senha'
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Confirme sua senha',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject(
                        new Error('As senhas devem ser iguais!')
                      )
                    },
                  }),
                ]}
              >
                <Input.Password size='large' />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 16 }}>
                <Button
                  type='primary'
                  htmlType='submit'
                  className='login-form-button'
                >
                  Trocar Senha
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </FormContainer>
      </Content>
    </Container>
  )
}
