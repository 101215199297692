import React, { useEffect, useState } from 'react'
import {
  Skeleton,
  message,
  Table,
  Space,
  Button,
  Drawer,
  Popconfirm,
  Descriptions,
  Modal,
  Typography,
  Tooltip,
  Form,
  Spin,
  InputNumber,
  Select,
  Switch,
} from 'antd'
import NumberFormat from 'react-number-format'
import {
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
  SubnodeOutlined,
  CloseOutlined,
  CheckOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { useHistory, useLocation } from 'react-router-dom'
import { Container, PageTitle } from 'components'
import { formatarMoeda } from 'services/utils'
import { Enviar, Cancelar } from 'components/Buttons'
import api from 'services/api'

const { Column } = Table
const { Text, Paragraph } = Typography

function ItensDuplicados() {
  const location = useLocation()
  const locals = location.state

  const history = useHistory()
  const [form] = Form.useForm()
  const [itens, setItens] = useState([])
  const [contrato, setContrato] = useState({})
  const [listItems, setListItems] = useState([])
  const [equipmentTypes, setEquipmentTypes] = useState([])
  const [locais, setLocais] = useState([])
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [visible, setVisible] = useState(false)
  const [mode, setMode] = useState('insert')
  const [load, setLoad] = useState(false)
  const [item, setItem] = useState(null)
  const [open, setOpen] = useState(false)
  const [duplicado, setDuplicado] = useState(false)

  useEffect(() => {
    async function loadData() {
      setContrato(locals.contrato)
      setItens(locals.items.items)
      loadItems()
      try {
        await api.get('/equipmenttype').then(({ data }) => {
          setEquipmentTypes(data)
        })
      } catch (error) {
        message.error('Erro ao carregar os dados, atualize a pagina')
      } finally {
        setLoading(false)
      }
    }
    loadData()
  }, [load])

  const loadLocais = async () => {
    await api.get(`/local?clientId=${contrato.clientId}`).then(({ data }) => {
      setLocais(data)
    })
  }

  const loadLocal = async (itemId) => {
    await api
      .get(
        `/local?clientId=${contrato.clientId}&itemId=${itemId}&contractId=${contrato.id}`
      )
      .then(({ data }) => {
        setLocais(data)
      })
  }

  const onClose = () => {
    form.resetFields()
    setMode('insert')
    setVisible(false)
  }

  const loadItems = async () => {
    try {
      await api.get(`/item`).then(({ data }) => {
        setListItems(data)
      })
    } catch (error) {
      setLoading(false)

      message.error('Erro ao carregar os dados, atualize a pagina')
    }
  }

  return (
    <Container>
      <Skeleton loading={loading} active>
        <PageTitle
          onBack={() =>
            history.push({
              pathname: `/contratos/${contrato.id}/itens`,
              state: { ...contrato },
            })
          }
          title={`Itens individuais: ${
            locals.items.description ? locals.items.description : ''
          }`}
          extra={[
            <Button
              icon={<PlusOutlined />}
              onClick={() => {
                loadLocal(itens[0].itemId)
                setMode('insert')
                setVisible(true)
                setDuplicado(false)
                form.setFieldsValue({
                  itemId: itens[0].itemId,
                  itemDuplicado: false,
                  number: itens[0].number,
                })
              }}
              key='1'
            >
              Adicionar Local
            </Button>,
          ]}
        >
          <Descriptions size='small' column={2}>
            <Descriptions.Item label='Quantidade total'>
              {locals.items.quantidadeTotal ? locals.items.quantidadeTotal : ''}
            </Descriptions.Item>
          </Descriptions>
        </PageTitle>
        <Table
          dataSource={itens}
          loading={loading}
          rowKey={(record) => record.id}
          size='middle'
          style={{ margin: 20 }}
        >
          <Column title='Número' dataIndex='number' key='number' />
          <Column
            title='Local'
            dataIndex='local'
            key='local'
            render={(_, record) => record.local.name}
          />
          <Column
            title='Quantidade Disponivel'
            dataIndex='quantity'
            key='quantity'
            render={(_, record) => `${record.totalServicos}/${record.quantity}`}
          />
          <Column
            title='Valor Unitário'
            dataIndex='unityValue'
            key='unityValue'
            render={(text) => formatarMoeda(text)}
          />
          <Column
            dataIndex='actions'
            key='actions'
            render={(_, record) => (
              <Space>
                <Tooltip title='Visualizar' placement='top'>
                  <Button
                    icon={<SearchOutlined />}
                    onClick={() => {
                      setItem(record)
                      setOpen(true)
                    }}
                  />
                </Tooltip>
                <Tooltip title='Editar' placement='top'>
                  <Button
                    icon={<EditOutlined />}
                    onClick={() => {
                      loadLocais()
                      form.setFieldsValue({
                        ...record,
                        unityValue: Number(record.unityValue),
                        itemDuplicado: false,
                      })
                      setDuplicado(false)
                      setItem(record)
                      setMode('update')
                      setVisible(true)
                    }}
                  />
                </Tooltip>
                <Tooltip title='Aditivos' placement='top'>
                  <Button
                    icon={<SubnodeOutlined />}
                    onClick={() =>
                      history.push({
                        pathname: `/contratos/${contrato.id}/itens/${record.id}/aditivos`,
                        state: { ...contrato, item: record },
                      })
                    }
                  />
                </Tooltip>
                <Tooltip title='Excluir item' placement='top'>
                  <Popconfirm
                    title='Deseja realmente excluir este item?'
                    okText='Sim'
                    cancelText='Não'
                    onConfirm={async () => {
                      await api
                        .delete(`/itemlocal/${record.id}`)
                        .then(() => {
                          message.success('Item excluido com sucesso')
                          setLoad(!load)
                        })
                        .catch(() => {
                          message.error('Erro ao excluir item, tente novamente')
                        })
                    }}
                  >
                    <Button
                      icon={<DeleteOutlined />}
                      style={{ color: '#e80909' }}
                    />
                  </Popconfirm>
                </Tooltip>
              </Space>
            )}
          />
        </Table>
        <Drawer
          visible={visible}
          onClose={onClose}
          width={550}
          destroyOnClose
          title='Adicionar Item ao Contrato'
        >
          <Spin spinning={saving}>
            <Form
              form={form}
              layout='vertical'
              onFinish={(value) => {
                const data = {
                  ...value,
                  contractId: contrato.id,
                  clientId: contrato.cliente.id,
                  itemDuplicado: value.itemDuplicado,
                }
                if (value.itemDuplicado === false) {
                  delete data.itemDuplicado
                  delete data.clientId
                }
                setSaving(true)
                if (mode === 'insert') {
                  api
                    .post('/itemlocal', data)
                    .then(() => {
                      message.success('Cadastro realizado com sucesso!')
                      onClose()
                      setLoad(!load)
                    })
                    .catch((e) => {
                      if (e.response.data.errors) {
                        e.response.data.errors.forEach((info) => {
                          message.error(info.message)
                        })
                      } else {
                        message.error(e.response.data.error)
                      }
                    })
                    .finally(() => setSaving(false))
                } else if (mode === 'update') {
                  api
                    .put(`/itemlocal/${item.id}`, data)
                    .then(() => {
                      message.success('Cadastro realizado com sucesso!')
                      onClose()
                      setLoad(!load)
                    })
                    .catch((e) => {
                      if (e.response.data.errors) {
                        e.response.data.errors.forEach((info) => {
                          message.error(info.message)
                        })
                      } else {
                        message.error(e.response.data.error)
                      }
                    })
                    .finally(() => setSaving(false))
                }
              }}
            >
              <Form.Item
                label='Item'
                name='itemId'
                rules={[
                  {
                    required: true,
                    message: 'Por favor, o Item deve ser informado',
                  },
                ]}
              >
                <Select
                  size='large'
                  disabled={true}
                  showSearch
                  placeholder='Selecione...'
                  optionLabelProp='label'
                  optionFilterProp='children'
                >
                  {listItems.map((itemUni) => (
                    <Select.Option
                      key={itemUni.id}
                      value={itemUni.id}
                      label={itemUni.description}
                    >
                      {itemUni.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label='Repetir item'>
                <Space>
                  <Form.Item
                    valuePropName='checked'
                    name='itemDuplicado'
                    initialValue={duplicado}
                    noStyle
                    shouldUpdate
                  >
                    <Switch
                      disabled={true}
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      onChange={(value) => {
                        console.log(value)
                        setDuplicado(value)
                      }}
                    />
                  </Form.Item>
                  <Tooltip
                    placement='topLeft'
                    title='Quando selecionado, o item será duplicado para todos locais do contrato.'
                  >
                    <Button size='small' shape='circle'>
                      ?
                    </Button>
                  </Tooltip>
                </Space>
              </Form.Item>
              {!duplicado && (
                <Form.Item
                  label='Local'
                  name='localId'
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, o Local deve ser informado',
                    },
                  ]}
                  shouldUpdate
                >
                  <Select
                    size='large'
                    disabled={mode !== 'insert'}
                    showSearch
                    placeholder='Selecione...'
                    optionLabelProp='label'
                    optionFilterProp='children'
                  >
                    {locais.map((itemUni) => (
                      <Select.Option
                        key={itemUni.id}
                        value={itemUni.id}
                        label={`${itemUni.name}`}
                      >
                        {itemUni.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              <Form.Item label='Numero do item' name='number'>
                <InputNumber size='large' style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item
                label='Valor do item'
                name='unityValue'
                trigger='onValueChange'
                rules={[
                  {
                    required: true,
                    message: 'Por favor, o valor deve ser informado',
                  },
                ]}
              >
                <NumberFormat
                  disabled={mode !== 'insert'}
                  thousandSeparator='.'
                  decimalSeparator=','
                  prefix='R$'
                  decimalScale={2}
                  className='ant-input'
                  fixedDecimalScale
                  onValueChange={(value) =>
                    form.setFieldsValue({ unityValue: value.floatValue })
                  }
                />
              </Form.Item>
              <Form.Item label='Tipo de equipamento' name='equipmentTypeId'>
                <Select
                  size='large'
                  placeholder='Selecione...'
                  optionLabelProp='label'
                >
                  {equipmentTypes.map((type) => (
                    <Select.Option
                      key={type.id}
                      value={type.id}
                      label={`${type.name}`}
                    >
                      {type.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label='Quantidade' name='quantity'>
                <InputNumber size='large' style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item style={{ justifyContent: 'center' }}>
                <Enviar
                  disabled={mode === 'view'}
                  type='primary'
                  htmlType='submit'
                >
                  {mode === 'insert' ? 'Cadastrar' : 'Atualizar'}
                </Enviar>
                <Cancelar danger onClick={onClose}>
                  Cancelar
                </Cancelar>
              </Form.Item>
            </Form>
          </Spin>
        </Drawer>
        <Modal
          title='Item'
          visible={open}
          onOk={() => setOpen(false)}
          onCancel={() => setOpen(false)}
          footer={null}
        >
          {item && (
            <>
              <Text strong>Número</Text>
              <Paragraph>{item.number}</Paragraph>
              <Text strong>Descrição</Text>
              <Paragraph>{item.description}</Paragraph>
              <Text strong>Quantidade utilizada</Text>
              <Paragraph>{`${item.totalServicos}/${item.quantity}`}</Paragraph>
              <Text strong>Unidade de Medida</Text>
              <Paragraph>{item.unit}</Paragraph>
              <Text strong>Valor unitário</Text>
              <Paragraph>{formatarMoeda(item.unityValue)}</Paragraph>
            </>
          )}
        </Modal>
      </Skeleton>
    </Container>
  )
}

export default ItensDuplicados
