import React from 'react'
import { withRouter } from 'react-router-dom'

// import { Container } from './styles';

function Home() {
  return <div>Início</div>
}

export default withRouter(Home)
