import React, { useEffect, useState } from 'react'
import {
  Button,
  Divider,
  Drawer,
  Form,
  Input,
  Select,
  Spin,
  Switch,
  Tooltip,
  message,
} from 'antd'
import { Cancelar, Enviar } from '../Buttons'
import DatePicker from '../DatePicker'
import api from 'services/api'
import { parseISO } from 'date-fns'
import {
  CheckOutlined,
  CloseOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'

export default function OrdemServico({
  config: { drawer, ordem },
  refreshData,
  setVisible,
}) {
  const [form] = Form.useForm()
  const [tecnicos, setTecnicos] = useState([])
  const [contratos, setContratos] = useState([])
  const [clientes, setClientes] = useState([])
  const [locais, setLocais] = useState([])
  const [setores, setSetores] = useState([])
  const [unidades, setUnidades] = useState([])
  const [tipoServicos, setTipoServicos] = useState([])
  const [tipoEquipamentos, setTipoEquipamentos] = useState([])
  const [loading, setLoading] = useState([])
  const [pmocs, setPmocs] = useState([])

  const [isVisible, setIsVisible] = useState('')

  const { Option } = Select

  useEffect(() => {
    setLoading(true)
    if (drawer === 'update' && ordem) {
      console.log(ordem)
      form.setFieldsValue({
        ...ordem,
        previsaoAtendimento: ordem.previsaoAtendimento
          ? parseISO(ordem.previsaoAtendimento)
          : '',
        previsaoConclusao: ordem.previsaoConclusao
          ? parseISO(ordem.previsaoConclusao)
          : '',
        endDate: ordem.endDate ? parseISO(ordem.endDate) : '',
      })
      loadContratos(ordem.clientId)
      loadTipos()
      loadTipoEquipamentos()
      loadClientes()
      loadLocais(ordem.clientId)
      if (ordem.setorId) {
        loadSetores(ordem.localId)
        if (ordem.unidadeId) {
          loadUnidades(ordem.setorId)
        }
      }
    }
    async function loadTecnicos() {
      try {
        setLoading(true)
        await api
          .get(`/user?accessProfile=tecnico`)
          .then(({ data }) => setTecnicos(data))
      } finally {
        setLoading(false)
      }
    }
    loadTecnicos()
  }, [drawer])

  const loadContratos = async (clientId) => {
    await api
      .get(`/contract/os/`, {
        params: {
          clientId: clientId,
        },
      })
      .then(({ data }) => {
        setContratos(data)
        if (ordem?.pmocId) {
          const arr = data?.find((contrato) => contrato.id === ordem.contractId)
          setPmocs(arr?.pmocs)
        }
      })
  }

  const loadTipos = async () => {
    await api.get('/servicetype').then(({ data }) => setTipoServicos(data))
  }

  const loadTipoEquipamentos = async () => {
    await api
      .get('/equipmenttype')
      .then(({ data }) => setTipoEquipamentos(data))
  }

  const loadClientes = async () => {
    await api.get('/client').then(({ data }) => setClientes(data))
  }

  const loadLocais = async (clientId) => {
    await api.get(`/local?clientId=${clientId}`).then(({ data }) => {
      setLocais(data)
    })
  }

  const loadSetores = async (localId = null) => {
    if (localId) {
      await api.get(`/setor?localId=${localId}`).then(({ data }) => {
        setSetores(data)
      })
    }
  }

  const loadUnidades = async (setorId = null) => {
    if (setorId) {
      await api.get(`/unidade?setorId=${setorId}`).then(({ data }) => {
        setUnidades(data)
      })
    }
  }

  const onSave = async (values) => {
    setLoading(true)
    drawer === 'update'
      ? await api
          .put(`/ordem_servico/normal/${ordem.id}`, values)
          .then(onClose)
          .catch(() => {
            setLoading(false)
          })
          .finally(() => {
            setLoading(false)
          })
      : await api
          .post('/ordem_servico/normal', values)
          .then(onClose)
          .catch(() => {
            setLoading(false)
          })
          .finally(() => {
            setLoading(false)
          })
  }

  const onClose = () => {
    setLoading(false)
    form.resetFields()
    setLocais([])
    setPmocs([])
    setUnidades([])
    refreshData()
    setVisible({ drawer: 'none' })
  }

  const handleOpenDrawer = (type) => {
    setIsVisible(type)
  }

  const handleCloseDrawer = async (refresh) => {
    if (refresh) {
      if (isVisible === 'sector') {
        await api
          .get(`/setor?localId=${form.getFieldValue('localId')}`)
          .then(({ data }) => {
            setSetores(data)
          })
      }
      if (isVisible === 'unit') {
        await api
          .get(`/unidade?setorId=${form.getFieldValue('setorId')}`)
          .then(({ data }) => {
            setUnidades(data)
          })
      }
    }
    setIsVisible('')
  }

  return (
    <Drawer
      destroyOnClose
      visible={drawer !== 'none'}
      width={550}
      onClose={onClose}
      title={`${
        drawer === 'insert' ? 'Adicionar' : 'Atualizar'
      } Ordem de Serviço`}
    >
      <Spin tip='Carregando...' size='large' spinning={loading}>
        <Form form={form} layout='vertical' onFinish={onSave}>
          <Form.Item
            label='Tipo de ordem de serviço'
            name='atendimento'
            required
          >
            <Select placeholder='Selecione...' size='large'>
              <Option key='contrato' value='contrato'>
                Contrato
              </Option>
              <Option key='avulso' value='avulso'>
                Avulsa
              </Option>
              <Option key='orcamento' value='orcamento'>
                Orçamento
              </Option>
            </Select>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prev, current) =>
              prev.atendimento !== current.atendimento
            }
          >
            {({ getFieldValue }) =>
              getFieldValue('atendimento') === 'contrato' ? (
                <>
                  <Form.Item
                    label={
                      <>
                        Ordem de serviço com equipamento?
                        <Tooltip title='Ao selecionar essa opção, o Técnico deverá informar um equipamento na Ordem de Serviço.'>
                          <QuestionCircleOutlined
                            style={{
                              marginLeft: 8,
                              fontSize: 16,
                              cursor: 'help',
                            }}
                          />
                        </Tooltip>
                      </>
                    }
                    valuePropName='checked'
                    name='isRequiredEquipment'
                    initialValue={true}
                    required
                  >
                    <Switch
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                    />
                  </Form.Item>
                  <Form.Item
                    label='Tipo de serviço'
                    name='typeServiceId'
                    rules={[
                      {
                        required: true,
                        message: 'O tipo de serviço precisa ser informado',
                      },
                    ]}
                  >
                    <Select
                      size='large'
                      showSearch
                      placeholder='Selecione...'
                      optionLabelProp='label'
                      optionFilterProp='children'
                      allowClear
                      onDropdownVisibleChange={loadTipos}
                    >
                      {tipoServicos.map(({ id, name }) => (
                        <Option key={id} value={id} label={name}>
                          {name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label='Tipo de equipamento'
                    name='equipmentTypeId'
                    rules={[
                      {
                        required: true,
                        message: 'O tipo de equipamento precisa ser informado',
                      },
                    ]}
                  >
                    <Select
                      size='large'
                      showSearch
                      placeholder='Selecione...'
                      optionLabelProp='label'
                      optionFilterProp='children'
                      allowClear
                      onDropdownVisibleChange={loadTipoEquipamentos}
                    >
                      {tipoEquipamentos.map(({ id, name }) => (
                        <Option key={id} value={id} label={name}>
                          {name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label='Cliente'
                    name='clientId'
                    rules={[
                      {
                        required: true,
                        message: 'O cliente precisa ser selecionado',
                      },
                    ]}
                  >
                    <Select
                      size='large'
                      showSearch
                      allowClear
                      placeholder='Selecione...'
                      optionLabelProp='label'
                      optionFilterProp='children'
                      onDropdownVisibleChange={loadClientes}
                      onChange={(value) => loadContratos(value)}
                    >
                      {clientes.map((cliente) => (
                        <Option
                          key={cliente.id}
                          value={cliente.id}
                          label={cliente.nomeFantasia}
                        >
                          {cliente.nomeFantasia}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {contratos?.length > 0 ? (
                    <Form.Item
                      label='Contrato'
                      name='contractId'
                      rules={[
                        {
                          required: true,
                          message: 'O contrato precisa ser selecionado',
                        },
                      ]}
                    >
                      <Select
                        size='large'
                        showSearch
                        placeholder='Selecione...'
                        optionLabelProp='label'
                        optionFilterProp='children'
                        allowClear
                        onChange={(value) => {
                          const arr = contratos?.find(
                            (contrato) => contrato.id === value
                          )
                          setPmocs(arr?.pmocs)
                          loadLocais(arr.clientId)
                        }}
                      >
                        {contratos?.map((contrato) => (
                          <Option
                            key={contrato.id}
                            value={contrato.id}
                            label={contrato.name}
                          >
                            {contrato.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  ) : null}
                  {pmocs.length > 0 ? (
                    <Form.Item label='PMOC' name='pmocId'>
                      <Select
                        size='large'
                        placeholder='Selecione...'
                        allowClear
                        showSearch
                        optionLabelProp='label'
                        optionFilterProp='children'
                      >
                        {pmocs?.map((unidade) => (
                          <Option
                            key={unidade.id}
                            value={unidade.id}
                            label={unidade.name}
                          >
                            {unidade.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  ) : null}
                  <Form.Item
                    label='Local'
                    name='localId'
                    rules={[
                      {
                        required: true,
                        message: 'O local precisa ser selecionado',
                      },
                    ]}
                  >
                    <Select
                      size='large'
                      placeholder='Selecione...'
                      showSearch
                      allowClear
                      optionLabelProp='label'
                      optionFilterProp='children'
                      onSelect={(value) => {
                        loadSetores(value)
                      }}
                    >
                      {locais.map((local) => (
                        <Option
                          key={local.id}
                          value={local.id}
                          label={local.name}
                        >
                          {local.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 8,
                    }}
                  >
                    <Form.Item
                      label='Unidade'
                      name='setorId'
                      rules={[
                        {
                          required: true,
                          message: 'A unidade precisa ser selecionada',
                        },
                      ]}
                      style={{
                        width: '100%',
                      }}
                    >
                      <Select
                        size='large'
                        placeholder='Selecione...'
                        showSearch
                        allowClear
                        optionLabelProp='label'
                        optionFilterProp='children'
                        onSelect={(value) => {
                          loadUnidades(value)
                        }}
                      >
                        {setores.length > 0
                          ? setores.map((setor) => (
                              <Option
                                key={setor.id}
                                value={setor.id}
                                label={setor.name}
                              >
                                {setor.name}
                              </Option>
                            ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Button
                      size='large'
                      style={{
                        padding: '0 11px',
                        borderColor: '#aaa',
                        marginBottom: -5,
                      }}
                      onClick={() => handleOpenDrawer('sector')}
                    >
                      <PlusOutlined />
                    </Button>
                  </div>
                  <SectorDrawer
                    onClose={handleCloseDrawer}
                    visible={isVisible}
                    localId={form.getFieldValue('localId')}
                  />
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 8,
                    }}
                  >
                    <Form.Item
                      label='Setor'
                      name='unidadeId'
                      style={{
                        width: '100%',
                      }}
                    >
                      <Select
                        size='large'
                        placeholder='Selecione...'
                        allowClear
                        showSearch
                        optionLabelProp='label'
                        optionFilterProp='children'
                      >
                        {unidades.map((unidade) => (
                          <Option
                            key={unidade.id}
                            value={unidade.id}
                            label={unidade.name}
                          >
                            {unidade.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Button
                      size='large'
                      style={{
                        padding: '0 11px',
                        borderColor: '#aaa',
                        marginBottom: -5,
                      }}
                      onClick={() => handleOpenDrawer('unit')}
                    >
                      <PlusOutlined />
                    </Button>
                  </div>
                  <UnitDrawer
                    onClose={handleCloseDrawer}
                    visible={isVisible}
                    setorId={form.getFieldValue('setorId')}
                  />
                </>
              ) : null
            }
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prev, current) =>
              prev.atendimento !== current.atendimento
            }
          >
            {({ getFieldValue }) =>
              getFieldValue('atendimento') === 'avulso' ? (
                <>
                  <Form.Item
                    label='Cliente'
                    name='clientId'
                    rules={[
                      {
                        required: true,
                        message: 'O cliente precisa ser selecionado',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      allowClear
                      placeholder='Selecione...'
                      optionLabelProp='label'
                      optionFilterProp='children'
                      onDropdownVisibleChange={loadClientes}
                      size='large'
                    >
                      {clientes.map((cliente) => (
                        <Option
                          key={cliente.id}
                          value={cliente.id}
                          label={cliente.nomeFantasia}
                        >
                          {cliente.nomeFantasia}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </>
              ) : null
            }
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prev, current) =>
              prev.atendimento !== current.atendimento
            }
          >
            {({ getFieldValue }) =>
              getFieldValue('atendimento') !== 'contrato' ? (
                <>
                  <Form.Item
                    label='Local de atendimento'
                    name='localAtendimento'
                  >
                    <Input size='large' />
                  </Form.Item>
                </>
              ) : null
            }
          </Form.Item>
          <Form.Item label='Nº de ref. cliente' name='numRef'>
            <Input size='large' />
          </Form.Item>
          <Form.Item label='Nº de ref. interno' name='numRefClick'>
            <Input size='large' />
          </Form.Item>
          <Form.Item
            label='Descrição do serviço'
            name='description'
            rules={[
              {
                required: true,
                message: 'Por favor, a descrição deve ser informada',
              },
            ]}
          >
            <Input.TextArea
              size='large'
              showCount
              maxLength={255}
              autoSize={{ minRows: 3, maxRows: 7 }}
            />
          </Form.Item>
          <Form.Item label='Técnico' name='tecnicoId'>
            <Select
              placeholder='Selecione...'
              showSearch
              allowClear
              optionLabelProp='label'
              optionFilterProp='children'
              size='large'
            >
              <Option key={0} value={null} label='Não definido'>
                Não definido
              </Option>
              {tecnicos.map((tecnico) => (
                <Option
                  key={tecnico.id}
                  value={tecnico.id}
                  label={tecnico.name}
                >
                  {`${tecnico.name} | ${tecnico.totalOS}`}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label='Previsão de atendimento' name='previsaoAtendimento'>
            <DatePicker size='large' style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item label='Previsão de conclusão' name='previsaoConclusao'>
            <DatePicker size='large' style={{ width: '100%' }} />
          </Form.Item>
          {ordem?.status === 'finalizado' ? (
            <Form.Item label='Data de finalização' name='endDate'>
              <DatePicker size='large' style={{ width: '100%' }} />
            </Form.Item>
          ) : null}
          <Divider style={{ borderColor: '#aaa' }} />
          <div style={{ display: 'flex' }}>
            <Enviar
              type='primary'
              htmlType='submit'
              style={{ flex: 1 }}
              size='large'
            >
              {drawer === 'insert' ? 'Adicionar' : 'Atualizar'}
            </Enviar>
            <Cancelar danger onClick={onClose} style={{ flex: 1 }} size='large'>
              Cancelar
            </Cancelar>
          </div>
        </Form>
      </Spin>
    </Drawer>
  )
}

const SectorDrawer = ({ onClose, localId, visible }) => {
  const [form] = Form.useForm()

  const handleCloseModal = (refresh) => {
    onClose(refresh)
    form.resetFields()
  }

  return (
    <Drawer
      visible={visible === 'sector'}
      onClose={() => handleCloseModal()}
      width={550}
      title='Adicionar Unidade'
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={(value) => {
          const data = { name: value.name, localId }

          api
            .post('/setor', data)
            .then(() => {
              message.success('Cadastro realizado com sucesso!')
              handleCloseModal(true)
            })
            .catch((e) => {
              if (e.response.data.errors) {
                e.response.data.errors.forEach((info) => {
                  message.error(info.message)
                })
              } else {
                message.error(e.response.data.error)
              }
            })
        }}
      >
        <Form.Item
          label='Unidade'
          name='name'
          rules={[
            {
              required: true,
              message: 'Por favor, o unidade deve ser informado',
            },
          ]}
        >
          <Input size='large' />
        </Form.Item>
        <Divider style={{ borderColor: '#aaa' }} />
        <div style={{ display: 'flex' }}>
          <Enviar
            type='primary'
            htmlType='submit'
            style={{ flex: 1 }}
            size='large'
          >
            Adicionar
          </Enviar>
          <Cancelar
            danger
            onClick={() => handleCloseModal()}
            style={{ flex: 1 }}
            size='large'
          >
            Cancelar
          </Cancelar>
        </div>
      </Form>
    </Drawer>
  )
}

const UnitDrawer = ({ onClose, setorId, visible }) => {
  const [form] = Form.useForm()

  const handleCloseModal = (refresh) => {
    onClose(refresh)
    form.resetFields()
  }

  return (
    <Drawer
      visible={visible === 'unit'}
      onClose={() => handleCloseModal()}
      width={550}
      title='Adicionar Unidade'
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={(value) => {
          const data = { ...value, setorId }
          api
            .post('/unidade', data)
            .then(() => {
              message.success('Cadastro realizado com sucesso!')
              handleCloseModal(true)
            })
            .catch((e) => {
              if (e.response.data.errors) {
                e.response.data.errors.forEach((info) => {
                  message.error(info.message)
                })
              } else {
                message.error(e.response.data.error)
              }
            })
        }}
      >
        <Form.Item
          label='Setor'
          name='name'
          rules={[
            {
              required: true,
              message: 'Por favor, o setor deve ser informado',
            },
          ]}
        >
          <Input size='large' />
        </Form.Item>
        <Divider style={{ borderColor: '#aaa' }} />
        <div style={{ display: 'flex' }}>
          <Enviar
            type='primary'
            htmlType='submit'
            style={{ flex: 1 }}
            size='large'
          >
            Adicionar
          </Enviar>
          <Cancelar
            danger
            onClick={() => handleCloseModal()}
            style={{ flex: 1 }}
            size='large'
          >
            Cancelar
          </Cancelar>
        </div>
      </Form>
    </Drawer>
  )
}
