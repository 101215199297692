/* eslint-disable react/display-name */
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  Modal,
  Table,
  message,
  Typography,
  Image,
  Space,
  Tooltip,
  Button,
  Empty,
  Tag,
  Drawer,
  Spin,
  Form,
  Select,
  Input,
  Popconfirm,
} from 'antd'
import {
  DeleteOutlined,
  DollarOutlined,
  EditOutlined,
  FileImageOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import api from '../../../services/api'
import { Container, PageTitle } from '../../../components'
import { Cancelar, Enviar } from 'components/Buttons'
import Highlighter from 'react-highlight-words'
import { formatarMoeda } from 'services/utils'
import NumberFormat from 'react-number-format'

const { Column } = Table
const { Text, Paragraph } = Typography

function Servico() {
  const { serviceId, osId } = useParams()
  const history = useHistory()
  const [form] = Form.useForm()
  const [items, setitems] = useState([])
  const [item, setItem] = useState({})
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [localItems, setLocalItems] = useState([])
  const [orderService, setOrderService] = useState({})
  const [visible, setVisible] = useState(false)
  const [visiblePrice, setVisiblePrice] = useState(false)
  const [visibleAddItem, setVisibleAddItem] = useState(false)
  const [servicoItem, setServicoItem] = useState({})
  const [selectedItem, setSelectedItem] = useState({})
  const [load, setLoad] = useState(false)

  useEffect(() => {
    loadServicos()
    getOrderService()
  }, [load])

  async function loadServicos() {
    setLoading(true)
    await api
      .get(`/ordem_servico/listar_itens/?servicoId=${serviceId}`)
      .then(({ data }) => {
        setitems(data)
      })
      .catch(() => {
        message.error('Erro ao carregar os dados, atualize a pagina')
        setLoading(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  useEffect(() => {
    getItens()
  }, [orderService])

  async function getItens() {
    setLoading(true)
    if (orderService.contractId !== undefined) {
      await api
        .get(`/ordem_servico/itens/mobile/`, {
          params: {
            contractId: orderService.contractId,
            localId: orderService.localId,
            typeServiceId: orderService.typeServiceId,
            equipmentTypeId: orderService.equipmentTypeId,
          },
        })
        .then(({ data }) => {
          setLocalItems(data)
        })
        .finally(() => setLoading(false))
    }
  }

  async function getOrderService() {
    setLoading(true)
    await api
      .get(`/order-service/${osId}`)
      .then(({ data }) => {
        setOrderService(data)
      })
      .finally(() => setLoading(false))
  }

  function onCloseDrawer() {
    setVisible(false)
    setServicoItem({})
    setSelectedItem({})
    form.resetFields()
  }

  function onCloseDrawerPrice() {
    setVisiblePrice(false)
    setServicoItem({})
    setSelectedItem({})
    form.resetFields()
  }

  function onCloseDrawerAddItem() {
    setVisibleAddItem(false)
    setServicoItem({})
    setSelectedItem({})
    form.resetFields()
  }

  async function onSubmit(value) {
    await api
      .post(`/ordem_servico/servico/change-item/${serviceId}`, {
        servicoItemId: servicoItem.id,
        localId: orderService.localId,
        itemLocalId: Number(value.itemId),
        quantity: value.quantity ? value.quantity : 1,
      })
      .then(() => {
        setLoad(!load)
      })
      .finally(() => {
        onCloseDrawer()
      })
  }

  async function onSubmitAddPrice(value) {
    await api
      .put(`/ordem_servico/servico/addservice/${serviceId}`, {
        itemLocalId: Number(value.itemId),
        quantity: value.quantity ? value.quantity : 1,
      })
      .then(() => {
        setLoad(!load)
      })
      .finally(() => {
        onCloseDrawer()
      })
  }

  async function onSubmitPrice(value) {
    console.log(value.itemPrice)
    await api
      .post(`/ordem_servico/servico/change-item-price/${serviceId}`, {
        servicoItemId: servicoItem.id,
        itemPrice: Number(value.itemPrice),
      })
      .then(() => {
        setLoad(!load)
      })
      .finally(() => {
        onCloseDrawerPrice()
      })
  }

  async function handleDeleteItem(servicoItemId) {
    await api
      .post(`/ordem_servico/servico/delete-item/${serviceId}`, {
        servicoItemId: servicoItemId,
        localId: orderService.localId,
      })
      .finally(() => {
        setLoad(!load)
      })
  }

  const filteredItems = localItems.filter((localItem) => {
    return !items.find(
      (serviceItem) => localItem.item.id === serviceItem.item.item.id
    )
  })

  const searchInput = useRef(null)

  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          size='large'
          ref={searchInput}
          placeholder={`Procurar por ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}
          >
            Procurar
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size='small'
            style={{
              width: 90,
            }}
          >
            Limpar
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({
                closeDropdown: false,
              })
              setSearchText(selectedKeys[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filtrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : '#777',
        }}
      />
    ),
    filterMode: 'tree',
    onFilter: (value, record) =>
      record?.item?.item?.description
        ?.toString()
        ?.toLowerCase()
        ?.includes(value?.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text, record) => {
      return searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={
            record.item.item.description
              ? record.item.item.description.toString()
              : ''
          }
        />
      ) : (
        record.item.item.description
      )
    },
  })

  return (
    <Container>
      <PageTitle
        title={`Itens do Serviço - ${serviceId}`}
        onBack={() => history.goBack()}
        extra={[
          <Button
            type='primary'
            size='large'
            icon={<PlusOutlined />}
            onClick={() => {
              setVisibleAddItem(true)
            }}
            key='1'
          >
            Adicionar Item
          </Button>,
        ]}
      />
      <Table
        dataSource={items}
        loading={loading}
        rowKey={(record) => record.id}
        size='middle'
        style={{ margin: 20 }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<span>Não há registro aqui</span>}
            />
          ),
        }}
      >
        <Column
          title='ID'
          dataIndex='id'
          key='id'
          width={'60px'}
          render={(_, record) => <Tag>{record?.id}</Tag>}
        />
        <Column
          title='Numero'
          key='numero'
          width={'60px'}
          render={(_, record) => {
            return record?.item?.number
          }}
        />
        <Column
          title='Descrição'
          dataIndex='description'
          key='description'
          render={(_, record) => <Text>{record.item?.item.description}</Text>}
          filterSearch={true}
          {...getColumnSearchProps('description')}
        />
        <Column
          title='Preço do Item'
          dataIndex='itemPrice'
          key='itemPrice'
          render={(_, record) => (
            <Text>
              {record.itemPrice
                ? formatarMoeda(record.itemPrice)
                : 'Não Informado'}
            </Text>
          )}
        />
        <Column
          key='actions'
          width={'200px'}
          title='Ações'
          render={(_, record) => (
            <Space>
              <Tooltip placement='top' title='Fotos'>
                <Button
                  icon={<FileImageOutlined />}
                  onClick={() => {
                    history.push(`item/${record.id}`)
                  }}
                />
              </Tooltip>
              <Tooltip placement='top' title='Visualizar'>
                <Button
                  icon={<SearchOutlined />}
                  onClick={() => {
                    setOpen(true)
                    setItem(record)
                    console.log(record)
                  }}
                />
              </Tooltip>
              <Tooltip placement='top' title='Editar Preço'>
                <Button
                  icon={<DollarOutlined />}
                  onClick={() => {
                    setServicoItem(record)
                    form.setFieldsValue({ itemPrice: Number(record.itemPrice) })
                    setVisiblePrice(true)
                  }}
                />
              </Tooltip>

              <Tooltip placement='top' title='Editar Item'>
                <Button
                  icon={<EditOutlined />}
                  onClick={() => {
                    setVisible(true)
                    setServicoItem(record)
                  }}
                />
              </Tooltip>
              <Popconfirm
                title='Você tem certeza que quer deletar esse item?'
                placement='rightTop'
                onConfirm={() => handleDeleteItem(record.id)}
                okText='Sim'
                cancelText='Não'
              >
                <Tooltip placement='top' title='Deletar Item'>
                  <Button icon={<DeleteOutlined />} danger />
                </Tooltip>
              </Popconfirm>
            </Space>
          )}
        />
      </Table>
      <Modal
        title='Serviço realizado'
        visible={open}
        onCancel={() => setOpen(false)}
        onOk={() => setOpen(false)}
        footer={null}
      >
        {item && (
          <>
            <Text strong>Item</Text>
            <Paragraph>{item?.item?.item?.description}</Paragraph>
            <Text strong>Fotos do Atendimento</Text>
            <Space direction='horizontal' style={{ width: '100%' }}>
              <Image.PreviewGroup>
                {item?.fotos?.map((foto) => {
                  return (
                    <Image
                      src={foto.photo}
                      width={150}
                      height={150}
                      key={foto.id}
                      style={{ objectFit: 'cover' }}
                    />
                  )
                })}
              </Image.PreviewGroup>
            </Space>
          </>
        )}
      </Modal>
      <Drawer
        visible={visiblePrice}
        onClose={onCloseDrawerPrice}
        width={550}
        title='Trocar Item'
      >
        <Spin spinning={loading}>
          <Form form={form} layout='vertical' onFinish={onSubmitPrice}>
            <Form.Item
              label='Preço'
              name='itemPrice'
              trigger='onValueChange'
              rules={[
                {
                  required: true,
                  message: 'Por favor, o Preço deve ser informado',
                },
              ]}
            >
              <NumberFormat
                style={{ padding: '8px' }}
                thousandSeparator='.'
                decimalSeparator=','
                prefix='R$'
                decimalScale={2}
                className='ant-input'
                fixedDecimalScale
                onValueChange={(value) =>
                  form.setFieldsValue({ itemPrice: value.floatValue })
                }
              />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 7 }}>
              <Enviar type='primary' htmlType='submit' size='large'>
                Cadastrar
              </Enviar>
              <Cancelar danger size='large' onClick={onCloseDrawer}>
                Cancelar
              </Cancelar>
            </Form.Item>
          </Form>
        </Spin>
      </Drawer>
      <Drawer
        visible={visibleAddItem}
        onClose={onCloseDrawerAddItem}
        width={550}
        title='Adicionar Item'
      >
        <Spin spinning={loading}>
          <Form form={form} layout='vertical' onFinish={onSubmitAddPrice}>
            <Form.Item
              label='Item'
              name='itemId'
              rules={[
                {
                  required: true,
                  message: 'Por favor, o Item deve ser informado',
                },
              ]}
            >
              {filteredItems && (
                <Select
                  size='large'
                  showSearch
                  removeIcon
                  allowClear
                  optionFilterProp='children'
                  onSelect={(e) => {
                    const getItem = filteredItems.find((item) => item.id === +e)
                    setSelectedItem(getItem)
                  }}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {filteredItems.map((item) => {
                    return (
                      <Select.Option key={item.id}>
                        {`${item.number} - ${item.item.description}`}
                      </Select.Option>
                    )
                  })}
                </Select>
              )}
            </Form.Item>
            {selectedItem?.item?.isConsumible && (
              <Form.Item
                label='Quantidade'
                name='quantity'
                rules={[
                  {
                    required: true,
                    message: 'Por favor, a Quantidade deve ser informada',
                  },
                ]}
              >
                <Input
                  size='large'
                  type={'number'}
                  min={1}
                  defaultValue={servicoItem.quantity}
                />
              </Form.Item>
            )}
            <Form.Item wrapperCol={{ offset: 7 }}>
              <Enviar type='primary' htmlType='submit' size='large'>
                Cadastrar
              </Enviar>
              <Cancelar danger size='large' onClick={onCloseDrawer}>
                Cancelar
              </Cancelar>
            </Form.Item>
          </Form>
        </Spin>
      </Drawer>
      <Drawer
        visible={visible}
        onClose={onCloseDrawer}
        width={550}
        title='Trocar Item'
      >
        <Spin spinning={loading}>
          <Form form={form} layout='vertical' onFinish={onSubmit}>
            <Form.Item
              label='Item'
              name='itemId'
              rules={[
                {
                  required: true,
                  message: 'Por favor, o Item deve ser informado',
                },
              ]}
            >
              {filteredItems && (
                <Select
                  size='large'
                  showSearch
                  removeIcon
                  allowClear
                  optionFilterProp='children'
                  onSelect={(e) => {
                    const getItem = filteredItems.find((item) => item.id === +e)
                    setSelectedItem(getItem)
                  }}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {filteredItems.map((item) => {
                    return (
                      <Select.Option key={item.id}>
                        {`${item.number} - ${item.item.description}`}
                      </Select.Option>
                    )
                  })}
                </Select>
              )}
            </Form.Item>
            {selectedItem?.item?.isConsumible && (
              <Form.Item
                label='Quantidade'
                name='quantity'
                rules={[
                  {
                    required: true,
                    message: 'Por favor, a Quantidade deve ser informada',
                  },
                ]}
              >
                <Input
                  size='large'
                  type={'number'}
                  min={1}
                  defaultValue={servicoItem.quantity}
                />
              </Form.Item>
            )}
            <Form.Item wrapperCol={{ offset: 7 }}>
              <Enviar type='primary' htmlType='submit' size='large'>
                Cadastrar
              </Enviar>
              <Cancelar danger size='large' onClick={onCloseDrawer}>
                Cancelar
              </Cancelar>
            </Form.Item>
          </Form>
        </Spin>
      </Drawer>
    </Container>
  )
}

export default Servico
