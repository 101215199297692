import React, { useContext, useEffect, useState } from 'react'
import {
  Skeleton,
  message,
  Table,
  Button,
  Drawer,
  Form,
  Spin,
  Select,
  Menu,
  Dropdown,
  Divider,
} from 'antd'
import {
  FilterOutlined,
  DownloadOutlined,
  DownOutlined,
  SettingOutlined,
} from '@ant-design/icons'
import api, { pdfUrl } from '../../services/api'
import { Container, PageTitle } from '../../components'
import { Enviar, Cancelar } from '../../components/Buttons'
import { Helmet } from 'react-helmet'
import DatePicker from '../../components/DatePicker'
import AuthContext from 'contexts/auth'
import axios from 'axios'
import locale from 'antd/es/date-picker/locale/pt_BR'
const { RangePicker } = DatePicker

const { Column } = Table

const statusFilter = [
  { label: 'Vigente', value: 'vigente' },
  { label: 'Aditivado', value: 'aditivado' },
  { label: 'Cancelado', value: 'cancelado' },
  { label: 'Vencido', value: 'vencido' },
  { label: 'Encerrado', value: 'encerrado' },
]

const dateFormat = 'DD/MM/YYYY'

function RelatorioSaldosContratos() {
  const { user } = useContext(AuthContext)
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [filtrados, setFiltrados] = useState([])
  const [clients, setClients] = useState([])
  const [contracts, setContracts] = useState([])

  const [date, setDate] = useState([])

  useEffect(() => {
    loadClients()
    loadContracts()
  }, [])

  const loadClients = async (attendantId) => {
    await api
      .get(`/client`, {
        params: {
          attendantId,
        },
      })
      .then(({ data }) => {
        setClients(data)
      })
      .catch(() => {
        message.error('Erro ao carregar os dados.')
      })
  }

  const loadContracts = async (clientId) => {
    await api
      .get(`/contract/os`, {
        params: {
          clientId,
        },
      })
      .then(({ data }) => {
        setContracts(data)
      })
      .catch(() => {
        message.error('Erro ao carregar os dados.')
      })
  }

  const onClose = () => {
    setVisible(false)
  }

  const exportTable = async () => {
    setLoading(true)
    const nameFile = `Saldo-de-Contrato`
    await axios
      .post(
        `${pdfUrl}contract-balance-report/`,
        { user, data: filtrados },
        {
          responseType: 'blob',
        }
      )
      .then((response) => {
        // const newWin = window.open(
        // 'about:blank',
        // 'Axios data',
        // 'width=841,height=800'
        // )
        // newWin.document.write(response.data)
        //
        message.success({
          content: 'Baixando PDF...',
        })
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${nameFile}.pdf`)
        document.body.appendChild(link)
        link.click()
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const exportRow = async (record) => {
    setLoading(true)
    const nameFile = record.nomeFantasia
    await axios
      .post(
        `${pdfUrl}contract-balance-report/`,
        { user, data: [record] },
        {
          responseType: 'blob',
        }
      )
      .then((response) => {
        message.success({
          content: 'Baixando PDF...',
        })
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${nameFile}.pdf`)
        document.body.appendChild(link)
        link.click()
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onSubmit = async (value) => {
    setLoading(true)
    api
      .get(`reports/contract-balance/`, {
        params: {
          clientId: value.clientId,
          contractId: value.contractId,
          status: value.status,
          date: value.date
            ?.map((date) => {
              return date.toISOString().slice(0, 10)
            })
            .toString(),
        },
      })
      .then(({ data }) => {
        setFiltrados(data)
      })
      .catch(() => {
        message.error('Algo inesperado ocorreu')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const menuRow = (record) => (
    <Menu>
      <Menu.Item key='1'>
        <Button
          icon={<DownloadOutlined />}
          onClick={() => {
            exportRow(record)
          }}
        >
          Baixar Relatório
        </Button>
      </Menu.Item>
    </Menu>
  )

  return (
    <Container>
      <Helmet>
        <title>Arcke | Relatório - Faturamento</title>
      </Helmet>
      <Skeleton loading={false}>
        <PageTitle
          title='Relatório de Saldo de Contrato'
          extra={[
            <Dropdown
              key='1'
              trigger={['click']}
              disabled={false || filtrados.length < 1}
              overlay={
                <Menu>
                  <Menu.Item key='1'>
                    <Button icon={<DownloadOutlined />} onClick={exportTable}>
                      Baixar Tabela - PDF
                    </Button>
                  </Menu.Item>
                </Menu>
              }
              placement='bottomCenter'
            >
              <Button size='large'>
                Exportar Tabela <DownOutlined />
              </Button>
            </Dropdown>,
            <Button
              icon={<FilterOutlined />}
              type='primary'
              size='large'
              onClick={() => {
                setVisible(true)
              }}
              key='2'
            >
              Filtrar
            </Button>,
          ]}
        />
        <Table
          dataSource={filtrados}
          loading={loading}
          rowKey={(record) => record.id}
          size='middle'
          style={{ margin: 20 }}
        >
          <Column
            title='Nome'
            dataIndex='name'
            key='name'
            render={(_, record) => record.nomeFantasia}
          />
          <Column
            title='Contratos'
            dataIndex='contratos'
            key='contratos'
            render={(_, record) => record.contracts.length}
          />
          {/* <Column
            title='Total'
            dataIndex='total'
            key='total'
            render={(_, record) => formatarMoeda(record.total)}
          /> */}

          <Column
            title='Ações'
            dataIndex='actions'
            key='actions'
            render={(_, record) => (
              <Dropdown
                trigger={['click']}
                key='1'
                overlay={menuRow(record)}
                placement='bottomCenter'
              >
                <Button icon={<SettingOutlined />}>Ações</Button>
              </Dropdown>
            )}
          />
        </Table>
        <Drawer
          visible={visible}
          onClose={onClose}
          width={550}
          title='Filtro de Relatório'
        >
          <Spin spinning={loading}>
            <Form form={form} layout='vertical' onFinish={onSubmit}>
              <Form.Item label='Cliente'>
                <Form.Item
                  name='clientId'
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                  noStyle
                >
                  <Select
                    size='large'
                    showSearch
                    placeholder='Selecione...'
                    optionLabelProp='label'
                    optionFilterProp='children'
                    allowClear
                    onChange={loadContracts}
                  >
                    {clients.map((client) => (
                      <Select.Option
                        key={client.id}
                        value={client.id}
                        label={client.nomeFantasia}
                      >
                        {client.nomeFantasia}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form.Item>
              <Form.Item label='Contrato'>
                <Form.Item
                  name='contractId'
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                  noStyle
                >
                  <Select
                    size='large'
                    showSearch
                    placeholder='Selecione...'
                    optionLabelProp='label'
                    optionFilterProp='children'
                    allowClear
                  >
                    {contracts.map((contract) => (
                      <Select.Option
                        key={contract.id}
                        value={contract.id}
                        label={contract.name}
                      >
                        {contract.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form.Item>
              <Form.Item label='Status'>
                <Form.Item
                  name='status'
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                  noStyle
                >
                  <Select
                    size='large'
                    showSearch
                    placeholder='Selecione...'
                    optionLabelProp='label'
                    optionFilterProp='children'
                    allowClear
                  >
                    {statusFilter.map((status) => (
                      <Select.Option
                        key={status.value}
                        value={status.value}
                        label={status.label}
                      >
                        {status.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form.Item>
              <Form.Item label='Data de finalização' name='date'>
                <RangePicker
                  format={dateFormat}
                  locale={locale}
                  onChange={(e) => setDate(e)}
                  size='large'
                  style={{ minWidth: '100%' }}
                />
              </Form.Item>
              <Divider style={{ borderColor: '#aaa' }} />
              <div style={{ display: 'flex' }}>
                <Enviar
                  disabled={!(date?.length > 0)}
                  type='primary'
                  htmlType='submit'
                  size='large'
                  style={{ flex: 1 }}
                >
                  Gerar Relatório
                </Enviar>
                <Cancelar
                  danger
                  onClick={onClose}
                  size='large'
                  style={{ flex: 1 }}
                >
                  Cancelar
                </Cancelar>
              </div>
            </Form>
          </Spin>
        </Drawer>
      </Skeleton>
    </Container>
  )
}

export { RelatorioSaldosContratos }
