import styled from 'styled-components'
import { PageHeader, Table as TableAnt } from 'antd'
import { MinusCircleOutlined } from '@ant-design/icons'

export { default as Header } from './Header'
export { default as SideBar } from './SideBar'

export const Container = styled.div`
  flex: 1;
`

export const PageTitle = styled(PageHeader)`
  border: 1px solid rgb(235, 237, 240);
  background-color: #fff;
  padding: 15px 10px;
`
export const Menos = styled(MinusCircleOutlined)`
  margin: 8px 8px 7px -27px;
  cursor: pointer;
  position: absolute;
  top: 4px;
  font-size: 16px;
  color: #bbb;
  transition: all 0.3s;
  &:hover {
    color: #1c7e94;
  }
`

export const Table = styled(TableAnt)``

export const CardContent = styled.div`
  padding: 15px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`
