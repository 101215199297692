/* eslint-disable react/display-name */
import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  message,
  Table,
  Space,
  Button,
  Popconfirm,
  Tooltip,
  Empty,
  Input,
} from 'antd'
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined,
  ClearOutlined,
} from '@ant-design/icons'
import api from 'services/api'
import { Container, PageTitle } from 'components'
import DrawerContext from 'contexts/DrawerContext'
import { DrawerItems } from 'components/Drawer/DrawerItems'
import { Helmet } from 'react-helmet'
import Highlighter from 'react-highlight-words'

const { Column } = Table

function Items() {
  const { load, setLoad, setMode, setOpen } = useContext(DrawerContext)
  const [loading, setLoading] = useState(false)
  const [itens, setItens] = useState([])
  const [item, setItem] = useState(null)

  useEffect(() => {
    async function loadItems() {
      setLoading(true)
      try {
        await api.get('/item').then(({ data }) => {
          setItens(data)
        })
        setLoading(false)
      } finally {
        setLoading(false)
      }
      console.log(load)
    }
    loadItems()
  }, [load])

  const searchInput = useRef(null)

  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8, display: 'flex', alignItems: 'center' }}>
        <Input
          ref={searchInput}
          placeholder={`Pesquisar descrição`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ display: 'block' }}
        />

        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          style={{ marginLeft: 6, marginRight: 6, minWidth: 32 }}
        />
        <Button
          icon={<ClearOutlined />}
          onClick={() => clearFilters && handleReset(clearFilters)}
          style={{ minWidth: 32 }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : '#777',
          fontSize: 15,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const routes = [
    {
      path: '/',
      breadcrumbName: 'Cadastros',
    },
  ]

  return (
    <Container>
      <Helmet>
        <title>Arcke | Itens</title>
      </Helmet>
      <PageTitle
        title='Itens de Contratos'
        breadcrumb={{ routes }}
        breadcrumbRender={(props, breadcrumb) => (
          <div style={{ marginBottom: -4 }}>{breadcrumb}</div>
        )}
        extra={[
          <Button
            type='primary'
            size='large'
            icon={<PlusOutlined />}
            onClick={async () => {
              setMode('insert')
              setOpen(true)
            }}
            key='1'
          >
            Cadastrar Item de Contrato
          </Button>,
        ]}
      />
      <Table
        dataSource={itens}
        loading={loading}
        rowKey={(record) => record.id}
        size='middle'
        style={{ margin: 20 }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <>
                  <span>Não há registro aqui</span>
                </>
              }
            >
              <Button
                icon={<PlusOutlined />}
                key='insert'
                type='primary'
                size='large'
                onClick={() => {
                  setMode('insert')
                  setOpen(true)
                }}
              >
                Cadastrar
              </Button>
            </Empty>
          ),
        }}
      >
        <Column title='ID' dataIndex='id' key='id' />
        <Column
          title='Descrição'
          dataIndex='description'
          key='description'
          filterMode='tree'
          filterSearch={true}
          {...getColumnSearchProps('description')}
        />
        <Column
          title='Ações'
          dataIndex='actions'
          width={20}
          key='actions'
          render={(_, record) => (
            <Space>
              <Tooltip title='Editar' placement='top'>
                <Button
                  icon={<EditOutlined />}
                  onClick={async () => {
                    setItem(record)
                    setMode('update')
                    setOpen(true)
                  }}
                />
              </Tooltip>
              <Tooltip title='Excluir Item' placement='top'>
                <Popconfirm
                  title='Deseja realmente excluir este item?'
                  okText='Sim'
                  cancelText='Não'
                  onConfirm={async () => {
                    await api
                      .delete(`/item/${record.id}`)
                      .then(() => {
                        message.success('Item excluido com sucesso')
                        setLoad(true)
                      })
                      .catch(() => {
                        message.error('Erro ao excluir item, tente novamente')
                      })
                  }}
                >
                  <Button
                    icon={<DeleteOutlined />}
                    style={{ color: '#e80909' }}
                  />
                </Popconfirm>
              </Tooltip>
            </Space>
          )}
        />
      </Table>
      <DrawerItems item={item} />
    </Container>
  )
}

export default Items
