/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react'
import { Helmet } from 'react-helmet'
import {
  Button,
  Typography,
  Spin,
  Card,
  Row,
  Form,
  message,
  Divider,
  Input,
  Upload,
  Select,
} from 'antd'

import { Container, PageTitle } from '../../components'
import api from '../../services/api'
import AuthContext, { TOKEN_API } from 'contexts/auth'
import { UploadOutlined } from '@ant-design/icons'

const style = {
  width: 'calc(50% - 20px)',
  marginRight: 10,
  marginLeft: 10,
}

const styleTwo = {
  width: 'calc(33% - 20px)',
  marginRight: 10,
  marginLeft: 10,
}

const styleMargin = {
  marginRight: 10,
  marginLeft: 10,
}

const states = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO',
]

function Organization() {
  const { user } = useContext(AuthContext)
  const [load, setLoad] = useState(true)
  const [mode, setMode] = useState('')
  const [loading, setLoading] = useState(false)
  const [organization, setOrganization] = useState({})
  const [upload, setUpload] = useState(true)
  const [image, setImage] = useState([])
  const [file, setFile] = useState([])
  const token = localStorage.getItem(TOKEN_API)

  const [form] = Form.useForm()
  const [formAddress] = Form.useForm()

  useEffect(() => {
    async function loadData() {
      setLoading(true)
      try {
        await api.get('/organization').then(({ data }) => {
          setOrganization(data)
          if (data.logo) {
            setImage([
              {
                uid: '2',
                name: 'Logo',
                status: 'done',
                url: data.logo,
              },
            ])
          }
          if (data.etiqueta) {
            setFile([
              {
                uid: '1',
                name: 'Etiqueta',
                status: 'done',
                url: data.etiqueta,
              },
            ])
          }
          if (!data.address) {
            formAddress.resetFields()
          }
          formAddress.setFieldsValue(data.address)
          form.setFieldsValue(data)
        })
      } catch (error) {
        console.log(error)
        message.error('Algo de inesperado ocorreu.')
      }
      setLoading(false)
    }
    loadData()
  }, [load])

  const toggleMode = (mode) => {
    mode === 'insert' ? setMode('') : setMode('insert')
  }

  const beforeUpload = (file) => {
    const validFormat = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!validFormat) {
      message.error('Envie somente arquivos do formato JPG/PNG')
      return false
    }
    return validFormat
  }

  function onBlurCep(ev) {
    const { value } = ev.target

    const cep = value?.replace(/[^0-9]/g, '')

    if (cep?.length !== 8) {
      return
    }

    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then((data) => {
        console.log(data)
        formAddress.setFieldsValue({
          estado: data.uf,
          cidade: data.localidade,
          bairro: data.bairro,
          rua: data.logradouro,
        })
      })
  }

  const onFinish = async () => {
    setLoading(true)
    try {
      const values = form.getFieldValue()
      const address = formAddress.getFieldValue()
      const data = { ...values, logo: image[0]?.url, etiqueta: file[0]?.url }

      api
        .put(`/organization/${organization.id}`, { ...data, address })
        .then(() => {
          setLoading(false)
          setOrganization({ ...data })
          message.success('Dados da Organização salvos com sucesso!')
          toggleMode(mode)
          setLoad(!load)
        })
    } catch (error) {
      if (error.response.data.errors) {
        error.response.data.errors.forEach((info) => {
          message.error(info.message)
        })
      } else {
        message.error(error.response.data.error)
      }
    }
    setLoading(false)
  }

  const props = {
    name: 'file',
    listType: 'picture',
    multiple: false,
    action: process.env.REACT_APP_API + `upload`,
    data: {
      pasta: 'logos',
      organization: user?.organization.bucket_name,
    },
    headers: {
      authorization: `Bearer ${token}`,
    },
    fileList: file,
    onRemove: ({ uid }) => {
      setFile(file.filter((fl) => fl.uid === uid))
    },
    onChange({ fileList }) {
      let newFileList = [...fileList]
      newFileList = newFileList?.map((file) => {
        if (file.response) {
          file.url = file.response.url
        }
        return file
      })
      setFile(newFileList)
    },
    accept: 'image/*',
  }

  return (
    <Container>
      <Helmet>
        <title>Arcke | Organização</title>
      </Helmet>
      <Spin spinning={loading}>
        <PageTitle
          title='Informações da Organização'
          extra={[
            <Button
              type='primary'
              disabled={mode !== 'insert'}
              size='large'
              onClick={onFinish}
              key='1'
            >
              Salvar
            </Button>,
            <Button
              type={mode !== 'insert' ? 'primary' : 'danger'}
              size='large'
              onClick={() => {
                toggleMode(mode)
                if (mode === 'insert') {
                  setLoad(!load)
                }
              }}
              key='2'
            >
              {mode !== 'insert' ? 'Editar Organização' : 'Cancelar'}
            </Button>,
          ]}
        />
        <Card style={{ margin: 20 }}>
          <Form form={form} layout='vertical'>
            <Row style={{ justifyContent: 'center' }}>
              <Form.Item
                label='Razão social'
                name='name'
                rules={[
                  {
                    required: true,
                    message: 'Por favor, o CEP deve ser informado',
                  },
                ]}
                style={style}
              >
                <Input disabled={mode !== 'insert'} size='large' />
              </Form.Item>
              <Form.Item
                label='Nome fantasia'
                name='nomeFantasia'
                rules={[
                  {
                    required: true,
                    message: 'Por favor, a Cidade deve ser informada',
                  },
                ]}
                style={style}
              >
                <Input disabled={mode !== 'insert'} size='large' />
              </Form.Item>
            </Row>
            <Row style={{ justifyContent: 'center' }}>
              <Form.Item
                label='Telefone'
                name='telefone'
                rules={[
                  {
                    required: true,
                    message: 'Por favor, o CEP deve ser informado',
                  },
                ]}
                style={style}
              >
                <Input disabled={mode !== 'insert'} size='large' />
              </Form.Item>
              <Form.Item
                label='Email'
                name='email'
                rules={[
                  {
                    required: true,
                    message: 'Por favor, a Cidade deve ser informada',
                  },
                ]}
                style={style}
              >
                <Input disabled={mode !== 'insert'} size='large' />
              </Form.Item>
            </Row>
            <Row style={{ justifyContent: 'space-between' }}>
              <Form.Item
                label='CNPJ'
                name='cnpj'
                rules={[
                  {
                    required: true,
                    message: 'Por favor, a Rua deve ser informada',
                  },
                ]}
                style={styleTwo}
              >
                <Input disabled={mode !== 'insert'} size='large' />
              </Form.Item>
              <Form.Item
                label='Inscrição estadual'
                name='inscricao'
                rules={[
                  {
                    required: true,
                    message: 'Por favor, o Numero deve ser informado',
                  },
                ]}
                style={styleTwo}
              >
                <Input disabled={mode !== 'insert'} size='large' />
              </Form.Item>
              <Form.Item
                label='Imposto sobre serviço'
                name='serviceTax'
                rules={[
                  {
                    required: false,
                    message: 'Por favor, o Numero deve ser informado',
                  },
                ]}
                style={styleTwo}
              >
                <Input
                  type='number'
                  disabled={mode !== 'insert'}
                  size='large'
                />
              </Form.Item>
            </Row>
            <Row style={{ justifyContent: 'space-between' }}>
              <Form.Item
                style={{
                  width: 'calc(50% - 20px)',
                  marginRight: 10,
                  marginLeft: 10,
                }}
                label='Logo'
              >
                <Upload
                  disabled={mode !== 'insert'}
                  fileList={image}
                  maxCount={1}
                  name='file'
                  data={{
                    pasta: 'logos',
                    organization: user?.organization.bucket_name,
                  }}
                  listType='picture'
                  action={process.env.REACT_APP_API + 'upload'}
                  headers={{ Authorization: `Bearer ${token}` }}
                  onRemove={() => setImage([])}
                  onChange={({ fileList }) => {
                    let newFileList = [...fileList]
                    newFileList = newFileList?.map((file) => {
                      if (file.response) {
                        file.url = file.response.url
                      }
                      return file
                    })
                    setImage(newFileList)
                  }}
                  beforeUpload={beforeUpload}
                  multiple={false}
                >
                  {image[0]?.url ? null : (
                    <Button
                      icon={<UploadOutlined />}
                      disabled={mode !== 'insert'}
                    >
                      Upload
                    </Button>
                  )}
                </Upload>
              </Form.Item>
              <Form.Item
                style={{
                  width: 'calc(50% - 20px)',
                  marginRight: 10,
                  marginLeft: 10,
                }}
                label='Etiqueta'
              >
                <Upload {...props} disabled={mode !== 'insert'}>
                  {file[0]?.url ? null : (
                    <Button
                      icon={<UploadOutlined />}
                      disabled={mode !== 'insert'}
                    >
                      Upload
                    </Button>
                  )}
                </Upload>
              </Form.Item>
            </Row>
          </Form>
        </Card>
        <Card style={{ margin: 20 }}>
          <Form
            form={formAddress}
            layout='vertical'
            onFinish={(value) => {
              const values = { ...value, organizationId: organization.id }
              setLoading(true)
              api
                .post(`/client/clientaddress`, values)
                .then(() => {
                  setLoading(false)
                  message.success('Endereço atualizado com sucesso!')
                })
                .catch((e) => {
                  if (e.response.data.errors) {
                    e.response.data.errors.forEach((info) => {
                      message.error(info.message)
                    })
                  } else {
                    message.error(e.response.data.error)
                  }
                  setLoading(false)
                })
            }}
          >
            <Row style={{ justifyContent: 'center' }}>
              <Form.Item
                label='CEP'
                name='cep'
                rules={[
                  {
                    required: true,
                    message: 'Por favor, o CEP deve ser informado',
                  },
                ]}
                style={{ width: 'calc(33% - 20px)', ...styleMargin }}
              >
                <Input
                  disabled={mode !== 'insert'}
                  onBlur={onBlurCep}
                  size='large'
                />
              </Form.Item>
              <Form.Item
                label='Estado'
                name='estado'
                rules={[
                  {
                    required: true,
                    message: 'Por favor, o Estado deve ser informado',
                  },
                ]}
                style={{ width: 'calc(33% - 20px)', ...styleMargin }}
              >
                <Select
                  disabled={mode !== 'insert'}
                  size='large'
                  placeholder='Selecione o Estado'
                >
                  {states.map((state) => (
                    <Select.Option key={state}>{state}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label='Cidade'
                name='cidade'
                rules={[
                  {
                    required: true,
                    message: 'Por favor, a Cidade deve ser informada',
                  },
                ]}
                style={{ width: 'calc(33% - 20px)', ...styleMargin }}
              >
                <Input disabled={mode !== 'insert'} size='large' />
              </Form.Item>
            </Row>
            <Row style={{ justifyContent: 'center' }}>
              <Form.Item
                label='Bairro'
                name='bairro'
                rules={[
                  {
                    required: true,
                    message: 'Por favor, o Bairro deve ser informado',
                  },
                ]}
                style={{ width: 'calc(40% - 30px)', ...styleMargin }}
              >
                <Input disabled={mode !== 'insert'} size='large' />
              </Form.Item>
              <Form.Item
                label='Rua'
                name='rua'
                rules={[
                  {
                    required: true,
                    message: 'Por favor, a Rua deve ser informada',
                  },
                ]}
                style={{ width: 'calc(40% - 30px)', ...styleMargin }}
              >
                <Input disabled={mode !== 'insert'} size='large' />
              </Form.Item>
              <Form.Item
                label='Numero'
                name='numero'
                rules={[
                  {
                    required: true,
                    message: 'Por favor, o Numero deve ser informado',
                  },
                ]}
                style={{ width: 'calc(20% - 20px)', ...styleMargin }}
              >
                <Input disabled={mode !== 'insert'} size='large' />
              </Form.Item>
            </Row>
            <Row style={{ justifyContent: 'center' }}>
              <Form.Item
                label='Complemento'
                name='complemento'
                style={{
                  width: 'calc(100% - 40px)',
                  marginLeft: 10,
                  marginRight: 10,
                }}
              >
                <Input disabled={mode !== 'insert'} size='large' />
              </Form.Item>
            </Row>
            <Divider />
          </Form>
        </Card>
      </Spin>
    </Container>
  )
}

export { Organization }
