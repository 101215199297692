import styled from 'styled-components'
import { Layout } from 'antd'

export const Container = styled(Layout)`
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
`

export const Content = styled(Layout)`
  width: 100%;
  transition-property: all;
  transition-duration: 0.3s;
  background-color: #f0f0f0;
`
