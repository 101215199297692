import React from 'react'
import { Switch } from 'react-router-dom'
import { Header } from 'components'
import ProtectedRoute from './protected'
import Home from 'pages/Home'
import Usuarios from 'pages/Usuarios'
import Contratos from 'pages/Contratos'
import Itens from 'pages/Contratos/Itens'
import Aditivos from 'pages/Contratos/AditivosItem'
import AditivosContrato from 'pages/Contratos/AditivosContrato'
import Items from 'pages/Items/index'
import Servicos from 'pages/OrdemServico/Servicos'
import OrdemServico from 'pages/OrdemServico'
import Marcas from 'pages/Marcas'
import { Container, Content } from './styles'
import 'antd/dist/antd.css'
import Dashboard from 'pages/Dashboard'
import Cargos from 'pages/Cargos/Cargos'

import Clientes from 'pages/Clientes/Clientes'
import Equipamentos from 'pages/Clientes/Equipamentos'
import Locais from 'pages/Clientes/Locais'
import Setores from 'pages/Clientes/Setores'
import Unidades from 'pages/Clientes/Unidades'
import TipoEquipamentos from 'pages/TipoEquipamentos'
import ItensDuplicados from 'pages/Contratos/ItensDuplicados'
import ItensDetalhados from 'pages/Contratos/ItensDetalhados'
import Relatorios from 'pages/Relatorios/Relatorios'
import TipoServico from 'pages/TipoServico'
import { Organization } from 'pages/Organização'
import RelatorioItemsAgrupados from 'pages/Relatorios/RelatorioItemsAgrupados'
import Pmocs from 'pages/Pmoc'
import Questions from 'pages/Questions'
import Servico from 'pages/OrdemServico/Servicos/servico'
import ItemPhotos from 'pages/OrdemServico/Servicos/fotos'
import Assistentes from 'pages/Usuarios/Assistentes'
import { RelatorioClientes } from 'pages/Relatorios/RelatoriosCliente'
import { Pecas } from 'pages/Pecas'
import { PecasOrdem } from 'pages/OrdemServico/Pecas'
import { RelatorioOrdens } from 'pages/Relatorios/RelatoriosOrdens'
import { RelatorioContratos } from 'pages/Relatorios/RelatoriosContratos'
import { RelatorioPecas } from 'pages/Relatorios/RelatoriosPecas'
import { UsuariosCliente } from 'pages/Clientes/Usuarios'
import { Logs } from 'pages/Logs'
import { RelatorioConsumoItems } from 'pages/Relatorios/RelatorioConsumo'
import { RelatorioEquipamentos } from 'pages/Relatorios/RelatoriosEquipamentos'
import PlanilhaAtendimentos from 'pages/Relatorios/PlanilhaAtendimentos'
import { Metas } from 'pages/Metas'
import { RelatorioSaldosContratos } from 'pages/Relatorios/RelatoriosSaldoContratos'
import { RelatoriosTempoContratos } from 'pages/Relatorios/RelatoriosTempoContratos'
import RelatorioItemsDetalhados from 'pages/Relatorios/RelatorioItemsDetalhados'
import { RelatorioLocais } from 'pages/Relatorios/RelatorioLocais'
import { HistoricoOrdem } from 'pages/OrdemServico/Historico'
import { RelatorioServicoEquipamentos } from 'pages/Relatorios/RelatorioServicoEquipamentos'

export default function App() {
  // const [collapse, setCollapse] = useState(false)
  return (
    <Container>
      {/* <SideBar collapse={collapse} setCollapse={setCollapse} /> */}
      <Content /* style={{ paddingLeft: collapse ? '80px' : '260px' }} */>
        <Header />
        <Switch>
          <ProtectedRoute path='/' exact component={Dashboard} />
          <ProtectedRoute path='/home' exact component={Home} />
          <ProtectedRoute path='/metas' exact component={Metas} />
          <ProtectedRoute path='/pmocs' exact component={Pmocs} />
          <ProtectedRoute path='/questions' exact component={Questions} />
          <ProtectedRoute path='/perfis' exact component={Cargos} />
          <ProtectedRoute path='/organizacao' exact component={Organization} />
          <ProtectedRoute path='/relatorios' exact component={Relatorios} />
          <ProtectedRoute
            path='/relatorio-pecas'
            exact
            component={RelatorioPecas}
          />
          <ProtectedRoute
            path='/relatorio-contratos'
            exact
            component={RelatorioContratos}
          />
          <ProtectedRoute
            path='/relatorio-saldos-contratos'
            exact
            component={RelatorioSaldosContratos}
          />
          <ProtectedRoute
            path='/relatorio-tempo-contratos'
            exact
            component={RelatoriosTempoContratos}
          />
          <ProtectedRoute
            path='/relatorio-ordens'
            exact
            component={RelatorioOrdens}
          />
          <ProtectedRoute
            path='/relatorio-items-agrupados'
            exact
            component={RelatorioItemsAgrupados}
          />
          <ProtectedRoute
            path='/relatorio-items-detalhados'
            exact
            component={RelatorioItemsDetalhados}
          />
          <ProtectedRoute
            path='/relatorio-consumo-items'
            exact
            component={RelatorioConsumoItems}
          />
          <ProtectedRoute
            path='/relatorio-clientes'
            exact
            component={RelatorioClientes}
          />
          <ProtectedRoute
            path='/relatorio-locais'
            exact
            component={RelatorioLocais}
          />
          <ProtectedRoute
            path='/relatorio-equipamentos'
            exact
            component={RelatorioEquipamentos}
          />
          <ProtectedRoute
            path='/relatorio-servicos-equipamentos'
            exact
            component={RelatorioServicoEquipamentos}
          />
          <ProtectedRoute
            path='/planilha-atendimentos'
            exact
            component={PlanilhaAtendimentos}
          />
          <ProtectedRoute path='/usuarios' exact component={Usuarios} />
          <ProtectedRoute path='/usuarios/:id' exact component={Assistentes} />
          <ProtectedRoute path='/contratos' exact component={Contratos} />
          <ProtectedRoute path='/os' exact component={OrdemServico} />
          <ProtectedRoute path='/dashboard' exact component={Dashboard} />
          <ProtectedRoute path='/marcas' exact component={Marcas} />
          <ProtectedRoute path='/pecas' exact component={Pecas} />
          <ProtectedRoute path='/tiposervico' exact component={TipoServico} />
          <ProtectedRoute
            path='/tipoequipamentos'
            exact
            component={TipoEquipamentos}
          />
          <ProtectedRoute path='/itens' exact component={Items} />
          <ProtectedRoute path='/contratos/:id/itens' exact component={Itens} />
          <ProtectedRoute
            path='/contratos/:id/itens/:item/detalhe'
            exact
            component={ItensDetalhados}
          />
          <ProtectedRoute
            path='/contratos/:id/aditivoscontrato'
            exact
            component={AditivosContrato}
          />
          <ProtectedRoute
            path='/contratos/:id/itens/:itemId/aditivos'
            exact
            component={Aditivos}
          />
          <ProtectedRoute
            path='/contratos/:id/itens/:localId/locais'
            exact
            component={ItensDuplicados}
          />
          <ProtectedRoute
            path='/contratos/:contractId/os'
            exact
            component={OrdemServico}
          />
          <ProtectedRoute
            path='/service-order/:osId/services/'
            exact
            component={Servicos}
          />
          <ProtectedRoute
            path='/service-order/:osId/historico/'
            exact
            component={HistoricoOrdem}
          />
          <ProtectedRoute
            path='/service-order/:osId/pecas/'
            exact
            component={PecasOrdem}
          />
          <ProtectedRoute
            path='/service-order/:osId/services/:serviceId'
            exact
            component={Servico}
          />
          <ProtectedRoute
            path='/service-order/:osId/services/:serviceId/item/:itemId'
            exact
            component={ItemPhotos}
          />
          <ProtectedRoute path='/clientes' exact component={Clientes} />
          <ProtectedRoute
            path='/clientes/:clientId/usuarios'
            exact
            component={UsuariosCliente}
          />
          <ProtectedRoute
            path='/clientes/:clientId/equipamentos'
            exact
            component={Equipamentos}
          />
          <ProtectedRoute
            path='/clientes/:id/locais'
            exact
            component={Locais}
          />
          <ProtectedRoute
            path='/clientes/:id/locais/:localId/setores'
            exact
            component={Setores}
          />
          <ProtectedRoute
            path='/clientes/:id/locais/:localId/setores/:setorId/unidades'
            exact
            component={Unidades}
          />
          <ProtectedRoute path='/logs' exact component={Logs} />
        </Switch>
      </Content>
    </Container>
  )
}
