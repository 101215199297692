import styled from 'styled-components'

export const Container = styled.div`
  height: 99.9vh;
  background-color: #001529;
  /* box-shadow: 3px 0px 3px -3px #000000; */
`

export const Box = styled.div`
  height: 72vh;
  overflow-x: hidden;
  overflow-y: auto;
`

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: #fff;
`

export const Logo = styled.img`
  background-color: #fff;
  max-width: 80%;
  height: auto;
`

export const Title = styled.p``

export const NavigatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #001529;
  & a {
    text-decoration: none;
  }
`

export const MenuItem = styled.div`
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: rgba(255, 255, 255, 0.65);
  padding-left: 15px;
  &:hover {
    background-color: #1890ff;
    color: #ffffff;
  }
`

export const Label = styled.p`
  margin-left: 15px;
`
