import React, { useEffect, useRef, useState } from 'react'
import { message, Table, Button, Spin, Empty, Tag, Select, Space } from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import api from '../../services/api'
import { Container, PageTitle } from '../../components'
import { Helmet } from 'react-helmet'
import { format, parseISO } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'
import DatePicker from '../../components/DatePicker'
import locale from 'antd/es/date-picker/locale/pt_BR'

const { Column } = Table
const { RangePicker } = DatePicker

const methodsColors = {
  POST: { name: 'CRIAR', color: '#2eb772' },
  PUT: { name: 'ATUALIZAR', color: '#a161db' },
  DELETE: { name: 'DELETAR', color: '#ef1b1b' },
}

const dateFormat = 'DD/MM/YYYY'

const Logs = () => {
  const [loading, setLoading] = useState(false)
  const [load, setLoad] = useState(true)
  const [data, setData] = useState([])
  const [meta, setMeta] = useState({ current_page: 1, per_page: 10 })
  const [users, setUsers] = useState([])

  const [userFilter, setUserFilter] = useState(null)
  const [methodsFilter, setMethodsFilter] = useState(null)
  const [dateFilter, setDateFilter] = useState(null)
  const methodsRef = useRef(null)

  useEffect(() => {
    loadData()
  }, [load])

  const loadData = async () => {
    setLoading(true)
    await api
      .get(`/logs/?page=${meta.current_page}&pageSize=${meta.per_page}`, {
        params: {
          methods: methodsFilter,
          userId: userFilter,
          date: dateFilter,
        },
      })
      .then(({ data }) => {
        setData(data.data)
        setMeta(data.meta)
      })
      .catch(() => {
        message.error('Erro ao carregar os dados, atualize a pagina')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const loadUsers = async () => {
    await api.get('/user').then(({ data }) => {
      setUsers(data)
    })
  }

  return (
    <Container>
      <Helmet>
        <title>Arcke | Logs</title>
      </Helmet>
      <Spin spinning={loading}>
        <PageTitle title='Logs' />
        <Table
          dataSource={data}
          loading={loading}
          rowKey={(record) => record.id}
          size='middle'
          pagination={{
            total: meta?.total,
            pageSize: meta?.per_page,
            current: meta?.current_page,
            showTotal: (total) => `Total de ${total} itens`,
            onChange: async (page, pageSize) => {
              setMeta({
                ...meta,
                per_page: pageSize,
                current_page: page,
              })
              setLoad(!load)
            },
          }}
          style={{ margin: 20 }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <>
                    <span>Não há registro aqui</span>
                  </>
                }
              >
                <Button
                  icon={<PlusOutlined />}
                  key='insert'
                  type='primary'
                  size='large'
                >
                  Adicionar
                </Button>
              </Empty>
            ),
          }}
        >
          <Column
            title='Ação'
            dataIndex='method'
            key='method'
            render={(_, record) => (
              <Tag color={methodsColors[record.method].color}>
                {methodsColors[record.method].name}
              </Tag>
            )}
            filterIcon={(filtered) => (
              <SearchOutlined
                style={{ color: filtered ? '#1890ff' : '#777', fontSize: 15 }}
              />
            )}
            onFilter={(value, record) =>
              record.numRef
                ? record.numRef
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase())
                : false
            }
            filterDropdown={({
              selectedKeys = [],
              setSelectedKeys,
              confirm,
              clearFilters,
            }) => (
              <div style={{ padding: 8 }}>
                <Select
                  size='large'
                  allowClear
                  mode='multiple'
                  ref={methodsRef}
                  style={{ width: 188, marginBottom: 8, display: 'block' }}
                  onChange={(e) => {
                    const temp = [...e]
                    setSelectedKeys(temp)
                  }}
                  value={selectedKeys}
                >
                  <Select.Option value={'POST'}>CRIAR</Select.Option>
                  <Select.Option value={'PUT'}>ATUALIZAR</Select.Option>
                  <Select.Option value={'DELETE'}>DELETAR</Select.Option>
                </Select>
                <Space>
                  <Button
                    type='primary'
                    icon={<SearchOutlined />}
                    size='small'
                    onClick={() => {
                      setMeta({ current_page: 1, per_page: 10 })
                      setMethodsFilter(selectedKeys.toString())
                      setLoad(!load)
                    }}
                  >
                    Filtrar
                  </Button>
                  <Button
                    size='small'
                    onClick={() => {
                      if (clearFilters) {
                        setSelectedKeys([])
                        setMethodsFilter(null)
                        setLoad(!load)
                      }
                    }}
                  >
                    Limpar
                  </Button>
                </Space>
              </div>
            )}
          />
          <Column
            title='Realizado Por'
            dataIndex='userId'
            key='userId'
            render={(_, record) => record.user.name}
            filterIcon={(filtered) => (
              <SearchOutlined
                onClick={() => {
                  if (users.length < 1) {
                    loadUsers()
                  }
                }}
                style={{ color: filtered ? '#1890ff' : '#777', fontSize: 15 }}
              />
            )}
            filterDropdown={({
              selectedKeys,
              setSelectedKeys,
              confirm,
              clearFilters,
            }) => (
              <div style={{ padding: 8 }}>
                <Select
                  size='large'
                  showSearch
                  allowClear
                  value={selectedKeys}
                  optionFilterProp='children'
                  style={{ width: 280, marginBottom: 8, display: 'block' }}
                  onSelect={(e) => setSelectedKeys([e])}
                  onClear={() => {
                    setSelectedKeys([''])
                    setUserFilter(null)
                    setLoad(!load)
                  }}
                >
                  {users.map((user) => {
                    return (
                      <Select.Option key={user.id} value={user.id}>
                        {user.name}
                      </Select.Option>
                    )
                  })}
                </Select>
                <Space>
                  <Button
                    type='primary'
                    icon={<SearchOutlined />}
                    size='small'
                    onClick={() => {
                      setMeta({ current_page: 1, per_page: 10 })
                      setUserFilter(selectedKeys[0])
                      setLoad(!load)
                    }}
                  >
                    Filtrar
                  </Button>
                  <Button
                    size='small'
                    onClick={() => {
                      if (clearFilters) {
                        setSelectedKeys([''])
                        setUserFilter(null)
                        setLoad(!load)
                      }
                    }}
                  >
                    Limpar
                  </Button>
                </Space>
              </div>
            )}
          />
          <Column
            title='Realizado Em'
            dataIndex='createdAt'
            key='createdAt'
            render={(_, record) =>
              format(parseISO(record.createdAt), "dd/MM/yyyy 'às' HH:mm", {
                locale: ptBR,
              })
            }
            filterIcon={(filtered) => (
              <SearchOutlined
                style={{ color: filtered ? '#1890ff' : '#777', fontSize: 15 }}
              />
            )}
            filterDropdown={({
              selectedKeys,
              setSelectedKeys,
              confirm,
              clearFilters,
            }) => (
              <div
                style={{
                  padding: 8,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                }}
              >
                <RangePicker
                  format={dateFormat}
                  locale={locale}
                  defaultValue={selectedKeys}
                  onChange={(e) => {
                    if (e) {
                      const temp = [...e]
                      setSelectedKeys(temp)
                    }
                  }}
                />
                <Space>
                  <Button
                    type='primary'
                    icon={<SearchOutlined />}
                    size='small'
                    onClick={() => {
                      setMeta({ current_page: 1, per_page: 10 })
                      console.log(
                        selectedKeys
                          .map((date) => date.toISOString().slice(0, 10))
                          .toString()
                      )
                      setDateFilter(
                        selectedKeys
                          .map((date) => date.toISOString().slice(0, 10))
                          .toString()
                      )
                      setLoad(!load)
                    }}
                  >
                    Filtrar
                  </Button>
                  <Button
                    size='small'
                    onClick={() => {
                      if (clearFilters) {
                        setSelectedKeys(null)
                        setDateFilter(null)
                        setLoad(!load)
                      }
                    }}
                  >
                    Limpar
                  </Button>
                </Space>
              </div>
            )}
          />
          <Column title='Rota' dataIndex='path' key='path' />
        </Table>
      </Spin>
    </Container>
  )
}

export { Logs }
