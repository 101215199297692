import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Button, Row, Tooltip } from 'antd'
import { PlusOutlined, ClearOutlined } from '@ant-design/icons'
import { withRouter } from 'react-router-dom'
import { Container, PageTitle } from '../../components'
import { DashboardContainer } from './style'
import Relatorio from './Tabs/tabOne'

function Dashboard() {
  const [visible, setVisible] = useState(false)
  const [resetFilter, setResetFilter] = useState(false)
  return (
    <Container>
      <Helmet>
        <title>Arcke | Dashboard</title>
      </Helmet>
      <PageTitle
        title='Dashboard'
        extra={[
          <Tooltip key='2' title='Resetar Filtros'>
            <Button
              type='ghost'
              size='large'
              onClick={() => {
                setResetFilter(!resetFilter)
              }}
              icon={<ClearOutlined />}
            />
          </Tooltip>,
          <Button
            icon={<PlusOutlined />}
            type='primary'
            size='large'
            onClick={() => {
              setVisible(true)
            }}
            key='1'
          >
            Filtrar Informações da Dashboard
          </Button>,
        ]}
      />
      <Row style={{ height: '100%', width: '100%', marginTop: 10 }}>
        <DashboardContainer>
          <Relatorio
            visible={visible}
            setVisible={setVisible}
            resetFilter={resetFilter}
          />
        </DashboardContainer>
      </Row>
    </Container>
  )
}

export default withRouter(Dashboard)
