/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react'
import {
  message,
  Table,
  Space,
  Button,
  Drawer,
  Popconfirm,
  Descriptions,
  Modal,
  Typography,
  Tooltip,
  Form,
  Spin,
  InputNumber,
  Select,
  Switch,
  Empty,
  Divider,
  Input,
} from 'antd'
import NumberFormat from 'react-number-format'
import {
  EditOutlined,
  DeleteOutlined,
  EnvironmentOutlined,
  SearchOutlined,
  SubnodeOutlined,
  CloseOutlined,
  CheckOutlined,
  PlusOutlined,
  ClearOutlined,
} from '@ant-design/icons'
import { useHistory, useParams, Link } from 'react-router-dom'
import { Container, PageTitle } from 'components'
import { formatarMoeda } from 'services/utils'
import { Enviar, Cancelar } from 'components/Buttons'
import api from 'services/api'
import Highlighter from 'react-highlight-words'

const { Column } = Table
const { Text, Paragraph } = Typography

function Itens() {
  const { id } = useParams()
  const history = useHistory()
  const [form] = Form.useForm()

  const [contrato, setContrato] = useState({})
  const [listItems, setListItems] = useState([])
  const [itens, setItens] = useState([])
  const [locais, setLocais] = useState([])
  const [item, setItem] = useState(null)

  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [visible, setVisible] = useState(false)
  const [mode, setMode] = useState('insert')
  const [load, setLoad] = useState(false)
  const [open, setOpen] = useState(false)

  const [duplicado, setDuplicado] = useState(false)
  const [metaPagination, setMetaPagination] = useState({
    page: 1,
    per_page: 10,
  })

  const [descriptionFilter, setDescriptionFilter] = useState(null)
  const [numberFilter, setNumberFilter] = useState(null)

  useEffect(() => {
    loadData()
  }, [load])

  const loadData = async () => {
    setLoading(true)
    try {
      await api.get(`/contract/${id}`).then(({ data }) => {
        setContrato(data)
      })
      await api
        .get(`/itemlocal?contractId=${id}`, {
          params: {
            page: metaPagination?.page,
            size: metaPagination?.per_page,
            description: descriptionFilter,
            number: numberFilter,
          },
        })
        .then(({ data }) => {
          setItens(data.data)
          setMetaPagination(data.meta)
        })
    } catch (error) {
      message.error('Erro ao carregar os dados. tente novamente')
      history.push('/contratos')
    } finally {
      setLoading(false)
    }
  }

  const loadLocais = async () => {
    await api.get(`/local?clientId=${contrato.clientId}`).then(({ data }) => {
      setLocais(data)
    })
  }

  const loadItems = async () => {
    try {
      await api.get(`/item?contractId=${contrato.id}`).then(({ data }) => {
        setListItems(data)
      })
    } catch (error) {
      setLoading(false)

      message.error('Erro ao carregar os dados, atualize a pagina')
    }
  }

  const loadItem = async () => {
    try {
      await api.get(`/item`).then(({ data }) => {
        setListItems(data)
      })
    } catch (error) {
      setLoading(false)

      message.error('Erro ao carregar os dados, atualize a pagina')
    }
  }

  const onClose = () => {
    form.resetFields()
    setMode('insert')
    setLoad(!load)
    setVisible(false)
  }

  const routes = [
    {
      path: '/contratos',
      breadcrumbName: 'Contratos',
    },
  ]

  function itemRender(route) {
    return (
      <Link to={{ pathname: route.path }} key={route.breadcrumbName}>
        {route.breadcrumbName}
      </Link>
    )
  }

  return (
    <Container>
      <Spin spinning={loading}>
        <PageTitle
          title={`Itens do Contrato: ${contrato ? contrato.name : ''}`}
          breadcrumb={{ routes, itemRender }}
          breadcrumbRender={(props, breadcrumb) => (
            <div style={{ marginBottom: -4 }}>{breadcrumb}</div>
          )}
          extra={[
            <Button
              icon={<PlusOutlined />}
              size='large'
              type='primary'
              onClick={() => {
                setDuplicado(false)
                setMode('insert')
                loadItems()
                loadLocais()
                setVisible(true)
              }}
              key='1'
            >
              Adicionar Item ao Contrato
            </Button>,
          ]}
        ></PageTitle>
        <div
          style={{
            backgroundColor: '#fff',
            height: 50,
            display: 'flex',
            alignItems: 'center',
            padding: '0 10px',
            marginTop: 10,
            margin: '10px',
          }}
        >
          <Descriptions size='small' column={2}>
            <Descriptions.Item label='Objeto'>
              {contrato.objeto ? contrato.objeto : ''}
            </Descriptions.Item>
            <Descriptions.Item label='Valor'>
              {contrato.value ? formatarMoeda(contrato.value) : ''}
            </Descriptions.Item>
          </Descriptions>
        </div>
        <Table
          pagination={{
            total: metaPagination?.total,
            pageSize: metaPagination?.per_page,
            current: metaPagination?.current_page,
            showTotal: (total) => `Total de ${total} itens`,
            onChange: async (page, pageSize) => {
              setMetaPagination({ ...metaPagination, page, per_page: pageSize })
              setLoad(!load)
            },
          }}
          dataSource={itens}
          loading={loading}
          rowKey={(record) => record.id}
          size='middle'
          style={{ margin: 20 }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <>
                    <span>Não há registro aqui</span>
                  </>
                }
              >
                <Button
                  icon={<PlusOutlined />}
                  key='insert'
                  type='primary'
                  size='large'
                  onClick={() => {
                    setDuplicado(false)
                    setMode('insert')
                    loadItems()
                    loadLocais()
                    setVisible(true)
                  }}
                >
                  Adicionar
                </Button>
              </Empty>
            ),
          }}
        >
          <Column
            title='Numero'
            dataIndex='number'
            render={(_, record) => record.number}
            key='id'
            filterIcon={(filtered) => (
              <SearchOutlined
                style={{ color: filtered ? '#1890ff' : '#777', fontSize: 15 }}
              />
            )}
            onFilter={(value, record) =>
              record.numRef
                ? record.numRef
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase())
                : false
            }
            filterDropdown={({ selectedKeys, setSelectedKeys }) => (
              <div
                style={{ padding: 8, display: 'flex', alignItems: 'center' }}
              >
                <Input
                  placeholder='Filtrar Numero'
                  type='number'
                  value={selectedKeys[0]}
                  style={{ width: 150, display: 'block' }}
                  onChange={(e) =>
                    setSelectedKeys(e.target.value ? [e.target.value] : [])
                  }
                  onPressEnter={() => {
                    setMetaPagination({ current_page: 1, per_page: 10 })
                    setNumberFilter(selectedKeys[0])
                    setLoad(!load)
                  }}
                />
                <Button
                  type='primary'
                  icon={<SearchOutlined />}
                  style={{ marginLeft: 6, marginRight: 6, minWidth: 32 }}
                  onClick={() => {
                    setMetaPagination({ current_page: 1, per_page: 10 })
                    setNumberFilter(selectedKeys[0] ?? '')
                    setLoad(!load)
                  }}
                />
                <Button
                  icon={<ClearOutlined />}
                  style={{ minWidth: 32 }}
                  onClick={() => {
                    setSelectedKeys('')
                    setNumberFilter(null)
                    setLoad(!load)
                  }}
                />
              </div>
            )}
          />
          <Column
            title='Descrição'
            dataIndex='description'
            key='description'
            width={600}
            filterIcon={(filtered) => (
              <SearchOutlined
                style={{ color: filtered ? '#1890ff' : '#777', fontSize: 15 }}
              />
            )}
            onFilter={(value, record) =>
              record.numRef
                ? record.numRef
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase())
                : false
            }
            filterDropdown={({
              selectedKeys,
              setSelectedKeys,
              clearFilters,
            }) => (
              <div
                style={{ padding: 8, display: 'flex', alignItems: 'center' }}
              >
                <Input
                  placeholder='Filtrar Descrição'
                  value={selectedKeys[0]}
                  style={{ width: 250, display: 'block' }}
                  onChange={(e) =>
                    setSelectedKeys(e.target.value ? [e.target.value] : [])
                  }
                  onPressEnter={() => {
                    setMetaPagination({ current_page: 1, per_page: 10 })
                    setDescriptionFilter(selectedKeys[0])
                    setLoad(!load)
                  }}
                />
                <Button
                  type='primary'
                  icon={<SearchOutlined />}
                  style={{ marginLeft: 6, marginRight: 6, minWidth: 32 }}
                  onClick={() => {
                    setMetaPagination({ current_page: 1, per_page: 10 })
                    setDescriptionFilter(selectedKeys[0] ?? '')
                    setLoad(!load)
                  }}
                />
                <Button
                  icon={<ClearOutlined />}
                  style={{ minWidth: 32 }}
                  onClick={() => {
                    if (clearFilters) {
                      setDescriptionFilter(null)
                      clearFilters()
                      setLoad(!load)
                    }
                  }}
                />
              </div>
            )}
            render={(_, record) => {
              return descriptionFilter !== null ? (
                <Highlighter
                  highlightStyle={{
                    backgroundColor: '#ffc069',
                    padding: 0,
                  }}
                  searchWords={[descriptionFilter]}
                  autoEscape
                  textToHighlight={
                    record.item.description
                      ? record.item.description.toString()
                      : ''
                  }
                />
              ) : (
                record.item.description
              )
            }}
          />
          <Column
            title='Quantidade Utilizada'
            dataIndex='quantidadeTotal'
            key='quantidadeTotal'
            render={(_, record) =>
              `${record.usage[0].usage}/${record.quantity}`
            }
          />
          <Column
            title='Unidade de Medida'
            dataIndex='unit'
            key='unit'
            render={(_, record) => record.item.unit}
          />
          <Column
            title='Valor Unitário'
            dataIndex='unityValue'
            key='unityValue'
            render={(_, record) => `${formatarMoeda(record.unityValue)}`}
          />
          <Column
            title='Ações'
            dataIndex='actions'
            key='actions'
            render={(_, record) => (
              <Space>
                <Tooltip title='Locais' placement='top'>
                  <Button
                    icon={<EnvironmentOutlined />}
                    onClick={() => {
                      history.push({
                        pathname: `/contratos/${contrato.id}/itens/${record.id}/detalhe`,
                        state: { contrato: contrato, item: record },
                      })
                    }}
                  />
                </Tooltip>
                <Tooltip title='Editar' placement='top'>
                  <Button
                    icon={<EditOutlined />}
                    disabled={record.itemDuplicado === false}
                    onClick={() => {
                      loadItem()
                      loadLocais()
                      setDuplicado(true)
                      form.setFieldsValue({
                        ...record,
                        item: record.itemId,
                        itemDuplicado: true,
                        unityValue: Number(record.unityValue),
                      })
                      setItem(record)
                      setMode('update')
                      setVisible(true)
                    }}
                  />
                </Tooltip>
                <Tooltip title='Aditivos' placement='top'>
                  <Button
                    icon={<SubnodeOutlined />}
                    onClick={() =>
                      history.push({
                        pathname: `/contratos/${contrato.id}/itens/${record.id}/aditivos`,
                        state: { ...contrato, item: record },
                      })
                    }
                  />
                </Tooltip>
                <Tooltip title='Excluir item' placement='top'>
                  <Popconfirm
                    title='Deseja realmente excluir este item?'
                    okText='Sim'
                    cancelText='Não'
                    disabled={record.itemDuplicado === false}
                    onConfirm={async () => {
                      console.log(record)
                      await api
                        .delete(`/itemlocal/${record.id}`)
                        .then(() => {
                          message.success('Item excluido com sucesso')
                          setLoad(!load)
                        })
                        .catch(() => {
                          message.error('Erro ao excluir item, tente novamente')
                        })
                    }}
                  >
                    <Button
                      disabled={record.itemDuplicado === false}
                      icon={<DeleteOutlined />}
                      style={{ color: '#e80909' }}
                    />
                  </Popconfirm>
                </Tooltip>
              </Space>
            )}
          />
        </Table>
        <Drawer
          visible={visible}
          onClose={onClose}
          width={550}
          destroyOnClose
          title='Adicionar Item ao Contrato'
        >
          <Spin spinning={saving}>
            <Form
              form={form}
              layout='vertical'
              onFinish={(value) => {
                const data = {
                  ...value,
                  contractId: contrato.id,
                  clientId: contrato.cliente.id,
                  itemDuplicado: value.itemDuplicado,
                }
                if (value.itemDuplicado !== true) {
                  delete data.clientId
                }
                setSaving(true)
                if (mode === 'insert') {
                  api
                    .post('/itemlocal', data)
                    .then(() => {
                      message.success('Cadastro realizado com sucesso!')
                      onClose()
                      setLoad(!load)
                    })
                    .catch((e) => {
                      if (e.response.data.errors) {
                        e.response.data.errors.forEach((info) => {
                          message.error(info.message)
                        })
                      } else {
                        message.error(e.response.data.error)
                      }
                    })
                    .finally(() => setSaving(false))
                } else if (mode === 'update') {
                  delete data.clientId
                  api
                    .put(`/itemlocal/updatelocal/${item.id}`, data)
                    .then(() => {
                      message.success('Cadastro realizado com sucesso!')
                      onClose()
                      setLoad(!load)
                    })
                    .catch((e) => {
                      if (e.response.data.errors) {
                        e.response.data.errors.forEach((info) => {
                          message.error(info.message)
                        })
                      } else {
                        message.error(e.response.data.error)
                      }
                    })
                    .finally(() => setSaving(false))
                }
              }}
            >
              <Form.Item
                label='Item'
                name='itemId'
                rules={[
                  {
                    required: true,
                    message: 'Por favor, o Item deve ser informado',
                  },
                ]}
              >
                <Select
                  size='large'
                  disabled={mode !== 'insert'}
                  showSearch
                  placeholder='Selecione...'
                  optionLabelProp='label'
                  optionFilterProp='children'
                  onSelect={async (e) => {
                    await api
                      .get(
                        `/local?clientId=${contrato.clientId}&contractId=${contrato.id}&itemId=${e}`
                      )
                      .then(({ data }) => {
                        setLocais(data)
                      })
                  }}
                >
                  {listItems.map((itemUni) => (
                    <Select.Option
                      key={itemUni.id}
                      value={itemUni.id}
                      label={itemUni.description}
                    >
                      {`${itemUni.id} | ${itemUni.description}`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label='Repetir item'>
                <Space>
                  <Form.Item
                    valuePropName='checked'
                    name='itemDuplicado'
                    initialValue={false}
                    noStyle
                    shouldUpdate
                  >
                    <Switch
                      disabled={mode !== 'insert'}
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      onChange={(value) => setDuplicado(value)}
                    />
                  </Form.Item>
                  <Tooltip
                    placement='topLeft'
                    title='Quando selecionado, o item será duplicado para todos locais do contrato.'
                  >
                    <Button size='small' shape='circle'>
                      ?
                    </Button>
                  </Tooltip>
                </Space>
              </Form.Item>
              {!duplicado && (
                <Form.Item
                  label='Local'
                  name='localId'
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, o Local deve ser informado',
                    },
                  ]}
                  shouldUpdate
                >
                  <Select
                    size='large'
                    disabled={mode !== 'insert'}
                    showSearch
                    placeholder='Selecione...'
                    optionLabelProp='label'
                    optionFilterProp='children'
                  >
                    {locais.map((itemUni) => (
                      <Select.Option
                        key={itemUni.id}
                        value={itemUni.id}
                        label={`${itemUni.name}`}
                      >
                        {itemUni.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              <Form.Item label='Número do item' name='number'>
                <InputNumber style={{ width: '100%' }} size='large' />
              </Form.Item>
              <Form.Item
                label='Valor do item'
                name='unityValue'
                trigger='onValueChange'
                rules={[
                  {
                    required: true,
                    message: 'Por favor, o valor deve ser informado',
                  },
                ]}
              >
                <NumberFormat
                  style={{ padding: '8px' }}
                  thousandSeparator='.'
                  decimalSeparator=','
                  prefix='R$'
                  decimalScale={2}
                  className='ant-input'
                  fixedDecimalScale
                  onValueChange={(value) =>
                    form.setFieldsValue({ unityValue: value.floatValue })
                  }
                />
              </Form.Item>
              <Form.Item label='Quantidade' name='quantity'>
                <InputNumber style={{ width: '100%' }} size='large' />
              </Form.Item>
              <Divider style={{ borderColor: '#aaa' }} />
              <div style={{ display: 'flex', width: '100%' }}>
                <Enviar
                  disabled={mode === 'view'}
                  type='primary'
                  htmlType='submit'
                  size='large'
                  style={{ flex: 1 }}
                >
                  {mode === 'insert' ? 'Adicionar' : 'Atualizar'}
                </Enviar>
                <Cancelar
                  danger
                  onClick={onClose}
                  size='large'
                  style={{ flex: 1 }}
                >
                  Cancelar
                </Cancelar>
              </div>
            </Form>
          </Spin>
        </Drawer>
        <Modal
          title='Item'
          visible={open}
          onOk={() => setOpen(false)}
          onCancel={() => setOpen(false)}
          footer={null}
        >
          {item && (
            <>
              <Text strong>Número</Text>
              <Paragraph>{item.number}</Paragraph>
              <Text strong>Descrição</Text>
              <Paragraph>{item.item.description}</Paragraph>
              <Text strong>Quantidade utilizada</Text>
              {/* <Paragraph>
                {item.itemDuplicado
                  ? `${item.items[0].totalServicos}/${
                      item.quantidadeTotal / item.items.length
                    }`
                  : `${item.items[0].totalServicos}/${item.quantidadeTotal}`}
              </Paragraph> */}
              <Text strong>Unidade de Medida</Text>
              <Paragraph>{item.item.unit}</Paragraph>
              {/* <Text strong>Valor unitário</Text>
              <Paragraph>{formatarMoeda(item.items[0].unityValue)}</Paragraph>
              <Text strong>
                {item.items[0].itemDuplicado ? (
                  <Tag color='green'> Item Duplicado </Tag>
                ) : (
                  <Tag color='red'> Item Não Duplicado </Tag>
                )}
              </Text> */}
            </>
          )}
        </Modal>
      </Spin>
    </Container>
  )
}

export default Itens
