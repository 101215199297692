import React, { useEffect, useState, useContext } from 'react'
import {
  Skeleton,
  message,
  Table,
  Button,
  Drawer,
  Form,
  Spin,
  Select,
  Menu,
  Dropdown,
  Empty,
  Divider,
} from 'antd'
import DatePicker from '../../components/DatePicker'
import {
  DeleteOutlined,
  FilterOutlined,
  DownloadOutlined,
  DownOutlined,
  SettingOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import api, { pdfUrl } from '../../services/api'
import { Container, PageTitle } from '../../components'
import { Enviar, Cancelar } from '../../components/Buttons'
import { format } from 'date-fns'
import { formatarMoeda } from 'services/utils'
import AuthContext from 'contexts/auth'
import axios from 'axios'
const { Column } = Table

function RelatorioClientes() {
  const { user } = useContext(AuthContext)
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [filtrados, setFiltrados] = useState([])
  const [date, setDate] = useState(null)
  const [clients, setClients] = useState([])
  const [locals, setLocals] = useState([])
  const [client, setClient] = useState([])
  const [contracts, setContracts] = useState([])
  const [filterQuery, setFilterQuery] = useState({})

  useEffect(() => {
    loadClients()
  }, [])

  const loadClients = async (attendantId) => {
    await api
      .get(`/client`, {
        params: {
          attendantId,
        },
      })
      .then(({ data }) => {
        setClients(data)
      })
      .catch(() => {
        message.error('Erro ao carregar os dados.')
      })
  }

  const loadContracts = async (clientId) => {
    await api
      .get(`/contract/os`, {
        params: {
          clientId,
        },
      })
      .then(({ data }) => {
        setContracts(data)
      })
      .catch(() => {
        message.error('Erro ao carregar os dados.')
      })
  }

  const loadLocal = async (clientId) => {
    await api
      .get(`/local`, {
        params: {
          clientId,
        },
      })
      .then(({ data }) => {
        setLocals(data)
      })
      .catch(() => {
        message.error('Erro ao carregar os dados.')
      })
  }

  const onClose = () => {
    setVisible(false)
  }

  const openDrawer = () => {
    setVisible(true)
  }

  const disabledDate = (current) => {
    return current && current < date
  }

  const exportTable = async () => {
    setLoading(true)
    const startDate = format(filterQuery.startDate, 'dd-MM-yyyy')
    const endDate = format(
      filterQuery.endDate ? filterQuery.endDate : new Date(),
      'dd-MM-yyyy'
    )
    const nameFile = `${startDate} à ${endDate}`
    await axios
      .post(
        `${pdfUrl}locals-report/`,
        {
          user,
          data: {
            client,
            locals: filtrados,
            period: {
              startDate: filterQuery.startDate,
              endDate: filterQuery.endDate ? filterQuery.endDate : new Date(),
            },
          },
        },
        {
          responseType: 'blob',
        }
      )
      .then((response) => {
        message.success({
          content: 'Baixando PDF...',
        })
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${nameFile}.pdf`)
        document.body.appendChild(link)
        link.click()
      })
      // .then((response) => {
      //   const newWin = window.open(
      //     'about:blank',
      //     'Axios data',
      //     'width=840,height=800'
      //   )
      //   newWin.document.write(response.data)
      // })
      .finally(() => {
        setLoading(false)
      })
  }

  const menu = (
    <Menu>
      <Menu.Item key='1'>
        <Button icon={<DownloadOutlined />} onClick={exportTable}>
          Baixar Tabela - PDF
        </Button>
      </Menu.Item>
    </Menu>
  )

  const menuTabela = (record) => (
    <Menu>
      <Menu.Item key='1'>
        <Button
          icon={<DownloadOutlined />}
          style={{ width: '100%' }}
          onClick={exportTable}
        >
          Relatório
        </Button>
      </Menu.Item>
      <Menu.Item key='3'>
        <Button
          icon={<DeleteOutlined />}
          style={{ color: '#e80909', width: '100%' }}
          onClick={async () => {
            const index = filtrados.findIndex((item) => record.id === item.id)
            const filtradosArray = [...filtrados]
            filtradosArray.splice(index, 1)
            setFiltrados(filtradosArray)
            message.success('Item excluido com sucesso')
          }}
        >
          Excluir Linha
        </Button>
      </Menu.Item>
    </Menu>
  )

  const handleSubmit = async (value) => {
    setLoading(true)
    setFilterQuery(value)
    const startDate = value.startDate
      ? format(value.startDate, 'yyyy-MM-dd')
      : null
    const endDate = value.endDate ? format(value.endDate, 'yyyy-MM-dd') : null
    await api
      .get(`/reports/locals/`, {
        params: {
          startDate,
          endDate,
          clientId: value.clientId,
          contractId: value.contractId,
          localId: value.localId,
        },
      })
      .then(({ data }) => {
        const { locals: _, ...clientData } = data
        setClient(clientData)
        setFiltrados(data.locals)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Container>
      <Skeleton loading={false} active>
        <PageTitle
          title='Relatório de Faturamento por Cliente'
          extra={[
            <Dropdown
              key='1'
              trigger={['click']}
              disabled={false || filtrados.length < 1}
              overlay={menu}
              placement='bottomCenter'
            >
              <Button size='large'>
                Exportar Tabela <DownOutlined />
              </Button>
            </Dropdown>,
            <Button
              type='primary'
              size='large'
              icon={<FilterOutlined />}
              onClick={() => {
                openDrawer()
              }}
              key='2'
            >
              Filtrar
            </Button>,
          ]}
        />
        <Table
          dataSource={filtrados}
          loading={loading}
          rowKey={(record) => record.id}
          size='middle'
          style={{ margin: 20 }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <>
                    <span>Não há registro aqui</span>
                  </>
                }
              >
                <Button
                  icon={<PlusOutlined />}
                  key='insert'
                  type='primary'
                  size='large'
                  onClick={() => {
                    openDrawer()
                  }}
                >
                  Adicionar
                </Button>
              </Empty>
            ),
          }}
        >
          <Column title='Local' dataIndex='local' key='local' />
          <Column title='Endereço' dataIndex='address' key='address' />
          <Column
            title='Serviços Realizados'
            dataIndex='servicos'
            key='servicos'
            render={(_, record) => `${record.orders.length}`}
          />
          <Column
            title='Valor'
            dataIndex='valor'
            key='valor'
            render={(_, record) =>
              `${formatarMoeda(Number(record.totalLocal))}`
            }
          />
          <Column
            title='Ações'
            dataIndex='actions'
            key='actions'
            render={(_, record) => (
              <Dropdown
                trigger={['click']}
                key='1'
                disabled={filtrados.length < 1 || true}
                overlay={menuTabela(record)}
                placement='bottomCenter'
              >
                <Button icon={<SettingOutlined />}>Ações</Button>
              </Dropdown>
            )}
          />
        </Table>
        <Drawer
          visible={visible}
          onClose={onClose}
          width={550}
          title='Filtro de Relatório'
        >
          <Spin spinning={loading}>
            <Form form={form} layout='vertical' onFinish={handleSubmit}>
              <Form.Item label='Cliente'>
                <Form.Item
                  name='clientId'
                  rules={[
                    {
                      required: true,
                      message: 'Informe um cliente.',
                    },
                  ]}
                  noStyle
                >
                  <Select
                    size='large'
                    showSearch
                    placeholder='Selecione...'
                    optionLabelProp='label'
                    optionFilterProp='children'
                    onChange={(e) => {
                      loadContracts(e)
                      loadLocal(e)
                    }}
                    allowClear
                  >
                    {clients.map((client) => (
                      <Select.Option
                        key={client.id}
                        value={client.id}
                        label={client.nomeFantasia}
                      >
                        {client.nomeFantasia}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form.Item>
              <Form.Item label='Contrato'>
                <Form.Item
                  name='contractId'
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                  noStyle
                >
                  <Select
                    size='large'
                    showSearch
                    placeholder='Selecione...'
                    optionLabelProp='label'
                    optionFilterProp='children'
                    allowClear
                  >
                    {contracts.map((contract) => (
                      <Select.Option
                        key={contract.id}
                        value={contract.id}
                        label={contract.name}
                      >
                        {contract.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form.Item>
              <Form.Item label='Locais'>
                <Form.Item
                  name='localId'
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                  noStyle
                >
                  <Select
                    size='large'
                    showSearch
                    placeholder='Selecione um Local'
                    optionLabelProp='label'
                    optionFilterProp='children'
                    allowClear
                  >
                    {locals.map((local) => (
                      <Select.Option
                        key={local.id}
                        value={local.id}
                        label={local.name}
                      >
                        {local.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form.Item>
              <Form.Item label='Data de início' name='startDate'>
                <DatePicker
                  style={{ width: '100%' }}
                  onChange={(value) => {
                    setDate(value)
                  }}
                  size='large'
                />
              </Form.Item>
              <Form.Item label='Data de finalização' name='endDate'>
                <DatePicker
                  style={{ width: '100%' }}
                  disabled={date === null}
                  disabledDate={disabledDate}
                  size='large'
                />
              </Form.Item>
              <Divider style={{ borderColor: '#aaa' }} />
              <div style={{ display: 'flex' }}>
                <Enviar
                  type='primary'
                  htmlType='submit'
                  style={{ flex: 1 }}
                  size='large'
                >
                  Gerar Relatório
                </Enviar>
                <Cancelar
                  danger
                  onClick={onClose}
                  style={{ flex: 1 }}
                  size='large'
                >
                  Cancelar
                </Cancelar>
              </div>
            </Form>
          </Spin>
        </Drawer>
      </Skeleton>
    </Container>
  )
}

export { RelatorioClientes }
