/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react'
import {
  message,
  Table,
  Space,
  Button,
  Drawer,
  Popconfirm,
  Tooltip,
  Form,
  Input,
  Spin,
  Empty,
  Switch,
  Divider,
} from 'antd'
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import api from '../../services/api'
import { Container, PageTitle } from '../../components'
import { Enviar, Cancelar } from '../../components/Buttons'
import { Helmet } from 'react-helmet'

const { Column } = Table

const features = [
  {
    feature: 'needPmoc',
    label: 'Necessita de PMOC',
    name: 'needPmoc',
  },
  {
    feature: 'model',
    name: 'model',
    label: 'Modelo',
  },
  {
    feature: 'serialNumber',
    name: 'serialNumber',
    label: 'Número de Série',
  },
  {
    feature: 'voltage',
    name: 'voltage',
    label: 'Voltagem',
  },
  {
    feature: 'btus',
    name: 'btus',
    label: 'BTUS',
  },
  {
    feature: 'trs',
    name: 'trs',
    label: 'TRS',
  },
  {
    feature: 'capacity',
    name: 'capacity',
    label: 'Capacidade',
  },
  {
    feature: 'gas',
    name: 'gas',
    label: 'Gás',
  },
  {
    feature: 'brand',
    name: 'brand',
    label: 'Marca',
  },
  {
    feature: 'ventilation',
    name: 'ventilation',
    label: 'Ventilação',
  },
  {
    feature: 'photo',
    name: 'photo',
    label: 'Foto',
  },
  {
    feature: 'date',
    name: 'date',
    label: 'Data de Fabricação',
  },
]

function TipoEquipamentos() {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [mode, setMode] = useState('insert')
  const [load, setLoad] = useState(false)
  const [tipoEquipamento, setTipoEquipamento] = useState([])
  const [tipo, setTipo] = useState(null)

  useEffect(() => {
    loadData()
  }, [load])

  const onClose = () => {
    form.resetFields()
    setVisible(false)
    setLoad(!load)
    setMode('')
  }

  const openDrawer = () => {
    form.resetFields()
    setVisible(true)
  }

  function handleEquipmentTypeEditing(value) {
    form.setFieldsValue(value)
    setMode('update')
    setTipo(value)
    setVisible(true)
  }

  async function handleSubmitForm(value) {
    setLoading(true)
    if (mode === 'insert') {
      const data = {
        ...value,
        name: value.name.toUpperCase(),
      }
      api
        .post('/equipmenttype', data)
        .then(() => {
          setLoading(false)
          message.success('Cadastro realizado com sucesso!')
          onClose()
        })
        .catch((e) => {
          setLoading(false)
          if (e.response.data.errors) {
            e.response.data.errors.forEach((info) => {
              message.error(info.message)
            })
          } else {
            message.error(e.response.data.error)
          }
        })
    } else {
      const data = {
        ...value,
        name: value.name.toUpperCase(),
      }
      api
        .put(`/equipmenttype/${tipo.id}`, data)
        .then(() => {
          setLoading(false)
          message.success('Cadastro alterado com sucesso!')
          onClose()
        })
        .catch((e) => {
          setLoading(false)
          if (e.response.data.errors) {
            e.response.data.errors.forEach((info) => {
              message.error(info.message)
            })
          } else {
            message.error(e.response.data.error)
          }
        })
    }
  }

  async function loadData() {
    setLoading(true)
    try {
      await api.get('/equipmenttype').then(({ data }) => {
        setTipoEquipamento(data)
        setLoading(false)
        setLoad(false)
      })
    } catch (error) {
      setLoading(false)
      setLoad(false)
      message.error('Erro ao carregar os dados, atualize a pagina')
    }
  }

  const routes = [
    {
      path: '/',
      breadcrumbName: 'Cadastros',
    },
  ]

  return (
    <Container>
      <Helmet>
        <title>Arcke | Tipos de Equipamentos</title>
      </Helmet>
      <Spin spinning={loading}>
        <PageTitle
          title='Tipos de Equipamentos'
          breadcrumb={{ routes }}
          breadcrumbRender={(props, breadcrumb) => (
            <div style={{ marginBottom: -4 }}>{breadcrumb}</div>
          )}
          extra={[
            <Button
              type='primary'
              size='large'
              icon={<PlusOutlined />}
              onClick={(e) => {
                setMode('insert')
                openDrawer()
              }}
              key='1'
            >
              Cadastrar Tipo de Equipamento
            </Button>,
          ]}
        />
        <Table
          dataSource={tipoEquipamento}
          loading={loading}
          rowKey={(record) => record.id}
          size='middle'
          style={{ margin: 20 }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <>
                    <span>Não há registro aqui</span>
                  </>
                }
              >
                <Button
                  icon={<PlusOutlined />}
                  key='insert'
                  type='primary'
                  size='large'
                  onClick={() => {
                    setMode('insert')
                    openDrawer()
                  }}
                >
                  Cadastrar
                </Button>
              </Empty>
            ),
          }}
        >
          <Column title='Tipo de Equipamento' dataIndex='name' key='name' />
          <Column
            title='Ações'
            dataIndex='actions'
            key='actions'
            width={20}
            render={(_, record) => (
              <Space>
                <Tooltip title='Editar' placement='top'>
                  <Button
                    icon={<EditOutlined />}
                    onClick={() => handleEquipmentTypeEditing(record)}
                  />
                </Tooltip>
                <Tooltip title='Excluir Tipo de Equipamento' placement='top'>
                  <Popconfirm
                    title='Deseja realmente excluir este item?'
                    okText='Sim'
                    cancelText='Não'
                    onConfirm={async () => {
                      await api
                        .delete(`/equipmenttype/${record.id}`)
                        .then(() => {
                          message.success('Item excluido com sucesso')
                          setLoad(true)
                        })
                        .catch(() => {
                          message.error('Erro ao excluir item, tente novamente')
                        })
                    }}
                  >
                    <Button
                      icon={<DeleteOutlined />}
                      style={{ color: '#e80909' }}
                    />
                  </Popconfirm>
                </Tooltip>
              </Space>
            )}
          />
        </Table>
        <Drawer
          visible={visible}
          onClose={onClose}
          width={550}
          title={`${
            mode === 'insert' ? 'Cadastrar' : 'Atualizar'
          } Tipo de Equipamento`}
        >
          <Spin spinning={loading}>
            <Form form={form} layout='vertical' onFinish={handleSubmitForm}>
              <Form.Item
                label='Tipo de equipamento'
                name='name'
                rules={[
                  {
                    required: true,
                    message: 'Por favor, o nome do tipo deve ser informado',
                  },
                ]}
              >
                <Input size='large' />
              </Form.Item>
              {features.slice(0, 1).map((feature) => {
                return (
                  <Form.Item
                    key={feature.feature}
                    label={feature.label}
                    name={feature.name}
                    valuePropName={'checked'}
                    style={{ width: '50%' }}
                  >
                    <Switch size='large' />
                  </Form.Item>
                )
              })}
              <Divider style={{ borderColor: '#bbb' }} />
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                {features.slice(1).map((feature) => {
                  return (
                    <Form.Item
                      key={feature.feature}
                      label={feature.label}
                      name={feature.name}
                      valuePropName={'checked'}
                      style={{ width: '50%' }}
                    >
                      <Switch size='large' />
                    </Form.Item>
                  )
                })}
              </div>
              <Divider style={{ borderColor: '#bbb' }} />
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Enviar
                  disabled={mode === 'view'}
                  type='primary'
                  htmlType='submit'
                  size='large'
                  style={{ flex: 1 }}
                >
                  {mode === 'insert' ? 'Cadastrar' : 'Atualizar'}
                </Enviar>
                <Cancelar
                  danger
                  onClick={onClose}
                  size='large'
                  style={{ flex: 1 }}
                >
                  Cancelar
                </Cancelar>
              </div>
            </Form>
          </Spin>
        </Drawer>
      </Spin>
    </Container>
  )
}

export default TipoEquipamentos
