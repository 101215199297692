import React, { useState } from 'react'
import { Form, Input, Button, Spin, message, Col, Row } from 'antd'
import { MailOutlined, LockOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { Container, Content, FormContainer, LogoContainer } from './styles'
// import logo from 'assets/image/logo.svg'
import logo from 'assets/image/logo.svg'
import api from 'services/api'
import { useAuth } from 'contexts/auth'
import { Helmet } from 'react-helmet'

function Login() {
  const [form] = Form.useForm()
  const [isResetPassword, setResetPassword] = useState(false)
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const { login } = useAuth()

  function handleResetPassword() {
    form.resetFields()
    !isResetPassword ? setResetPassword(true) : setResetPassword(false)
  }
  return (
    <Container>
      <Helmet>
        <title>Arcke | Login</title>
      </Helmet>
      <Content>
        <FormContainer>
          <LogoContainer>
            <img src={logo} alt='logo' />
          </LogoContainer>
          <Spin spinning={loading}>
            {isResetPassword ? (
              <Form
                labelCol={{ span: 4 }}
                form={form}
                onFinish={async (values) => {
                  setLoading(true)
                  api
                    .post('/forgotpass', values)
                    .then(() => {
                      setLoading(false)
                      message.success('Email enviado com sucesso')
                      setResetPassword(false)
                    })
                    .catch((e) => {
                      setLoading(false)
                      if (e.response?.data !== undefined) {
                        message.error(e.response.data.error)
                      } else {
                        message.error(`Falha ao se comunicar com servidor`)
                      }
                    })
                }}
              >
                <Form.Item
                  label='Email'
                  name='email'
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      message: 'O email deve ser informado',
                    },
                  ]}
                >
                  <Input size='large' prefix={<MailOutlined />} />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 3 }}>
                  <Row>
                    <Col>
                      <Form.Item>
                        <Button
                          type='primary'
                          htmlType='submit'
                          className='login-form-button'
                        >
                          Enviar Formulário
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item wrapperCol={{ offset: 4 }}>
                        <Button type='text' onClick={handleResetPassword}>
                          Fazer Login
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            ) : (
              <Form
                labelCol={{ span: 8 }}
                form={form}
                size='large'
                layout='vertical'
                onFinish={async (values) => {
                  setLoading(true)
                  await login(values)
                    .then(() => history.push('/os'))
                    .catch((e) => {
                      setLoading(false)
                      console.log(e.response.data)
                      if (e.response?.data !== undefined) {
                        if (e.response?.data.error) {
                          message.error('Conta desativada.')
                        } else {
                          message.error('Login ou Senha inválidos.')
                        }
                      } else {
                        message.error(`Falha ao se comunicar com servidor`)
                      }
                    })
                }}
              >
                <Form.Item
                  label='Email:'
                  name='email'
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      message: 'O email deve ser informado',
                    },
                  ]}
                >
                  <Input size='large' prefix={<MailOutlined />} />
                </Form.Item>
                <Form.Item
                  label='Senha:'
                  name='password'
                  rules={[
                    {
                      required: true,
                      message: 'A senha deve ser informada',
                    },
                  ]}
                >
                  <Input.Password size='large' prefix={<LockOutlined />} />
                </Form.Item>
                <Form.Item>
                  <Row
                    style={{
                      justifyContent: 'space-between',
                      marginTop: '20px',
                    }}
                  >
                    <Col>
                      <Form.Item>
                        <Button type='text' onClick={handleResetPassword}>
                          Esqueci a Senha
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item>
                        <Button
                          type='primary'
                          htmlType='submit'
                          className='login-form-button'
                        >
                          Entrar
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            )}
          </Spin>
        </FormContainer>
      </Content>
    </Container>
  )
}

export default Login
