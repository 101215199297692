import React, { createContext, useState } from 'react'

const DrawerContext = createContext({
  open: false,
  load: true,
  mode: {},
  setOpen: () => null,
  setLoad: () => null,
  setMode: () => null,
})

export const DrawerProvider = ({ children }) => {
  const [load, setLoad] = useState(true)
  const [mode, setMode] = useState(null)
  const [open, setOpen] = useState(false)

  return (
    <DrawerContext.Provider
      value={{ load, mode, setLoad, setMode, open, setOpen }}
    >
      {children}
    </DrawerContext.Provider>
  )
}

export default DrawerContext
