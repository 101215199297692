import React, { useState, useEffect, useContext } from 'react'
import {
  Skeleton,
  message,
  Table,
  Button,
  Drawer,
  Form,
  Spin,
  Select,
  Menu,
  Dropdown,
  Empty,
  Tag,
  Divider,
} from 'antd'
import {
  FilterOutlined,
  DownloadOutlined,
  DownOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { Container, PageTitle } from '../../components'
import { Enviar, Cancelar } from '../../components/Buttons'
import { Helmet } from 'react-helmet'
import api, { pdfUrl } from '../../services/api'
import { format } from 'date-fns'
import axios from 'axios'
import AuthContext from 'contexts/auth'
import { formatarMoeda } from 'services/utils'

const { Column } = Table

function RelatorioConsumoItems() {
  const { user } = useContext(AuthContext)
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState([])
  const [clientes, setClientes] = useState([])
  const [contracts, setContracts] = useState([])
  const [items, setItems] = useState([])
  const [loadingTable, setLoadingTable] = useState(false)
  const [loadingSelect, setLoadingSelect] = useState(false)

  const [filter, setFilter] = useState(null)

  const onClose = () => {
    // form.resetFields()
    setVisible(false)
  }

  const loadClients = async () => {
    setLoadingSelect(true)
    await api.get('/client').then(({ data }) => {
      setClientes(data)
    })
  }

  const loadContracts = async (value) => {
    setLoadingSelect(true)
    await api
      .get(`/contract/os/?clientId=${value}`)
      .then(({ data }) => {
        setContracts(data)
      })
      .finally(() => {
        setLoadingSelect(false)
      })
  }

  const openDrawer = () => {
    setVisible(true)
  }

  useEffect(() => {
    if (filter) {
      onSubmit(filter)
    }
  }, [])

  const onSubmit = async (value) => {
    setLoadingTable(true)
    setFilter(value)
    try {
      await api
        .get(`/reports/item-balance/`, {
          params: {
            clientId: value.clientId,
            contractId: value.contractId,
            itemId: value.itemId?.toString(),
            // date: new Date(value.dataInicio).toISOString().slice(0, 10),
          },
        })
        .then(({ data }) => {
          setData(data)
        })
    } catch (err) {
      message.error('Informe as datas corretas')
      setLoadingTable(false)
    } finally {
      setLoadingTable(false)
    }
  }

  const menu = () => (
    <Menu>
      <Menu.Item key='1'>
        <Button
          icon={<DownloadOutlined />}
          disabled={data.length < 1}
          onClick={() => tableExportToPdf()}
          style={{ width: '100%' }}
        >
          Baixar Relatório
        </Button>
      </Menu.Item>
    </Menu>
  )

  const tableExportToPdf = async () => {
    setLoadingTable(true)

    const dateInicio = format(new Date(), 'yyyy/MM/dd')

    await axios
      .post(
        `${pdfUrl}item-balance-report/`,
        { user, data },
        {
          responseType: 'blob',
        }
      )
      .then((response) => {
        message.success({
          content: 'Baixando PDF...',
        })
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `${dateInicio
            .replace('/', '-')
            .replace('/', '-')
            .replace('/', '-')}.pdf`
        )
        document.body.appendChild(link)
        link.click()
      })
    setLoadingTable(false)
  }

  return (
    <Container>
      <Helmet>
        <title>Arcke | Relatório - Consumo de Itens</title>
      </Helmet>
      <Skeleton loading={false} active>
        <PageTitle
          title='Relatório de Saldo de Item por Contrato'
          extra={[
            <Dropdown
              key='1'
              trigger={['click']}
              overlay={menu}
              placement='bottomCenter'
            >
              <Button size='large'>
                Exportar Tabela <DownOutlined />
              </Button>
            </Dropdown>,
            <Button
              icon={<FilterOutlined />}
              type='primary'
              size='large'
              onClick={() => {
                openDrawer()
              }}
              key='2'
            >
              Filtrar
            </Button>,
          ]}
        />
        <Table
          dataSource={data}
          scroll={{ x: 1500 }}
          pagination={{
            total: data?.length,
          }}
          loading={loadingTable}
          rowKey={(record) => record.id}
          size='middle'
          style={{ margin: 20 }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <>
                    <span>Não há registro aqui</span>
                  </>
                }
              >
                <Button
                  icon={<PlusOutlined />}
                  key='insert'
                  type='primary'
                  size='large'
                  onClick={() => {
                    openDrawer()
                  }}
                >
                  Adicionar
                </Button>
              </Empty>
            ),
          }}
        >
          <Column
            title='ID'
            dataIndex='id'
            width={100}
            key='id'
            fixed
            render={(_, record) => <Tag>{record?.id}</Tag>}
          />
          <Column
            title='Contrato'
            width={250}
            fixed
            dataIndex='name'
            key='name'
          />
          <Column
            title='Valor'
            dataIndex='value'
            width={120}
            key='value'
            render={(_, record) => formatarMoeda(record?.value)}
          />
          <Column
            title='Itens'
            width={170}
            dataIndex='quantity'
            key='quantity'
            render={(_, record) => record?.items?.length}
          />
        </Table>
        <Drawer
          visible={visible}
          onClose={onClose}
          width={550}
          title='Filtro de Relatório'
        >
          <Spin spinning={false}>
            <Form form={form} layout='vertical' onFinish={onSubmit}>
              <Form.Item
                rules={[{ required: true, message: 'Selecione um cliente' }]}
                label='Clientes'
                name='clientId'
              >
                <Select
                  size='large'
                  showSearch
                  placeholder='Selecione...'
                  allowClear
                  notFoundContent={loadingSelect ? <Spin /> : null}
                  onFocus={() => {
                    if (clientes.length < 1) {
                      loadClients()
                    }
                  }}
                  onChange={(value) => {
                    loadContracts(value)
                  }}
                  optionLabelProp='label'
                  optionFilterProp='children'
                >
                  {clientes.map((cliente) => (
                    <Select.Option
                      key={cliente.id}
                      value={cliente.id}
                      label={cliente.nomeFantasia}
                    >
                      {cliente.nomeFantasia}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label='Contrato' name='contractId'>
                <Select
                  size='large'
                  showSearch
                  placeholder='Selecione...'
                  optionLabelProp='label'
                  allowClear
                  notFoundContent={loadingSelect ? <Spin /> : null}
                  optionFilterProp='children'
                >
                  {contracts.map((contrato) => (
                    <Select.Option
                      key={contrato.id}
                      value={contrato.id}
                      label={`Contrato: ${contrato.name}`}
                    >
                      {`Contrato: ${contrato.name}`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label='Items' name='itemId'>
                <Select
                  size='large'
                  mode='multiple'
                  placeholder='Selecione...'
                  optionLabelProp='label'
                  optionFilterProp='children'
                  maxTagCount='responsive'
                  allowClear
                  onFocus={async () => {
                    await api.get('/item').then(({ data }) => {
                      setItems(data)
                    })
                  }}
                >
                  {items.map((item) => (
                    <Select.Option
                      key={item.id}
                      value={item.id}
                      label={item.description}
                    >
                      {item.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {/* <Form.Item label='Data de Inicio' name='dataInicio'>
                <DatePicker
                  style={{ width: '100%' }}
                  onChange={(value) => {
                    setDate(value)
                  }}
                />
              </Form.Item> */}
              <Divider style={{ borderColor: '#aaa' }} />
              <div style={{ display: 'flex' }}>
                <Enviar
                  type='primary'
                  htmlType='submit'
                  size='large'
                  style={{ flex: 1 }}
                >
                  Gerar Relatório
                </Enviar>
                <Cancelar
                  danger
                  onClick={onClose}
                  size='large'
                  style={{ flex: 1 }}
                >
                  Cancelar
                </Cancelar>
              </div>
            </Form>
          </Spin>
        </Drawer>
      </Skeleton>
    </Container>
  )
}

export { RelatorioConsumoItems }
