import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Drawer,
  Input,
  message,
  Select,
  Spin,
  Form,
  Switch,
  Button,
  Divider,
} from 'antd'
import { Cancelar, Enviar } from 'components/Buttons'
import DrawerContext from 'contexts/DrawerContext'
import React, { useContext, useEffect, useState } from 'react'
import api from 'services/api'
import { DrawerTipoServico } from '../../pages/TipoServico/Drawer/index'
import { DrawerTipoEquipamentos } from '../../pages/TipoEquipamentos/Drawer/index'

const { Option } = Select

const DrawerItems = ({ item }) => {
  const { load, open, mode, setLoad, setMode, setOpen } =
    useContext(DrawerContext)

  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)
  const [typeServices, setTypeServices] = useState([])
  const [tiposEquipamentos, setTiposEquipamentos] = useState([])
  const [
    drawerCreateServiceTypeIsVisible,
    setDrawerCreateServiceTypeIsVisible,
  ] = useState(false)
  const [
    drawerCreateEquipmentTypeIsVisible,
    setDrawerCreateEquipmentTypeIsVisible,
  ] = useState(false)

  const openDrawerCreateServiceType = () => {
    setDrawerCreateServiceTypeIsVisible(true)
  }

  const openDrawerCreateEquipmentType = () => {
    setDrawerCreateEquipmentTypeIsVisible(true)
  }

  const onCloseDrawerCreateServiceTypeIsVisible = () => {
    setDrawerCreateServiceTypeIsVisible(false)
    loadTipoServico()
  }

  const onCloseDrawerCreateEquipmentTypeIsVisible = () => {
    setDrawerCreateEquipmentTypeIsVisible(false)
    loadTipoEquipamentos()
  }

  useEffect(() => {
    async function load() {
      openDrawer()
      if (mode === 'update') {
        form.setFieldsValue({
          ...item,
          unityValue: Number(item.unityValue),
          tiposEquipamentos: item.tiposEquipamentos.map((tipo) => tipo.id),
          servicesType: item.servicesType.map((tipo) => tipo.id),
        })
      }
    }
    load()
  }, [mode])

  const onSave = async (value) => {
    setLoading(true)
    if (mode === 'insert') {
      const data = {
        ...value,
        description: value.description.toUpperCase(),
      }
      await api
        .post('/item', data)
        .then(() => {
          setLoading(false)
          message.success('Cadastro realizado com sucesso!')
          onClose()
          setLoad(!load)
        })
        .finally(() => setLoading(false))
    } else {
      const data = {
        ...value,
        description: value.description.toUpperCase(),
      }
      await api
        .put(`/item/${item.id}`, data)
        .then(() => {
          setLoading(false)
          message.success('Cadastro realizado com sucesso!')
          onClose()
          setLoad(!load)
        })
        .finally(() => setLoading(false))
    }
  }

  const openDrawer = () => {
    loadTipoServico()
    loadTipoEquipamentos()
  }

  const loadTipoServico = async () => {
    await api.get('/servicetype').then(({ data }) => {
      setTypeServices(data)
    })
  }

  const loadTipoEquipamentos = async () => {
    await api.get('/equipmenttype').then(({ data }) => {
      setTiposEquipamentos(data)
    })
  }

  const onClose = () => {
    form.resetFields()
    setMode('insert')
    setOpen(false)
    setLoad(!load)
  }

  return (
    <Drawer
      visible={open}
      onClose={onClose}
      width={550}
      title={`${
        mode === 'insert' ? 'Cadastrar' : 'Atualizar'
      } Item de Contrato`}
    >
      <Spin spinning={loading}>
        <Form form={form} layout='vertical' onFinish={onSave}>
          <Form.Item
            label='Descrição'
            name='description'
            rules={[
              {
                required: true,
                max: 500,
                message: 'Por favor, a descrição deve ser informada',
              },
            ]}
          >
            <Input.TextArea
              autoSize={{ minRows: 3, maxRows: 7 }}
              size='large'
              showCount
              maxLength={500}
            />
          </Form.Item>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Form.Item
              label='Tipo serviço'
              name='servicesType'
              style={{
                width: '100%',
              }}
              rules={[
                {
                  required: true,
                  message: 'Por favor, o tipo de serviço deve ser informado',
                },
              ]}
            >
              <Select
                size='large'
                style={{ width: '100%' }}
                placeholder='Selecione...'
                optionLabelProp='label'
                onDropdownVisibleChange={loadTipoServico}
                mode='multiple'
              >
                {typeServices.map((service) => (
                  <Option
                    key={service.id}
                    value={service.id}
                    label={service.name}
                  >
                    {service.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Button
              size='large'
              style={{
                padding: '0 11px',
                borderColor: '#aaa',
                marginBottom: -5,
              }}
              onClick={() => {
                openDrawerCreateServiceType()
              }}
            >
              <PlusOutlined />
            </Button>
            <DrawerTipoServico
              isVisible={drawerCreateServiceTypeIsVisible}
              onClose={onCloseDrawerCreateServiceTypeIsVisible}
              loading={loading}
              setLoading={setLoading}
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Form.Item
              label='Tipos de equipamentos'
              name='tiposEquipamentos'
              style={{ width: '100%' }}
              rules={[
                {
                  required: true,
                  message:
                    'Por favor, os tipos de equipamentos devem ser selecionados',
                },
              ]}
            >
              <Select
                size='large'
                style={{ width: '100%' }}
                placeholder='Selecione...'
                optionLabelProp='label'
                mode='multiple'
              >
                {tiposEquipamentos.map((tipo) => (
                  <Option key={tipo.id} value={tipo.id} label={tipo.name}>
                    {tipo.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Button
              size='large'
              style={{
                padding: '0 11px',
                borderColor: '#aaa',
                marginBottom: -5,
              }}
              onClick={() => {
                openDrawerCreateEquipmentType()
              }}
            >
              <PlusOutlined />
            </Button>
            <DrawerTipoEquipamentos
              isVisible={drawerCreateEquipmentTypeIsVisible}
              onClose={onCloseDrawerCreateEquipmentTypeIsVisible}
              loading={loading}
              setLoading={setLoading}
            />
          </div>
          <Form.Item
            label='Unidade'
            name='unit'
            rules={[
              {
                required: true,
                message: 'Por favor, a unidade deve ser informada',
              },
            ]}
          >
            <Select
              size='large'
              placeholder='Selecione...'
              optionLabelProp='label'
            >
              <Option key='UN' value='UN' label='UN - Unidade'>
                UN - Unidade
              </Option>
              <Option key='CX' value='CX' label='CX - Caixa'>
                CX - Caixa
              </Option>
              <Option key='CJ' value='CJ' label='CJ - Conjunto'>
                CJ - Conjunto
              </Option>
              <Option key='KIT' value='KIT' label='KIT - Kit'>
                KIT - Kit
              </Option>
              <Option key='BR' value='BR' label='BR - Barra'>
                BR - Barra
              </Option>
              <Option key='M1' value='M1' label='M - Metro'>
                M - Metro
              </Option>
              <Option key='M2' value='M2' label='M2 - Metro Quadrado'>
                M2 - Metro Quadrado
              </Option>
              <Option key='M3' value='M3' label='M3 - Metro Cúbico'>
                M3 - Metro Cúbico
              </Option>
              <Option key='GL' value='GL' label='GL - Galão'>
                GL - Galão
              </Option>
              <Option key='HRS' value='HRS' label='HRS - Horas Trabalhadas'>
                HRS - Horas trabalhadas
              </Option>
              <Option key='KG' value='KG' label='KG - Quilograma'>
                KG - Quilograma
              </Option>
              <Option key='LT' value='LT' label='LT - Litro'>
                LT - Litro
              </Option>
              <Option key='SERV' value='SERV' label='SERV - Serviço'>
                SERV - Serviço
              </Option>
              <Option key='TON' value='TON' label='TON - Tonelada'>
                TON - Tonelada
              </Option>
              <Option key='PC' value='PC' label='PC - Pacote'>
                PC - Pacote
              </Option>
              <Option key='ROLO' value='ROLO' label='ROLO - Rolo'>
                ROLO - Rolo
              </Option>
            </Select>
          </Form.Item>
          <Form.Item
            label='Consumível'
            valuePropName='checked'
            name='isConsumible'
            initialValue={false}
          >
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </Form.Item>
          <Divider style={{ borderColor: '#bbb' }} />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Enviar
              disabled={mode === 'view'}
              type='primary'
              htmlType='submit'
              size='large'
              style={{ flex: 1 }}
            >
              {mode === 'insert' ? 'Cadastrar' : 'Atualizar'}
            </Enviar>
            <Cancelar danger onClick={onClose} size='large' style={{ flex: 1 }}>
              Cancelar
            </Cancelar>
          </div>
        </Form>
      </Spin>
    </Drawer>
  )
}

export { DrawerItems }
