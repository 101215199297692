import React, { useEffect, useState } from 'react'
import {
  Skeleton,
  message,
  Table,
  Space,
  Button,
  Drawer,
  Descriptions,
  Modal,
  Typography,
  Tooltip,
  Form,
  Spin,
  InputNumber,
  Select,
  Popconfirm,
  Divider,
} from 'antd'
import { DeleteOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { useLocation, useParams, Link } from 'react-router-dom'
import { Container, PageTitle } from 'components'
import { formatarMoeda } from 'services/utils'
import { Enviar, Cancelar } from 'components/Buttons'
import { format, parseISO } from 'date-fns'
import api from 'services/api'

const { Column } = Table
const { Text, Paragraph } = Typography

function Aditivos() {
  const location = useLocation()

  const { itemId, id } = useParams()
  const [contract] = useState(location.state)
  const [itemLocal, setItemLocal] = useState({})
  const [form] = Form.useForm()
  const [itens, setItens] = useState([])
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [visible, setVisible] = useState(false)
  const [mode, setMode] = useState('insert')
  const [load, setLoad] = useState(false)
  const [item, setItem] = useState(null)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    async function getData() {
      console.log(contract)
      try {
        setLoading(true)
        const { data } = await api.get(
          `/itemlocal/itens_detalhados?contractId=${id}&itemId=${itemId}`
        )
        setItemLocal(data)
      } catch (error) {
        message.error('Erro ao carregar os dados, atualize a pagina')
      } finally {
        setLoading(false)
      }
    }
    getData()
  }, [])

  useEffect(() => {
    async function loadData() {
      setLoading(true)
      try {
        await api
          .get(`/historicoaditivo?itemLocalId=${itemId}`)
          .then(({ data }) => {
            setItens(data)
          })

        setItemLocal(itemLocal)
      } catch (error) {
        message.error('Erro ao carregar os dados, atualize a pagina')
      } finally {
        setLoading(false)
      }
    }
    loadData()
  }, [load])

  const onClose = () => {
    form.resetFields()
    setMode('insert')
    setVisible(false)
  }

  const handleSubmitForm = (value) => {
    const data = {
      itemLocalId: itemId,
      ...value,
    }
    console.log(data)
    setSaving(false)

    api
      .post('/historicoaditivo', data)
      .then(() => {
        message.success('Cadastro realizado com sucesso!')
        onClose()
        setLoad(!load)
      })
      .catch((e) => {
        if (e.response.data.errors) {
          e.response.data.errors.forEach((info) => {
            message.error(info.message)
          })
        } else {
          message.error(e.response.data.error)
        }
      })
      .finally(() => setSaving(false))
  }

  const routes = [
    {
      path: '/contratos',
      breadcrumbName: 'Contratos',
    },
    {
      path: `/contratos/${id}/itens`,
      breadcrumbName: 'Itens do Contrato',
    },
  ]

  function itemRender(route) {
    return (
      <Link to={{ pathname: route.path }} key={route.breadcrumbName}>
        {route.breadcrumbName}
      </Link>
    )
  }

  return (
    <Container>
      <Skeleton loading={loading} active>
        <PageTitle
          title={`Aditivos do Item`}
          breadcrumb={{ routes, itemRender }}
          breadcrumbRender={(props, breadcrumb) => (
            <div style={{ marginBottom: -4 }}>{breadcrumb}</div>
          )}
          extra={[
            <Button
              type='primary'
              size='large'
              icon={<PlusOutlined />}
              onClick={() => {
                setMode('insert')
                setVisible(true)
              }}
              key='1'
            >
              Adicionar Aditivo ao Item
            </Button>,
          ]}
        ></PageTitle>
        <div
          style={{
            backgroundColor: '#fff',
            height: 50,
            display: 'flex',
            alignItems: 'center',
            padding: '0 10px',
            marginTop: 10,
            margin: '10px',
          }}
        >
          <Descriptions size='small' column={2}>
            <Descriptions.Item label='Item'>
              {itemLocal.item ? itemLocal.item.description : ''}
            </Descriptions.Item>
            <Descriptions.Item label='Valor'>
              {formatarMoeda(itemLocal.unityValue)}
            </Descriptions.Item>
          </Descriptions>
        </div>
        <Table
          dataSource={itens}
          loading={loading}
          rowKey={(record) => record.id}
          size='middle'
          style={{ margin: 20 }}
        >
          {/* <Column title='Número' dataIndex='id' key='id' /> */}
          <Column
            title='Valor Antigo'
            dataIndex='historicoQuantidade'
            key='historicoQuantidade'
            render={(_, record) => record.historicoQuantidade}
          />
          <Column
            title='Novo Valor'
            dataIndex='aditivoQuantidade'
            key='aditivoQuantidade'
            render={(_, record) => record.aditivoQuantidade}
          />
          <Column
            title='Ações'
            dataIndex='actions'
            key='actions'
            render={(_, record) => (
              <Space>
                <Tooltip title='Visualizar' placement='top'>
                  <Button
                    icon={<SearchOutlined />}
                    onClick={() => {
                      setItem(record)
                      setOpen(true)
                    }}
                  />
                </Tooltip>
                {record.id === itens[itens.length - 1].id && (
                  <Tooltip title='Deletar' placement='top'>
                    <Popconfirm
                      title='Tem certeza que quer remover esse aditivo?'
                      onConfirm={() => {
                        api
                          .delete(`/historicoaditivo/${record.id}`)
                          .then(() => {
                            message.success('Cadastro deletado com sucesso!')
                          })
                          .catch((e) => {
                            if (e.response.data.errors) {
                              e.response.data.errors.forEach((info) => {
                                message.error(info.message)
                              })
                            } else {
                              message.error(e.response.data.error)
                            }
                          })
                          .finally(() => setLoad(!load))
                      }}
                      okText='Sim'
                      cancelText='Nao'
                    >
                      <Button icon={<DeleteOutlined />} />
                    </Popconfirm>
                  </Tooltip>
                )}
              </Space>
            )}
          />
        </Table>
        <Drawer
          visible={visible}
          onClose={onClose}
          width={550}
          destroyOnClose
          title='Adicionar Aditivo ao Item'
        >
          <Spin spinning={saving}>
            <Form form={form} layout='vertical' onFinish={handleSubmitForm}>
              <Form.Item
                label='Tipo'
                name='type'
                rules={[
                  {
                    required: true,
                    message: 'Por favor, o tipo deve ser informado',
                  },
                ]}
              >
                <Select
                  size='large'
                  style={{ width: '100%' }}
                  placeholder='Selecione o tipo de calculo...'
                >
                  <Select.Option value={'percentage'}>
                    Porcentagem
                  </Select.Option>
                  <Select.Option value={'quantity'}>Quantidade</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                label='Quantidade'
                name='quantity'
                rules={[
                  {
                    required: true,
                    message: 'Por favor, a quantidade deve ser informada',
                  },
                ]}
              >
                <InputNumber size='large' style={{ width: '100%' }} min={1} />
              </Form.Item>
              <Divider style={{ borderColor: '#aaa' }} />
              <div style={{ display: 'flex' }}>
                <Enviar
                  disabled={mode === 'view'}
                  type='primary'
                  htmlType='submit'
                  style={{ flex: 1 }}
                  size='large'
                >
                  {mode === 'insert' ? 'Adicionar' : 'Atualizar'}
                </Enviar>
                <Cancelar
                  danger
                  onClick={onClose}
                  style={{ flex: 1 }}
                  size='large'
                >
                  Cancelar
                </Cancelar>
              </div>
            </Form>
          </Spin>
        </Drawer>
        <Modal
          title='Aditivo'
          visible={open}
          onOk={() => setOpen(false)}
          onCancel={() => setOpen(false)}
          footer={null}
        >
          {item && (
            <>
              <Text strong>ID</Text>
              <Paragraph>{item.id}</Paragraph>
              <Text strong>Historico</Text>
              <Paragraph>{item.historicoQuantidade}</Paragraph>
              <Text strong>Quantidade Aditivo</Text>
              <Paragraph>{item.aditivoQuantidade}</Paragraph>
              <Text strong>Data do Aditivo</Text>
              <Paragraph>
                {format(parseISO(item.createdAt), 'dd/MM/yyyy')}
              </Paragraph>
            </>
          )}
        </Modal>
      </Skeleton>
    </Container>
  )
}

export default Aditivos
