/* eslint-disable no-param-reassign */
import { message } from 'antd'
import axios from 'axios'
import { TOKEN_API } from 'contexts/auth'

const api = axios.create({ baseURL: process.env.REACT_APP_API })
export const pdfUrl = process.env.REACT_APP_PDF

api.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem(TOKEN_API)}`
  config.headers.common.Authorization = `Bearer ${localStorage.getItem(
    TOKEN_API
  )}`
  return config
})

api.interceptors.response.use(undefined, (error) => {
  if (error.response) {
    if (error.response.status === 401) {
      if (error.response.data.errors) {
        message.error('Erro ao completar requisição, tente novamente!')
      } else {
        message.error(error.response.data.error)
      }
    }
  }
  return Promise.reject(error)
})

export default api
