import React, { useContext, useEffect, useState } from 'react'
import {
  Skeleton,
  message,
  Table,
  Space,
  Button,
  Drawer,
  Tooltip,
  Form,
  Spin,
  Select,
  Checkbox,
  Menu,
  Dropdown,
  Empty,
  Divider,
} from 'antd'
import DatePicker from '../../components/DatePicker'
import {
  DeleteOutlined,
  FilterOutlined,
  DownloadOutlined,
  DownOutlined,
  SettingOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import api, { pdfUrl } from '../../services/api'
import { Container, PageTitle } from '../../components'
import { Enviar, Cancelar } from '../../components/Buttons'
import { format } from 'date-fns'
import { formatarMoeda } from 'services/utils'
import AuthContext from 'contexts/auth'
import { Helmet } from 'react-helmet'
import axios from 'axios'

const { Column } = Table

const status = [
  {
    id: 1,
    status: 'aberto',
    label: 'Aberto',
  },
  {
    id: 2,
    status: 'execucao',
    label: 'Execução',
  },
  {
    id: 3,
    status: 'aguardando',
    label: 'Aguardando',
  },
  {
    id: 4,
    status: 'finalizado',
    label: 'Finalizado',
  },
  {
    id: 5,
    status: 'reaberto',
    label: 'Reaberto',
  },
  {
    id: 6,
    status: 'atrasado',
    label: 'Atrasado',
  },
  {
    id: 6,
    status: 'validado',
    label: 'Validado',
  },
]

function Relatorios() {
  const { user } = useContext(AuthContext)
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [mode, setMode] = useState('insert')
  const [load, setLoad] = useState(false)
  const [filtrados, setFiltrados] = useState([])
  const [tecnicos, setTecnicos] = useState([])
  const [date, setDate] = useState(null)
  const [contracts, setContracts] = useState([])
  const [items, setItems] = useState([])
  const [clientes, setClientes] = useState([])

  const [checkedList, setCheckedList] = useState([])
  const [indeterminate, setIndeterminate] = useState(true)
  const [checkAll, setCheckAll] = useState(false)

  const [filterQuery, setFilterQuery] = useState({})

  useEffect(() => {
    async function loadEquipments() {
      setLoading(true)
      try {
        await api.get(`/user?accessProfile=tecnico`).then(({ data }) => {
          setTecnicos(data)
        })
        await api.get('/contract/os').then(({ data }) => {
          setContracts(data)
        })
        await api.get('/item').then(({ data }) => {
          setItems(data)
        })
        await api.get('/client').then(({ data }) => {
          setClientes(data)
        })
        setLoading(false)
        setLoad(false)
      } catch (error) {
        setLoading(false)
        setLoad(false)
        message.error('Erro ao carregar os dados, atualize a pagina')
      }
    }
    loadEquipments()
  }, [load])

  const onClose = () => {
    setVisible(false)
  }

  const openDrawer = (record) => {
    setVisible(true)
  }

  function disabledDate(current) {
    return current && current < date
  }

  const loadItems = async (contratoId) => {
    try {
      await api.get(`/item?contractId=${contratoId}`).then(({ data }) => {
        setItems(data)
      })
    } catch (error) {
      setLoading(false)

      message.error('Erro ao carregar os dados, atualize a pagina')
    }
  }

  const onChange = (list) => {
    setCheckedList(list)
    setIndeterminate(!!list.length && list.length < tecnicos.length)
    setCheckAll(list.length === tecnicos.length)
  }

  const onCheckAllChange = (e) => {
    const ids = tecnicos.map((tecnico) => {
      return tecnico.id
    })
    setCheckedList(
      e.target.checked
        ? form.setFieldsValue({
            tecnicoId: ids,
          })
        : form.setFieldsValue({
            tecnicoId: [],
          })
    )
    setIndeterminate(false)
    setCheckAll(e.target.checked)
  }

  const tableExportToPdf = async () => {
    const data = { ...filterQuery }
    const dataFim = data.dataFim
      ? format(new Date(data.dataFim), 'yyyy/MM/dd')
      : false
    const dateInicio = format(new Date(data.dataInicio), 'yyyy/MM/dd')

    api
      .get(
        `reports/detailed-report-comission/?${
          data.tecnicoId ? `tecnicoId=${data.tecnicoId}` : ''
        }&dataInicio=${dateInicio}&dataFim=${data.dataFim ? `${dataFim}` : ''}${
          data.contractId ? `&contractId=${data.contractId}` : ''
        }${data.itemId ? `&itemId=${data.itemId}` : ''}${
          data.clientId ? `&clientId=${data.clienteId}` : ''
        }${data.status ? `&status=${data.status}` : ''}`
      )
      .then(async ({ data }) => {
        user.periodo = {
          start: dateInicio,
          end: dataFim,
        }
        await axios
          .post(
            `${pdfUrl}comission-reports/`,
            { user, data },
            {
              responseType: 'blob',
            }
          )
          .then((response) => {
            message.success({
              content: 'Baixando PDF...',
            })
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute(
              'download',
              `${dateInicio
                .replace('/', '-')
                .replace('/', '-')
                .replace('/', '-')}.pdf`
            )
            document.body.appendChild(link)
            link.click()
          })
      })
  }

  const apiResponse = async (value) => {
    setFilterQuery(value)
    return new Promise((resolve, reject) => {
      const data = { ...value }
      const dataFim = data.dataFim ? format(data.dataFim, 'yyyy-MM-dd') : null
      try {
        api
          .get(`reports/detailed-report-comission/`, {
            params: {
              dataInicio: format(data.dataInicio, 'yyyy-MM-dd'),
              dataFim,
              tecnicoId: data.tecnicoId.toString(),
              contractId: data.contractId,
              itemId: data.itemId,
              clientId: data.clienteId,
              status: data.status,
            },
          })
          .then((data) => {
            resolve(data)
          })
      } catch (err) {
        message.error('Informe as datas corretas')
        reject(err)
      }
    })
  }

  const onSubmit = async (value) => {
    await apiResponse(value).then((data) => {
      setFiltrados(data.data)
    })
  }

  const menu = (
    <Menu>
      <Menu.Item key='1'>
        <Button
          icon={<DownloadOutlined />}
          onClick={async () => {
            tableExportToPdf()
          }}
        >
          Baixar Tabela - PDF
        </Button>
      </Menu.Item>
      <Menu.Item key='2'>
        <Button
          disabled={true}
          icon={<DownloadOutlined />}
          onClick={async () => {
            // const csvtest = cosnvertCSV(filtrados)
            // const date = new Date()
            // const blob = new Blob([csvtest], {
            //   type: 'text/csv;charset=utf-8;',
            // })
          }}
        >
          Baixar Tabela - CSV
        </Button>
      </Menu.Item>
    </Menu>
  )

  const menuTabela = (record) => (
    <Menu>
      <Menu.Item key='1'>
        <Button
          icon={<DownloadOutlined />}
          style={{ width: '100%' }}
          onClick={async () => {
            console.log(record)
          }}
        >
          Baixar Relatório
        </Button>
      </Menu.Item>
      <Menu.Item key='3'>
        <Button
          icon={<DeleteOutlined />}
          style={{ color: '#e80909', width: '100%' }}
          onClick={async () => {
            const index = filtrados.findIndex((item) => record.id === item.id)
            const filtradosArray = [...filtrados]
            filtradosArray.splice(index, 1)
            setFiltrados(filtradosArray)
            message.success('Item excluido com sucesso')
          }}
        >
          Excluir Linha
        </Button>
      </Menu.Item>
    </Menu>
  )

  return (
    <Container>
      <Helmet>
        <title>Arcke | Relatório - Comissões</title>
      </Helmet>
      <Skeleton loading={loading} active>
        <PageTitle
          title='Relatório de Comissão Líquida Simplificada'
          extra={[
            <Dropdown
              key='1'
              trigger={['click']}
              disabled={false || filtrados.length < 1}
              overlay={menu}
              placement='bottomCenter'
            >
              <Button size='large'>
                Exportar Tabela <DownOutlined />
              </Button>
            </Dropdown>,
            <Button
              icon={<FilterOutlined />}
              type='primary'
              size='large'
              onClick={() => {
                setMode('insert')
                openDrawer()
              }}
              key='2'
            >
              Filtrar
            </Button>,
          ]}
        />
        <Table
          dataSource={filtrados}
          loading={loading}
          rowKey={(record) => record.id}
          size='middle'
          style={{ margin: 20 }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <>
                    <span>Não há registro aqui</span>
                  </>
                }
              >
                <Button
                  icon={<PlusOutlined />}
                  key='insert'
                  type='primary'
                  size='large'
                  onClick={() => {
                    setMode('insert')
                    openDrawer()
                  }}
                >
                  Adicionar
                </Button>
              </Empty>
            ),
          }}
        >
          <Column title='Nome' dataIndex='name' key='name' />
          <Column
            title='Comissão'
            dataIndex='comissao'
            key='comissao'
            render={(text) => `${formatarMoeda(Number(text))}`}
          />
          <Column title='Serviços Realizados(OS)' dataIndex='os' key='os' />
          <Column
            title='Serviços Realizados(Orçamentos)'
            dataIndex='singleos'
            key='singleos'
          />
          <Column
            title='Ações'
            dataIndex='actions'
            key='actions'
            render={(_, record) => (
              <Space>
                <Dropdown
                  trigger={['click']}
                  key='1'
                  disabled={false || filtrados.length < 1}
                  overlay={menuTabela(record)}
                  placement='bottomCenter'
                >
                  <Button>
                    Ações <SettingOutlined />
                  </Button>
                </Dropdown>
              </Space>
            )}
          />
        </Table>
        <Drawer
          visible={visible}
          onClose={onClose}
          width={550}
          title='Filtro de Relatório'
        >
          <Spin spinning={loading}>
            <Form
              form={form}
              layout='vertical'
              onFinish={onSubmit}
              defaultValue={{ tecnicoId: checkedList }}
            >
              <Form.Item label='Técnico'>
                <Space style={{ width: '100%' }}>
                  <Form.Item
                    name='tecnicoId'
                    rules={[
                      {
                        required: true,
                        message: 'Selecine ao menos um técnico',
                      },
                    ]}
                    noStyle
                  >
                    <Select
                      size='large'
                      showSearch
                      style={{ width: 480 }}
                      placeholder='Selecione...'
                      optionLabelProp='label'
                      optionFilterProp='children'
                      allowClear
                      mode='multiple'
                      onChange={onChange}
                      maxTagCount='responsive'
                    >
                      {tecnicos.map((tecnico) => (
                        <Select.Option
                          key={tecnico.id}
                          value={tecnico.id}
                          label={tecnico.name}
                        >
                          {tecnico.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Tooltip placement='topLeft' title='Selecionar todos.'>
                    <Checkbox
                      indeterminate={indeterminate}
                      onChange={onCheckAllChange}
                      checked={checkAll}
                    />
                  </Tooltip>
                </Space>
              </Form.Item>
              <Form.Item label='Data de início' name='dataInicio'>
                <DatePicker
                  style={{ width: '100%' }}
                  onChange={(value) => {
                    setDate(value)
                  }}
                  size='large'
                />
              </Form.Item>
              <Form.Item label='Data de finalização' name='dataFim'>
                <DatePicker
                  size='large'
                  style={{ width: '100%' }}
                  disabled={date === null}
                  disabledDate={disabledDate}
                />
                {/* ARRAY['aberto'::text, 'execucao'::text, 'aguardando'::text, 'finalizado'::text, 'reaberto'::text, 'atrasado'::text])) */}
              </Form.Item>
              <Form.Item label='Status' name='status'>
                <Select
                  size='large'
                  showSearch
                  placeholder='Selecione...'
                  allowClear
                  optionLabelProp='label'
                  optionFilterProp='children'
                >
                  {status.map((stats) => (
                    <Select.Option
                      key={stats.id}
                      value={stats.status}
                      label={stats.label}
                    >
                      {stats.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label='Contrato' name='contractId'>
                <Select
                  size='large'
                  showSearch
                  placeholder='Selecione...'
                  optionLabelProp='label'
                  allowClear
                  optionFilterProp='children'
                  onChange={(value) => {
                    if (value !== undefined) loadItems(value)
                  }}
                >
                  {contracts.map((contrato) => (
                    <Select.Option
                      key={contrato.id}
                      value={contrato.id}
                      label={`Contrato: ${contrato.name}`}
                    >
                      {`Contrato: ${contrato.name}`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label='Itens' name='itemId'>
                <Select
                  size='large'
                  mode='multiple'
                  placeholder='Selecione...'
                  optionLabelProp='label'
                  optionFilterProp='children'
                  maxTagCount='responsive'
                  allowClear
                >
                  {items.map((item) => (
                    <Select.Option
                      key={item.id}
                      value={item.id}
                      label={item.description}
                    >
                      {item.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label='Clientes' name='clienteId'>
                <Select
                  size='large'
                  showSearch
                  placeholder='Selecione...'
                  allowClear
                  optionLabelProp='label'
                  optionFilterProp='children'
                >
                  {clientes.map((cliente) => (
                    <Select.Option
                      key={cliente.id}
                      value={cliente.id}
                      label={cliente.nomeFantasia}
                    >
                      {cliente.nomeFantasia}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Divider style={{ borderColor: '#aaa' }} />
              <div style={{ display: 'flex' }}>
                <Enviar
                  size='large'
                  disabled={date === null}
                  type='primary'
                  htmlType='submit'
                  style={{ flex: 1 }}
                >
                  Gerar Relatório
                </Enviar>
                <Cancelar
                  danger
                  disabled={mode === null}
                  onClick={onClose}
                  size='large'
                  style={{ flex: 1 }}
                >
                  Cancelar
                </Cancelar>
              </div>
            </Form>
          </Spin>
        </Drawer>
      </Skeleton>
    </Container>
  )
}

export default Relatorios
