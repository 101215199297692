import React from 'react'
import { message, Drawer, Form, Input, Spin, Divider } from 'antd'
import api from '../../../services/api'
import { Cancelar, Enviar } from '../../../components/Buttons'

export function DrawerServicoPMOC({ isVisible, onClose, loading, setLoading }) {
  const [form] = Form.useForm()

  return (
    <Drawer
      visible={isVisible}
      onClose={() => {
        form.resetFields()
        onClose()
      }}
      width={550}
      title={'Cadastrar serviço do PMOC'}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout='vertical'
          onFinish={(value) => {
            setLoading(true)
            const data = {
              question: value.question.toUpperCase(),
            }
            api
              .post('/questions', data)
              .then(() => {
                setLoading(false)
                message.success('Cadastro realizado com sucesso!')
                form.resetFields()
                onClose()
              })
              .catch((e) => {
                setLoading(false)
                if (e.response.data.errors) {
                  e.response.data.errors.forEach((info) => {
                    message.error(info.message)
                  })
                } else {
                  message.error(e.response.data.error)
                }
              })
          }}
        >
          <Form.Item
            label='Serviço de PMOC'
            name='question'
            rules={[
              {
                required: true,
                message: 'Por favor, o serviço deve ser informado.',
              },
            ]}
          >
            <Input size='large' />
          </Form.Item>
          <Divider style={{ borderColor: '#aaa' }} />
          <div style={{ display: 'flex' }}>
            <Enviar
              type='primary'
              htmlType='submit'
              size='large'
              style={{ flex: 1 }}
            >
              Cadastrar
            </Enviar>
            <Cancelar
              size='large'
              danger
              onClick={() => {
                form.resetFields()
                onClose()
              }}
              style={{ flex: 1 }}
            >
              Cancelar
            </Cancelar>
          </div>
        </Form>
      </Spin>
    </Drawer>
  )
}
