import React from 'react'
import { message, Drawer, Form, Input, Spin, Switch, Divider } from 'antd'
import api from '../../../services/api'
import { Cancelar, Enviar } from '../../../components/Buttons'

const features = [
  {
    feature: 'needPmoc',
    label: 'Necessita de PMOC',
    name: 'needPmoc',
  },
  {
    feature: 'model',
    name: 'model',
    label: 'Modelo',
  },
  {
    feature: 'serialNumber',
    name: 'serialNumber',
    label: 'Número de Série',
  },
  {
    feature: 'voltage',
    name: 'voltage',
    label: 'Voltagem',
  },
  {
    feature: 'btus',
    name: 'btus',
    label: 'BTUS',
  },
  {
    feature: 'trs',
    name: 'trs',
    label: 'TRS',
  },
  {
    feature: 'capacity',
    name: 'capacity',
    label: 'Capacidade',
  },
  {
    feature: 'gas',
    name: 'gas',
    label: 'Gás',
  },
  {
    feature: 'brand',
    name: 'brand',
    label: 'Marca',
  },
  {
    feature: 'ventilation',
    name: 'ventilation',
    label: 'Ventilação',
  },
  {
    feature: 'photo',
    name: 'photo',
    label: 'Foto',
  },
  {
    feature: 'date',
    name: 'date',
    label: 'Data de Fabricação',
  },
]

export function DrawerTipoEquipamentos({
  isVisible,
  onClose,
  loading,
  setLoading,
}) {
  const [form] = Form.useForm()

  return (
    <Drawer
      visible={isVisible}
      onClose={onClose}
      width={550}
      title='Cadastrar Tipo de Equipamento'
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout='vertical'
          onFinish={(value) => {
            setLoading(true)
            const data = {
              ...value,
              name: value.name.toUpperCase(),
            }
            api
              .post('/equipmenttype', data)
              .then(() => {
                setLoading(false)
                message.success('Cadastro realizado com sucesso!')
                onClose()
              })
              .catch((e) => {
                setLoading(false)
                if (e.response.data.errors) {
                  e.response.data.errors.forEach((info) => {
                    message.error(info.message)
                  })
                } else {
                  message.error(e.response.data.error)
                }
              })
          }}
        >
          <Form.Item
            label='Tipo de equipamento'
            name='name'
            rules={[
              {
                required: true,
                message: 'Por favor, o nome do tipo deve ser informado',
              },
            ]}
          >
            <Input size='large' />
          </Form.Item>
          {features.slice(0, 1).map((feature) => {
            return (
              <Form.Item
                key={feature.feature}
                label={feature.label}
                name={feature.name}
                valuePropName={'checked'}
                style={{ width: '50%' }}
              >
                <Switch size='large' />
              </Form.Item>
            )
          })}
          <Divider style={{ borderColor: '#bbb' }} />
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            {features.slice(1).map((feature) => {
              return (
                <Form.Item
                  key={feature.feature}
                  label={feature.label}
                  name={feature.name}
                  valuePropName={'checked'}
                  style={{ width: '50%' }}
                >
                  <Switch size='large' />
                </Form.Item>
              )
            })}
          </div>
          <Divider style={{ borderColor: '#bbb' }} />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Enviar
              type='primary'
              htmlType='submit'
              size='large'
              style={{ flex: 1 }}
            >
              Cadastrar
            </Enviar>
            <Cancelar danger onClick={onClose} size='large' style={{ flex: 1 }}>
              Cancelar
            </Cancelar>
          </div>
        </Form>
      </Spin>
    </Drawer>
  )
}
