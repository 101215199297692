/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Table,
  Drawer,
  message,
  Space,
  Tooltip,
  Popconfirm,
  Input,
  Form,
  Spin,
  Checkbox,
  Divider,
  Empty,
} from 'antd'
import { Helmet } from 'react-helmet'
import api from '../../services/api'
import { Container, PageTitle } from '../../components'
import { Cancelar, Enviar } from 'components/Buttons'

const { Column } = Table

function Cargos() {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [mode, setMode] = useState('insert')
  const [load, setLoad] = useState(false)
  const [cargos, setCargos] = useState([])
  const [cargo, setCargo] = useState(null)
  const [permissoes, setPermissoes] = useState([])
  const permissionsOptions = {
    contracts: [
      { label: 'Ver', value: 'contracts_list' },
      { label: 'Criar', value: 'contracts_create' },
      { label: 'Atualizar', value: 'contracts_update' },
      { label: 'Deletar', value: 'contracts_delete' },
    ],
    os: [
      { label: 'Ver', value: 'os_list' },
      { label: 'Criar', value: 'os_create' },
      { label: 'Atualizar', value: 'os_update' },
      { label: 'Deletar', value: 'os_delete' },
    ],
    users: [
      { label: 'Ver', value: 'users_list' },
      { label: 'Criar', value: 'users_create' },
      { label: 'Atualizar', value: 'users_update' },
      { label: 'Deletar', value: 'users_delete' },
    ],
    clients: [
      { label: 'Ver', value: 'clients_list' },
      { label: 'Criar', value: 'clients_create' },
      { label: 'Atualizar', value: 'clients_update' },
      { label: 'Deletar', value: 'clients_delete' },
    ],
  }

  const labelPermissions = {
    contracts: 'CONTRATOS',
    os: 'os',
    users: 'USUáRIOS',
    clients: 'CLIENTES',
  }

  useEffect(() => {
    async function loadEquipments() {
      setLoading(true)
      try {
        await api.get('/roles').then(({ data }) => {
          setCargos(data)
        })
        await api.get('/permissions').then(({ data }) => {
          setPermissoes(data)
        })
        setLoading(false)
        setLoad(false)
      } catch (error) {
        setLoading(false)
        setLoad(false)
        if (error) {
          message.error(error.response?.data.error)
          return
        }
        message.error(error.message)
      }
    }
    loadEquipments()
  }, [load])

  const openDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    form.resetFields()
    setMode('insert')
    setVisible(false)
    setLoad(!load)
  }

  const routes = [
    {
      path: '/',
      breadcrumbName: 'Cadastros',
    },
  ]

  return (
    <Container>
      <Helmet>
        <title>Arcke | Perfis de Acesso</title>
      </Helmet>
      <Spin spinning={loading}>
        <PageTitle
          title='Perfis de Acesso'
          breadcrumb={{ routes }}
          breadcrumbRender={(props, breadcrumb) => (
            <div style={{ marginBottom: 4 }}>{breadcrumb}</div>
          )}
          extra={[
            <Button
              type='primary'
              size='large'
              icon={<PlusOutlined />}
              onClick={() => {
                setMode('insert')
                openDrawer()
              }}
              key='1'
            >
              Cadastrar Perfil de Acesso
            </Button>,
          ]}
        />
        <Table
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <>
                    <span>Não há registro aqui</span>
                  </>
                }
              >
                <Button
                  icon={<PlusOutlined />}
                  key='insert'
                  type='primary'
                  size='large'
                  onClick={() => {
                    setMode('insert')
                    openDrawer()
                  }}
                >
                  Adicionar
                </Button>
              </Empty>
            ),
          }}
          dataSource={cargos}
          loading={loading}
          rowKey={(record) => record.id}
          size='middle'
          style={{ margin: 20, borderRadius: 2 }}
        >
          <Column
            title='Perfil de Acesso'
            dataIndex='role_name'
            key='roleName'
          />
          <Column
            title='Ações'
            dataIndex='actions'
            key='actions'
            width={20}
            render={(_, record) => (
              <Space>
                <Tooltip title='Editar' placement='bottom'>
                  <Button
                    icon={<EditOutlined />}
                    onClick={() => {
                      function getPerms(perm, titlePerm) {
                        if (perm.permission_name.split('_')[0] === titlePerm)
                          return perm.permission_name
                      }
                      const permissionsOs = record.permissions
                        .filter((perm) => getPerms(perm, 'os'))
                        .map((perm) => {
                          return perm.permission_name
                        })
                      const permissionsUsers = record.permissions
                        .filter((perm) => getPerms(perm, 'users'))
                        .map((perm) => {
                          return perm.permission_name
                        })
                      const permissionsClients = record.permissions
                        .filter((perm) => getPerms(perm, 'clients'))
                        .map((perm) => {
                          return perm.permission_name
                        })
                      const permissionsContracts = record.permissions
                        .filter((perm) => getPerms(perm, 'contracts'))
                        .map((perm) => {
                          return perm.permission_name
                        })
                      const data = {
                        roleName: record.role_name,
                        os: permissionsOs,
                        users: permissionsUsers,
                        clients: permissionsClients,
                        contracts: permissionsContracts,
                        description: record.description,
                      }
                      console.log(record)
                      form.setFieldsValue(data)
                      setCargo(record)
                      setMode('update')
                      openDrawer()
                    }}
                  />
                </Tooltip>
                <Tooltip title='Excluir Perfil de Acesso' placement='bottom'>
                  <Popconfirm
                    title='Deseja realmente excluir este item?'
                    okText='Sim'
                    cancelText='Não'
                    onConfirm={async () => {
                      await api
                        .delete(`/cargos/${record.id}`)
                        .then(() => {
                          message.success('Item excluido com sucesso')
                          setLoad(true)
                        })
                        .catch(() => {
                          message.error('Erro ao excluir item, tente novamente')
                        })
                    }}
                  >
                    <Button
                      icon={<DeleteOutlined />}
                      style={{ color: '#e80909' }}
                    />
                  </Popconfirm>
                </Tooltip>
              </Space>
            )}
          />
        </Table>
        <Drawer
          visible={visible}
          onClose={onClose}
          width={'40%'}
          title={`${
            mode === 'insert' ? 'Cadastrar' : 'Atualizar'
          } Perfil de Acesso`}
        >
          <Spin spinning={loading}>
            <Form
              form={form}
              layout='vertical'
              initialValues={{
                contracts: 'contracts_list',
                os: 'os_list',
                users: 'users_list',
                clients: 'clients_list',
              }}
              onFinish={(value) => {
                const permissions = {
                  contracts: value.contracts || [],
                  os: value.os || [],
                  users: value.users || [],
                  clients: value.clients || [],
                }
                value.permissions = permissions
                setLoading(true)
                const data = { ...value }
                if (mode === 'insert') {
                  data.roleName = data.roleName.toUpperCase()
                  api
                    .post('/roles', data)
                    .then(() => {
                      setLoading(false)
                      message.success('Cadastro realizado com sucesso!')
                      onClose()
                    })
                    .catch((e) => {
                      setLoading(false)
                      if (e?.response?.data) {
                        message.error(e.response.data)
                        return
                      }
                      if (e.response.data.errors) {
                        e.response.data.errors.forEach((info) => {
                          message.error(info.message)
                        })
                      } else {
                        message.error(e.response.data.error)
                      }
                    })
                } else {
                  data.roleName = data.roleName.toUpperCase()
                  api
                    .put(`/roles/${cargo.id}`, data)
                    .then(() => {
                      setLoading(false)
                      message.success('Cadastro atualizado com sucesso!')
                      onClose()
                    })
                    .catch((e) => {
                      setLoading(false)
                      if (e.response.data.errors) {
                        e.response.data.errors.forEach((info) => {
                          message.error(info.message)
                        })
                      } else {
                        message.error(e.response.data.error)
                      }
                    })
                }
              }}
            >
              <Form.Item
                label='Nome'
                name='roleName'
                rules={[
                  {
                    required: true,
                    message: 'Por favor, o nome do cargo deve ser informado',
                  },
                ]}
              >
                <Input size='large' />
              </Form.Item>
              <Form.Item
                label='Descrição'
                name='description'
                rules={[
                  {
                    required: true,
                    message:
                      'Por favor, a descrição do cargo deve ser informada',
                  },
                ]}
              >
                <Input size='large' />
              </Form.Item>
              <Divider style={{ borderColor: '#bbb' }} />
              {Object.keys(permissionsOptions).map(function (key, index) {
                const opcoesInterna = []
                for (const perm of permissionsOptions[key]) {
                  opcoesInterna.push(perm)
                }
                return (
                  <Form.Item
                    key={index}
                    name={key}
                    label={labelPermissions[key].toUpperCase()}
                  >
                    <Checkbox.Group options={opcoesInterna} />
                  </Form.Item>
                )
              })}
              <Divider style={{ borderColor: '#bbb' }} />
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Enviar
                  disabled={mode === 'view'}
                  type='primary'
                  htmlType='submit'
                  size='large'
                  style={{ flex: 1 }}
                >
                  {mode === 'insert' ? 'Cadastrar' : 'Atualizar'}
                </Enviar>
                <Cancelar
                  danger
                  onClick={onClose}
                  size='large'
                  style={{ flex: 1 }}
                >
                  Cancelar
                </Cancelar>
              </div>
            </Form>
          </Spin>
        </Drawer>
      </Spin>
    </Container>
  )
}

export default Cargos
