import React, { useEffect, useState } from 'react'
import {
  message,
  Table,
  Button,
  Drawer,
  Modal,
  Typography,
  Form,
  Spin,
  Select,
  Upload,
  Tag,
  Tabs,
  Input,
  Space,
  Tooltip,
  Popconfirm,
  InputNumber,
  Switch,
} from 'antd'
import {
  DeleteOutlined,
  DownloadOutlined,
  FileAddOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import DatePicker from 'components/DatePicker'
import { useHistory, useLocation } from 'react-router-dom'
import { Container, PageTitle } from 'components'
import { Enviar, Cancelar } from 'components/Buttons'
import { format, parseISO } from 'date-fns'
import api from 'services/api'
import { TOKEN_API } from 'contexts/auth'

const { Column } = Table
const { Text, Paragraph } = Typography
const { TabPane } = Tabs

function Aditivos() {
  const location = useLocation()
  const [contract, setContract] = useState(location.state)
  const token = localStorage.getItem(TOKEN_API)

  const history = useHistory()
  const [form] = Form.useForm()
  const [itens, setItens] = useState([])
  const [aditivosValores, setAditivosValores] = useState([])
  const [aditivosItems, setAditivosItems] = useState([])
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [visible, setVisible] = useState(false)
  const [visibleValue, setVisibleValue] = useState(false)
  const [visibleItem, setVisibleItem] = useState(false)
  const [table, setTable] = useState('tempo')
  const [mode, setMode] = useState('insert')
  const [load, setLoad] = useState(false)
  const [item] = useState(null)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    async function loadData() {
      setLoading(true)
      try {
        await api
          .get(`/historicoaditivocontrato?contractId=${contract.id}`)
          .then(({ data }) => {
            setItens(data)
          })
        await api
          .get(`/historicoaditivocontratovalores?contractId=${contract.id}`)
          .then(({ data }) => {
            setAditivosValores(data)
          })
        await api
          .get(`/historicoaditivocontratoitems?contractId=${contract.id}`)
          .then(({ data }) => {
            console.log(data)
            setAditivosItems(data)
          })

        setContract(contract)
      } catch (error) {
        message.error('Erro ao carregar os dados, atualize a pagina')
      } finally {
        setLoading(false)
      }
    }
    loadData()
  }, [load])

  const onClose = () => {
    form.resetFields()
    setMode('insert')
    setVisible(false)
  }

  const beforeUpload = (file) => {
    const validFormat = file.type === 'application/pdf'
    if (!validFormat) {
      message.error('Envie somente arquivos do formato PDF')
    }
    return validFormat
  }

  const openDrawerInsert = () => {
    if (table === 'tempo') {
      setVisible(true)
    } else if (table === 'valor') {
      setVisibleValue(true)
    } else if (table === 'item') {
      setVisibleItem(true)
    }
  }

  function callback(key) {
    if (key === '1') {
      setTable('tempo')
    }
    if (key === '2') {
      setTable('valor')
    }
    if (key === '3') {
      setTable('item')
    }
  }

  return (
    <Container>
      <PageTitle
        onBack={() =>
          history.push({
            pathname: `/contratos/`,
            state: contract,
          })
        }
        title={`Aditivos do contrato: ${contract?.name ? contract.name : ''}`}
        extra={[
          <Button
            icon={<FileAddOutlined />}
            onClick={() => {
              setMode('insert')
              openDrawerInsert()
            }}
            key='1'
          >
            Adicionar Aditivo
          </Button>,
        ]}
      />
      <div className='card-container'>
        <Tabs type='card' defaultActiveKey='1' onChange={callback}>
          <TabPane tab='Aditivos de Tempo' key='1'>
            <Table
              dataSource={itens}
              loading={loading}
              rowKey={(record) => record.id}
              size='middle'
              style={{ margin: 20 }}
            >
              <Column title='Número' dataIndex='id' key='id' />
              <Column
                title='Historico Data'
                dataIndex='historicoData'
                key='historicoData'
                render={(_, record) =>
                  format(parseISO(record.historicoData), 'dd/MM/yyyy')
                }
              />

              <Column
                title='Aditivo Data'
                dataIndex='aditivoData'
                key='aditivoData'
                render={(_, record) =>
                  format(parseISO(record.aditivoData), 'dd/MM/yyyy')
                }
              />
              <Column
                title='Tipo'
                dataIndex='tipo'
                key='tipo'
                render={(_, record) =>
                  record.tipo === 'aditivo' ? (
                    <Tag color='red'>Aditivo</Tag>
                  ) : (
                    <Tag color='purple'>Renovação</Tag>
                  )
                }
              />
            </Table>
          </TabPane>
          <TabPane tab='Aditivos de Valores' key='2'>
            <Table
              dataSource={aditivosValores}
              loading={loading}
              rowKey={(record) => record.id}
              size='middle'
              style={{ margin: 20 }}
            >
              <Column title='Número' dataIndex='id' key='id' />
              <Column
                title='Aditivo'
                dataIndex='aditivo'
                key='historicoData'
                render={(_, record) => (
                  <Tag color={'green'}>{record.aditivo}%</Tag>
                )}
              />

              <Column
                title='Data do Aditivo'
                dataIndex='createdAt'
                key='createdAt'
                render={(_, record) =>
                  format(parseISO(record.createdAt), 'dd/MM/yyyy')
                }
              />
              <Column
                title='Ações'
                dataIndex='actions'
                key='actions'
                render={(_, record) => (
                  <Space>
                    {aditivosValores[aditivosValores.length - 1].id ===
                      record.id && (
                      <Tooltip title='Deletar' placement='top'>
                        <Popconfirm
                          title='Tem certeza que quer remover esse aditivo??'
                          onConfirm={() => {
                            api
                              .delete(
                                `/historicoaditivocontratovalores/${record.id}`
                              )
                              .then(() => {
                                message.success(
                                  'Cadastro deletado com sucesso!'
                                )
                              })
                              .catch((e) => {
                                if (e.response.data.errors) {
                                  e.response.data.errors.forEach((info) => {
                                    message.error(info.message)
                                  })
                                } else {
                                  message.error(e.response.data.error)
                                }
                              })
                              .finally(() => setLoad(!load))
                          }}
                          okText='Sim'
                          cancelText='Não'
                        >
                          <Button icon={<DeleteOutlined />}>Deletar</Button>
                        </Popconfirm>
                      </Tooltip>
                    )}
                  </Space>
                )}
              />
            </Table>
          </TabPane>
          <TabPane tab='Aditivos de Itens' key='3'>
            <Table
              dataSource={aditivosItems}
              loading={loading}
              rowKey={(record) => record.id}
              size='middle'
              style={{ margin: 20 }}
            >
              <Column title='Número' dataIndex='id' key='id' />
              <Column
                title='Aditivo'
                dataIndex='aditivo'
                key='historicoData'
                render={(_, record) => (
                  <Tag color={'green'}>{record?.quantidade}</Tag>
                )}
              />
              <Column
                title='Tipo'
                dataIndex='tipo'
                key='tipo'
                render={(_, record) => (
                  <Tag color={'blue'}>
                    {record?.tipo === 'quantity' ? 'QUANTIDADE' : 'PORCENTAGEM'}
                  </Tag>
                )}
              />
              <Column
                title='Data do Aditivo'
                dataIndex='createdAt'
                key='createdAt'
                render={(_, record) =>
                  format(parseISO(record.createdAt), 'dd/MM/yyyy')
                }
              />
              <Column
                title='Ações'
                dataIndex='actions'
                key='actions'
                render={(_, record) => (
                  <Space>
                    {aditivosItems[aditivosItems.length - 1].id ===
                      record.id && (
                      <Tooltip title='Deletar' placement='top'>
                        <Popconfirm
                          title='Tem certeza que quer remover esse aditivo??'
                          onConfirm={() => {
                            api
                              .delete(
                                `/historicoaditivocontratoitems/${record.id}`
                              )
                              .then(() => {
                                message.success(
                                  'Cadastro deletado com sucesso!'
                                )
                              })
                              .catch((e) => {
                                if (e.response.data.errors) {
                                  e.response.data.errors.forEach((info) => {
                                    message.error(info.message)
                                  })
                                } else {
                                  message.error(e.response.data.error)
                                }
                              })
                              .finally(() => setLoad(!load))
                          }}
                          okText='Sim'
                          cancelText='Não'
                        >
                          <Button icon={<DeleteOutlined />}>Deletar</Button>
                        </Popconfirm>
                      </Tooltip>
                    )}
                  </Space>
                )}
              />
            </Table>
          </TabPane>
        </Tabs>
      </div>
      <Drawer
        visible={visible}
        onClose={onClose}
        width={550}
        destroyOnClose
        title='Adicionar Aditivo ao Contrato'
      >
        <Spin spinning={false}>
          <Form
            form={form}
            layout='vertical'
            onFinish={(value) => {
              const data = {
                ...value,
                contractId: contract.id,
                historicoData: contract.endDate,
                tipo: 'renovacao',
              }
              setSaving(!saving)
              api
                .post('/historicoaditivocontrato', data)
                .then(() => {
                  message.success('Cadastro realizado com sucesso!')
                  onClose()
                  setLoad(!load)
                })
                .catch((e) => {
                  if (e.response.data.errors) {
                    e.response.data.errors.forEach((info) => {
                      message.error(info.message)
                    })
                  } else {
                    message.error(e.response.data.error)
                  }
                })
                .finally(() => setSaving(!saving))
            }}
          >
            <Form.Item
              label='Data'
              name='aditivoData'
              key='aditivoData'
              rules={[
                {
                  required: true,
                  message: 'Por favor, o contrato deve ser informado',
                },
              ]}
            >
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item label='Zerar itens' name='resetItems' key='resetItems'>
              <Switch />
            </Form.Item>

            <Form.Item label='Arquivo' name='contractFile' valuePropName='file'>
              {contract && contract?.contractFile ? (
                <a
                  href={contract.contractFile}
                  target='_blank'
                  rel='noreferrer'
                  download
                >
                  Download Contrato
                </a>
              ) : null}
              <br />
              <Upload
                name='file'
                listType='text'
                action={`${process.env.REACT_APP_API}/upload`}
                headers={{ Authorization: `Bearer ${token}` }}
                onDownload={(file) => window.open(file.response.url)}
                onChange={({ file }) => {
                  if (file.status === 'done') {
                    form.setFieldsValue({
                      contractFile: file.response.url,
                    })
                  }
                }}
                showUploadList={{
                  showDownloadIcon: true,
                  downloadIcon: <DownloadOutlined />,
                }}
                beforeUpload={beforeUpload}
                multiple={false}
              >
                <Button icon={<UploadOutlined />}>Enviar</Button>
              </Upload>
            </Form.Item>

            <Form.Item style={{ justifyContent: 'center' }} name='enviar'>
              <Enviar
                disabled={mode === 'view'}
                type='primary'
                htmlType='submit'
              >
                {mode === 'insert' ? 'Cadastrar' : 'Atualizar'}
              </Enviar>
              <Cancelar danger onClick={onClose}>
                Cancelar
              </Cancelar>
            </Form.Item>
          </Form>
        </Spin>
      </Drawer>
      <Drawer
        visible={visibleValue}
        onClose={() => {
          setVisibleValue(false)
        }}
        width={550}
        destroyOnClose
        title='Adicionar Aditivo de Valor ao Contrato'
      >
        <Spin spinning={false}>
          <Form
            form={form}
            layout='vertical'
            onFinish={(value) => {
              const data = {
                ...value,
                contractId: contract.id,
              }
              setSaving(!saving)
              console.log(data)
              api
                .post('/historicoaditivocontratovalores', data)
                .then(() => {
                  message.success('Cadastro realizado com sucesso!')
                  onClose()
                  setLoad(!load)
                })
                .catch((e) => {
                  if (e.response.data.errors) {
                    e.response.data.errors.forEach((info) => {
                      message.error(info.message)
                    })
                  } else {
                    message.error(e.response.data.error)
                  }
                })
                .finally(() => setSaving(!saving))
              setSaving(!saving)
            }}
          >
            <Form.Item
              label='Porcentagem'
              name='aditivoValor'
              key='aditivoValor'
              rules={[
                {
                  required: true,
                  message: 'Por favor, o contrato deve ser informado',
                },
              ]}
            >
              <Input
                size='large'
                placeholder='Porcentagem do aditivo'
                style={{ width: '100%' }}
                type='number'
              />
            </Form.Item>

            <Form.Item label='Arquivo' name='contractFile' valuePropName='file'>
              {contract && contract?.contractFile ? (
                <a
                  href={contract.contractFile}
                  target='_blank'
                  rel='noreferrer'
                  download
                >
                  Download Contrato
                </a>
              ) : null}
              <br />
              <Upload
                name='file'
                listType='text'
                action={`${process.env.REACT_APP_API}/upload`}
                headers={{ Authorization: `Bearer ${token}` }}
                onDownload={(file) => window.open(file.response.url)}
                onChange={({ file }) => {
                  if (file.status === 'done') {
                    form.setFieldsValue({
                      contractFile: file.response.url,
                    })
                  }
                }}
                showUploadList={{
                  showDownloadIcon: true,
                  downloadIcon: <DownloadOutlined />,
                }}
                beforeUpload={beforeUpload}
                multiple={false}
              >
                <Button icon={<UploadOutlined />}>Enviar</Button>
              </Upload>
            </Form.Item>

            <Form.Item style={{ justifyContent: 'center' }} key='enviar'>
              <Enviar
                disabled={mode === 'view'}
                type='primary'
                htmlType='submit'
              >
                {mode === 'insert' ? 'Cadastrar' : 'Atualizar'}
              </Enviar>
              <Cancelar danger onClick={onClose}>
                Cancelar
              </Cancelar>
            </Form.Item>
          </Form>
        </Spin>
      </Drawer>
      <Drawer
        visible={visibleItem}
        onClose={() => {
          setVisibleItem(false)
        }}
        width={550}
        destroyOnClose
        title='Adicionar Aditivo de Valor ao Contrato'
      >
        <Spin spinning={false}>
          <Form
            form={form}
            layout='vertical'
            onFinish={(value) => {
              const data = {
                ...value,
                contractId: contract.id,
              }
              setSaving(!saving)
              api
                .post('/historicoaditivocontratoitems', data)
                .then(() => {
                  message.success('Cadastro realizado com sucesso!')
                  onClose()
                  setLoad(!load)
                })
                .catch((e) => {
                  if (e.response.data.errors) {
                    e.response.data.errors.forEach((info) => {
                      message.error(info.message)
                    })
                  } else {
                    message.error(e.response.data.error)
                  }
                })
                .finally(() => setSaving(!saving))
              setSaving(!saving)
            }}
          >
            <Form.Item
              label='Tipo'
              name='tipo'
              rules={[
                {
                  required: true,
                  message: 'Por favor, o tipo deve ser informado',
                },
              ]}
            >
              <Select
                size='large'
                style={{ width: '100%' }}
                placeholder='Selecione o tipo de calculo...'
              >
                <Select.Option value={'percentage'}>Porcentagem</Select.Option>
                <Select.Option value={'quantity'}>Quantidade</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label='Quantidade'
              name='quantidade'
              rules={[
                {
                  required: true,
                  message: 'Por favor, a quantidade deve ser informada',
                },
              ]}
            >
              <InputNumber size='large' style={{ width: '100%' }} min={1} />
            </Form.Item>
            <Form.Item style={{ justifyContent: 'center' }}>
              <Enviar
                disabled={mode === 'view'}
                type='primary'
                htmlType='submit'
              >
                {mode === 'insert' ? 'Cadastrar' : 'Atualizar'}
              </Enviar>
              <Cancelar danger onClick={onClose}>
                Cancelar
              </Cancelar>
            </Form.Item>
          </Form>
        </Spin>
      </Drawer>
      <Modal
        title='Aditivo'
        visible={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={null}
      >
        {table === 'tempo' && item && (
          <>
            <Text strong>Número</Text>
            <Paragraph>{item.id}</Paragraph>
            <Text strong>Historico</Text>
            <Paragraph>
              {format(parseISO(item?.historicoData), 'dd/MM/yyyy')}
            </Paragraph>
            <Text strong>Data do Aditivo</Text>
            <Paragraph>
              {format(parseISO(item?.aditivoData), 'dd/MM/yyyy')}
            </Paragraph>
            <Text strong>Tipo</Text>
            <Paragraph>
              {item.tipo === 'aditivo' ? (
                <Tag color='red'>Aditivo</Tag>
              ) : (
                <Tag color='purple'>Renovação</Tag>
              )}
            </Paragraph>
          </>
        )}
      </Modal>
    </Container>
  )
}

export default Aditivos
