import React, { useEffect, useState, useContext } from 'react'
import {
  Drawer,
  Spin,
  Form,
  Input,
  Select,
  Radio,
  Upload,
  message,
  Divider,
  Button,
} from 'antd'
import { Cancelar, Enviar } from 'components/Buttons'
import DatePicker from 'components/DatePicker'
import { parseISO } from 'date-fns'
import api from 'services/api'
import { useParams } from 'react-router-dom'
import AuthContext, { TOKEN_API } from '../../contexts/auth'
import { DrawerTipoEquipamentos } from '../../pages/TipoEquipamentos/Drawer/index'
import { PlusOutlined } from '@ant-design/icons'

const BTUS = [
  7500, 9000, 10000, 12000, 13000, 18000, 22000, 24000, 30000, 32000, 36000,
  48000, 58000, 60000,
]

function DrawerEquipamentos({
  config: { drawer, equipamento, client },
  setVisible,
  onCloseDrawer,
}) {
  const { user } = useContext(AuthContext)
  const [form] = Form.useForm()
  const { clientId } = useParams()
  const token = localStorage.getItem(TOKEN_API)
  const [loading, setLoading] = useState(false)
  const [locais, setLocais] = useState([])
  const [setores, setSetores] = useState([])
  const [unidades, setUnidades] = useState([])
  const [marcas, setMarcas] = useState([])
  const [tiposEquipamento, setTiposEquipamentos] = useState([])
  const [fileListBanner, setFileListBanner] = useState([])
  const [equipmentType, setEquipmentType] = useState({})
  const [modalTipoEquipamentoIsOpen, setModalTipoEquipamentoIsOpen] =
    useState(false)

  const { Option } = Select

  useEffect(() => {
    if (drawer === 'update' && equipamento) {
      loadMarcas()
      loadTipos()
      loadLocais()
      loadSetores(equipamento.localId)
      loadUnidades(equipamento.setorId)
      setFileListBanner([
        {
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: equipamento.image,
          thumbUrl: equipamento.image,
        },
      ])
      form.setFieldsValue({
        ...equipamento,
        fabricacao: parseISO(equipamento.fabricacao),
      })
    }
  }, [drawer])

  const loadTipos = async () => {
    await api.get('/equipmenttype').then(({ data }) => {
      setTiposEquipamentos(data)
      if (equipamento) {
        onSelectEquipmentType(equipamento.equipmentTypeId)
      }
    })
  }

  const loadMarcas = async () => {
    await api.get('/marca').then(({ data }) => {
      setMarcas(data)
    })
  }

  const loadLocais = async () => {
    setLoading(true)
    await api
      .get(`/local`, {
        params: {
          clientId: equipamento?.clientId || client?.id || clientId,
        },
      })
      .then(({ data }) => {
        setLocais(data)
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false))
  }

  const loadSetores = async (localId = null) => {
    if (localId) {
      setLoading(true)
      await api
        .get(`/setor?localId=${localId}`)
        .then(({ data }) => {
          setSetores(data)
        })
        .finally(() => setLoading(false))
    }
  }

  const loadUnidades = async (setorId = null) => {
    if (setorId) {
      setLoading(true)
      await api
        .get(`/unidade?setorId=${setorId}`)
        .then(({ data }) => {
          setUnidades(data)
        })
        .finally(() => setLoading(false))
    }
  }

  const onClose = () => {
    setLoading(false)
    form.resetFields()
    setFileListBanner([])
    setVisible({ drawer: 'none' })
    if (onCloseDrawer) {
      onCloseDrawer()
    }
  }

  const onSubmit = async (value) => {
    setLoading(true)
    drawer === 'insert'
      ? await api
          .post('/equipment', {
            ...value,
            clientId: clientId || client.id,
          })
          .then(onClose)
          .catch(() => setLoading(false))
      : await api
          .put(`/equipment/${equipamento.id}`, {
            ...value,
            clientId: clientId || equipamento.clientId || client.id,
            serialNumber: value.serialNumber ? value.serialNumber : null,
            model: value.model ? value.model : null,
          })
          .then(onClose)
          .catch(() => setLoading(false))
  }

  const beforeUpload = (file) => {
    const validFormat = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!validFormat) {
      message.error('Envie somente arquivos do formato JPG/PNG')
      return false
    }
    return validFormat
  }

  const onSelectEquipmentType = (value) => {
    const type = tiposEquipamento.find((type) => type.id === value)
    setEquipmentType(type)
  }

  return (
    <Drawer
      destroyOnClose
      visible={drawer !== 'none'}
      onClose={onClose}
      width={550}
      title='Adicionar Equipamento ao Cliente'
    >
      <Spin spinning={loading}>
        <Form form={form} layout='vertical' onFinish={onSubmit}>
          <Form.Item
            label='Equipamento'
            name='name'
            rules={[
              {
                required: true,
                message: 'Por favor, o equipamento deve ser informado',
              },
            ]}
          >
            <Input size='large' />
          </Form.Item>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Form.Item
              label='Tipo de equipamento'
              name='equipmentTypeId'
              style={{ width: '100%' }}
              rules={[
                {
                  required: true,
                  message: 'O tipo de equipamento precisa ser informado',
                },
              ]}
            >
              <Select
                size='large'
                style={{ width: '100%' }}
                placeholder='Selecione...'
                optionLabelProp='label'
                optionFilterProp='children'
                onChange={onSelectEquipmentType}
                onDropdownVisibleChange={loadTipos}
              >
                {tiposEquipamento.map((tipo) => (
                  <Option key={tipo.id} label={tipo.name} value={tipo.id}>
                    {tipo.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Button
              size='large'
              style={{
                padding: '0 11px',
                borderColor: '#aaa',
                marginBottom: -5,
              }}
              onClick={() => {
                setModalTipoEquipamentoIsOpen(true)
              }}
            >
              <PlusOutlined />
            </Button>
            <DrawerTipoEquipamentos
              isVisible={modalTipoEquipamentoIsOpen}
              onClose={() => setModalTipoEquipamentoIsOpen(false)}
              loading={loading}
              setLoading={setLoading}
            />
          </div>

          <Form.Item
            label='Local'
            name='localId'
            rules={[
              {
                required: true,
                message: 'O local precisa ser selecionado',
              },
            ]}
          >
            <Select
              size='large'
              placeholder='Selecione...'
              showSearch
              allowClear
              optionLabelProp='label'
              optionFilterProp='children'
              onDropdownVisibleChange={loadLocais}
              onSelect={(value) => {
                loadSetores(value)
              }}
            >
              {locais.map((local) => (
                <Option key={local.id} value={local.id} label={local.name}>
                  {local.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {setores.length > 0 ? (
            <Form.Item label='Unidade' name='setorId'>
              <Select
                size='large'
                placeholder='Selecione...'
                showSearch
                allowClear
                optionLabelProp='label'
                optionFilterProp='children'
                onSelect={(value) => {
                  loadUnidades(value)
                }}
              >
                {setores.map((setor) => (
                  <Option key={setor.id} value={setor.id} label={setor.name}>
                    {setor.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ) : null}
          {unidades?.length > 0 ? (
            <Form.Item label='Setor' name='unidadeId'>
              <Select
                size='large'
                placeholder='Selecione...'
                allowClear
                showSearch
                optionLabelProp='label'
                optionFilterProp='children'
              >
                {unidades.map((unidade) => (
                  <Option
                    key={unidade.id}
                    value={unidade.id}
                    label={unidade.name}
                  >
                    {unidade.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ) : null}
          {equipmentType?.brand ? (
            <Form.Item
              name='marcaId'
              label='Marca'
              rules={[
                {
                  required: true,
                  message: 'Por favor, a marca deve ser informada',
                },
              ]}
            >
              <Select
                size='large'
                placeholder='Selecione...'
                optionLabelProp='label'
                optionFilterProp='children'
                onDropdownVisibleChange={loadMarcas}
              >
                {marcas.map((marca) => (
                  <Option key={marca.id} label={marca.name} value={marca.id}>
                    {marca.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ) : null}
          {equipmentType?.serialNumber ? (
            <Form.Item
              label='Número de série'
              name='serialNumber'
              rules={[
                {
                  required: false,
                  message: 'Por favor, o número de série deve ser informada',
                },
              ]}
            >
              <Input size='large' />
            </Form.Item>
          ) : null}
          {equipmentType?.model ? (
            <Form.Item
              label='Modelo'
              name='model'
              rules={[
                {
                  required: false,
                  max: 255,
                  message: 'Por favor, o modelo deve ser informada',
                },
              ]}
            >
              <Input.TextArea
                size='large'
                autoSize={{ minRows: 3, maxRows: 7 }}
                showCount
                maxLength={255}
              />
            </Form.Item>
          ) : null}
          {equipmentType?.voltage ? (
            <Form.Item name='voltagem' label='Voltagem'>
              <Radio.Group>
                <Radio value='127v'>127 V</Radio>
                <Radio value='240v'>240 V</Radio>
                <Radio value='380v'>380 V</Radio>
              </Radio.Group>
            </Form.Item>
          ) : null}
          {equipmentType?.gas ? (
            <Form.Item name='gas' label='Gás'>
              <Radio.Group>
                <Radio value='R134'>R134a</Radio>
                <Radio value='R404'>R404a</Radio>
                <Radio value='R600'>R600a</Radio>
                <Radio value='R290'>R290</Radio>
                <Radio value='R141'>R141b</Radio>
                <Radio value='R410'>R410a</Radio>
                <Radio value='R22'>R22</Radio>
                <Radio value='R32'>R32</Radio>
                <Radio value='R407'>R407c</Radio>
                <Radio value='Agua'>Água</Radio>
              </Radio.Group>
            </Form.Item>
          ) : null}
          {equipmentType?.date ? (
            <Form.Item name='fabricacao' label='Data de fabricação'>
              <DatePicker />
            </Form.Item>
          ) : null}
          {equipmentType?.btus ? (
            <Form.Item
              label='BTUS'
              name='btus'
              rules={[
                {
                  required: false,
                  message:
                    'Por favor, os BTUS do equipamento deve ser informado',
                },
              ]}
            >
              <Select
                size='large'
                placeholder='Selecione...'
                allowClear
                showSearch
                optionLabelProp='label'
                optionFilterProp='children'
              >
                {BTUS.map((btu) => (
                  <Option
                    key={btu}
                    value={btu}
                    label={btu.toLocaleString('pt-br')}
                  >
                    {btu.toLocaleString('pt-br')}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ) : null}
          {equipmentType?.trs ? (
            <Form.Item
              label='TRs'
              name='trs'
              rules={[
                {
                  required: false,
                  message: 'Por favor, o TRs do equipamento deve ser informado',
                },
              ]}
            >
              <Select
                size='large'
                placeholder='Selecione...'
                allowClear
                showSearch
                optionLabelProp='label'
                optionFilterProp='children'
              >
                <Option value={5}>{5}</Option>
                <Option value={7.5}>{7.5}</Option>
                <Option value={10}>{10}</Option>
                <Option value={12.5}>{12.5}</Option>
                <Option value={15}>{15}</Option>
                <Option value={20}>{20}</Option>
              </Select>
            </Form.Item>
          ) : null}
          {equipmentType?.capacity ? (
            <Form.Item
              label='Litros'
              name='capacity'
              rules={[
                {
                  required: false,
                  message: 'Por favor, o TRs do equipamento deve ser informado',
                },
              ]}
            >
              <Select
                size='large'
                placeholder='Selecione...'
                allowClear
                showSearch
                optionLabelProp='label'
                optionFilterProp='children'
              >
                <Option value={20}>20 Litros</Option>
                <Option value={25}>25 Litros</Option>
                <Option value={50}>50 Litros</Option>
                <Option value={100}>100 Litros</Option>
                <Option value={200}>200 Litros</Option>
                <Option value={350}>350 Litros</Option>
                <Option value={400}>400 Litros</Option>
              </Select>
            </Form.Item>
          ) : null}
          {equipmentType?.ventilation ? (
            <Form.Item label='Ventilação' name='ventilation'>
              <Select
                size='large'
                placeholder='Selecione...'
                allowClear
                showSearch
                optionLabelProp='label'
                optionFilterProp='children'
              >
                <Option value={'Grelahs'}>Grelahs</Option>
                <Option value={'Hi-Waal'}>Hi-Waal</Option>
                <Option value={'Cassete'}>Cassete</Option>
                <Option value={'Piso Teto'}>Piso teto</Option>
              </Select>
            </Form.Item>
          ) : null}
          {equipmentType?.photo ? (
            <Form.Item label='Foto' name='image' valuePropName='image'>
              <Upload.Dragger
                name='file'
                listType='picture'
                fileList={fileListBanner}
                data={{
                  pasta: 'equipamentos',
                  organization: user?.organization?.bucket_name,
                }}
                action={`${process.env.REACT_APP_API}upload`}
                headers={{ Authorization: `Bearer ${token}` }}
                onChange={({ file, fileList }) => {
                  if (file.status !== 'uploading') {
                    form.setFieldsValue({
                      image: file?.response?.url,
                    })
                  }
                  if (file.status === 'done') {
                    message.success(`${file.name} Arquivo enviado com sucesso`)
                  } else if (file.status === 'error') {
                    message.error(`${file.name} file upload failed.`)
                  }
                  if (fileList) {
                    setFileListBanner(fileList)
                  }
                }}
                showUploadList
                beforeUpload={beforeUpload}
                multiple={false}
              >
                <div>
                  <div style={{ marginTop: 8, width: '100%' }}>
                    Selecione ou Arraste o arquivo
                  </div>
                </div>
              </Upload.Dragger>
            </Form.Item>
          ) : null}
          <Divider style={{ borderTopColor: '#aaa' }} />
          <div style={{ display: 'flex', width: '100%' }}>
            <Enviar
              type='primary'
              htmlType='submit'
              style={{ flex: 1 }}
              size='large'
            >
              Adicionar
            </Enviar>
            <Cancelar danger onClick={onClose} style={{ flex: 1 }} size='large'>
              Cancelar
            </Cancelar>
          </div>
        </Form>
      </Spin>
    </Drawer>
  )
}

export { DrawerEquipamentos }
